import React, { ReactNode, useEffect } from "react";

import { useParams } from "react-router-dom";
import { ActivityTableViewRoot } from ".";
import AuditionService from "../audition.service";
import { fileManagerApi } from "../filemanager";
import { ActivityTableContextProvider, useActivityTable } from "./context";

interface ActivityTableRootProps {
	auditions?: any[];
	setReloadSignal: any;
	tabelaCurriculo: any;
	props?: any;
	children?: ReactNode;
}

export default function ActivityTableRoot({
	auditions,
	setReloadSignal,
	tabelaCurriculo,
	children,
	props,
}: ActivityTableRootProps) {
	return (
		<ActivityTableContextProvider>
			{children}
			<ActivityTableCompound
				auditions={auditions}
				setReloadSignal={setReloadSignal}
				tabelaCurriculo={tabelaCurriculo}
			/>
		</ActivityTableContextProvider>
	);
}

export function ActivityTableCompound(props: any) {
	const params = useParams();
	const { setData, getVideo, setOpen, data, getAIEvaluation } =
		useActivityTable();

	useEffect(() => {
		if (params.submission) {
			console.log(JSON.stringify(params));
			new AuditionService(fileManagerApi)
				.getOne(params.submission?.toString())
				.then((res: any) => {
					if (res.data && res.data[0] && res.data[0].file_key) {
						setData(res.data[0]);
						getVideo(res.data[0].file_url);
						setOpen(true);
					}
				})
				.catch((err) => {
					console.log("__--__--__--__");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			data &&
			data.transcription &&
			data.transcription[0] &&
			data.transcription[0].comprehend_sentiment
		) {
			return;
		} else if (data && data.transcription && data.transcription[0]) {
			getAIEvaluation(data.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<React.Fragment>
			<ActivityTableViewRoot.ActivityTableContainer>
				<ActivityTableViewRoot.ActivityTableHeader props={props} />
				<ActivityTableViewRoot.ActivityTableBody props={props} />
			</ActivityTableViewRoot.ActivityTableContainer>
		</React.Fragment>
	);
}
