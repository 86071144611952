import { TableBody } from "@mui/material";
import { ReactNode } from "react";
import { ActivityTableViewRoot } from ".";

interface ActivityTableBodyProps {
	children?: ReactNode;
	props: any;
}

export function ActivityTableBody({ props }: ActivityTableBodyProps) {
	return (
		<TableBody>
			{props.auditions?.length === 0 ? (
				<ActivityTableViewRoot.ActivityEmptyTableRow />
			) : (
				props.auditions &&
				props.auditions.length > 0 &&
				props.auditions.map((row: any) => (
					<ActivityTableViewRoot.ActivityTableRow
						row={row}
						props={props}
					/>
				))
			)}
		</TableBody>
	);
}
