import { Box, CssBaseline } from "@mui/material";
import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import AppHeader from "../components/layout/AppHeader";
import CandidaturasEmpresa from "../pages/Private/Candidaturas/CandidaturasEmpresa";
import Empresas from "../pages/Private/Empresas";
import MeuCurriculo from "../pages/Private/MeuCurriculo";
import Vagas from "../pages/Private/Vagas";
import NotFound from "../pages/Public/NotFound";
import SignUp from "../pages/Public/SignUp";

/**
 *
 * @returns Rotas para usuário do tipo Empresa
 */
const EmpresaRouter = (): React.ReactElement => {
	return (
		<Fragment>
			<CssBaseline />
			<AppHeader>
				<Box
					sx={{
						marginTop: "64px",
					}}
				></Box>
				<Routes>
					<Route path="/" element={<Empresas />} />
					<Route path="/signup" element={<SignUp />} />
					<Route path="/empresas" element={<Empresas />} />
					<Route path="/vagas" element={<Vagas />} />
					<Route path="candidaturas">
						<Route path="" element={<CandidaturasEmpresa />} />
						<Route path=":id" element={<CandidaturasEmpresa />} />
					</Route>
					<Route path="curriculum">
						<Route path=":id" element={<MeuCurriculo />} />
					</Route>
					<Route path="*" element={<NotFound />} />
				</Routes>
			</AppHeader>
		</Fragment>
	);
};

export default EmpresaRouter;
