import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalDeleteUsuarioView from "./ModalDeleteUsuario.view";

function ModalDeleteUsuario(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalDeleteUsuarioView userId={props.userId} dados={props.dados} />
		</ModalContextProvider>
	);
}

export default ModalDeleteUsuario;
