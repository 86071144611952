import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalDeleteTipoContratoView from "./ModalDeleteTipoContrato.view";

function ModalDeleteTipoContrato(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalDeleteTipoContratoView
				tipoContratoId={props.tipoContratoId}
				dados={props.dados}
			/>
		</ModalContextProvider>
	);
}

export default ModalDeleteTipoContrato;
