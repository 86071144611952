import { Business, Close, Work } from "@mui/icons-material";
import {
	Box,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
	IconButton,
	Typography,
	useTheme,
} from "@mui/material";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import ListaDetalhesVagaAluno from "../../../components/Detalhes/DetalhesVagaAluno";
import ModalHeader from "../../../components/Modal/ModalHeader";
import TabelaCandidaturas from "../../../components/Tabelas/TabelaCandidaturas";
import { useAuthContext } from "../../../context/Authentication/hooks/useAuth";
import api from "../../../services/api";
import Notify from "../../../utils/notification";
import IndexedCard from "../HomePage/components/HomePageItemView";

export default function CandidaturasAluno() {
	const params = useParams();
	const { auth } = useAuthContext();
	// alert(JSON.stringify(auth));
	const theme = useTheme();
	const [open, setOpen] = useState<boolean>(false);
	const [job, setJob] = useState<any>({
		vaga: null,
		usuario: null,
		empresa: null,
	});
	const [jobs, setJobs] = useState<any>({
		meta: null,
		data: [],
	});
	const [showList] = useState<boolean>(false);
	const [candidaturaModalStatus, setCandidaturaModalStatus] =
		useState<boolean>(false);

	const [profile, setProfile] = useState<any>(null);
	const loadProfile = async () => {
		const token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		const apiUrl = `${process.env.REACT_APP_URL}/profile`;

		await axios
			.get(`${apiUrl}`, {
				headers: {
					Authorization: "Bearer " + token,
				},
			})
			.then((res: any) => {
				setProfile(res.data[0].student);
			});
	};

	const jobsForStudents = async (value?: number | string) => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		await api
			.get("/jobs-for-student", {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				console.log(res.data);
				setJobs(res.data);
			});
	};

	const [applications, setApplications] = useState<any>(null);
	const getApplications = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		await api
			.get("/applications", {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then(async (res: any) => {
				console.log(res.data);
				var aplicacoes: any[] = [];

				res.data.forEach(async (item) => {
					await api
						.get("/applications/" + item.id, {
							headers: { Authorization: `Bearer ${token}` },
						})
						.then((res: any) => {
							aplicacoes.push(res.data);
						});
				});
				// setApplications(res.data);
				setApplications(aplicacoes);
			});
	};

	const handleCandidaturaStatus = async (item: any) => {
		await loadProfile().then(async () => {
			if (item && item.applications && item.applications.length === 0) {
				await api
					.post("/applications", {
						job_id: item.id,
						ra: profile.student.ra,
					})
					.then(() => {
						Notify("Candidatura submetida com sucesso!", "success");
					})
					.finally(jobsForStudents);
			} else if (
				item &&
				item.applications &&
				item.applications.length > 0
			) {
				item &&
					item.applications &&
					item.applications.forEach(async (i: any) => {
						await api
							.delete(`/applications/${i.id}`)
							.then((res: any) => {
								Notify(
									"Candidatura removida com sucesso",
									"success"
								);
							})
							.catch((err: any) => {
								Notify(
									"Falha ao remover candidatura!",
									"error"
								);
							})
							.finally(() => {
								jobsForStudents();
							});
					});
			}
			setCandidaturaModalStatus(false);
		});
	};

	const getApplicationsByJobId = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		await api
			.get("/jobs/" + params.id, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				setApplications(res.data[0].applications);
			});
	};

	const jobForStudentDetails = async (id: any) => {
		// alert(JSON.stringify(id, null, 4));
		localStorage.setItem("id_vaga", id.toString());

		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		if (id === null || id === 0) {
			return Promise.resolve("ID is null  or 0, cancelling the call");
		} else {
			await api
				.get(`/jobs/${id.id}/student`, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((res) => {
					// setJobs(res.data[0]);
					setJob({
						vaga: res.data[0],
						usuario: res.data[0].user,
						empresa: res.data[0].company,
					});
				})
				.finally(() => {
					setOpen(true);
				});
		}
	};

	useEffect(() => {
		if (params.id) getApplicationsByJobId();
		else getApplications();
		jobsForStudents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<Container maxWidth="xl">
				{auth.role !== "ALUNO" ? (
					<TabelaCandidaturas candidaturas={applications} />
				) : (
					<Fragment>
						{/* <pre>{JSON.stringify(jobs.data, null, 4)}</pre> */}
						<Grid container spacing={2} sx={{ mt: 3 }}>
							<Box p={3}>
								<Typography variant="h5">
									Candidaturas
								</Typography>
							</Box>
							{!showList &&
								jobs.data.map((item, index) => {
									return (
										item &&
										item.applications &&
										item.applications.length > 0 && (
											<Grid
												item
												xs={12}
												sm={12}
												md={12}
												lg={12}
												xl={12}
											>
												<IndexedCard
													variant="list_view"
													key={index}
													item={item}
													theme={theme}
													setJob={setJob}
													setJobs={setJobs}
													setOpen={setOpen}
													jobsForStudents={
														jobsForStudents
													}
													jobForStudentDetails={
														jobForStudentDetails
													}
													setCandidaturaModalStatus={
														setCandidaturaModalStatus
													}
												/>
											</Grid>
										)
									);
								})}
							<Dialog open={candidaturaModalStatus}>
								<ModalHeader
									header={`Confirmação de ${
										job &&
										job.applications &&
										job.applications.length > 0
											? "exclusão"
											: "aplicação"
									} de candidatura`}
									icon={<Work />}
									close={() =>
										setCandidaturaModalStatus(false)
									}
								/>
								<DialogContent>
									Deseja confirmar a{" "}
									{job &&
									job.applications &&
									job.applications.length > 0
										? "exclusão"
										: "aplicação"}{" "}
									da candidatura à vaga de:{" "}
									<b>"{job.title}"</b> em <b>{job.company}</b>
									?
									{/* <pre>
											{JSON.stringify(job, null, 4)}
										</pre> */}
								</DialogContent>
								<DialogActions>
									<Button
										variant="text"
										onClick={() => {
											setCandidaturaModalStatus(false);
										}}
									>
										Cancelar
									</Button>
									<Button
										variant="contained"
										disableElevation
										onClick={() => {
											handleCandidaturaStatus(job);
										}}
									>
										Confirmar
									</Button>
								</DialogActions>
							</Dialog>
						</Grid>
					</Fragment>
				)}
			</Container>
			{/* DIALOG com detalhes da vaga */}
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				maxWidth="md"
				sx={{ zIndex: 99999 }}
			>
				<Box
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box p={2} sx={{ display: "flex", gap: "12px" }}>
						<Business />{" "}
						<Typography variant="body1">
							Detalhes da vaga
						</Typography>
					</Box>
					<Box p={1}>
						<IconButton
							sx={{
								color: "white",
							}}
							onClick={() => setOpen(false)}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
				<DialogContent>
					<DialogContentText>
						{/* <pre>{JSON.stringify(job, null, 4)}</pre> */}
						<ListaDetalhesVagaAluno
							vaga={job.vaga}
							usuario={job.usuario}
							empresa={job.empresa}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="text" onClick={() => setOpen(false)}>
						Voltar
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}
