import {
	Add,
	Bookmark,
	BookmarkRemove,
	Close,
	Hail,
	Place,
} from "@mui/icons-material";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import { Fragment } from "react";

export interface ListItemInterface {
	item?: any;
	logo?: any;
	breakpoint?: any;
	theme?: any;
	setJob?: any;
	jobForStudentDetails?: any;
	setCandidaturaModalStatus?: any;
	bookmark?: any;
	deleteBookMark?: any;
	sendBookMark?: any;
}

export function HomePageItemListView({
	item,
	logo,
	breakpoint,
	theme,
	setJob,
	jobForStudentDetails,
	setCandidaturaModalStatus,
	bookmark,
	deleteBookMark,
	sendBookMark,
}: ListItemInterface) {
	return (
		<Fragment>
			<Paper
				className="card"
				sx={{
					mb: 2,
					overflow: "hidden",
				}}
				elevation={2}
			>
				{/* <pre>{JSON.stringify(item, null, 4)}</pre> */}
				<Box
					sx={{
						display: "flex",
						alignItems: "flex-start",
						justifyContent: "space-between",
						gap: "12px",
						position: "relative",
						// flexWrap: {
						// 	xs: "wrap",
						// 	md: "nowrap",
						// },
						// flexDirection: { xs: "column-reverse", md: "row" },
					}}
				>
					<Box
						sx={{
							p: 1,
							width: "180px",
							height: "180px",
							display: breakpoint ? "none" : "flex",
							alignItems: "center",
							justifyContent: "center",
							// border: "solid red 2px",
						}}
					>
						{item && item.logo_image && item.logo_image ? (
							<img
								src={
									item.logo_image ??
									"https://placehold.co/180x150"
								}
								width="156"
								height="auto"
								alt=""
								style={
									{
										// paddingLeft: "12px",
										// marginTop: "4px",
										// borderRadius: "4px",
									}
								}
							/>
						) : (
							<img
								src={
									theme.palette.mode === "dark"
										? "./img/Logo 06.png"
										: "./img/Logo 02.png"
								}
								alt=""
								// width="156"
								height="96px"
								style={{
									paddingTop: "12px",
									marginBottom: "24px",
								}}
							/>
						)}
					</Box>
					<Box
						p={2}
						sx={{
							// border: "solid red thin",
							flexGrow: 1,
							height: "100%",
						}}
					>
						<Typography variant="body1">
							{item && item.title} (
							{item &&
								item.contract_type &&
								item.contract_type.name}
							)
						</Typography>
						<Typography
							variant="body1"
							sx={{
								color: "gray",
							}}
						>
							{item.company}
						</Typography>
						<Typography
							variant="body2"
							sx={{
								mt: 1,
								display: "flex",
								alignItems: "center",
								gap: "12px",
								color: "gray",
							}}
						>
							<Place />
							{item.city} - {item.state}
						</Typography>
						<Box
							sx={{
								mt: 2,
								mb: 3,
								// border: "solid red",
								display: "flex",
								alignItems: "flex-end",
								justifyContent: "flex-start",
								flexWrap: {
									xs: "wrap",
									md: "nowrap",
								},
								gap: "12px",
							}}
						>
							{/* Candidatar-se */}
							<Button
								variant="text"
								startIcon={<Add />}
								// sx={{ color: "black" }}
								onClick={async () => {
									setJob(item);
									jobForStudentDetails(item, item.id);
								}}
							>
								Detalhes
							</Button>
							<Button
								variant="contained"
								className="apply-button"
								disableElevation
								startIcon={
									item &&
									item.applications &&
									item.applications.length > 0 ? (
										<Close />
									) : (
										<Hail />
									)
								}
								// onClick={handleCandidaturaStatus}
								onClick={() => {
									setJob(item);
									setCandidaturaModalStatus(true);
								}}
								sx={{
									width: "250px",
								}}
							>
								{item &&
								item.applications &&
								item.applications.length > 0
									? "Cancelar candidatura"
									: "Candidatar-se"}
							</Button>
						</Box>
					</Box>
					<Box>
						<IconButton
							className="bookmark-icon-button"
							onClick={() =>
								bookmark ? deleteBookMark() : sendBookMark()
							}
						>
							{bookmark ? (
								<BookmarkRemove className="remove-bookmark-icon" />
							) : (
								<Bookmark className="add-bookmark-icon" />
							)}
						</IconButton>
					</Box>
				</Box>
			</Paper>
		</Fragment>
	);
}
