import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Backdrop,
	CircularProgress,
	Divider,
	IconButton,
	InputAdornment,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/Authentication/hooks/useAuth";
import { postLogin } from "../../../services/login";

function Copyright(props: any) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link color="inherit" to="https://mui.com/">
				UNINTA
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

export default function SignInAdministrador() {
	const theme = useTheme();

	const navigate = useNavigate();
	const { setAuth } = useAuthContext();

	const [open, setOpen] = React.useState<boolean>(false);
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	/**
	 * @function login
	 * Faz a chamda da função postLogin passando data por paramentro, caso a requisição der certo seta data recebido da requisição no AuthContext, seta um valor no sessionStorage com o nome da chave de token e faz uma mudança de rota para /.
	 * @param {LoginData} data -
	 */
	async function login(data: any) {
		setOpen(true);
		postLogin(data)
			.then((data) => {
				localStorage.setItem("@app-jobs:token", JSON.stringify(data));
				setAuth(data);
				navigate("/");
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => setOpen(false));
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		login({
			email: data.get("email"),
			password: data.get("password"),
		});
	};

	const [showPassword, setShowPassword] = React.useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	return (
		<React.Fragment>
			<Backdrop open={open} style={{ zIndex: 10000 }}>
				<CircularProgress />
			</Backdrop>
			<Grid container>
				<Grid item md={6} sx={{ display: matches ? "" : "none" }}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							height: "100vh",
							// backgroundColor: "#f8f8f8",
							// backgroundColor: theme.palette.primary.main,
							gap: "16vh",
						}}
					>
						<Typography
							variant="h5"
							style={{
								color: "#333 !important",
								textAlign: "center",
								fontWeight: "500",
							}}
						>
							Conecte-se com mais de 30 mil talentos
							<br /> em todo o Brasil
						</Typography>
						<img
							alt="aiamis"
							src="https://dirin.s3.amazonaws.com/ecossistema_v5/assets/search-rh_Pranchet.svg"
							className="imgPrancheta"
							width="60%"
						/>
						<img
							alt="Instituições"
							src="img/Logos Instituições b-01-1.svg"
							style={{
								width: "60%",
							}}
						/>
					</Box>
				</Grid>
				<Grid item md={6}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							height: "100vh",
						}}
						p={3}
					>
						<img
							src={
								theme.palette.mode === "dark"
									? "./img/Logo 06.png"
									: "./img/Logo 02.png"
							}
							alt=""
							height="120"
							style={{ paddingTop: "12px", marginBottom: "24px" }}
						/>
						<Typography component="h1" variant="h5">
							Anuncie suas vagas
						</Typography>
						<Box
							component="form"
							onSubmit={handleSubmit}
							noValidate
							sx={{ mt: 1 }}
						>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email"
								name="email"
								autoComplete="email"
								autoFocus
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Senha"
								type={showPassword ? "text" : "password"}
								id="password"
								autoComplete="current-password"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={
													togglePasswordVisibility
												}
											>
												{showPassword ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<FormControlLabel
								control={
									<Checkbox
										value="remember"
										color="primary"
									/>
								}
								label="Permanecer conectado"
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								disableElevation
								sx={{ mt: 3, mb: 2 }}
							>
								Continuar
							</Button>
							<Grid container>
								{/* TODO: Verificar se o recurso de recuperar senha está disponível */}
								{/* <Grid item xs={12}>
									<Link
										to="#"
										style={{
											textDecoration: "none",
											color: theme.palette.primary.main,
										}}
									>
										Esqueceu sua senha?
									</Link>
								</Grid> */}
								<Grid item xs={12}>
									<Link
										to="/signup"
										style={{
											textDecoration: "none",
											color: theme.palette.primary.main,
										}}
									>
										{"Não possui uma conta? Inscreva-se"}
									</Link>
								</Grid>
							</Grid>
						</Box>
						<Divider />
						<Box mt={5}>
							<Link
								to="/"
								style={{
									textDecoration: "none",
									color: theme.palette.primary.main,
								}}
							>
								{"Continuar como aluno"}
							</Link>
						</Box>
						<Copyright sx={{ mt: 8, mb: 4 }} />
					</Box>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
