import React from "react";
import TabelaUsuarios from "../../../components/Tabelas/TabelaUsuarios";

export default function UsuariosUsuarios(): React.ReactElement {
	return (
		<>
			<TabelaUsuarios />
		</>
	);
}
