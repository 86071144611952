import axios, { AxiosInstance } from "axios";

export default class BiometryService {

	constructor(private api: AxiosInstance) {
		this.api = axios.create({
			baseURL: process.env.REACT_APP_FILE_MANAGER_API
		})

		this.api.interceptors.request.use((config) => {
			let token: any = JSON.parse(localStorage.getItem("@palco-profissional:token") as string).data.accessToken;
			let user: any = JSON.parse(localStorage.getItem("@palco-profissional:token") as string).data.usuario.id;

			if (token && config.headers) {

				config.headers["Authorization"] = `Bearer ${token}`;
				config.headers["ra"] = user;

			}

			return config;
		});
	}

	// async getAll(ra: string|number): Promise<any> {
	// 	return await this.api.get("/biometrics?ra=" + ra)
	// }

	async getAll(ra: string | number, params?: any) {
		return await this.api.get("/biometrics", {
			params: {
				user_id: ra,
				...params
			}
		})
	}

	async getOne(ra: string | number, id: string | number): Promise<any> {
		return await this.api.get(`/biometrics/${Number(id)}`, {
			params: {
				id: id,
				ra: ra
			}
		})
	}

	async create(data: any): Promise<any> {
		return await this.api.post('/biometrics', data)
	}

	async update(id: number | string, data: any): Promise<any> {
		return await this.api.put(`/biometrics/${Number(id)}`, data);
	}

	async delete(id: number | string): Promise<any> {
		return await this.api.delete(`/biometrics/${Number(id)}`)
	}
}