import { Add, Business, Close, PlaylistRemove } from "@mui/icons-material";
import {
	Box,
	Dialog,
	Divider,
	Grid,
	IconButton,
	Paper,
	Typography,
	useTheme,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useAuthContext } from "../../../../../context/Authentication/hooks/useAuth";
import { useMeuCurriculo } from "../../context";
import Evento from "./Evento";
import EventoForm from "./EventoForm";

/**
 *
 * @returns Retorna o componente de agrupamento de eventos com a lista de eventos separados em grupamentos
 * a partir dos event-types cadastrados e permitidos
 */
export default function Eventos() {
	const theme = useTheme();
	const { auth } = useAuthContext();
	const { gruposDeEvento } = useMeuCurriculo();

	const [open, setOpen] = useState<boolean>(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	// const [gruposDeEvento, setGruposDeEvento] = useState<any | any[]>([]);

	return (
		<Fragment>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<Box
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box p={2} sx={{ display: "flex", gap: "12px" }}>
						<Business />
						<Typography variant="body1">
							Cadastrar novo evento
						</Typography>
					</Box>
					<Box p={1}>
						<IconButton
							sx={{
								color: "white",
							}}
							onClick={handleClose}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
				<EventoForm setOpen={setOpen} evento={undefined} />
			</Dialog>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Box
					mt={3}
					p={3}
					sx={{
						display: "flex",
						alignItems: "center",
						jusitifyContent: "flex-start",
						gap: "12px",
					}}
				>
					<Typography variant="h5">
						Participação em Eventos
					</Typography>
					<IconButton
						onClick={handleOpen}
						size="small"
						sx={{
							bgcolor: theme.palette.primary.main,
							color: "white",
							display:
								auth.role === "admin" ||
								auth.role === "representante_empresa"
									? "none"
									: "",
						}}
					>
						<Add />
					</IconButton>
				</Box>
				{/* <pre>{JSON.stringify(gruposDeEvento, null, 4)}</pre> */}
				{Object.keys(gruposDeEvento).length > 0 && gruposDeEvento ? (
					Object.keys(gruposDeEvento).map(
						(grupo: any, index: number) => {
							return (
								<Fragment key={index}>
									<Box sx={{ paddingLeft: 3 }}>
										<Typography
											variant="body1"
											sx={{
												fontWeight: "600",
												fontSize: "1.2rem",
											}}
										>
											{grupo}
										</Typography>
									</Box>
									{gruposDeEvento[grupo].map((h, index) => {
										return (
											<Evento evento={h} key={index} />
										);
									})}
									<Divider
										sx={{
											mb: 2,
											mt: 2,
										}}
									/>
								</Fragment>
							);
						}
					)
				) : (
					<Box>
						<Paper
							elevation={1}
							sx={{
								minHeight: "250px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
							}}
						>
							<PlaylistRemove
								sx={{
									width: "64px",
									height: "auto",
									mb: 3,
									color: "gray",
								}}
							/>
							<Typography sx={{ p: 2, textAlign: "center" }}>
								Nenhum evento encontrado
							</Typography>
						</Paper>
					</Box>
				)}
			</Grid>
		</Fragment>
	);
}
