import { Warning } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { Empresa } from "../../../../models/Empresa";
import {
	deleteEmpresa,
	getEmpresa,
} from "../../../../services/crud/crud.empresa";
import Notify from "../../../../utils/notification";
import ModalHeader from "../../ModalHeader";

function ModalDeleteEmpresaView(props) {
	const navigate = useNavigate();
	let idEmpresaDeletar;
	const { open, handleOpen, handleClose } = useModalContext();
	const { auth } = useAuthContext();
	const [modalDelete, setModalDelete] = useState(false);
	const [enableDelete, setEnableDelete] = useState<boolean>(false);

	/**
	 * @function handleNaoEDeletado
	 * Faz uma verifição se o tipo de contrato foi deletado.
	 * @param {Empresa} empresa - Representa cada empresa.
	 * @returns {boolean} - Verifica os contratos que não foram deletados.
	 */
	function handleNaoEDeletado(empresa: Empresa) {
		return empresa.id !== idEmpresaDeletar;
	}

	/**
	 * @function handleEmpresa
	 * Faz uma mudança de rotas para /vagas
	 */
	function handleEmpresa() {
		localStorage.setItem("id_empresa_filter", props.companieId.toString());
		navigate(`/vagas`);
	}

	/**
	 * @function handleDeletaEmpresa
	 * Verifica se o usuário é um admin ou se está ativo no sistema, se sim salva na variavel empresa a empresa ao qual o usuário quer deletar e em seguida verifica se a quantidade de vagas da empresa é igual a 0, caso sim será salvo uma chave de nome id_empresa_filter com o valor do id da empresa a ser deletada e abrirá o modal questionando se o usuário deseja ver as vagas existentes, caso não será emitido um Notify para a confirmação da deleção. Se o usuário não estiveer ativo ou não for admin será emitido um Notify.
	 * @param { number} id - Representa o id da empresa.
	 * @param { number} isActive - Representa se o usuário esta ativo, recebendo 1 para ativo e 0 para desativo.
	 */
	async function handleDeletaEmpresa(id: number, isActive: number) {
		var active = 0;
		var empresa: any = null;
		if (isActive) {
			active = isActive;
		}
		if (!isActive) {
			await getEmpresa(id).then(async (res: any) => {
				// alert(JSON.stringify(res));
				// alert(JSON.stringify(res.user));
				active = res.user.is_active;
				empresa = res;
			});
		}

		if (
			(active === 1 || active === 1 || auth.role === "admin") &&
			empresa !== null
		) {
			//   empresa = props.dados.tableRows.empresas.filter((empresa: Empresa) => {
			//     return empresa.id === id;
			//   });

			if (empresa.jobs.length > 0) {
				handleOpen();
			} else {
				setModalDelete(!modalDelete);
			}
		} else {
			Notify(
				"Você não está ativo no sistema, aguarde sua ativação!",
				"warning"
			);
		}
	}

	function ConfirmDeletaEmpresa(id: number) {
		setModalDelete(!modalDelete);
		deleteEmpresa(id)
			.then(() => {
				idEmpresaDeletar = id;
				let novoArrayEmpresas =
					props.dados.tableRows.empresas.filter(handleNaoEDeletado);
				props.dados.setTableRows({ empresas: novoArrayEmpresas });
			})
			.catch((e) => {
				console.log(e);
			});
	}

	return (
		<>
			{/* <Backdrop
        open={true}
        sx={{
          zIndex: 99999,
        }}
      >
        <CircularProgress />
      </Backdrop> */}
			<Button
				onClick={() => {
					handleDeletaEmpresa(props.companieId, props.isActive);
				}}
				title="Deletar"
				data-testid="modal-delete-empresa"
			>
				<DeleteForeverIcon style={{ color: "#292B2D" }} />
			</Button>

			<Dialog maxWidth="lg" open={open}>
				<DialogTitle>
					{"Você não pode deletar a empresa caso a vagas existentes"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Quer ver as vagas existentes?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "end",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							style={{
								backgroundColor: "#B50000",
								color: "white",
								height: "40px",
								width: "20%",
								marginRight: "20px",
							}}
							onClick={handleClose}
						>
							Não
						</Button>
						<Button
							variant="contained"
							color="primary"
							style={{
								backgroundColor: "#B50000",
								color: "white",
								height: "40px",
								width: "20%",
								marginRight: "20px",
							}}
							onClick={() => {
								handleEmpresa();
							}}
						>
							Sim
						</Button>
					</div>
				</DialogActions>
			</Dialog>
			<Dialog open={modalDelete}>
				<ModalHeader header={"Excluir empresa"} icon={<Warning />} />
				<DialogContent>
					<Box
						sx={{
							display: "flex",
							alignItems: "flex-start",
							gap: "12px",
						}}
					>
						<Typography
							variant="body1"
							sx={{
								mb: 3,
							}}
						>
							<b>Atenção! Esta é uma ação permanente</b>.
							<br /> Para prosseguir com a exclusão da empresa
							digite "excluir" e clique em confirmar.
						</Typography>
					</Box>
					<FormControl fullWidth>
						<TextField
							name="confirma_delete"
							label=""
							placeholder="Excluir"
							onChange={(e) => {
								if (
									e.target.value.toLowerCase() === "excluir"
								) {
									setEnableDelete(true);
								} else {
									setEnableDelete(false);
								}
							}}
						/>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						color="primary"
						onClick={() => {
							setModalDelete(!modalDelete);
						}}
					>
						Não
					</Button>
					<Button
						variant="contained"
						disableElevation
						disabled={!enableDelete}
						color="primary"
						onClick={() => {
							ConfirmDeletaEmpresa(props.companieId);
						}}
					>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
export default ModalDeleteEmpresaView;
