import { SessionProvider } from "../../../../context/session/SessionProvider";
import AuditionComponent from "./audition.component.template";

export default function AuditionModule() {
	return (
		<>
			<SessionProvider>
				<AuditionComponent />
			</SessionProvider>
		</>
	);
}
