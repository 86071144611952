import { pink } from "@material-ui/core/colors";
import { createTheme as createMuiTheme } from "@mui/material";
import { orange, red } from "@mui/material/colors";

/**
 * 
 * @param dark 
 * @returns responsável por criar objeto de tema e alternar entre modo claro e modo escuro
 * 
 * - define paleta de cores, fontes tipográficas, formas geométricas e estilo da barra de rolagem
 */
export const createTheme = (dark: boolean) => {
	return createMuiTheme({
		palette: {
			mode: dark ? "dark" : "light",
			background: {
				default: dark ? "#080808" : "#f8f8f8",
			},
			primary: {
				main: dark ? red[900] : red[900],
			},
			secondary: {
				main: dark ? pink["A400"] : orange[500],
			},
		},
		shape: {
			borderRadius: 12,
		},
		// typography: {
		// 	h5: {
		// 		fontFamily: ["Space Grotesk", "sans-serif"].join(),
		// 	},
		// 	h4: {
		// 		fontFamily: ["Space Grotesk", "sans-serif"].join(),
		// 	},
		// },
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					"::-webkit-scrollbar": {
						width: "12px",
					},
					"::-webkit-scrollbar-thumb": {
						backgroundColor: dark ? "#212121" : "#c4c4c4",
					},
				},
			},
		},
	});
};
