import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Typography,
	useTheme,
} from "@mui/material";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { useHomePage } from "../../../../context/HomePage";
import { postTerms } from "../../../../services/terms";

export interface HomePageTermsDialogProps {
	termState?: any;
	theme?: any;
	auth?: any;
	setTermState?: any;
}

export function HomePageTermsDialog() {
	const theme = useTheme();
	const { auth } = useAuthContext();
	const { termState, setTermState } = useHomePage();
	return (
		<Dialog open={termState.dialog} maxWidth="md" sx={{ zIndex: 99999 }}>
			<Box
				style={{
					backgroundColor: theme.palette.primary.main,
					color: "white",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<Box p={2} sx={{ display: "flex", gap: "12px" }}>
					<Typography variant="body1">Termos e Condições</Typography>
				</Box>
			</Box>
			<DialogContent>
				<DialogContentText>
					<div
						dangerouslySetInnerHTML={{
							__html: termState.termText,
						}}
					/>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant="text"
					onClick={() => {
						postTerms({
							user_id: auth.user_id,
							term_id: termState.idTerm,
						}).then(() => {
							setTermState((prevTermState) => ({
								...prevTermState,
								dialog: !prevTermState.dialog,
							}));
						});
					}}
				>
					Aceitar Termo
				</Button>
			</DialogActions>
		</Dialog>
	);
}
