import React from "react";
import { Button, Container, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import "./styles.scss";
import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import Notify from "../../../utils/notification";
import { useParams, useSearchParams } from "react-router-dom"
import { postReset } from "../../../services/login";
import { useNavigate } from "react-router-dom";
import { ResetData } from "../../../models/Login";

function FormularioRecoverPasswordView(): React.ReactElement {
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onSubmit" });
  const useStyles = makeStyles((theme) => ({
    textField: {
      '& label': {
        color: 'grey !important',
      },
      '& label.Mui-focused': {
        color: 'black !important',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'Grey',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'Grey',
        },
        // '&:hover fieldset': {
        //   borderColor: 'blue',
        // },
        '&.Mui-focused fieldset': {
          borderColor: 'Grey',
        },
      },
    }
  }));
  const classes = useStyles();
  const { email } = useParams();
  const [query] = useSearchParams();
  const signature = query.get('signature');
  const navigate = useNavigate();

  /**
    * @function verificarSenha
    * Faz uma verificação de string que representão a senha e a senha de confirmação.
    * @param {string} senha - Representa a senha digitada pelo usuário.
    * @param {string} confirmacao - Representa a confirmção da senha do usuário.
    * @returns Retorna true ou false de acordo com a verificação.
    */
  function verificarSenha(senha: string, confirmacao: string): boolean {
    if (senha !== confirmacao) {
      return false;
    } else {
      return true;
    }
  }

  async function recoverPassword(data: ResetData) {
    if (verificarSenha(data.password, data.passwordConfirm)) {
      postReset(email, signature, data).then(() => {
        navigate("/");
      }).catch((e) => {
      })
    } else {
      Notify('Senhas diferentes!', "warning");
    }
  };

  return (
    <div className="divContainer">
      <div className="divAiamisForgot">
        <img alt="aiamis" src="../../../img/aiamis_Prancheta_1.svg" className="imgAiamisForgot" />
        <h1 className="spanOportunidadesForgot">&nbsp; | Oportunidades </h1>
      </div>
      <Container component="section" className="containerForgot" data-testid="formulario-cadastro-usuario">
        <form onSubmit={handleSubmit((data) => recoverPassword({
          password: data.password,
          passwordConfirm: data.passwordConfirm,
        }))}>
          <div>
            <Typography style={{ marginTop: "30px" }} className="titleCampSignUp">Nova senha</Typography>
            <TextField
              className={classes.textField}
              placeholder="Nova senha"
              type="password"
              id="senha"
              fullWidth
              margin="none"
              {...register("password", {
                required: true,
                minLength: {
                  value: 8,
                  message: "A senha tem que ter no mínimo 8 dígitos",
                },
              })}
              error={errors.password?.type === "required"}
              helperText={errors.password?.type === "required" && <span>O campo "Senha" é obrigatório!</span>}
            />
            {errors.password?.message !== "" && <span style={{
              color: "#d32f2f", fontSize: "0.75rem", margin: "0px 0px 0px 15px"
            }}
            >{errors.password?.message}</span>}
          </div>

          <div>
            <Typography style={{ marginTop: "30px" }} className="titleCampSignUp">Confirmar nova senha</Typography>
            <TextField
              className={classes.textField}
              placeholder="Confirmar nova senha"
              type="password"
              id="senhaConfirme"
              fullWidth
              margin="none"
              {...register("passwordConfirm", {
                required: true, minLength: {
                  value: 8,
                  message: "A senha tem que ter no mínimo 8 dígitos",
                },
              })}
              error={errors.passwordConfirm?.type === "required"}
              helperText={errors.passwordConfirm?.type === "required" && <span>O campo "Confirmar Senha" é obrigatório!</span>}
            />
            {errors.passwordConfirm?.message !== "" && <span
              style={{
                color: "#d32f2f", fontSize: "0.75rem", margin: "-5px 0px 0px 17px"
              }}
            >{errors.passwordConfirm?.message}</span>}
          </div>


          <Box className="boxButton">
            <Button type="submit" className="buttonForgot">
              Redefinir senha
            </Button>
          </Box>
        </form>
      </Container>
    </div>
  );
}

export default FormularioRecoverPasswordView;
