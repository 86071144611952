import { Container } from "@mui/material";
import { ElementType, Fragment, ReactNode } from "react";
import { useHomePage } from "../../../../context/HomePage";
import { HomePageBackdrop } from "./HomePageBackdrop";

export interface HomePageRootProps {
	children?: ReactNode;
	backdrop?: ElementType;
}
export function HomePageRoot({
	children,
	backdrop: Backdrop,
}: HomePageRootProps) {
	const { showBackdrop } = useHomePage();

	return (
		<Fragment>
			<HomePageBackdrop showBackdrop={showBackdrop} />
			<Container maxWidth={"xl"}>{children}</Container>
		</Fragment>
	);
}
