import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Badge } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import Notify from "../../../../utils/notification";
import FormularioCadastroVaga from "../../../Formularios/Cadastro/FormularioCadastroVaga";
import ModalHeader from "../../ModalHeader";
import "../styles.scss";

function ModalCadastroVagaView(props) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down(700));
	const lg = useMediaQuery(theme.breakpoints.up("sm"));
	const { auth } = useAuthContext();
	const { open, handleOpen } = useModalContext();

	/**
	 * @function userAtive
	 * Verifica se o usuário logado é um admin ou se está ativo no sistema, se sim abre o modal de FormularioCadastroVaga, senão emite um Notify.
	 * @param {number} id - Representa o role do usuário logado no sistema.
	 */
	function userAtive(id: number) {
		if (id === 1 || auth.role === "admin") {
			handleOpen();
		} else {
			Notify(
				"Você não esta ativo no sistema, aguarde sua ativação",
				"warning"
			);
		}
	}

	return (
		<div className="divButtonModalCadastro">
			<Button
				variant="contained"
				size="large"
				endIcon={<AddIcon />}
				data-testid="modal-cadastro-vaga"
				onClick={() => {
					userAtive(props.isActive);
				}}
			>
				ADICIONAR VAGA
			</Button>
			<Dialog
				fullScreen={fullScreen}
				fullWidth
				maxWidth={lg === true ? "md" : false}
				open={open}
				// onClose={handleClose}
			>
				<ModalHeader header="Cadastrar vaga" icon={<Badge />} />
				<DialogContent>
					<FormularioCadastroVaga />
				</DialogContent>
			</Dialog>
		</div>
	);
}
export default ModalCadastroVagaView;
