import { Box, Container, Grid, Paper, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import FormComponent from "../Cadastro/FormComponent";
import "./styles.scss";

function FormularioSignUpView() {
	const theme = useTheme();
	const navigate = useNavigate();

	// const [etapaAtual, setEtapaAtual] = useState(0);
	// const formularios = [
	//   <FormularioDadosUsuarioView avancarEtapa={proximo} />,
	//   <FormularioDadosEmpresaView
	//     avancarEtapa={proximo}
	//     retrocederEtapa={anterior}
	//   />,
	// ];

	/**
	 * @function proximo
	 * Atualiza o step setando +1 no valor da etapaAtual.
	 */
	// function proximo() {
	//   setEtapaAtual(etapaAtual + 1);
	// }

	/**
	 * @function anterior
	 * Atualiza o step setando -1 no valor da etapaAtual
	 */
	// function anterior() {
	//   setEtapaAtual(etapaAtual - 1);
	// }

	return (
		<>
			<Box
				data-testid="formulario-sign-up"
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: "12px",
					p: 3,
				}}
			>
				<img
					src={
						theme.palette.mode === "dark"
							? "./img/Logo 06.png"
							: "./img/Logo 02.png"
					}
					alt=""
					height="160"
					style={{ paddingTop: "12px", cursor: "pointer" }}
					onClick={() => {
						navigate("/");
					}}
				/>
			</Box>
			{/* <div className="divStepper">
        <Stepper className="stepper" activeStep={etapaAtual} alternativeLabel>
          <Step>
            <StepLabel>Dados Pessoais</StepLabel>
          </Step>
          <Step>
            <StepLabel>Dados da Empresa</StepLabel>
          </Step>
        </Stepper>
        {formularios[etapaAtual]}
      </div> */}
			<Container style={{ marginBottom: "20px" }}>
				<Grid container>
					<Grid item md={3} />
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
						<Paper
							elevation={0}
							sx={{
								mt: 5,
								border: "solid thin #ddd",
								borderRadius: "12px",
								p: 3,
							}}
						>
							<FormComponent />
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default FormularioSignUpView;
