import { Close, VideoCall } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";

export function ActivityViewerDialogHeader({ theme, setEnabled, setOpen }) {
	return (
		<>
			{/* Header do diálogo */}
			<Box
				style={{
					backgroundColor: theme.palette.primary.main,
					color: "white",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<Box p={2} sx={{ display: "flex", gap: "12px" }}>
					<VideoCall />
					<Typography variant="body1">Dados da submissão</Typography>
				</Box>
				<Box p={1}>
					<IconButton
						sx={{
							color: "white",
						}}
						onClick={() => {
							setEnabled(false);
							setOpen(false);
							// if (
							// 	!state.user.professor &&
							// 	!state.user.moodle_admin
							// ) {
							// 	window.location.href = "/student";
							// }
						}}
					>
						<Close />
					</IconButton>
				</Box>
			</Box>
		</>
	);
}
