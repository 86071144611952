import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import FormularioCadastroTiposContrato from "../../../Formularios/Cadastro/FormularioCadastroTiposContrato";
import ModalHeader from "../../ModalHeader";
import "../styles.scss";

function ModalCadastroTiposContratoView() {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down(700));
	// const lg = useMediaQuery(theme.breakpoints.up("sm"));
	const { open, handleOpen } = useModalContext();

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				display: "flex",
				justifyContent: "end",
				alignItems: "center",
				marginRight: "5px",
			}}
		>
			<Button
				style={{
					float: "right",
					border: "#BD0E0E solid",
					height: "70%",
					width: "219.34px",
				}}
				className="buttonModalCadastro"
				onClick={handleOpen}
				data-testid="modal-cadastro-tipos-contrato"
			>
				ADICIONAR CONTRATO
				<AddIcon />
			</Button>
			<Dialog
				fullScreen={fullScreen}
				fullWidth
				maxWidth={"lg"}
				open={open}
				className="dialogWidth"
			>
				<ModalHeader header={"Adicionar contrato"} icon={<AddIcon />} />
				<DialogContent>
					<DialogContentText>
						<FormularioCadastroTiposContrato />
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}
export default ModalCadastroTiposContratoView;
