import { Backdrop, CircularProgress } from "@mui/material";

export interface HomePageBackDropProps {
	showBackdrop: boolean;
}

export function HomePageBackdrop({ showBackdrop }: HomePageBackDropProps) {
	return (
		<Backdrop
			open={showBackdrop}
			sx={{
				zIndex: 99999,
			}}
		>
			<CircularProgress />
		</Backdrop>
	);
}
