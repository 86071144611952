import { Container } from "@mui/material";
import React, { Fragment } from "react";
import { getOrientationsAll } from "../../../services/crud/crud.orientacoes";
import CardsView from "./cards";

export default function OrientacoesAluno() {
	const [stateOrientations, setStateOrientations] = React.useState<any>([]);

	React.useEffect(() => {
		getOrientationsAll().then((data) => {
			setStateOrientations(data);
		});
	}, []);

	return (
		<Fragment>
			<Container
				maxWidth="xl"
				style={{
					marginTop: "100px",
					marginBottom: "10vh",
					// border: "solid red",
					// width: "80%",
					display: "flex",
					msFlexDirection: "row",
					gap: "30px",
					flexWrap: "wrap",
					flexDirection: "row",
					alignContent: "center",
					justifyContent: "flex-start",
				}}
			>
				{stateOrientations?.map((orientacao: any) => {
					return (
						<>
							{/* <h1>SX</h1> */}
							<CardsView orientacao={orientacao} />
						</>
					);
				})}
			</Container>
		</Fragment>
	);
}
