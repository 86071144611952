import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Container } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import TabelaCandidaturas from "../../../components/Tabelas/TabelaCandidaturas";
import api from "../../../services/api";

/**
 *
 * @description Tela de candidaturas Empresa e Administrador partilham de views parecidas
 */
export default function CandidaturasEmpresa(): JSX.Element {
	// const { auth } = useAuthContext();
	const history = useNavigate();
	const params = useParams();
	const [applications, setApplications] = useState<any | []>([]);
	const [, setFullApplications] = useState<any | []>([]);

	/**
	 * @description define o estado das aplicações realizadas no sistema
	 */

	const getApplications = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		try {
			await api
				.get("/applications", {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(async (res: any) => {
					res.data.forEach((item: any) => {
						if (item.jobs.length > 0)
							item.jobs.forEach(async (job) => {
								await api
									.get("/jobs/" + job.id, {
										headers: {
											Authorization: `Bearer ${token}`,
										},
									})
									.then((res: any) => {
										// var d = res.data[0];
										if (Array.isArray(res.data)) {
											if (
												res.data[0].applications &&
												res.data[0].applications
													.length > 0
											) {
												setFullApplications((prev) => [
													...prev,
													res.data[0],
												]);
												setApplications((prev) => [
													...prev,
													...res.data[0].applications,
												]);
											}
										} else {
											setFullApplications((prev) => [
												...prev,
												res.data,
											]);
											setApplications((prev) => [
												...prev,
												...res.data.applications,
											]);
										}
									});
							});
					});
				})
				.then(() => {
					toast.success("Listando candidaturas encontradas.");
				});
		} catch (err) {
			toast.error(
				"Falha ao buscar candidaturas! Recarregue a página e tente novamente."
			);
		}
	};

	const getApplicationsByJobId = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		await api
			.get("/jobs/" + params.id, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				if (Array.isArray(res.data)) {
					if (
						res.data[0].applications &&
						Array.isArray(res.data[0].applications)
					) {
						setApplications(res.data[0].applications);
					}
				} else {
					setApplications(res.data.applications);
				}
			});
	};

	useEffect(() => {
		if (params.id) {
			getApplicationsByJobId();
		} else {
			getApplications();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<Box p={2}>
				<Container maxWidth="xl">
					{/* {auth.role} */}
					<Button
						variant="contained"
						disableElevation
						startIcon={<ArrowBack />}
						onClick={() => history(-1)}
						sx={{ mb: 3 }}
					>
						Voltar
					</Button>
					<TabelaCandidaturas
						applications={applications}
						setCandidaturas={setApplications}
					/>
				</Container>
				{/* <pre>{JSON.stringify(applications, null, 4)}</pre> */}
			</Box>
		</Fragment>
	);
}
