import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Backdrop,
	CircularProgress,
	Divider,
	IconButton,
	InputAdornment,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/Authentication/hooks/useAuth";
import { usePalcoProfissional } from "../../../context/PalcoProfissional";
import {
	postLoginAlunoPalcoVirtual,
	postLoginStudent,
} from "../../../services/login";

export default function SignInAluno() {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	const navigate = useNavigate();
	const { dispatch } = usePalcoProfissional();
	const { setAuth } = useAuthContext();

	const [open, setOpen] = React.useState<boolean>(false);
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		login({
			ra: data.get("ra"),
			password: data.get("password"),
		});
	};

	async function login(data: any) {
		setOpen(true);

		try {
			const alunoPalcoVirtualRes = await postLoginAlunoPalcoVirtual(data);

			if (alunoPalcoVirtualRes.status === 401) {
				throw new Error("Unauthorized"); // Throw an error if status is 401
			}

			delete alunoPalcoVirtualRes.config;
			localStorage.setItem(
				"@palco-profissional:token",
				JSON.stringify(alunoPalcoVirtualRes)
			);

			localStorage.setItem(
				"@palco-profissional:state",
				JSON.stringify(alunoPalcoVirtualRes.data)
			);

			dispatch({
				action: "restore_state",
				payload: alunoPalcoVirtualRes.data,
			});

			const studentData = await postLoginStudent(data);
			localStorage.setItem(
				"@app-jobs:token",
				JSON.stringify(studentData)
			);
			setAuth(studentData);
			navigate("/");
		} catch (err) {
			console.error(err);
			toast.error("Falha ao autenticar");
		} finally {
			setOpen(false);
		}
	}

	const [showPassword, setShowPassword] = React.useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	return (
		<React.Fragment>
			<Backdrop open={open} style={{ zIndex: 10000 }}>
				<CircularProgress />
			</Backdrop>
			<Grid container>
				<Grid item md={6} sx={{ display: matches ? "" : "none" }}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							height: "100vh",
							// backgroundColor: "#f8f8f8",
							// backgroundColor: theme.palette.primary.main,
							gap: "16vh",
						}}
					>
						<Typography
							variant="h5"
							style={{
								color: "#333 !important",
								textAlign: "center",
								fontWeight: "500",
							}}
						>
							Potencialize sua carreira explorando oportunidades
							<br /> únicas, abrindo novos caminhos para seu
							crescimento
							<br /> profissional.
						</Typography>
						<img
							alt="aiamis"
							// src="https://dirin.s3.amazonaws.com/ecossistema_v5/assets/search-rh_Pranchet.svg"
							src="https://dirin.s3.amazonaws.com/uninta-jobs-assets/jobs.svg"
							width="80%"
						/>
						<img
							alt="Instituições"
							src="img/Logos Instituições b-01-1.svg"
							style={{
								width: "60%",
							}}
						/>
					</Box>
				</Grid>
				<Grid item md={6}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							height: "100vh",
							p: 3,
						}}
					>
						<img
							src={
								theme.palette.mode === "dark"
									? "./img/Logo 06.png"
									: "./img/Logo 02.png"
							}
							alt=""
							height="120"
							style={{ paddingTop: "12px", marginBottom: "24px" }}
						/>
						<Typography component="h1" variant="h5">
							Descubra novas oportunidades
						</Typography>
						<Box
							component="form"
							onSubmit={handleSubmit}
							noValidate
							sx={{ mt: 1 }}
						>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="RA"
								name="ra"
								autoComplete="ra"
								autoFocus
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Senha"
								type={showPassword ? "text" : "password"}
								id="password"
								autoComplete="current-password"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={
													togglePasswordVisibility
												}
											>
												{showPassword ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<FormControlLabel
								control={
									<Checkbox
										value="remember"
										color="primary"
									/>
								}
								label="Permanecer conectado"
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								disableElevation
								sx={{ mt: 3, mb: 2 }}
							>
								Continuar
							</Button>
							<Grid container>
								<Grid item xs={12}>
									<a
										href="https://academico.aiamis.com.br/Corpore.Net/SharedServices/LibPages/RecoverPassConfirmation.aspx?UserCaption=5LK%255C9F%255C3D%255C023%255C5B&ConfirmationCaption=%255C7B%255CFAbP%255C06%255C11Q%255C7C&RecoverContainerClassName=ASP.login_aspx,%2520App_Web_gi3lseyd,%2520Version%3D0.0.0.0,%2520Culture%3Dneutral,%2520PublicKeyToken%3Dnull&RecoverInitializeMethodName=GetRecoverPassServer&ServiceAlias=CorporeRM"
										target="_blank"
										rel="noopener noreferrer"
										style={{
											textDecoration: "none",
											color: theme.palette.primary.main,
										}}
									>
										Esqueceu sua senha?
									</a>
								</Grid>
								{/* Usuário do tipo aluno não cria conta por meio da plataforma Jobs */}
								{/* <Grid item xs={12}>
									<Link
										to="#"
										style={{
											textDecoration: "none",
											color: theme.palette.primary.main,
										}}
									>
										{"Não possui uma conta? Inscreva-se"}
									</Link>
								</Grid> */}
							</Grid>
						</Box>
						<Divider />
						<Box mt={5}>
							<Link
								to="/login-admin"
								style={{
									textDecoration: "none",
									color: theme.palette.primary.main,
								}}
							>
								{"Continuar como administrador"}
							</Link>
						</Box>
						<Copyright sx={{ mt: 8, mb: 4 }} />
					</Box>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

function Copyright(props: any) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link color="inherit" to="https://mui.com/">
				UNINTA
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}
