import { Business, Close, Delete, Edit } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	FormGroup,
	IconButton,
	Paper,
	Switch,
	Typography,
	useTheme,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { Fragment, useState } from "react";
import ModalHeader from "../../../../../components/Modal/ModalHeader";
import { useAuthContext } from "../../../../../context/Authentication/hooks/useAuth";
import Notification from "../../../../../utils/notification";
import { useMeuCurriculo } from "../../context";
import ExperienciaProfissionalForm from "./ExperienciaProfissionalForm";

export default function ExperienciaProfissional({ experience }: any) {
	const { auth } = useAuthContext();
	const theme = useTheme();
	const [open, setOpen] = useState<boolean>(false);
	const { setSignal } = useMeuCurriculo();
	const [candidaturaModalStatus, setCandidaturaModalStatus] =
		useState<boolean>(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = async (id) => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		const apiUrl = `${process.env.REACT_APP_URL}/professional-experiences`;

		await axios
			.delete(`${apiUrl}/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: AxiosResponse<any>) => {
				Notification(
					"Experiência profissional excluída com sucesso",
					"success"
				);
			})
			.finally(() => {
				setSignal(true);
				setCandidaturaModalStatus(false);
			});
	};

	const [, setSwitchValue] = useState(true); // or whatever the initial value should be

	const handleUpdate = async (id, value) => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		const apiUrl = `${process.env.REACT_APP_URL}/professional-experiences`;

		await axios
			.put(
				`${apiUrl}/${id}`,
				{
					visible: value,
				},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			)
			.then((res: AxiosResponse<any>) => {
				// Notification("Atualizando experiência profissional", "success");
			})
			.finally(() => {
				setSignal(true);
			});

		try {
			// Your existing axios code

			// Update the local state or any other logic you need
			setSwitchValue(value);
			Notification("Atualizando experiência profissional", "success");
		} catch (error) {
			console.error("Error updating API:", error);
		} finally {
			setSignal(true);
		}
	};

	return (auth.role === "admin" || auth.role === "representante_empresa") &&
		experience.visible === 0 ? (
		<Box></Box>
	) : (
		<Fragment>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<Box
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box p={2} sx={{ display: "flex", gap: "12px" }}>
						<Business />
						<Typography variant="body1">
							Editar experiência Profissional
						</Typography>
					</Box>
					<Box p={1}>
						<IconButton
							sx={{
								color: "white",
							}}
							onClick={handleClose}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
				<ExperienciaProfissionalForm
					experience={experience}
					setOpen={setOpen}
				/>
			</Dialog>

			<Dialog open={candidaturaModalStatus}>
				<ModalHeader
					header={`Confirmação de exclusão da experiência profissional`}
					icon={<Delete />}
					close={() => setCandidaturaModalStatus(false)}
				/>
				<DialogContent>
					Deseja confirmar a exclusão da experiência profissional
					cadastrada: <b>"{experience.role}"</b> em{" "}
					<b>{experience.company_name}</b>?
					{/* <pre>
											{JSON.stringify(job, null, 4)}
										</pre> */}
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						onClick={() => {
							setCandidaturaModalStatus(false);
						}}
					>
						Cancelar
					</Button>
					<Button
						variant="contained"
						disableElevation
						onClick={() => {
							console.log("TODO:");
							handleDelete(experience.id);
						}}
					>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
			<Paper elevation={0}>
				{/* <pre>{JSON.stringify(experience, null, 4)}</pre> */}
				<Box m={0} p={2} mb={2}>
					<Box
						sx={{
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "space-between",
							gap: "12px",
							flexWrap: {
								xs: "wrap",
								md: "nowrap",
							},
						}}
					>
						<Box>
							<Typography variant="body1" component="h5">
								Cargo: {experience.role}
							</Typography>
							<Box>
								<Typography>
									{experience.company_name}
								</Typography>
							</Box>
							{/* <Box
						mt={2}
						mb={2}
						sx={{
							display: "flex",
							gap: "12px",
						}}
					>
						<Chip
							label={`${experience.company_name} - ${experience.department}`}
						/>
						<Chip
							label={`${experience.city} - ${experience.state}`}
						/>
					</Box> */}
							<Box
								mt={1}
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "8px",
								}}
							>
								<Typography variant="body1" component="p">
									Período:{" "}
									{new Date(
										experience.started
									).toLocaleDateString()}{" "}
									a{" "}
									{new Date(
										experience.finished
									).toLocaleDateString()}
								</Typography>
								<Typography>
									Setor: {`${experience.department}`}
								</Typography>
								<Typography variant="body1" component="p">
									Descrição: {experience.description}
								</Typography>
							</Box>
							{/* <pre>{JSON.stringify(experience, null, 4)}</pre> */}
						</Box>
						<Box
							sx={{
								// border: "solid teal",
								alignItems: "center",
								justifyContent: "flex-start",
								gap: "6px",
								display:
									auth.role === "admin" ||
									auth.role === "representante_empresa"
										? "none"
										: "flex",
							}}
						>
							<IconButton
								size="small"
								sx={{
									bgcolor: theme.palette.primary.main,
									color: "white",
								}}
								onClick={() => {
									handleOpen();
								}}
							>
								<Edit />
							</IconButton>
							<IconButton
								size="small"
								sx={{
									bgcolor: theme.palette.primary.main,
									color: "white",
								}}
								onClick={async () => {
									// handleDelete(experience.id);
									setCandidaturaModalStatus(true);
								}}
							>
								<Delete />
							</IconButton>
							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											checked={
												experience.visible === 1
													? true
													: false
												//  ??
												// switchValue
											}
											onChange={(event) => {
												const newValue =
													event.target.checked;
												handleUpdate(
													experience.id,
													newValue
												);
											}}
										/>
									}
									label="Divulgar informação"
								/>
							</FormGroup>
						</Box>
					</Box>
				</Box>
			</Paper>
		</Fragment>
	);
}
