import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalDetalhesUsuarioView from "./ModalDetalhesUsuario.view";

function ModalDetalhesUsuario(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalDetalhesUsuarioView userId={props.userId} />
		</ModalContextProvider>
	);
}

export default ModalDetalhesUsuario;
