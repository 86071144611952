import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import {
	getVagas,
	getVagasEmpresa,
	getVagasRepresentante,
} from "../../../services/crud/crud.vaga";
// import { Vaga } from "../../../models/Vaga";
import { makeStyles } from "@material-ui/core";
import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/Authentication/hooks/useAuth";
import { Empresa } from "../../../models/Empresa";
import { TabelaVagas } from "../../../models/FormulariosAlteracao";
import { Meta } from "../../../models/Meta";
import { Usuario } from "../../../models/Usuario";
import {
	getAllEmpresas,
	getAllEmpresasRepresentante,
} from "../../../services/crud/crud.empresa";
import { getUsuario } from "../../../services/crud/crud.usuario";
import ModalAlteracaoVaga from "../../Modal/ModalAlteracao/ModalAlteracaoVaga";
import ModalCadastroVaga from "../../Modal/ModalCadastro/ModalCadastroVaga";
import ModalDeleteVaga from "../../Modal/ModalDelete/ModalDeleteVaga";
import ModalDetalhesVaga from "../../Modal/ModalDetalhes/ModalDetalhesVaga";
import { useReloadContext } from "../hooks/useReload";
import "../style.scss";

function TabelaVagasView() {
	const useStyles = makeStyles((theme) => ({
		select: {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey ",
			},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey !important",
			},
			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: "black !important",
			},
		},
		inputLabel: {
			// color: "red",
			"&.Mui-focused": {
				color: "black !important",
			},
		},
	}));
	const classes = useStyles();

	const { auth } = useAuthContext();
	const { reload, setReload } = useReloadContext();

	const [selectValue, setSelectValue] = useState("");
	const [paginacao, setPaginacao] = useState({} as Meta);

	const [dadosVagas, setDadosVagas] = useState({} as TabelaVagas);
	// const [tableRowsInitial, setTableRowsInitial] = useState({} as TabelaVagas);

	const [empresas, setEmpresas] = useState<Array<Empresa>>([]);
	const [representante, setRepresentante] = useState({} as Usuario);

	/**
	 * @function handleChangePage
	 * Faz uma requisição para a api e traz as informações da paginação e o array de tipos de contrato.
	 * @param {object} event - Representa o elemento que sofreu o evento.
	 * @param {number} page - Representa a página atual.
	 */
	function handleChangePage(event: unknown, page: number) {
		if (auth.role === "admin") {
			if (selectValue !== " ") {
				let idEmpresa = selectValue;

				getVagasEmpresa(`${page}`, Number(idEmpresa)).then((data) => {
					setPaginacao(data.meta);
					setSelectValue(idEmpresa);
					setDadosVagas({ vagas: data.data });
				});
			} else {
				getVagas(`${page}`).then((data) => {
					setPaginacao(data.meta);
					setDadosVagas({ vagas: data.data });
				});
			}
		} else {
			if (selectValue !== " ") {
				let idEmpresa = selectValue;

				getVagasEmpresa(`${page}`, Number(idEmpresa))
					.then((data) => {
						setPaginacao(data.meta);
						setSelectValue(idEmpresa);
						setDadosVagas({ vagas: data.data });
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				getVagasRepresentante(`${page}`)
					.then((data) => {
						setPaginacao(data.meta);
						setDadosVagas({ vagas: data.data });
						setSelectValue(" ");
						// setTableRowsInitial({ vagas: data.data });
					})
					.catch((e) => {
						console.log(e);
					});
			}
		}
	}

	/**
	 * @function exibirFiltroUsuario
	 * Verifica se o usuário logado é um admin, se sim retorna um componente select.
	 * @returns {JSX.Element} - Retorna um select que filtra as empresas por representates.
	 */
	function exibirFiltroUsuario(): JSX.Element {
		return (
			<FormControl
				size="small"
				fullWidth
				style={{ marginRight: "10px", marginLeft: "15px" }}
				// disabled={isDisabled(representante.is_active)}
			>
				<InputLabel className={classes.inputLabel}>
					Filtrar vagas por empresa
				</InputLabel>
				<Select
					className={classes.select}
					fullWidth
					style={{ height: "42.5px" }}
					label="Filtrar vagas por empresa"
					onChange={(event) => {
						console.log("Selected value:", event.target.value);
						// alert(event.target.value);
						filterCompany(event);
					}}
					value={selectValue}
				>
					<MenuItem value="">Todas as vagas</MenuItem>
					{empresas.length > 0 &&
						empresas?.map((empresa, index) => (
							<MenuItem
								value={empresa.id}
								key={index}
							>{`${empresa.name}`}</MenuItem>
						))}
				</Select>
			</FormControl>
		);
	}

	/**
	 * @function filterCompany
	 * Verifica se o valor do evento for diferente de vazio, caso sim atribui à constante results o array vagas atualizado com as vagas da empresa selecionada e em seguida atualiza o tableRows com o valor do results e o selecValue para o valor do evento, caso contrario seta o tableRows para o valor inicial e o selectValue para o valor do evento.
	 * @param event - Representa o elemento que sofreu o evento.
	 */
	async function filterCompany(event) {
		if (event.target.value !== "") {
			await getVagasEmpresa(null, event.target.value).then((data) => {
				setPaginacao(data.meta);
				setDadosVagas({ vagas: data.data });
			});

			// const results = tableRowsInitial.vagas.filter((vaga) => {
			//   return vaga.companies_id === event.target.value;
			// });

			// setDadosVagas({ vagas: results });

			setSelectValue(event.target.value);
		} else {
			setReload(!reload);
			// setSelectValue(event.target.value);
			// setDadosVagas(tableRowsInitial);
		}
	}

	/**
	 * @function isDisabled
	 * Vefirica se o usuário é um admin ou se esta ativo no sistema, senão desabilita o select.
	 * @param { boolean | undefined} is_active -
	 * @returns Retorna um boolean.
	 */
	// function isDisabled(is_active: boolean | undefined) {
	// 	if (is_active || auth.role === "admin") {
	// 		return false;
	// 	} else {
	// 		return true;
	// 	}
	// }

	useEffect(() => {
		if (auth.role === "admin") {
			getVagas(null)
				.then((data) => {
					// setTableRowsInitial({ vagas: data.data });
				})
				.catch((e) => {
					console.log(e);
				});

			getAllEmpresas()
				.then((data) => {
					setEmpresas(data);
				})
				.catch((e) => {
					console.log(e);
				});

			getVagas(null)
				.then((data) => {
					setPaginacao(data.meta);
					setDadosVagas({ vagas: data.data });
					setSelectValue(" ");
				})
				.catch((e) => {
					console.log(e);
				});
			// if (localStorage.getItem("id_empresa_filter")) {
			// 	let empresa_id = localStorage.getItem(
			// 		"id_empresa_filter"
			// 	) as string;

			// 	getVagasEmpresa(null, Number(empresa_id))
			// 		.then((data) => {
			// 			setPaginacao(data.meta);
			// 			setSelectValue(empresa_id);
			// 			setDadosVagas({ vagas: data.data });
			// 			localStorage.removeItem("id_empresa_filter");
			// 		})
			// 		.catch((e) => {
			// 			console.log(e);
			// 		});
			// } else {
			// 	getVagas(null)
			// 		.then((data) => {
			// 			setPaginacao(data.meta);
			// 			setDadosVagas({ vagas: data.data });
			// 			setSelectValue(" ");
			// 		})
			// 		.catch((e) => {
			// 			console.log(e);
			// 		});
			// }
		} else {
			getUsuario(auth.user_id)
				.then((data) => {
					setRepresentante(data);
				})
				.catch((e) => {
					console.log(e);
				});

			getAllEmpresasRepresentante()
				.then((data) => {
					setEmpresas(data);
				})
				.catch((e) => {
					console.log(e);
				});

			getVagasRepresentante(null)
				.then((data) => {
					setPaginacao(data.meta);
					setDadosVagas({ vagas: data.data });
					setSelectValue(" ");
					// setTableRowsInitial({ vagas: data.data });
				})
				.catch((e) => {
					console.log(e);
				});

			getUsuario(auth.user_id)
				.then((data) => {
					setRepresentante(data);
				})
				.catch((e) => {
					console.log(e);
				});
			// if (localStorage.getItem("id_empresa_filter")) {
			// 	let empresa_id = localStorage.getItem(
			// 		"id_empresa_filter"
			// 	) as string;

			// 	getVagasEmpresa(null, Number(empresa_id))
			// 		.then((data) => {
			// 			setPaginacao(data.meta);
			// 			setSelectValue(empresa_id);
			// 			setDadosVagas({ vagas: data.data });
			// 			localStorage.removeItem("id_empresa_filter");
			// 		})
			// 		.catch((e) => {
			// 			console.log(e);
			// 		});

			// 	getVagasRepresentante(null)
			// 		.then((data) => {
			// 			// setTableRowsInitial({ vagas: data.data });
			// 		})
			// 		.catch((e) => {
			// 			console.log(e);
			// 		});
			// } else {
			// 	getVagasRepresentante(null)
			// 		.then((data) => {
			// 			setPaginacao(data.meta);
			// 			setDadosVagas({ vagas: data.data });
			// 			setSelectValue(" ");
			// 			// setTableRowsInitial({ vagas: data.data });
			// 		})
			// 		.catch((e) => {
			// 			console.log(e);
			// 		});

			// 	getUsuario(auth.user_id)
			// 		.then((data) => {
			// 			setRepresentante(data);
			// 		})
			// 		.catch((e) => {
			// 			console.log(e);
			// 		});
			// }
		}
		setReload(true);
	}, [reload, setReload, auth.role, auth.user_id]);

	return (
		<>
			<div className="boxTabelaUser" data-testid="tabela-vagas">
				<Paper
					className="divPaper"
					sx={{
						mt: 2,
						// border: "solid thin #ddd"
					}}
					elevation={1}
				>
					<Box
						p={3}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Box>
							<Typography variant="h5">
								Vagas cadastradas
							</Typography>
							<Typography variant="body2">
								Listando todas as vagas cadastrados no sistema
							</Typography>
						</Box>
						<ModalCadastroVaga isActive={representante.is_active} />
					</Box>
					<Box sx={{ mt: 2, mb: 2 }}>
						<div className="divModalCadastro">
							<div style={{ display: "flex", width: "100%" }}>
								{/* <ModalMenuNavegacao /> */}
								{exibirFiltroUsuario()}
							</div>
						</div>
					</Box>
					<TableContainer className="tableContainer">
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow hover role="checkbox" tabIndex={-1}>
									<TableCell align="center">Título</TableCell>
									<TableCell align="center">
										Candidaturas
									</TableCell>

									<TableCell align="center">
										Empresa
									</TableCell>

									<TableCell align="center">Local</TableCell>

									<TableCell align="center">Área</TableCell>

									<TableCell align="center">Ações</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{/* <pre>{JSON.stringify(dadosVagas, null, 4)}</pre> */}
								{dadosVagas.vagas?.map((vaga: any, index) => (
									<TableRow key={index}>
										<TableCell align="left">
											<Link
												to={"/candidaturas/" + vaga.id}
												style={{
													textDecoration: "none",
													display: "flex",
													alignItems: "flex-start",
													gap: "6px",
												}}
											>
												{vaga.title}{" "}
											</Link>
										</TableCell>
										<TableCell align="center">
											{vaga.applications
												? vaga.applications.length
												: 0}
										</TableCell>

										<TableCell align="center">
											{vaga.company}
										</TableCell>

										<TableCell align="center">
											{`${vaga.city} - ${vaga.state}`}
										</TableCell>

										<TableCell align="center">
											{vaga.area}
										</TableCell>

										<TableCell align="center">
											<ModalAlteracaoVaga
												vagaId={vaga.id}
												vaga={vaga}
											/>
											<ModalDetalhesVaga
												vagaId={vaga.id}
											/>

											<ModalDeleteVaga
												vagaId={vaga.id}
												dados={{
													dadosVagas: dadosVagas,
													setDadosVagas: dadosVagas,
												}}
											/>

											{/* <IconButton
												onClick={() => {
													history(
														"/candidaturas/" +
															vaga.id
													);
												}}
											>
												<ContactPage />
											</IconButton> */}

											{/* <Button
												onClick={() => { handleDeletarVaga(vaga.id) }}
												title="Deletar"
											>
												<DeleteForeverIcon style={{ color: "#292B2D" }} />
											</Button> */}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					{/* <pre>{JSON.stringify(dadosVagas, null, 4)}</pre> */}

					<Stack alignItems="center" spacing={2} sx={{ p: 1 }}>
						<Pagination
							count={
								typeof paginacao === "undefined"
									? 1
									: paginacao.last_page
							}
							onChange={handleChangePage}
						/>
					</Stack>
				</Paper>

				{/* <MenuNavegacao /> */}
			</div>
		</>
	);
}

export default TabelaVagasView;
