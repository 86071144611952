import { MenuItem, Select } from "@material-ui/core";
import { Close } from "@mui/icons-material";
import {
	Box,
	Button,
	Checkbox,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	FormControl,
	IconButton,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { postSignup } from "../../../../services/signup";
import { getTerms } from "../../../../services/terms";
import maskCnpj from "../../../../utils/mask";

// ... (previous imports)

const FormComponent = () => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm({
		mode: "onSubmit",
		defaultValues: {
			// Set default values if needed
			user: {
				name: "",
				email: "",
				password: "",
				passwordConfirm: "",
			},
			company: {
				name: "",
				cnpj: "",
				numero_registro: "",
				about: "",
				area: "",
				email_curriculum: "",
				site: "",
				employees_number: 0,
			},
		},
	});
	const steps = ["Cadastro de representante", "Cadastro de empresa"];
	const [activeStep, setActiveStep] = React.useState(0);
	const [termState, setTermState] = useState<{
		term: boolean;
		dialog: boolean;
		termText: string;
	}>({ term: false, dialog: false, termText: "" });
	const theme = useTheme();
	const navigate = useNavigate();

	const [selectedField, setSelectedField] = useState<string>("cnpj");

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedField(event.target.value as string);
	};

	const onSubmit = async (data: any) => {
		if (activeStep === steps.length - 1) {
			try {
				if (selectedField === "cnpj") {
					data.company.cnpj = data.company.cnpj.replace(
						/[^\w]+/g,
						""
					);
					delete data.company.numero_registro;
				} else {
					delete data.company.cnpj;
				}
				await postSignup(data);
				navigate("/");
			} catch (error) {
				console.error("Erro ao realizar o cadastro:", error);
			}
		} else {
			setActiveStep((prevStep) => prevStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const termsData: any = await getTerms();
				const desiredObject: any = termsData?.find(
					(item) => item.type === "empresa"
				);

				setTermState((prevTermState) => ({
					...prevTermState,
					termText: desiredObject?.term_text,
				}));
			} catch (error) {
				console.error("Error fetching terms:", error);
			}
		};
		fetchData();
	}, []);

	return (
		<Container maxWidth="sm">
			<form
				onSubmit={handleSubmit((data) => {
					if (
						termState.term === false &&
						activeStep !== steps.length - 1
					) {
						setActiveStep((prevStep) => prevStep + 1);
					} else if (
						termState.term === true &&
						activeStep === steps.length - 1
					) {
						onSubmit(data);
					}
				})}
			>
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label, index) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>

				{activeStep === 0 && (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "24px",
						}}
					>
						<Typography
							variant="h6"
							gutterBottom
							sx={{ textAlign: "center", mt: 2 }}
						>
							Cadastro de representante
						</Typography>
						<Controller
							name="user.name"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									label="Nome"
									fullWidth
									error={!!errors["user"]?.name}
									helperText={
										errors["user"]?.name
											? errors["user"]?.name?.message
											: ""
									}
								/>
							)}
							rules={{ required: "Campo obrigatório" }}
						/>

						<Controller
							name="user.email"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									label="E-mail"
									fullWidth
									error={!!errors["user"]?.email}
									helperText={
										errors["user"]?.email
											? errors["user"]?.email?.message
											: ""
									}
								/>
							)}
							rules={{
								required: "Campo obrigatório",
								pattern: {
									value: /^\S+@\S+$/i,
									message: "E-mail inválido",
								},
							}}
						/>

						<Controller
							name="user.password"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									label="Senha"
									type="password"
									fullWidth
									error={!!errors["user"]?.password}
									helperText={
										errors["user"]?.password
											? errors["user"]?.password?.message
											: ""
									}
								/>
							)}
							rules={{
								required: "Campo obrigatório",
								pattern: {
									value: /^.{8,}$/,
									message:
										"Senha deve ter pelo menos 8 caracteres",
								},
							}}
						/>

						<Controller
							name="user.passwordConfirm"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									label="Confirmação de Senha"
									type="password"
									fullWidth
									error={!!errors["user"]?.passwordConfirm}
									helperText={
										errors["user"]?.passwordConfirm
											? errors["user"]?.passwordConfirm
													?.message
											: ""
									}
								/>
							)}
							rules={{
								required: "Campo obrigatório",
								validate: (value) =>
									value === getValues("user.password") ||
									"As senhas não coincidem",
							}}
						/>
					</Box>
				)}

				{activeStep === 1 && (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "24px",
						}}
					>
						<Typography
							variant="h6"
							gutterBottom
							sx={{ textAlign: "center", mt: 2 }}
						>
							Cadastro de empresa
						</Typography>
						<Controller
							name="company.name"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									label="Nome da Empresa"
									fullWidth
									error={!!errors["company"]?.name}
									helperText={
										errors["company"]?.name
											? errors["company"]?.name?.message
											: ""
									}
								/>
							)}
							rules={{ required: "Campo obrigatório" }}
						/>

						<FormControl sx={{ mb: 1 }}>
							<Select
								variant="outlined"
								value={selectedField}
								onChange={handleChange}
								fullWidth
								label="Selecione o campo"
							>
								<MenuItem value="cnpj">CNPJ</MenuItem>
								<MenuItem value="rnp">
									Registro Profissional
								</MenuItem>
							</Select>
						</FormControl>
						{selectedField === "cnpj" && (
							<Controller
								name="company.cnpj"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<TextField
										{...field}
										label="CNPJ"
										placeholder="99.999.999/9999-99"
										fullWidth
										error={!!errors["company"]?.cnpj}
										helperText={
											errors["company"]?.cnpj
												? errors["company"]?.cnpj
														?.message
												: ""
										}
										onChange={(e) => {
											const formattedValue = maskCnpj(
												e.target.value
											);
											field.onChange(formattedValue);
										}}
									/>
								)}
								// rules={{ required: "Campo obrigatório" }}
							/>
						)}

						{selectedField === "rnp" && (
							<Controller
								name="company.numero_registro"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<TextField
										{...field}
										label="Número de Registro"
										placeholder="Registro Profissional"
										fullWidth
										error={
											!!errors["company"]?.numero_registro
										}
										helperText={
											errors["company"]?.numero_registro
												? errors["company"]
														?.numero_registro
														?.message
												: ""
										}
										onChange={(e) => {
											field.onChange(e.target.value);
										}}
									/>
								)}
								// rules={{ required: "Campo obrigatório" }}
							/>
						)}

						<Controller
							name="company.about"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									label="Sobre a empresa"
									multiline
									fullWidth
									error={!!errors["company"]?.about}
									helperText={
										errors["company"]?.about
											? errors["company"]?.about?.message
											: ""
									}
								/>
							)}
							rules={{ required: "Campo obrigatório" }}
						/>

						<Controller
							name="company.area"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									label="Área de atuação"
									fullWidth
									error={!!errors["company"]?.area}
									helperText={
										errors["company"]?.area
											? errors["company"]?.area?.message
											: ""
									}
								/>
							)}
							rules={{ required: "Campo obrigatório" }}
						/>

						<Controller
							name="company.email_curriculum"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									label="E-mail para Currículos"
									fullWidth
									error={
										!!errors["company"]?.email_curriculum
									}
									helperText={
										errors["company"]?.email_curriculum
											? errors["company"]
													?.email_curriculum?.message
											: ""
									}
								/>
							)}
							rules={{
								// required: "Campo obrigatório",
								pattern: /^\S+@\S+$/i,
							}}
						/>

						<Controller
							name="company.site"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									{...field}
									label="Site"
									fullWidth
									error={!!errors["company"]?.site}
									helperText={
										errors["company"]?.site
											? errors["company"]?.site?.message
											: ""
									}
								/>
							)}
							// rules={{ required: "Campo obrigatório" }}
						/>

						<Controller
							name="company.employees_number"
							control={control}
							defaultValue={0}
							render={({ field }) => (
								<TextField
									{...field}
									label="Número de Empregados"
									fullWidth
									type="number"
									error={
										!!errors["company"]?.employees_number
									}
									helperText={
										errors["company"]?.employees_number
											? errors["company"]
													?.employees_number?.message
											: ""
									}
								/>
							)}
							// rules={{ required: "Campo obrigatório" }}
						/>

						<div>
							<Checkbox
								value={termState.term}
								onChange={(event) => {
									setTermState((prevTermState) => ({
										...prevTermState,
										term: event.target.checked,
									}));
								}}
							/>
							<label htmlFor="checkbox">
								Eu concordo com estes{" "}
								<span
									style={{ color: "blue" }}
									onClick={() => {
										setTermState((prevTermState) => ({
											...prevTermState,
											dialog: !prevTermState.dialog,
										}));
									}}
								>
									Termos e Condições
								</span>
								.
							</label>
						</div>
					</Box>
				)}

				<div
					style={{
						marginTop: "16px",
						display: "flex",
						gap: "12px",
						justifyContent: "flex-end",
					}}
				>
					<Button
						disabled={activeStep === 0}
						onClick={handleBack}
						disableElevation
					>
						Voltar
					</Button>
					<Button
						variant="contained"
						color="primary"
						type={"submit"}
						// type={
						//   termState.term === false ?
						//     activeStep === steps.length - 1 ? "submit" : "button"
						//     :
						//     "submit"
						// }
						disabled={
							termState.term === false
								? activeStep === steps.length - 1
									? true
									: false
								: false
						}
						// onClick={() => {
						//   if (termState.term === false && activeStep !== steps.length - 1) {
						//     setActiveStep((prevStep) => prevStep + 1);
						//   } else if (termState.term === true && activeStep === steps.length - 1) {
						//     return
						//   }
						// }}
						disableElevation
					>
						{activeStep === steps.length - 1
							? "Finalizar"
							: "Próximo"}
					</Button>
				</div>
			</form>

			<Dialog
				open={termState.dialog}
				onClose={() => {
					setTermState((prevTermState) => ({
						...prevTermState,
						dialog: !prevTermState.dialog,
					}));
				}}
				maxWidth="md"
				sx={{ zIndex: 99999 }}
			>
				<Box
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box p={2} sx={{ display: "flex", gap: "12px" }}>
						<Typography variant="body1">
							Termos e Condições
						</Typography>
					</Box>
					<Box p={1}>
						<IconButton
							sx={{
								color: "white",
							}}
							onClick={() => {
								setTermState((prevTermState) => ({
									...prevTermState,
									dialog: !prevTermState.dialog,
								}));
							}}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
				<DialogContent>
					<DialogContentText>
						<div
							dangerouslySetInnerHTML={{
								__html: termState.termText,
							}}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						onClick={() => {
							setTermState((prevTermState) => ({
								...prevTermState,
								dialog: !prevTermState.dialog,
							}));
						}}
					>
						Voltar
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
};

export default FormComponent;
