import React from "react";
import { ModalContextProvider } from "../../../context/Modal/modal.context";
import TabelaUsuariosView from "../TabelaUsuarios/TabelaUsuarios.view";
import { ReloadContextProvider } from "../reload.context";

function TabelaUsuarios(): React.ReactElement {
	return (
		<ReloadContextProvider>
			<ModalContextProvider>
				<TabelaUsuariosView />
			</ModalContextProvider>
		</ReloadContextProvider>
	);
}

export default TabelaUsuarios;
