import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";

/**
 *
 * @returns Componente de rodapé do projeto Uninta Jobs
 */
export default function AppFooter() {
	return (
		<Fragment>
			<Grid container>
				<Grid item md={12}>
					<Box
						p={2}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Typography>
							Centro Universitário INTA - UNINTA. Todos os
							direitos reservados. 2024.
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Fragment>
	);
}
