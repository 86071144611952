import axios from "axios";
import toast from "react-hot-toast";

const api = axios.create({
	baseURL: process.env.REACT_APP_URL,
	timeout: 5000,
	headers: {
		Accept: "application/json",
	},
});

// Add a response interceptor
api.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response && error.response.status === 401) {
			//   Clear cache (if applicable)
			//   Redirect to "/"
			setTimeout(() =>{
				toast.error(
					"Atenção, suas sessão expirou! Faça login novamente para prosseguir."
				);
			},5000)
			localStorage.clear();
			localStorage.clear();
			window.location.href = "/";
		}
		return Promise.reject(error);
	}
);

export default api;
