import React from "react";
import { ReloadContextProvider } from "../../Tabelas/reload.context";
import TabelaVagasView from "./TabelaVagas.view";

function TabelaVagas(): React.ReactElement {
    return (
        <ReloadContextProvider>
            <TabelaVagasView />
        </ReloadContextProvider>
    );
}

export default TabelaVagas;