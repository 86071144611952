import { Delete } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DialogContent, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { Vaga } from "../../../../models/Vaga";
import { deleteVaga } from "../../../../services/crud/crud.vaga";
import ModalHeader from "../../ModalHeader";

function ModalDeleteVagaView(props) {
	const { open, handleOpen, handleClose } = useModalContext();
	let idVagaDeletar;

	/**
	 * @function handleNaoEDeletado
	 * Faz uma verifição se a vaga foi deletada.
	 * @param {Vaga} vaga - Representa cada vaga.
	 * @returns {boolean} - Verifica as vagas que não foram deletadas.
	 */
	function handleNaoEDeletado(vaga: Vaga) {
		return vaga.id !== idVagaDeletar;
	}

	/**
	 * @function handleDeletarVaga
	 * Faz uma verificação se o usuário realmente quer deletar a vaga que foi selecionado, se sim, faz uma requisição para a api deletando-a do banco e filtrando as vagas que não foram deletadas para serem mostradas na tabela atualizada.
	 * @param {number} id - Id do tipo de contrato a ser deletado.
	 */
	function handleDeletarVaga(id: number) {
		handleClose();
		deleteVaga(id)
			.then(() => {
				idVagaDeletar = id;
				let novoArrayVagas =
					props.dados.tableRows.vagas.filter(handleNaoEDeletado);
				props.dados.setTableRows({ vagas: novoArrayVagas });
			})
			.catch((e) => {
				console.log(e);
			});
	}

	return (
		<>
			<IconButton
				onClick={() => {
					handleOpen();
				}}
				title="Deletar"
				data-testid="modal-delete-vaga"
			>
				<DeleteForeverIcon style={{ color: "#292B2D" }} />
			</IconButton>

			<Dialog open={open}>
				<ModalHeader
					header="Confirmção de exclusão de vaga"
					icon={<Delete />}
				/>
				<DialogContent>
					{"Você realmente deseja deletar esta vaga?"}
				</DialogContent>
				<DialogActions>
					<div
						style={{
							width: "100%",
							display: "flex",
							gap: "6px",
							justifyContent: "end",
						}}
					>
						<Button
							variant="text"
							color="primary"
							// style={{
							// 	backgroundColor: "#B50000",
							// 	color: "white",
							// 	height: "40px",
							// 	width: "20%",
							// 	marginRight: "20px",
							// }}
							onClick={() => {
								handleClose();
							}}
						>
							Não
						</Button>
						<Button
							variant="contained"
							color="primary"
							disableElevation
							// style={{
							// 	backgroundColor: "#B50000",
							// 	color: "white",
							// 	height: "40px",
							// 	width: "20%",
							// 	marginRight: "20px",
							// }}
							onClick={() => {
								handleDeletarVaga(props.vagaId);
							}}
						>
							Sim
						</Button>
					</div>
				</DialogActions>
			</Dialog>
		</>
	);
}
export default ModalDeleteVagaView;
