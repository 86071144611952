import { AxiosResponse } from "axios";
import { ReactNode, createContext, useContext, useState } from "react";
import { fileManagerApi } from "../../filemanager";

interface ActivityTableContextType {
	data: any;
	setData: React.Dispatch<React.SetStateAction<any>>;
	url: any;
	setUrl: React.Dispatch<React.SetStateAction<any>>;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	submission: any;
	setSubmission: React.Dispatch<React.SetStateAction<any>>;
	openDelete: boolean;
	setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
	deleteConfirmation: boolean;
	setDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
	getVideo: (path: string) => void;
	state: any;
	enabled: boolean;
	setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	analysis: GPTFeedbackInterface;
	setAnalysis: React.Dispatch<React.SetStateAction<GPTFeedbackInterface>>;
	queryResult: string;
	setQueryResult: React.Dispatch<
		React.SetStateAction<"PENDING" | "DONE" | "IDLE">
	>;
	comprehend: any;
	setComprehend: React.Dispatch<React.SetStateAction<any>>;
	grade: number | string;
	setGrade: React.Dispatch<React.SetStateAction<number | string>>;
	showImages: boolean;
	setShowImages: React.Dispatch<React.SetStateAction<boolean>>;
	getAIEvaluation: (id: string | number) => void;
}

const ActivityTableContext = createContext<ActivityTableContextType>({
	data: null,
	setData: () => {},
	url: null,
	setUrl: () => {},
	open: false,
	setOpen: () => {},
	submission: null,
	setSubmission: () => {},
	openDelete: false,
	setOpenDelete: () => {},
	deleteConfirmation: false,
	setDeleteConfirmation: () => {},
	getVideo: () => {},
	state: "",
	enabled: false,
	setEnabled: () => {},
	analysis: {
		gpt_avg: 0,
		gpt_feedback: "",
	},
	setAnalysis: () => {},
	queryResult: "IDLE",
	setQueryResult: () => {},
	comprehend: null,
	setComprehend: () => {},
	grade: 0,
	setGrade: () => {},
	showImages: false,
	setShowImages: () => {},
	getAIEvaluation: () => {},
});

export interface GPTFeedbackInterface {
	gpt_avg: number;
	gpt_feedback: string;
}

interface ActivityTableProviderProps {
	children?: ReactNode;
}

export const ActivityTableContextProvider = ({
	children,
}: ActivityTableProviderProps) => {
	const [data, setData] = useState<any>({});
	const [url, setUrl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [submission, setSubmission] = useState<any>(null);
	const [openDelete, setOpenDelete] = useState<boolean>(false);
	const [deleteConfirmation, setDeleteConfirmation] =
		useState<boolean>(false);

	let state = "";
	const [enabled, setEnabled] = useState<boolean>(false);
	const [analysis, setAnalysis] = useState<GPTFeedbackInterface>({
		gpt_avg: 0,
		gpt_feedback: "",
	});
	const [queryResult, setQueryResult] = useState<"PENDING" | "DONE" | "IDLE">(
		"IDLE"
	);
	const [comprehend, setComprehend] = useState<any>({});

	const [grade, setGrade] = useState<number | string>(0);

	const [showImages, setShowImages] = useState<boolean>(false);

	function getVideo(path: string) {
		setUrl(path);
		setOpen(true);
	}

	async function getAIEvaluation(id: string | number) {
		setQueryResult("PENDING");

		await fileManagerApi
			.get("/gpt/" + id)
			.then((res: AxiosResponse<GPTFeedbackInterface>) => {
				setAnalysis(res.data);
			});

		await fileManagerApi
			.get("/comprehend/" + id)
			.then((res: any) => {
				setComprehend(res.data);
				setQueryResult("DONE");
			})
			.finally(() => {
				// toast.info("Done")
				// window.location.href = "/student/submission/" + data.code;
				setQueryResult("IDLE");
			});
	}
	return (
		<ActivityTableContext.Provider
			value={{
				data,
				setData,
				url,
				setUrl,
				open,
				setOpen,
				submission,
				setSubmission,
				openDelete,
				setOpenDelete,
				getVideo,
				deleteConfirmation,
				setDeleteConfirmation,
				// Details Dialog
				state,
				enabled,
				setEnabled,
				queryResult,
				setQueryResult,
				analysis,
				setAnalysis,
				comprehend,
				setComprehend,
				grade,
				setGrade,
				showImages,
				setShowImages,
				getAIEvaluation,
			}}
		>
			{children}
		</ActivityTableContext.Provider>
	);
};

export const useActivityTable = () => {
	return useContext(ActivityTableContext);
};
