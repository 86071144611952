import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router";

export type ProfileType = {
	email: string;
	id: number;
	is_active: boolean | number;
	name: string;
	remember_me_token: string | null;
	role_id: number;
	created_at: string;
	updated_at: string;
};

const INITIAL_STATE: ProfileType = {
	email: "",
	id: 0,
	is_active: false,
	name: "",
	remember_me_token: null,
	role_id: 0,
	created_at: "",
	updated_at: "",
};

/**
 *
 * @returns retorna perfil do aluno
 */
const useProfile = () => {
	const params = useParams();
	const [perfil, setProfile] = useState<any>(INITIAL_STATE);

	const loadProfile = async () => {
		const token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		const apiUrl = `${process.env.REACT_APP_URL}/profile`;

		await axios
			.get(`${apiUrl}`, {
				headers: {
					Authorization: "Bearer " + token,
				},
			})
			.then((res: any) => {
				setProfile(res.data[0].student);
			});
	};

	const loadProfileById = async () => {
		const token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		const apiUrl = `${process.env.REACT_APP_URL}/students/${params.id}`;

		await axios
			.get(`${apiUrl}`, {
				headers: {
					Authorization: "Bearer " + token,
				},
			})
			.then((res: any) => {
				setProfile(res.data);
			});
	};

	return { perfil, loadProfile, loadProfileById };
};

export { useProfile };
