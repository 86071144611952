import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../context/Authentication/hooks/useAuth";
import { RespostaEmpresaId } from "../../../models/Empresa";
import { Usuario } from "../../../models/Usuario";
import {
	getEmpresa,
	getEmpresaRepresentante,
} from "../../../services/crud/crud.empresa";
import { getUsuario } from "../../../services/crud/crud.usuario";
import "../style.scss";

function ListaDetalhesEmpresaView(): React.ReactElement {
	let idEmpresa = Number(localStorage.getItem("id_empresa"));
	const [empresa, setEmpresa] = useState<RespostaEmpresaId>();
	const [usuario, setUsuario] = useState<Usuario>();
	const { auth } = useAuthContext();
	// const { handleClose } = useModalContext();

	useEffect(() => {
		if (auth.role === "admin" && idEmpresa) {
			getEmpresa(idEmpresa)
				.then(async (data: any) => {
					setEmpresa(data);
					// console.log("D: " + data.user_id);
					// console.log(data.user_id);
					// alert("D: " + data.user_id);
					await getUsuario(data.user_id)
						.then((data) => {
							setUsuario(data);
							localStorage.removeItem("id_empresa");
						})
						.catch((e) => {
							console.log(e);
						});
				})
				.catch((e) => {
					console.log(e);
				});
		} else if (idEmpresa) {
			getEmpresaRepresentante(idEmpresa)
				.then(async (data) => {
					setEmpresa(data);
					// alert("DE: " + data.user_id);
					await getUsuario(data.user_id)
						.then((data) => {
							setUsuario(data);
							localStorage.removeItem("id_empresa");
						})
						.catch((e) => {
							console.log(e);
						});
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [idEmpresa, auth.role]);

	return (
		<>
			<Container data-testid="detalhes-empresas">
				<Box mb={3}>
					<Typography variant="h5" sx={{ color: "black", mb: 1 }}>
						{empresa?.name}
					</Typography>
					<Typography variant="body1">
						Veja abaixo os dados da empresa
					</Typography>
				</Box>
				<table className="DetalhesUsuarioTable">
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography variant="body1">
								Representante:
							</Typography>
						</td>
						<td className="typographyDetalhes">{`${usuario?.name} | ${usuario?.email}`}</td>
					</tr>
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography variant="body1">
								Nome Fantasia:
							</Typography>{" "}
						</td>
						<td className="typographyDetalhes">{empresa?.name}</td>
					</tr>
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography variant="body1">CNPJ:</Typography>
						</td>
						<td className="typographyDetalhes">{empresa?.cnpj}</td>
					</tr>
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography variant="body1">Área:</Typography>
						</td>
						<td className="typographyDetalhes">{empresa?.area}</td>
					</tr>
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography variant="body1">
								E-mail pare recebimento de currículos:
							</Typography>
						</td>
						<td className="typographyDetalhes">
							{empresa?.email_curriculum}
						</td>
					</tr>
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography variant="body1">Site:</Typography>
						</td>
						<td className="typographyDetalhes">{empresa?.site}</td>
					</tr>
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography variant="body1">
								Número de colaboradores:
							</Typography>
						</td>
						<td className="typographyDetalhes">
							{empresa?.employees_number}
						</td>
					</tr>
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography variant="body1">
								Sobre a empresa:
							</Typography>
						</td>
						<td className="typographyDetalhes">
							{empresa?.about && empresa.about.length > 50
								? empresa?.about
										?.match(/.{1,50}/g)
										.map((line, index) => (
											<span key={index}>
												{line}
												<br />
											</span>
										))
								: empresa?.about}
						</td>
					</tr>
				</table>

				{/* <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">
            Representante:
          </Typography>
          <Typography className="typographyDetalhes">{`${usuario?.name} | ${usuario?.email}`}</Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">
            Nome Fantasia:
          </Typography>
          <Typography className="typographyDetalhes">
            {empresa?.name}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">CNPJ:</Typography>
          <Typography className="typographyDetalhes">
            {empresa?.cnpj}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Área:</Typography>
          <Typography className="typographyDetalhes">
            {empresa?.area}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">
            E-mail pare recebimento de currículos:
          </Typography>
          <Typography className="typographyDetalhes">
            {empresa?.email_curriculum}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Site:</Typography>
          <Typography className="typographyDetalhes">
            {empresa?.site}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">
            Número de colaboradores:
          </Typography>
          <Typography className="typographyDetalhes">
            {empresa?.employees_number}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">
            Sobre a empresa:
          </Typography>
          <Typography className="typographyDetalhes">
            {empresa?.about}
          </Typography>
        </Box> */}
				{/* Dados antigos */}

				{/* <hr></hr> */}
			</Container>
			{/* <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                    style={{
                        backgroundColor: "#B50000", color: "white", height: "40px", width: "15%"
                    }}
                >
                    voltar
                </ Button>
            </div> */}
		</>
	);
}

export default ListaDetalhesEmpresaView;
