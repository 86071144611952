import { Business, Close, Hail } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import ListaDetalhesVagaAluno from "../../../../components/Detalhes/DetalhesVagaAluno";
import { useHomePage } from "../../../../context/HomePage";

export interface HomePageDetailsDialogProps {
	open?: any;
	setOpen?: any;
	theme?: any;
	job?: any;
	setCandidaturaModalStatus?: any;
}

// Ajustar dialogo para ser do tipo fullScreen ao entrar no formato mobile
export function HomePageDetailsDialog() {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Adjust 'sm' if needed
	const { open, setOpen, job, setCandidaturaModalStatus } = useHomePage();
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="md"
			fullScreen={fullScreen}
			// sx={{ zIndex: 99999 }}
		>
			<Box
				style={{
					backgroundColor: theme.palette.primary.main,
					color: "white",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<Box p={2} sx={{ display: "flex", gap: "12px" }}>
					<Business />{" "}
					<Typography variant="body1">Detalhes da vaga</Typography>
				</Box>
				<Box p={1}>
					<IconButton
						sx={{
							color: "white",
						}}
						onClick={() => setOpen(false)}
					>
						<Close />
					</IconButton>
				</Box>
			</Box>
			<DialogContent>
				<DialogContentText>
					{/* <pre>{JSON.stringify(job, null, 4)}</pre> */}
					<ListaDetalhesVagaAluno
						job={job}
						vaga={job.vaga}
						usuario={job.usuario}
						empresa={job.empresa}
					/>
				</DialogContentText>
				{/* <pre>{JSON.stringify(auth, null, 4)}</pre> */}
			</DialogContent>
			<DialogActions>
				<Button variant="text" onClick={() => setOpen(false)}>
					Voltar
				</Button>
				<Button
					variant="contained"
					className="apply-button"
					disableElevation
					startIcon={
						job &&
						job.applications &&
						job.applications.length > 0 ? (
							<Close />
						) : (
							<Hail />
						)
					}
					// onClick={handleCandidaturaStatus}
					onClick={() => {
						// setJob(job.vaga);
						setCandidaturaModalStatus(true);
					}}
				>
					{job && job.applications && job.applications.length > 0
						? "Cancelar"
						: "Candidatar-se"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
