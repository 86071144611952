import { ChevronLeft, ChevronRight, Work } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	IconButton,
} from "@mui/material";
import { Fragment, useRef } from "react";
import ModalHeader from "../../../../components/Modal/ModalHeader";
import { useHomePage } from "../../../../context/HomePage";
import HomePageItemView from "./HomePageItemView";

export function HomePageShowCardView() {
	const {
		showList,
		theme,
		setJob,
		setJobs,
		setOpen,
		jobsForStudents,
		jobForStudentDetails,
		setCandidaturaModalStatus,
		jobs,
		job,
		candidaturaModalStatus,
		handleCandidaturaStatus,
	} = useHomePage();
	const ref = useRef<any>(null);
	const scroll = (scrollOffset) => {
		if (ref.current) ref.current.scrollLeft += scrollOffset;
	};

	return (
		<Fragment>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Box
					sx={{
						display: showList ? "none" : "flex",
						alignItems: "center",
						justifyContent: "space-between",
						gap: "24px",
						flexDirection: {
							xs: "column",
							sm: "column",
							md: "row",
						}, // Adjust flex direction for responsive behavior
					}}
					p={3}
					m={3}
				>
					<IconButton
						size="small"
						sx={{
							border: "solid thin #ddd",
							width: "36px",
							height: "36px",
							display: {
								xs: "none",
								sm: "none",
								md: "flex",
								alignItems: "center",
								justifyContent: "center",
							},
						}}
						onClick={() => scroll(-500)}
					>
						<ChevronLeft />
					</IconButton>
					<Box
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column",
								sm: "column",
								md: "row",
							}, // Adjust flex direction for responsive behavior
							alignItems: "center",
							justifyContent: "flex-start",
							mt: 3,
							mb: 3,
							gap: "12px",
							overflow: "hidden",
							overflowY: "hidden",
							overflowX: "scroll",
							transition: "ease-in-out",
							scrollBehavior: "smooth",
						}}
						ref={ref}
					>
						{!showList &&
							jobs.data.map((item: any, index: number) => {
								return (
									<Fragment>
										<HomePageItemView
											variant="card_view"
											key={index}
											item={item}
											theme={theme}
											setJob={setJob}
											setJobs={setJobs}
											setOpen={setOpen}
											jobsForStudents={jobsForStudents}
											jobForStudentDetails={
												jobForStudentDetails
											}
											setCandidaturaModalStatus={
												setCandidaturaModalStatus
											}
										/>
									</Fragment>
								);
							})}
						<Dialog
							open={candidaturaModalStatus}
							// sx={{ zIndex: 100000 }}
						>
							<ModalHeader
								header={`Confirmação de ${
									job &&
									job.applications &&
									job.applications.length > 0
										? "exclusão"
										: "aplicação"
								} de candidatura`}
								icon={<Work />}
								close={() => setCandidaturaModalStatus(false)}
							/>
							<DialogContent>
								Deseja confirmar a{" "}
								{job &&
								job.applications &&
								job.applications.length > 0
									? "exclusão"
									: "aplicação"}{" "}
								da candidatura à vaga de:{" "}
								<b>"{job.vaga ? job.vaga.title : job.title}"</b>{" "}
								em{" "}
								<b>
									{job.vaga
										? job.vaga.company.name
										: job.company}
								</b>
								?
								{/* <pre>
											{JSON.stringify(job, null, 4)}
										</pre> */}
							</DialogContent>
							<DialogActions>
								<Button
									variant="text"
									onClick={() => {
										setCandidaturaModalStatus(false);
									}}
								>
									Cancelar
								</Button>
								<Button
									variant="contained"
									disableElevation
									onClick={() => {
										handleCandidaturaStatus(job);
									}}
								>
									Confirmar
								</Button>
							</DialogActions>
						</Dialog>
					</Box>
					<IconButton
						size="small"
						sx={{
							border: "solid thin #ddd",
							width: "36px",
							height: "36px",
							display: {
								xs: "none",
								sm: "none",
								md: "flex",
								alignItems: "center",
								justifyContent: "center",
							},
						}}
						onClick={() => scroll(500)}
					>
						<ChevronRight />
					</IconButton>
					{/* <LinearProgress
								variant="determinate"
								value={100}
								sx={{ flexGrow: 1 }}
								color="secondary"
							/> */}
				</Box>
			</Grid>
		</Fragment>
	);
}
