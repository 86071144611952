import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Edit } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import FormularioAlteracaoTiposContrato from "../../../Formularios/Alteracoes/FormularioAlteracaoTiposContrato";
import ModalHeader from "../../ModalHeader";

function ModalAlteracaoTiposContratoView(props) {
	const { open, handleOpen } = useModalContext();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down(700));
	// const lg = useMediaQuery(theme.breakpoints.up("sm"));

	/**
	 * @function handleAtualizarTipoContrato
	 * Abre o modal de FormularioAlteracaoTiposContrato e seta um valor no sessionStorage com a chave de nome id_tipocontrato.
	 * @param {number} id - Representa o id do tipocontrato.
	 */
	function handleAtualizarTipoContrato(id: number) {
		handleOpen();
		localStorage.setItem("id_tipocontrato", id.toString());
	}

	return (
		<>
			<Button
				onClick={() => {
					handleAtualizarTipoContrato(props.tipoContratoId);
				}}
				title={"Alterar"}
				data-testid="modal-alteracao-tipos-contratos"
			>
				<BorderColorIcon style={{ color: "#292B2D" }} />
			</Button>

			<Dialog
				fullScreen={fullScreen}
				fullWidth
				maxWidth={"lg"}
				open={open}
				// className="dialogWidth"
			>
				<ModalHeader header="Editar contrato" icon={<Edit />} />
				<DialogContent className="dialogContent">
					<DialogContentText className="dialogContentText">
						<FormularioAlteracaoTiposContrato />
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
}
export default ModalAlteracaoTiposContratoView;
