import Notify from "../../utils/notification";
import api from "../api";

export async function postOrientations(data: any): Promise<any> {

    let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('banner_path', data.banner_path);
    formData.append('access_link', data.access_link);

    return await api.post("/orientations", formData, { headers: { "Authorization": `Bearer ${token}` } }).then((data) => {
        Notify("Orientação cadastrado com sucesso!");
        return data;
    }).catch((error: any) => {
        if (error.response) {
            if (error.response.data.errors) {
                error.response.data.errors.map((e) => Notify(e.message, "error"));
            } else {
            }
        } else if (error.request) {
            Notify("Sem resposta do servidor:", error.request);
        } else {
            Notify("Erro de configuração da solicitação:", error.message);
        }
        Notify("Orientação não foi cadastrado, verifique os dados e tente novamente!", "error");

        return error;
    });

}

export async function putOrientations(data: any): Promise<any> {
    console.log("🚀 ~ data:", data)

    let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

    const formData = new FormData();
    data.title && formData.append('title', data.title);
    data.description && formData.append('description', data.description);
    data.banner_path && formData.append('banner_path', data.banner_path);
    data.access_link && formData.append('access_link', data.access_link);
    data.show && formData.append('show', JSON.parse(data.show));

    return await api.put(`/orientations/${data.id}`, formData, { headers: { "Authorization": `Bearer ${token}` } }).then((data) => {
        Notify("Orientação atualizada com sucesso!");
        return data;
    }).catch((e: any) => {
        Notify("Orientação não foi atualizada, verifique os dados e tente novamente!", "error");
        return e;
    });
}


export async function deleteOrientations(data: any): Promise<any> {
    console.log("🚀 ~ data:", data)

    let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

    return await api.delete(`/orientations/${data.id}`, { headers: { "Authorization": `Bearer ${token}` } }).then((data) => {
        Notify("Orientação removida com sucesso!");
        return data;
    }).catch((e: any) => {
        Notify("Orientação não foi removida, verifique os dados e tente novamente!", "error");
        return e;
    });
}


export async function getOrientations({ page = 1, title = "" } = {}) {

    let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

    return await api.get(`/orientations?page=${page}${Boolean(title) ? `&title=${title}` : ""} `, { headers: { "Authorization": `Bearer ${token} ` } })
        .then(({ data }) => {
            return data;
        }).catch((e: any) => {
            return e;
        });

}

export async function getOrientationsAll() {

    let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

    return await api.get(`/orientations`, { headers: { "Authorization": `Bearer ${token} ` } })
        .then(({ data }) => {
            return data;
        }).catch((e: any) => {
            return e;
        });
}

export async function getOrientation(id: number): Promise<any> {

    let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

    return await api.get(`orientations / ${id} `, { headers: { "Authorization": `Bearer ${token} ` } })
        .then(({ data }) => {
            return data;
        }).catch((e: any) => {
            return e;
        });

}


export async function deleteUsuario(id: number) {

    let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

    return await api.delete(`/ orientations / ${id} `, { headers: { "Authorization": `Bearer ${token} ` } })
        .catch((e) => {
            return e;
        });

}