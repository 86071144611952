import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useModalContext } from "../../../context/Modal/hooks/useModal";

export default function ModalHeader({ header, icon, close }: any) {
	const theme = useTheme();
	const { handleClose } = useModalContext();
	return (
		<Box
			style={{
				backgroundColor: theme.palette.primary.main,
				color: "white",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				gap: "12px",
			}}
		>
			<Box p={2} sx={{ display: "flex", gap: "12px" }}>
				{icon} <Typography variant="body1">{header}</Typography>
			</Box>
			<Box p={1}>
				<IconButton
					sx={{
						color: "white",
					}}
					onClick={close ?? handleClose}
				>
					<Close />
				</IconButton>
			</Box>
		</Box>
	);
}
