import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
// import HomePage from '../pages/HomePage';
import { Box, CssBaseline } from "@mui/material";
import AppHeader from "../components/layout/AppHeader";
import { useAuthContext } from "../context/Authentication/hooks/useAuth";
import CandidaturasAdministrador from "../pages/Private/Candidaturas/CandidaturasAdministrador";
import Empresas from "../pages/Private/Empresas";
import MeuCurriculo from "../pages/Private/MeuCurriculo";
import OrientacoesAdmin from "../pages/Private/OrientacoesAdmin";
import TiposContrato from "../pages/Private/TiposContrato";
import Usuarios from "../pages/Private/Usuarios";
import Vagas from "../pages/Private/Vagas";
import NotFound from "../pages/Public/NotFound";
import SignUp from "../pages/Public/SignUp";

const AdminRouter = (): React.ReactElement => {
	const { auth } = useAuthContext();
	return (
		<Fragment>
			<CssBaseline />
			<AppHeader>
				<Box
					sx={{
						marginTop: "64px",
					}}
				>
					<Routes>
						<Route
							path="/"
							element={
								auth.role === "admin" ? <Usuarios /> : <Vagas />
							}
						/>
						<Route path="/signup" element={<SignUp />} />
						<Route path="/usuarios" element={<Usuarios />} />
						<Route path="candidaturas">
							<Route
								path=""
								element={<CandidaturasAdministrador />}
							/>
							<Route
								path=":id"
								element={<CandidaturasAdministrador />}
							/>
						</Route>

						<Route path="curriculum">
							<Route path=":id" element={<MeuCurriculo />} />
						</Route>

						<Route path="/empresas" element={<Empresas />} />
						<Route path="/vagas" element={<Vagas />} />
						<Route
							path="/tipos-contrato"
							element={<TiposContrato />}
						/>
						<Route
							path="/orientacoes"
							element={<OrientacoesAdmin />}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Box>
			</AppHeader>
		</Fragment>
	);
};

export default AdminRouter;
