import React, { createContext, useEffect, useState } from "react";
import { PropsAuthContext } from "./models/AuthPropsType";

const DEFAULT_VALUE = {
	auth: {
		role: "",
		type: "",
		token: "",
		expires_at: "",
		user_id: 0,
		student_id: 0,
		has_terms: false,
	},
	setAuth: () => {},
};

export const AuthContext = createContext<PropsAuthContext>(DEFAULT_VALUE);

export const AuthContextProvider: React.FC = ({ children }) => {
	const [auth, setAuth] = useState(DEFAULT_VALUE.auth);

	useEffect(() => {
		const token = localStorage.getItem("@app-jobs:token");

		if (token) {
			setAuth(JSON.parse(token));
		}
	}, []);

	return (
		<AuthContext.Provider value={{ auth, setAuth }}>
			{children}
		</AuthContext.Provider>
	);
};
