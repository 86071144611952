import React from "react";
import { useAuthContext } from "../context/Authentication/hooks/useAuth";
import AdminRouter from "./Admin.router";
import { AlunoRouter } from "./Aluno.router";
import EmpresaRouter from "./Empresa.router";
import PublicRouter from "./Public.router";

/**
 *
 * @returns Rotas da aplicação de acordo com o nível de ensino do usuário
 */
const Routes = (): React.ReactElement => {
	const { auth } = useAuthContext();

	switch (auth.role) {
		case "admin":
			return <AdminRouter />;
		case "representante_empresa":
			return <EmpresaRouter />;
		case "ALUNO":
			return <AlunoRouter />;
		default:
			return <PublicRouter />;
	}
};

export default Routes;
