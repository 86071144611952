import { Add } from "@mui/icons-material";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { Fragment, useState } from "react";
import useSession from "../../../../context/session/SessionHook";
import MiniInfo from "../dialogs/MiniInfo";

interface BannerProps {
	usuario: {
		nome: string;
		funcao?: string;
		email?: string;
	};
}

export function Banner({ usuario }: BannerProps) {
	const theme = useTheme();
	const { setActiveStep, images } = useSession();
	const [sample, setOpenSample] = useState<boolean>(false);

	return (
		<Fragment>
			<Grid
				item
				sm={12}
				md={12}
				mt={3}
				sx={{
					textAlign: "center",
					mt: 3,
				}}
			>
				<Box
					sx={{
						p: 3,
					}}
				>
					<Typography
						variant="h4"
						component={"h6"}
						style={{
							fontWeight: "bold",
							marginBottom: 3,
						}}
					>
						Olá, <br />
						<React.Fragment>
							<span
								style={{
									color: theme.palette.primary.main,
								}}
							>
								{usuario && usuario.nome && usuario.nome} 👋
							</span>
							<br />
						</React.Fragment>
						seja bem vindo(a) ao Palco Profissional
					</Typography>
					<Typography
						variant="body1"
						component={"p"}
						style={{
							fontSize: "1.3rem",
							marginTop: "20px",
						}}
					>
						Crie videocurrículos e pratique simulações de
						entrevistas para se destacar no mercado de trabalho.
						Receba feedback detalhado e aprimore suas habilidades.
						Vamos começar!
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Button
						size="large"
						variant="contained"
						disableElevation
						startIcon={<Add />}
						onClick={() => {
							setOpenSample(true);
						}}
					>
						Iniciar nova apresentação
					</Button>
				</Box>
				<MiniInfo
					open={sample}
					setOpen={setOpenSample}
					goAction={() => {
						if (images.length >= 5) {
							setActiveStep(1);
							window.location.href = "/recorder";
						} else {
							setActiveStep(0);
							window.location.href = "/biometrics";
						}
					}}
				/>
			</Grid>
		</Fragment>
	);
}
