import {
	Dialog,
	DialogContent,
	Grid,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useActivityTable } from "../tables/context";
import { ActivityViewerDialogBiometrics } from "./ActivityViewerDialog/ActivityViewerDialogBiometrics";
import { ActivityViewerDialogFeedback } from "./ActivityViewerDialog/ActivityViewerDialogFeedback";
import { ActivityViewerDialogFooter } from "./ActivityViewerDialog/ActivityViewerDialogFooter";
import { ActivityViewerDialogHeader } from "./ActivityViewerDialog/ActivityViewerDialogHeader";
import { ActivityViewerDialogHeading } from "./ActivityViewerDialog/ActivityViewerDialogHeading";
import { ActivityViewerDialogSubmission } from "./ActivityViewerDialog/ActivityViewerDialogSubmission";

export default function ActivityViewerDialog() {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));

	const {
		open,
		state,
		setEnabled,
		setOpen,
		data,
		comprehend,
		analysis,
		showImages,
		setShowImages,
		getAIEvaluation,
		queryResult,
		url,
	} = useActivityTable();

	return (
		<Dialog
			open={open}
			onClose={() => {
				setEnabled(false);
				setOpen(false);
			}}
			maxWidth="lg"
			fullWidth
		>
			<ActivityViewerDialogHeader
				theme={theme}
				setEnabled={setEnabled}
				setOpen={setOpen}
			/>
			<DialogContent>
				<Grid container>
					<ActivityViewerDialogHeading data={data} />
					<Grid item md={12}>
						<Grid container>
							<ActivityViewerDialogSubmission
								data={data}
								matches={matches}
								queryResult={queryResult}
								state={state}
								comprehend={comprehend}
								url={url}
							/>
							<ActivityViewerDialogFeedback
								analysis={analysis}
								data={data}
							/>
						</Grid>
					</Grid>
				</Grid>
				<ActivityViewerDialogFooter
					data={data}
					setShowImages={setShowImages}
					showImages={showImages}
					getAIEvaluation={getAIEvaluation}
				/>
				<ActivityViewerDialogBiometrics
					data={data}
					showImages={showImages}
				/>
			</DialogContent>
		</Dialog>
	);
}
