import { CheckCircle } from "@mui/icons-material";
import { Avatar, Container, Typography } from "@mui/material";
import { purple } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Usuario } from "../../../models/Usuario";
import { getUsuario } from "../../../services/crud/crud.usuario";
import { verificarRole } from "../../../utils/funcoes-globais";
import "../style.scss";

function ListaDetalhesUsuarioView(): React.ReactElement {
	let idUsuario = Number(localStorage.getItem("id_usuario"));
	const [usuario, setUsuario] = useState<Usuario>();
	// const { handleClose } = useModalContext();

	useEffect(() => {
		if (idUsuario > 0) {
			getUsuario(idUsuario)
				.then((data) => {
					setUsuario(data);
					localStorage.removeItem("id_usuario");
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [idUsuario]);

	return (
		<Container maxWidth="lg" component={"div"}>
			<Box
				sx={{
					// padding: "12px 24px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Avatar
					sx={{
						width: "64px",
						height: "64px",
						bgcolor: purple[500],
						mb: 3,
					}}
				>
					{usuario?.name[0]}
				</Avatar>
				<Typography variant="h5" sx={{ mb: 3 }}>
					{usuario?.name}
				</Typography>
			</Box>
			<Box data-testid="detalhes-usuario" component={"div"}>
				<table className="DetalhesUsuarioTable">
					<tbody>
						<tr>
							<td>
								<CheckCircle
									sx={{
										color: usuario?.is_active
											? "green"
											: "#ddd",
									}}
								/>
							</td>
							<td>Nome:</td>
							<td>{usuario?.name}</td>
						</tr>
						<tr>
							<td></td>
							<td>Cód. do Usuário:</td>
							<td>{usuario?.id}</td>
						</tr>
						<tr>
							<td></td>
							<td>E-mail:</td>
							<td>{usuario?.email}</td>
						</tr>
						<tr>
							<td></td>
							<td>Função:</td>
							<td>{verificarRole(usuario?.role_id)}</td>
						</tr>
					</tbody>
				</table>

				{/* <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <CheckCircle
              sx={{ color: usuario?.is_active ? "green" : "#ddd" }}
            />{" "}
            <span> Nome: {usuario?.name}</span>
          </Typography>
        </Box>

        <Box mt={1}>
          <Typography
            variant="body1"
            component="p"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            E-mail: {usuario?.email}
          </Typography>
        </Box>
        <Box
          mt={1}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Typography variant="body1" component="p">
            Função: {verificarRole(usuario?.role_id)}
          </Typography>
        </Box> */}
			</Box>
		</Container>
	);
}

export default ListaDetalhesUsuarioView;
