import { makeStyles } from "@material-ui/core";
import {
	Box,
	Button,
	Container,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { NivelUsuario } from "../../../../models/NivelUsuario";
import { AtualizarUsuario, PutUsuario } from "../../../../models/Usuario";
import { getNiveisUsuario } from "../../../../services/crud/crud.nivelUsuario";
import { getUsuario, putUsuario } from "../../../../services/crud/crud.usuario";
import Notify from "../../../../utils/notification";
import { useReloadContext } from "../../../Tabelas/hooks/useReload";

function FormularioAlteracaoUsuarioView(): React.ReactElement {
	let idUsuarioAlterar = Number(localStorage.getItem("id_usuario"));
	const {
		handleSubmit,
		getValues,
		control,
		reset,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	const [formValues, setFormValues] = useState({} as PutUsuario);
	const [niveisUsuario, setNiveisUsuario] = useState<Array<NivelUsuario>>([]);
	const { handleClose } = useModalContext();
	const { handleReload } = useReloadContext();
	const useStyles = makeStyles((theme) => ({
		textField: {
			"& label": {
				color: "grey !important",
			},
			"& label.Mui-focused": {
				color: "black !important",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				// '&:hover fieldset': {
				//   borderColor: 'blue',
				// },
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));
	const classes = useStyles();

	useEffect(() => {
		getUsuario(idUsuarioAlterar)
			.then((data) => {
				setFormValues({
					name: data.name,
					email: data.email,
					is_active: data.is_active?.toString(),
					role_id: data.role_id?.toString(),
				});
				reset({
					name: data.name,
					email: data.email,
					is_active: data.is_active?.toString(),
					role_id: data.role_id?.toString(),
				});
			})
			.catch((e) => {
				console.log(e);
			});

		getNiveisUsuario()
			.then((data) => {
				setNiveisUsuario(data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [idUsuarioAlterar, reset]);

	async function atualizacaoUsuario(data, event) {
		let arrayDados = Object.entries(data);
		let arrayFormValues = Object.entries(formValues);
		let dataUsuarios = arrayDados.filter((value, index) => {
			return value[1] !== arrayFormValues[index][1];
		});

		if (dataUsuarios.length > 0) {
			let newDataUsuarios = Object.fromEntries(dataUsuarios);
			putUsuario(idUsuarioAlterar, newDataUsuarios)
				.then(() => {
					handleClose();
					handleReload();
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			event.preventDefault();
			Notify(
				"Atualize a informação para efetuar a operação de atualização!",
				"warning"
			);
		}
	}

	const getFuncaoPorExtenso = (funcao) => {
		switch (funcao) {
			case "admin":
				return "Administrador";
			case "representante_empresa":
				return "Representante da empresa";
			case "estudante":
				return "Estudante";
		}
	};

	return (
		<Container
			//   className="container"
			component="section"
			//   style={{ width: "100%", height: "100%" }}
			data-testid="formulario-alteracao-usuario"
		>
			<Box sx={{ mb: 3, mt: 1 }}>
				<Typography variant="h5" component="h5">
					Editar Usuário
				</Typography>
				<Typography variant="body1" sx={{ mt: 1 }}>
					Atualize as informações do usuário e outros detalhes aqui.
					{/* Altera as informações de nome e contato, bem como função e
					status do usuário no sistema. */}
				</Typography>
			</Box>
			<form
				onSubmit={handleSubmit((data: AtualizarUsuario, event) =>
					atualizacaoUsuario(data, event)
				)}
				style={{ width: "100%", height: "100%" }}
			>
				<div>
					<Controller
						name="name"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="Nome*"
								type="text"
								fullWidth
								margin="normal"
								onChange={(e) => {
									onChange(e);
								}}
								value={getValues("name") ? value : ""}
								error={errors.name?.type === "required"}
								helperText={
									errors.name?.type === "required" && (
										<span>
											O campo "Nome" é obrigatório!
										</span>
									)
								}
							/>
						)}
					/>
				</div>

				<div>
					<Controller
						name="email"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="E-mail*"
								type="email"
								fullWidth
								margin="normal"
								onChange={(e) => {
									onChange(e);
								}}
								value={getValues("email") ? value : ""}
								error={errors.email?.type === "required"}
								helperText={
									errors.email?.type === "required" && (
										<span>
											O campo "E-mail" é obrigatório!
										</span>
									)
								}
							/>
						)}
					/>
				</div>

				<div>
					<Controller
						name="role_id"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="Função*"
								select
								fullWidth
								margin="normal"
								value={getValues("role_id") ? value : ""}
								onChange={(e) => {
									onChange(e);
								}}
								error={errors.role_id?.type === "required"}
								helperText={
									errors.role_id?.type === "required" && (
										<span>
											O campo "Função" é obrigatório!
										</span>
									)
								}
							>
								<MenuItem value="">
									Selecione a função do usuário
								</MenuItem>
								{niveisUsuario.map((nivelUsuario, index) => (
									<MenuItem
										value={nivelUsuario.id}
										key={index}
									>
										{/* {nivelUsuario.name} */}
										{getFuncaoPorExtenso(nivelUsuario.name)}
									</MenuItem>
								))}
							</TextField>
						)}
					/>
				</div>

				<div>
					<Controller
						name="is_active"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="Status do Usuário*"
								select
								fullWidth
								margin="normal"
								value={getValues("is_active") ? value : ""}
								onChange={(e) => {
									onChange(e);
								}}
								error={errors.is_active?.type === "required"}
								helperText={
									errors.is_active?.type === "required" && (
										<span>
											O campo "Status do Usuário" é
											obrigatório!
										</span>
									)
								}
							>
								<MenuItem value="">
									Selecione o status do usuário
								</MenuItem>
								<MenuItem value="1">Ativado</MenuItem>
								<MenuItem value="0">Desativado</MenuItem>
							</TextField>
						)}
					/>
				</div>

				<Box
					sx={{
						mt: 3,
						mb: 3,
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
						gap: "12px",
					}}
				>
					<Button variant="text" onClick={handleClose}>
						Cancelar
					</Button>
					<Button variant="contained" disableElevation type="submit">
						Atualizar
					</Button>
					{/* <BotaoVoltar /> */}
					{/* <BotaoAtualizar /> */}
				</Box>
			</form>
		</Container>
	);
}

export default FormularioAlteracaoUsuarioView;
