import { RespostaSignup, Signup } from "../models/Signup";
import Notify from "../utils/notification";
import api from "./api";

/**
 * @function postSignup 
 * Faz uma requisição do tipo post para a rota /signup da api. No caso de sucesso é retornado um objeto do tipo RespostaSigup 
 * @param {Signup} data - Dados do cadastro inicial (signup)
 * @returns 
 */

export async function postSignup(data: Signup): Promise<RespostaSignup> {

    return await api.post("/signup", data)
        .then(({ data }) => {
            Notify("Cadastro efetuado com sucesso!");
            return data;
        }).catch((e) => {
			if (e.response && e.response.data && e.response.data.errors) {
			  e.response.data.errors.forEach((error: any) => {
				// Map API field names to Portuguese
				const fieldTranslations = {
				  "user.name": "Nome",
				  "user.email": "E-mail",
				  "user.password": "Senha",
				  "user.passwordConfirm": "Confirmação de Senha",
				  "company.name": "Nome da Empresa",
				  "company.numero_registro": "Número de Registro",
				  "company.about": "Sobre a Empresa",
				  "company.area": "Área de Atuação",
				  "company.email_curriculum": "E-mail para Currículos",
				  "company.site": "Site",
				  "company.employees_number": "Número de Funcionários"
				};
	  
				const fieldName = fieldTranslations[error.field] || error.field; // Fallback to original if no translation
	  
				// const errorMessage = `Erro no campo ${fieldName}: ${error.message}`;
				const errorMessage = `Erro no campo ${fieldName}`;
				Notify(errorMessage, "error");
			  });
			} else {
			  Notify("An unexpected error occurred during signup.", "error");
			}
	  
			return null;
		  });
}