import { Backdrop, IconButton, makeStyles } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Box,
	CircularProgress,
	Container,
	Divider,
	Grid,
	InputAdornment,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/Authentication/hooks/useAuth";
import { usePalcoProfissional } from "../../../context/PalcoProfissional";
import { LoginData } from "../../../models/Login";
import {
	postLoginAlunoPalcoVirtual,
	postLoginStudent,
} from "../../../services/login";

const useStyles = makeStyles((theme) => ({
	textField: {
		"& label.Mui-focused": {
			color: "black",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "Grey",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "Grey",
			},
			"&.Mui-focused fieldset": {
				borderColor: "Grey",
			},
		},
	},
}));

function FormularioLoginAluno(): React.ReactElement {
	const theme = useTheme();
	const classes = useStyles();
	const navigate = useNavigate();
	const breakpoint = useMediaQuery(theme.breakpoints.up("md"));
	const { dispatch } = usePalcoProfissional();
	const { setAuth } = useAuthContext();
	const [showPassword, setShowPassword] = React.useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const [open, setOpen] = useState<boolean>(false);

	/**
	 * @function login
	 * Faz a chamda da função postLogin passando data por paramentro, caso a requisição der certo seta data recebido da requisição no AuthContext, seta um valor no sessionStorage com o nome da chave de token e faz uma mudança de rota para /.
	 * @param {LoginData} data -
	 */
	async function login(data: LoginData) {
		// alert(JSON.stringify(data));
		setOpen(true);
		await postLoginAlunoPalcoVirtual(data).then((data) => {
			localStorage.setItem(
				"@palco-profissional:token",
				JSON.stringify(data)
			);
			dispatch({ action: "restore_state", payload: data.data });
		});

		await postLoginStudent(data)
			.then((data) => {
				localStorage.setItem("@app-jobs:token", JSON.stringify(data));
				setAuth(data);
				navigate("/");
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => setOpen(false));
		// setOpen(true);
		// postLogin(data)
		//   .then((data) => {
		//     localStorage.setItem("@app-jobs:token", JSON.stringify(data));
		//     setAuth(data);
		//     navigate("/");
		//   })
		//   .catch((e) => {
		//     console.log(e);
		//   })
		//   .finally(() => setOpen(false));
	}

	return (
		<Fragment>
			<Backdrop open={open} style={{ zIndex: 10000 }}>
				<CircularProgress />
			</Backdrop>
			<Grid container>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					lg={6}
					xl={6}
					sx={{
						bgcolor: theme.palette.background.default,
						display: breakpoint ? "" : "none",
					}}
				>
					<div
						className="divPrancheta"
						style={{
							backgroundColor: "white",
						}}
					>
						<Typography
							className="pranchetaHeader"
							style={{
								color: "#333 !important",
							}}
						>
							Potencialize sua carreira explorando oportunidades
							<br /> únicas, abrindo novos caminhos para seu
							crescimento
							<br /> profissional.
						</Typography>
						<img
							alt="aiamis"
							// src="https://dirin.s3.amazonaws.com/ecossistema_v5/assets/search-rh_Pranchet.svg"
							src="https://dirin.s3.amazonaws.com/uninta-jobs-assets/jobs.svg"
							className="imgPrancheta"
						/>
						<img
							alt="Instituições"
							src="img/Logos Instituições b-01.svg"
							className="b-01Prancheta"
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					<Container
						component="section"
						sx={{
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
							height: "calc(100vh)",
							bgcolor: theme.palette.background.default,
						}}
					>
						<Box
							sx={{
								// m: 3,
								display: "flex",
								gap: "12px",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{/* <div className="divAiamis"> */}
							{/* <img
                alt="aiamis"
                src="img/aiamis_Prancheta_1.svg"
                className="imgAiamis"
                style={{
                  width: "25%",
                }}
              /> */}
							{/* <Divider orientation="vertical" /> */}
							<img
								src={
									theme.palette.mode === "dark"
										? "./img/Logo 06.png"
										: "./img/Logo 02.png"
								}
								alt=""
								height="120"
								style={{ paddingTop: "12px" }}
							/>

							{/* <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
              {" "}
              <strong> Oportunidades </strong>{" "}
            </Typography> */}
						</Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								flexDirection: "column",
								justifyContent: "center",
								height: "25vh",
								color: theme.palette.text.primary,
							}}
						>
							<Typography
								variant="h5"
								component="h5"
								sx={{
									fontWeight: "medium 500",
								}}
							>
								Descubra novas oportunidades
							</Typography>
						</Box>

						<Box
							style={{
								// border: "solid red thin",
								width: "65%",
							}}
						>
							<Typography
								variant="body1"
								component="p"
								textAlign="center"
								sx={{
									mb: 3,
									color: theme.palette.text.primary,
									position: "relative",
								}}
							>
								Entrar com Registro Acadêmico (RA)
							</Typography>
							<form
								style={
									{
										// border: "solid red thin",
									}
								}
								onSubmit={handleSubmit((data) =>
									login({
										ra: data.ra,
										password: data.password,
									})
								)}
							>
								<div>
									<Typography className="titleCamp">
										Insira seu RA
									</Typography>
									<TextField
										className={classes.textField}
										{...register("ra", { required: true })}
										type="text"
										// variant="outlined"
										fullWidth
										margin="none"
										placeholder="Ex: 123-456"
										error={errors.ra?.type === "required"}
										helperText={
											errors.ra?.type === "required" && (
												<span>
													O campo "RA" é obrigatório!
												</span>
											)
										}
									/>
								</div>
								<div style={{ marginTop: "10px" }}>
									<Typography className="titleCamp">
										Senha*
									</Typography>
									<TextField
										className={classes.textField}
										// type="password"
										type={
											showPassword ? "text" : "password"
										}
										placeholder="Insira aqui sua senha"
										margin="none"
										variant="outlined"
										fullWidth
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={
															handleClickShowPassword
														}
														onMouseDown={(e) => {
															e.preventDefault();
														}}
														edge="end"
													>
														{showPassword ? (
															<VisibilityOff />
														) : (
															<Visibility />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
										{...register("password", {
											required: true,
										})}
										error={
											errors.password?.type === "required"
										}
										helperText={
											errors.password?.type ===
												"required" && (
												<span>
													O campo "Senha" é
													obrigatório!
												</span>
											)
										}
									/>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
									}}
								>
									{/* <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox {...label} sx={{
                                    color: "#B50000",
                                    '&.Mui-checked': {
                                        color: "#B50000",
                                    },
                                }} />
                                <span className="spanConect">
                                    Matenha-me conectado
                                </span>
                            </div> */}

									<a
										href="https://academico.aiamis.com.br/Corpore.Net/SharedServices/LibPages/RecoverPassConfirmation.aspx?UserCaption=5LK%255C9F%255C3D%255C023%255C5B&ConfirmationCaption=%255C7B%255CFAbP%255C06%255C11Q%255C7C&RecoverContainerClassName=ASP.login_aspx,%2520App_Web_gi3lseyd,%2520Version%3D0.0.0.0,%2520Culture%3Dneutral,%2520PublicKeyToken%3Dnull&RecoverInitializeMethodName=GetRecoverPassServer&ServiceAlias=CorporeRM"
										target="_blank"
										rel="noopener noreferrer"
										style={{
											textDecoration: "none",
											marginTop: "5px",
										}}
									>
										<Typography
											className="spanForget"
											style={{
												color:
													theme.palette.mode ===
													"dark"
														? theme.palette.text
																.primary
														: "#B50000",
											}}
										>
											Esqueceu a senha?
										</Typography>
									</a>
								</div>
								<Button
									type="submit"
									variant="contained"
									disableElevation
									size="large"
									fullWidth
									sx={{
										mt: 3,
										mb: 3,
									}}
									// className="buttonLogIn"
								>
									Entrar
								</Button>
								<Divider />
								<Link to="/">
									<Button
										variant="outlined"
										fullWidth
										sx={{
											mt: 3,
										}}
									>
										Continuar como administrador
									</Button>
								</Link>
								<div
									style={{
										// display: "flex",
										alignItems: "center",
										margin: "20px 0px 0px 0px",
										display: "none",
										color: "black",
									}}
								>
									<span>
										Não possui cadastro?{" "}
										<Link
											to="/signup"
											style={{
												color: "black",
												textDecoration: "none",
												marginBottom: "12px",
											}}
										>
											Inscrever-se no UNINTA Jobs
										</Link>
									</span>
								</div>
							</form>
						</Box>
					</Container>
				</Grid>
			</Grid>
			{/* <div className="" data-testid="formulario-login"></div> */}
		</Fragment>
	);
}

export default FormularioLoginAluno;
