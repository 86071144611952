import React from "react";
import TabelaEmpresas from "../../../components/Tabelas/TabelaEmpresas";

export default function Empresas(): React.ReactElement {
	return (
		<>
			<TabelaEmpresas />
		</>
	);
}
