import { createContext } from "react";

const DEFAULT_VALUE = {
	activeStep: "",
	skipped: "",
	images: "",
	videos: "",
	detections: "",
	auditions: "",
	browserAgent: "",
	setActiveStep: () => {},
	setSkipped: () => {},
	setImages: () => {},
	setVideos: () => {},
	setDetections: () => {},
	setAuditions: () => {},
	setBrowserAgent: () => {},
};

const SessionContext = createContext<any>(DEFAULT_VALUE);

export default SessionContext;
