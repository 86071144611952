import { Search } from "@mui/icons-material";
import {
	Autocomplete,
	Box,
	Button,
	Container,
	FormControl,
	Grid,
	Input,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { CriarEmpresa } from "../../../../models/Empresa";
import { Usuario } from "../../../../models/Usuario";
import { postEmpresa } from "../../../../services/crud/crud.empresa";
import { getAllUsuarios } from "../../../../services/crud/crud.usuario";
import maskCnpj from "../../../../utils/mask";
import { useReloadContext } from "../../../Tabelas/hooks/useReload";

function FormularioCadastroEmpresaView(): React.ReactElement {
	const {
		register,
		handleSubmit,
		control,
		setError,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	// const [representante, setRepresentante] = useState("");
	// const [representanteDisplay, setRepresentanteDisplay] = useState("");
	const [representantes, setRepresentantes] = useState<any[]>([]);

	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [selectedCoverFile, setSelectedCoverFile] = useState<File | null>(
		null
	);

	const [selectedField, setSelectedField] = useState<string>("cnpj");

	const [cnpj, setCnpj] = useState("");
	const [rnp, setRNP] = useState("");
	const { handleClose } = useModalContext();
	const { handleReload } = useReloadContext();
	const { auth } = useAuthContext();

	// Define a function to handle file selection
	const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			setSelectedFile(e.target.files[0]);
		}
	};

	// Define a function to handle file selection
	const handleCoverFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			setSelectedCoverFile(e.target.files[0]);
		}
	};

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedField(event.target.value as string);
	};
	/**
	 * @function cadastroEmpresa
	 * Remove a máscara do cnpj com a ultilização de um replace, faz a chamada da função de postEmpresa enviando data como parâmetro, se caso a requisição der certo emite um Notify, chama as funções handleClose e handleReload fechando o modal e dando um refresh na página.
	 * @param {CriarEmpresa} data - Representa os dados cadastrados da empresa.
	 */
	async function cadastroEmpresa(data: CriarEmpresa, error?: any) {
		// alert(JSON.stringify(Object.keys(data)))

		const formData = new FormData();

		for (const key in data) {
			if (data[key] instanceof FileList) {
				formData.append(key, data[key][0]);
			} else {
				formData.append(key, data[key]);
			}
		}

		// postEmpresa(formData).then(() => {
		// 	handleClose();
		// 	handleReload();
		// });

		await postEmpresa(formData)
			.then((data) => {
				handleClose();
				handleReload();
				toast.success("Empresa cadastrada com sucesso!");
				return data;
			})
			.catch((e: any) => {
				if (e.response && e.response.data && e.response.data.errors) {
					const apiErrors = e.response.data.errors.reduce(
						(acc: any, error: any) => {
							setError(error.field, {
								type: error.rule,
								message: error.message,
							});
							acc[error.field] = {
								type: error.rule,
								message: error.message,
							};
							return acc;
						},
						{}
					);
					console.log(apiErrors); // Debugging line
					return apiErrors;
					// set the errors object with API errors
				}
				toast.error(
					"Empresa não foi cadastrada, verifique seus dados e tente novamente!"
				);
				return e;
			});
	}

	/**
	 * @function handleIsRepresentante
	 * Faz uma verificação se o usuário é um representante.
	 * @param {Usuario} usuario - Representa o usuário.
	 * @param data
	 * @returns Retorna true ou false de acordo com a verificação.
	 */
	function handleIsRepresentante(usuario: Usuario) {
		return usuario.role_id === 2;
	}

	const [searchText] = useState("");

	const filteredRepresentantes = representantes.filter(
		(rep) =>
			rep.name.toLowerCase().includes(searchText.toLowerCase()) ||
			rep.email.toLowerCase().includes(searchText.toLowerCase())
	);
	/**
	 * @function exibirSelectRepresentantesCadastro
	 * Faz uma verificação se o usuário logado é um admin, se sim retorna um componente com um select e dentro do select faz um map de todos os usuário representantes cadastrados no banco de dados, senão retorna um componente vazio.
	 * @returns {JSX.Element} - Retorna um componente, dependendo da verificação o componente será vazio ou com um select.
	 */
	// function exibirSelectRepresentantesCadastro(): JSX.Element {
	// 	if (auth.role === "admin") {
	// 		return (
	// 			<Grid item md={12}>
	// 				<FormControl fullWidth>
	// 					<Autocomplete
	// 						disablePortal
	// 						options={filteredRepresentantes}
	// 						getOptionLabel={(option) =>
	// 							`${option.name} | ${option.email}`
	// 						}
	// 						{...register("user_id", { required: true })}
	// 						value={representanteDisplay}
	// 						onChange={(_event, newValue: any) => {
	// 							if (
	// 								newValue &&
	// 								typeof newValue === "object" &&
	// 								"id" in newValue
	// 							) {
	// 								alert(newValue.id);
	// 								setRepresentante(newValue.id);
	// 								setRepresentanteDisplay(newValue);
	// 							} else {
	// 								setRepresentante("");
	// 								setRepresentanteDisplay("");
	// 							}
	// 						}}
	// 						renderInput={(params) => {
	// 							return (
	// 								<TextField
	// 									{...params}
	// 									label="Representante"
	// 									variant="outlined"
	// 									InputProps={{
	// 										...params.InputProps,
	// 									}}
	// 								/>
	// 							);
	// 						}}
	// 					/>
	// 				</FormControl>
	// 				{/* <TextField
	// 					// className={classes.textField}
	// 					label="Representante*"
	// 					select
	// 					fullWidth
	// 					// margin="normal"
	// 					value={representante}
	// 					{...register("user_id", { required: true })}
	// 					onChange={(event) => {
	// 						alert(event.target.value);
	// 						setRepresentante(event.target.value);
	// 					}}
	// 					error={errors.user_id?.type === "required"}
	// 					helperText={
	// 						errors.user_id?.type === "required" && (
	// 							<span>
	// 								O campo "Representante" é obrigatório!
	// 							</span>
	// 						)
	// 					}
	// 				>
	// 					<MenuItem value="">Selecione um representante</MenuItem>
	// 					{representantes.map((representante, index) => (
	// 						<MenuItem
	// 							value={representante.id}
	// 							key={index}
	// 						>{`${representante.name} | ${representante.email}`}</MenuItem>
	// 					))}
	// 				</TextField> */}
	// 			</Grid>
	// 		);
	// 	} else {
	// 		return <></>;
	// 	}
	// }

	function exibirSeletor() {
		if (auth.role === "admin") {
			return (
				<Grid item md={12}>
					<FormControl fullWidth>
						<Controller
							name="user_id"
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Autocomplete
									{...field}
									disablePortal
									options={filteredRepresentantes}
									getOptionLabel={(option) =>
										`${option.name} | ${option.email}`
									}
									value={
										filteredRepresentantes.find(
											(rep) => rep.id === field.value
										) || null
									}
									onChange={(_event, newValue) => {
										if (
											newValue &&
											typeof newValue === "object" &&
											"id" in newValue
										) {
											field.onChange(newValue.id);
										} else {
											field.onChange(null);
										}
									}}
									onBlur={field.onBlur}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Representante"
											variant="outlined"
											InputProps={{
												...params.InputProps,
												startAdornment: (
													<InputAdornment position="start">
														<Search />
													</InputAdornment>
												),
												// endAdornment: (
												// 	<InputAdornment position="end">
												// 		<TextField
												// 			variant="outlined"
												// 			placeholder="Pesquisar..."
												// 			value={searchText}
												// 			onChange={(event) =>
												// 				setSearchText(
												// 					event.target
												// 						.value
												// 				)
												// 			}
												// 		/>
												// 	</InputAdornment>
												// ),
											}}
										/>
									)}
								/>
							)}
						/>
						{errors.user_id?.type === "required" && (
							<span>O campo "Representante" é obrigatório!</span>
						)}
					</FormControl>
				</Grid>
			);
		} else {
			return <></>;
		}
	}
	useEffect(() => {
		getAllUsuarios()
			.then((data) => {
				setRepresentantes(data.filter(handleIsRepresentante));
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	return (
		<Container
			component="section"
			data-testid="formulario-cadastro-empresa"
		>
			<Typography variant="h5" component="h5" sx={{ mb: 2 }}>
				Adicionar Empresa
			</Typography>
			<form
				onSubmit={handleSubmit((data) => {
					//   alert(data.cnpj);
					if (selectedFile) {
						data.logo_image = selectedFile;
					}
					if (selectedCoverFile) {
						data.cover_image = selectedCoverFile;
					}

					var d = {
						user_id: data.user_id,
						name: data.name,
						cnpj: data.cnpj,
						numero_registro: data.numero_registro,
						about: data.about,
						area: data.area,
						email_curriculum: data.email_curriculum,
						site: data.site,
						employees_number: data.employees_number,
						cover_image: data.cover_image,
						logo_image: data.logo_image,
					};

					if (selectedField === "cnpj" && data.cnpj) {
						delete d.numero_registro;
						d.cnpj = data.cnpj.replace(/[^\w]+/g, "");
					} else {
						delete d.cnpj;
					}

					cadastroEmpresa(d, setError);
				})}
				encType="multipart/form-data"
			>
				<Grid container spacing={2}>
					{exibirSeletor()}
					{/* {exibirSelectRepresentantesCadastro()} */}
					<Grid item md={12}>
						<FormControl fullWidth>
							<TextField
								// className={classes.textField}
								label="Nome Fantasia"
								type="text"
								fullWidth
								// margin="normal"
								required
								{...register("name", { required: true })}
								error={errors.name?.type === "required"}
								helperText={
									errors.name?.type === "required" && (
										<span>
											O campo "Nome Fantasia" é
											obrigatório!
										</span>
									)
								}
							/>
						</FormControl>
					</Grid>

					<Grid item md={6}>
						<FormControl fullWidth>
							<FormControl sx={{ mb: 1 }}>
								<Select
									value={selectedField}
									onChange={handleChange}
									fullWidth
									label="Selecione o campo"
								>
									<MenuItem value="cnpj">CNPJ</MenuItem>
									<MenuItem value="rnp">
										Registro Profissional
									</MenuItem>
								</Select>
							</FormControl>

							{selectedField === "cnpj" && (
								<FormControl fullWidth>
									<TextField
										label="CNPJ"
										type="text"
										fullWidth
										value={cnpj}
										{...register("cnpj")}
										onChange={(e) => {
											setCnpj(maskCnpj(e.target.value));
										}}
										error={
											errors.cnpj?.type === "required" ||
											Boolean(errors.cnpj)
										}
										helperText={
											errors.cnpj?.type === "required" ? (
												<span>
													O campo "CNPJ" é
													obrigatório!
												</span>
											) : (
												errors.cnpj?.message &&
												"Falha ao validar o CNPJ fornecido"
											)
										}
									/>
								</FormControl>
							)}
							{selectedField === "rnp" && (
								<FormControl fullWidth>
									<TextField
										label="Registro Profissional*"
										type="text"
										fullWidth
										value={rnp}
										{...register("numero_registro")}
										onChange={(e) => {
											setRNP(e.target.value);
										}}
										error={
											errors.rnp?.type === "required" ||
											Boolean(errors.rnp)
										}
										helperText={
											errors.rnp?.type === "required" ? (
												<span>
													O campo "Registro
													Profissional" é obrigatório!
												</span>
											) : (
												errors.rnp?.message &&
												"Falha ao validar o Registro Profissional fornecido"
											)
										}
									/>
								</FormControl>
							)}
						</FormControl>
					</Grid>

					<Grid item md={6}>
						<TextField
							// className={classes.textField}
							{...register("area", { required: true })}
							label="Área de atuação*"
							type="text"
							fullWidth
							// margin="normal"
							error={errors.area?.type === "required"}
							helperText={
								errors.area?.type === "required" && (
									<span>
										O campo "Área de atuação" é obrigatório!
									</span>
								)
							}
						/>
					</Grid>

					<Grid item md={12}>
						<TextField
							// className={classes.textField}
							{...register("email_curriculum", {
								required: true,
							})}
							label="E-mail para recebimento de currículos*"
							type="email"
							fullWidth
							// margin="normal"
							error={errors.email_curriculum?.type === "required"}
							helperText={
								errors.email_curriculum?.type ===
									"required" && (
									<span>
										O campo "E-mail para recebimento de
										curriculum" é obrigatório!
									</span>
								)
							}
						/>
					</Grid>

					<Grid item md={12}>
						<FormControl fullWidth>
							<TextField
								// className={classes.textField}
								{...register("about", { required: true })}
								label="Sobre a empresa"
								type="text"
								fullWidth
								multiline
								minRows={4}
								maxRows={6}
								// margin="normal"
								error={errors.about?.type === "required"}
								helperText={
									errors.about?.type === "required" && (
										<span>
											O campo "Sobre a empresa" é
											obrigatório!
										</span>
									)
								}
							/>
						</FormControl>
					</Grid>

					<Grid item md={7}>
						<FormControl fullWidth>
							<TextField
								// className={classes.textField}
								{...register("site")}
								label="Site da empresa"
								type="text"
								fullWidth
								// margin="normal"
							/>
						</FormControl>
					</Grid>

					<Grid item md={5}>
						<FormControl fullWidth>
							<TextField
								// className={classes.textField}
								{...register("employees_number")}
								label="Número de Empregados"
								type="number"
								fullWidth
								// margin="normal"
							/>
						</FormControl>
					</Grid>

					<Grid item md={12}>
						<FormControl
							fullWidth
							sx={{
								mb: 2,
							}}
						>
							<Typography
								variant="body1"
								sx={{ fontWeight: "500", mb: 1, mt: 1 }}
							>
								Envio da logo
							</Typography>
							<Controller
								name="logo_image"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<div
										style={{
											backgroundColor: "#f8f8f8",
											borderRadius: "12px",
											padding: "12px",
											position: "relative",
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "12px",
										}}
									>
										<Input
											id="logo_image"
											type="file"
											sx={{
												display: "none",
											}}
											onChange={(e: any) => {
												const selectedFile =
													e.target.files[0];
												field.onChange(selectedFile);
												handleFileSelect(e);
											}}
										/>
										<label htmlFor="logo_image">
											<Button
												variant="contained"
												component="span"
												disableElevation
											>
												Escolher arquivo
											</Button>
										</label>
										{selectedFile
											? selectedFile.name
											: "Nenhum arquivo selecionado"}

										{/* {selectedFile && (
                      <img
                        width="180"
                        height="150"
                        src={URL.createObjectURL(selectedFile)}
                        alt=""
                      />
                    )} */}
									</div>
								)}
							/>
						</FormControl>
					</Grid>

					<Grid item md={12}>
						<FormControl
							fullWidth
							sx={{
								mb: 2,
							}}
						>
							<Typography
								variant="body1"
								sx={{ fontWeight: "500", mb: 1, mt: 1 }}
							>
								Envio da image de capa
							</Typography>
							<Controller
								name="cover_image"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<div
										style={{
											backgroundColor: "#f8f8f8",
											borderRadius: "12px",
											padding: "12px",
											position: "relative",
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "12px",
										}}
									>
										<Input
											id="cover_image"
											type="file"
											sx={{
												display: "none",
											}}
											onChange={(e: any) => {
												const selectedCoverFile =
													e.target.files[0];
												field.onChange(
													selectedCoverFile
												);
												handleCoverFileSelect(e);
											}}
										/>
										<label htmlFor="cover_image">
											<Button
												variant="contained"
												component="span"
												disableElevation
											>
												Escolher arquivo
											</Button>
										</label>
										{selectedCoverFile
											? selectedCoverFile.name
											: "Nenhum arquivo selecionado"}
										{/* <pre>{JSON.stringify(selectedCoverFile, null, 4)}</pre> */}
										{/* {selectedCoverFile && (
                      <img
                        width="180"
                        height="150"
                        src={URL.createObjectURL(selectedCoverFile)}
                        alt=""
                      />
                    )} */}
									</div>
								)}
							/>
						</FormControl>
					</Grid>

					<Grid item md={12}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								gap: "12px",
								mt: 3,
								mb: 3,
							}}
						>
							<Button variant="text" onClick={handleClose}>
								Cancelar
							</Button>
							<Button
								variant="contained"
								disableElevation
								type="submit"
							>
								Adicionar
							</Button>
							{/* <BotaoVoltar /> <BotaoAdicionar /> */}
						</Box>
					</Grid>
				</Grid>
			</form>
		</Container>
	);
}

export default FormularioCadastroEmpresaView;
