import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { TipoContrato } from "../../../../models/TipoContrato";
import { deleteTipoContrato } from "../../../../services/crud/crud.tiposContrato";

function ModalDeleteTipoContratoView(props) {
	const { open, handleOpen, handleClose } = useModalContext();
	let idTipoContratoDeletar;

	/**
	 * @function handleNaoEDeletado
	 * Faz uma verifição se o tipo de contrato foi deletado.
	 * @param {TipoContrato} tipoContrato - Representa cada tipo de contrato.
	 * @returns {boolean} - Verifica os contratos que não foram deletados.
	 */
	function handleNaoEDeletado(tipoContrato: TipoContrato) {
		return tipoContrato.id !== idTipoContratoDeletar;
	}

	/**
	 * @function handleDeletarTipoContrato
	 * Faz uma verificação se o usuário realmente quer deletar o tipo de contrato que foi selecionado, se sim, faz uma requisição para a api deletando-o do banco e filtrando os tipos de contrato que não foram deletados para serem mostrados na tabela atualizada.
	 * @param {number} id - Id do tipo de contrato a ser deletado.
	 */
	function handleDeletarTipoContrato(id: number) {
		handleClose();
		deleteTipoContrato(id)
			.then(() => {
				idTipoContratoDeletar = id;
				let novoArrayTiposContratos =
					props.dados.tableRows.tiposContratos.filter(
						handleNaoEDeletado
					);
				props.dados.setTableRows({
					tiposContratos: novoArrayTiposContratos,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	}

	return (
		<>
			<Button
				onClick={() => {
					handleOpen();
				}}
				title="Deletar"
				data-testid="modal-delete-tipo-contrato"
			>
				<DeleteForeverIcon style={{ color: "#292B2D" }} />
			</Button>

			<Dialog open={open}>
				<DialogTitle>
					{"Você realmente deseja deletar este contrato?"}
				</DialogTitle>
				<DialogActions>
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "end",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							style={{
								backgroundColor: "#B50000",
								color: "white",
								height: "40px",
								width: "20%",
								marginRight: "20px",
							}}
							onClick={() => {
								handleClose();
							}}
						>
							Não
						</Button>
						<Button
							variant="contained"
							color="primary"
							style={{
								backgroundColor: "#B50000",
								color: "white",
								height: "40px",
								width: "20%",
								marginRight: "20px",
							}}
							onClick={() => {
								handleDeletarTipoContrato(props.tipoContratoId);
							}}
						>
							Sim
						</Button>
					</div>
				</DialogActions>
			</Dialog>
		</>
	);
}
export default ModalDeleteTipoContratoView;
