import React from "react";
import { ModalContextProvider } from "../../../context/Modal/modal.context";
import { ReloadContextProvider } from "../reload.context";
import TabelaEmpresasView from "./TabelaEmpresas.view";

function TabelaEmpresas(): React.ReactElement {
	return (
		<ReloadContextProvider>
			<ModalContextProvider>
				<TabelaEmpresasView />
			</ModalContextProvider>
		</ReloadContextProvider>
	);
}

export default TabelaEmpresas;
