import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Box,
	Button,
	Container,
	IconButton,
	InputAdornment,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { NivelUsuario } from "../../../../models/NivelUsuario";
import { CriarUsuario } from "../../../../models/Usuario";
import { getNiveisUsuario } from "../../../../services/crud/crud.nivelUsuario";
import { postUsuario } from "../../../../services/crud/crud.usuario";
import Notify from "../../../../utils/notification";
import { useReloadContext } from "../../../Tabelas/hooks/useReload";
import "../styles.scss";

function FormularioCadastroUsuarioView(): React.ReactElement {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	const [role, setRole] = useState("");
	const [niveisUsuario, setNiveisUsuario] = useState<Array<NivelUsuario>>([]);
	const { handleClose } = useModalContext();
	const { handleReload } = useReloadContext();
	const useStyles = makeStyles((theme) => ({
		textField: {
			"& label": {
				color: "grey !important",
			},
			"& label.Mui-focused": {
				color: "black !important",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				// '&:hover fieldset': {
				//   borderColor: 'blue',
				// },
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));
	const classes = useStyles();

	/**
	 * @function verificarSenha
	 * Faz uma verificação se a senha e a senha de corfirmação que o usuário digitou são iguais.
	 * @param {string} senha - Representa a senha que o usuário digitou.
	 * @param {string} confirmacao - Representa a senha de confirmação que o usuário digitou.
	 * @returns Retorna true ou false de acordo com a verificação.
	 */
	function verificarSenha(senha: string, confirmacao: string): boolean {
		if (senha !== confirmacao) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * @function cadastroUsuario
	 * Faz uma verificação, se a verificação der verdadeira faz a chamada da função de postUsuario enviando data como parâmetro, se caso a requisição der certo emite um Notify, chama as funções handleClose e handleReload fechando o modal e dando um refresh na página, se caso a verificação der falso emite um Notify.
	 * @param data
	 */
	async function cadastroUsuario(data: CriarUsuario) {
		if (verificarSenha(data.password, data.passwordConfirm)) {
			postUsuario(data)
				.then(() => {
					handleClose();
					handleReload();
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			Notify("Senhas diferentes!", "error");
		}
	}

	const [showPassword, setShowPassword] = React.useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const togglePasswordConfirmVisibility = () => {
		setShowPasswordConfirm((prevShowPassword) => !prevShowPassword);
	};

	useEffect(() => {
		getNiveisUsuario()
			.then((data) => {
				setNiveisUsuario(data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	return (
		<Container
			// className="container"
			component="section"
			style={{ width: "100%", height: "100%" }}
			data-testid="formulario-cadastro-usuario"
		>
			<Box mt={3}>
				<Typography variant="h5" component="h5">
					Adicionar novo Usuário
				</Typography>
			</Box>
			<form
				onSubmit={handleSubmit((data) =>
					cadastroUsuario({
						name: data.name,
						email: data.email,
						password: data.password,
						passwordConfirm: data.passwordConfirm,
						is_active: Boolean(data.is_active),
						role_id: data.role_id,
					})
				)}
				style={{ width: "100%", height: "100%" }}
			>
				<div>
					<TextField
						className={classes.textField}
						label="Nome*"
						type="text"
						fullWidth
						margin="normal"
						{...register("name", { required: true })}
						error={errors.name?.type === "required"}
						helperText={
							errors.name?.type === "required" && (
								<span>O campo "Nome" é obrigatório!</span>
							)
						}
					/>
				</div>

				<div>
					<TextField
						className={classes.textField}
						{...register("email", { required: true })}
						label="E-mail*"
						type="email"
						fullWidth
						margin="normal"
						error={errors.email?.type === "required"}
						helperText={
							errors.email?.type === "required" && (
								<span>O campo "E-mail" é obrigatório!</span>
							)
						}
					/>
				</div>

				<div>
					<TextField
						style={{ marginBottom: "0px" }}
						className={classes.textField}
						{...register("password", {
							required: true,
							minLength: {
								value: 8,
								message:
									"A senha tem que ter no mínimo 8 dígitos",
							},
						})}
						label="Senha*"
						type={showPassword ? "text" : "password"}
						id="senha"
						fullWidth
						margin="normal"
						error={errors.password?.type === "required"}
						helperText={
							errors.password?.type === "required" && (
								<span>O campo "Senha" é obrigatório!</span>
							)
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={togglePasswordVisibility}
									>
										{showPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{errors.password?.message !== "" && (
						<span
							style={{
								color: "#d32f2f",
								fontSize: "0.75rem",
								margin: "0px 0px 0px 15px",
							}}
						>
							{errors.password?.message}
						</span>
					)}
				</div>

				<div>
					<TextField
						style={{ marginBottom: "0px" }}
						className={classes.textField}
						{...register("passwordConfirm", {
							required: true,
							minLength: {
								value: 8,
								message:
									"A senha tem que ter no mínimo 8 dígitos",
							},
						})}
						label="Confirme a senha*"
						type={showPasswordConfirm ? "text" : "password"}
						id="senhaConfirmacao"
						fullWidth
						margin="normal"
						error={errors.passwordConfirm?.type === "required"}
						helperText={
							errors.passwordConfirm?.type === "required" && (
								<span>
									O campo "Confirme a senha" é obrigatório!
								</span>
							)
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={
											togglePasswordConfirmVisibility
										}
									>
										{showPasswordConfirm ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{errors.passwordConfirm?.message !== "" && (
						<span
							style={{
								color: "#d32f2f",
								fontSize: "0.75rem",
								margin: "-5px 0px 0px 17px",
							}}
						>
							{errors.passwordConfirm?.message}
						</span>
					)}
				</div>

				<div>
					<Controller
						name="role_id"
						control={control}
						rules={{
							required: true,
						}}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="Função*"
								select
								fullWidth
								margin="normal"
								value={value}
								// {...register("role_id", { required: true })}
								onChange={(e) => {
									onChange(e);
								}}
								error={errors.role_id?.type === "required"}
								helperText={
									errors.role_id?.type === "required" && (
										<span>
											O campo "Função" é obrigatório!
										</span>
									)
								}
							>
								<MenuItem value="">
									Selecione a função do usuário
								</MenuItem>
								{niveisUsuario.map((nivelUsuario, index) => (
									<MenuItem
										value={nivelUsuario.id}
										key={index}
									>
										{nivelUsuario.name}
									</MenuItem>
								))}
							</TextField>
						)}
					/>
				</div>

				<div>
					<TextField
						className={classes.textField}
						label="Status do Usuário*"
						select
						fullWidth
						margin="normal"
						value={role}
						{...register("is_active", { required: true })}
						onChange={(event) => {
							setRole(event.target.value);
						}}
						error={errors.is_active?.type === "required"}
						helperText={
							errors.is_active?.type === "required" && (
								<span>
									O campo "Status do Usuário" é obrigatório!
								</span>
							)
						}
					>
						<MenuItem value="">
							Selecione o status do usuário
						</MenuItem>
						<MenuItem value={1}>Ativado</MenuItem>
						<MenuItem value={0}>Desativado</MenuItem>
					</TextField>
				</div>

				<Box
					sx={{
						mt: 3,
						mb: 3,
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
						gap: "12px",
					}}
				>
					<Button variant="text" onClick={handleClose}>
						Cancelar
					</Button>
					<Button variant="contained" disableElevation type="submit">
						Adicionar
					</Button>
					{/* <BotaoVoltar />
          <BotaoAdicionar /> */}
				</Box>
			</form>
		</Container>
	);
}

export default FormularioCadastroUsuarioView;
