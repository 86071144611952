import { Paper, Table, TableContainer } from "@mui/material";
import { ReactNode } from "react";

interface ActivityTableContainerProps {
	children?: ReactNode;
}

export function ActivityTableContainer({
	children,
}: ActivityTableContainerProps) {
	return (
		<TableContainer
			component={Paper}
			style={{
				overflow: "hidden",
				overflowX: "scroll",
				borderTopRightRadius: "12px",
				borderTopLeftRadius: "12px",
				// border: "solid thin #ccc",
			}}
		>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				{children}
			</Table>
		</TableContainer>
	);
}
