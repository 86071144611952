import { Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../context/Authentication/hooks/useAuth";
import { RespostaEmpresaId } from "../../../models/Empresa";
import { Usuario } from "../../../models/Usuario";
import { Vaga } from "../../../models/Vaga";
import {
	getEmpresa,
	getEmpresaRepresentante,
} from "../../../services/crud/crud.empresa";
import { getTipoContrato } from "../../../services/crud/crud.tiposContrato";
import { getUsuario } from "../../../services/crud/crud.usuario";
import {
	getVaga,
	getVagaRepresentante,
} from "../../../services/crud/crud.vaga";
import { estaAtivo } from "../../../utils/funcoes-globais";
import "../style.scss";

function ListaDetalhesVagaView(): React.ReactElement {
	let idVaga = Number(localStorage.getItem("id_vaga"));
	const [empresa, setEmpresa] = useState<RespostaEmpresaId>();
	const [usuario, setUsuario] = useState<Usuario>();
	const [vaga, setVaga] = useState<Vaga>();
	const { auth } = useAuthContext();
	// const { handleClose } = useModalContext();

	useEffect(() => {
		if (auth.role === "admin" && idVaga) {
			getVaga(idVaga)
				.then((res) => {
					let data = res;
					setVaga(data);
					getEmpresa(data.companies_id)
						.then((data) => {
							setEmpresa(data);
							getUsuario(data.user_id)
								.then((data) => {
									setUsuario(data);
									localStorage.removeItem("id_vaga");
								})
								.catch((e) => {
									console.log(e);
								});
						})
						.catch((e) => {
							console.log(e);
						});
					return data;
				})
				.then(async (data) => {
					await getTipoContrato(
						data.contract_types_id as number
					).then((res) => {
						setVaga((v: any) => {
							return { ...v, contract_details: res };
						});
					});
				});
		} else if (idVaga) {
			getVagaRepresentante(idVaga)
				.then((data) => {
					setVaga(data);
					getEmpresaRepresentante(data.companies_id)
						.then((data) => {
							setEmpresa(data);
							getUsuario(data.user_id)
								.then((data) => {
									setUsuario(data);
									localStorage.removeItem("id_vaga");
								})
								.catch((e) => {
									console.log(e);
								});
						})
						.catch((e) => {
							console.log(e);
						});
					return data;
				})
				.then(async (data) => {
					await getTipoContrato(
						data.contract_types_id as number
					).then((res) => {
						setVaga((v: any) => {
							return { ...v, contract_details: res };
						});
					});
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [idVaga, auth.role]);

	return (
		<>
			<Container data-testid="detalhes-vaga">
				<Typography variant="h5" sx={{ mb: 3 }}>
					{" "}
					Representante da empresa:{" "}
				</Typography>
				<table className="DetalhesUsuarioTable">
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography>Nome:</Typography>
						</td>
						<td className="typographyDetalhes">
							<Typography>{usuario?.name}</Typography>
						</td>
					</tr>
				</table>
				{/* <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Nome:</Typography>
          <Typography className="typographyDetalhes">
            {usuario?.name}
          </Typography>
        </Box> */}
				<Typography variant="h5" sx={{ mt: 3, mb: 3 }}>
					{" "}
					Informações da empresa:{" "}
				</Typography>
				<table className="DetalhesUsuarioTable">
					{/* <tr>
						<td className="typographyDetalhesUsuario">
							<Typography>Id:</Typography>
						</td>
						<td className="typographyDetalhes">
							<Typography>{vaga?.id}</Typography>
						</td>
					</tr> */}
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography>Nome:</Typography>
						</td>
						<td className="typographyDetalhes">
							<Typography>{empresa?.name}</Typography>
						</td>
					</tr>
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography>CNPJ:</Typography>
						</td>
						<td className="typographyDetalhes">
							<Typography>{empresa?.cnpj}</Typography>
						</td>
					</tr>
					<tr>
						<td className="typographyDetalhesUsuario">
							<Typography>Site:</Typography>
						</td>
						<td className="typographyDetalhes">
							<Typography>{empresa?.site}</Typography>
						</td>
					</tr>
				</table>

				{/* <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Id:</Typography>
          <Typography className="typographyDetalhes">{vaga?.id}</Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Nome:</Typography>
          <Typography className="typographyDetalhes">
            {empresa?.name}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">CNPJ:</Typography>
          <Typography className="typographyDetalhes">
            {empresa?.cnpj}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Site:</Typography>
          <Typography className="typographyDetalhes">
            {empresa?.site}
          </Typography>
        </Box>
        <hr></hr> */}

				<Typography variant="h5" sx={{ mt: 3, mb: 3 }}>
					{" "}
					Informações da Vaga:{" "}
				</Typography>
				{/* <pre>{JSON.stringify(vaga, null, 4)}</pre> */}
				<table className="DetalhesUsuarioTable">
					{/* <tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Id:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{vaga?.id}
							</Typography>
						</td>
					</tr> */}
					<tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Status:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{estaAtivo(Boolean(vaga?.status))}
							</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Vaga:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{vaga?.title}
							</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Local:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{`${vaga?.city} - ${vaga?.state}`}
							</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Área de conhecimento:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{vaga?.area}
							</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Tipo de contrato:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{vaga?.contract_details?.name}
							</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Descrição:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{vaga?.description}
							</Typography>
						</td>
					</tr>
				</table>

				{/* <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Id:</Typography>
          <Typography className="typographyDetalhes">{vaga?.id}</Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Status:</Typography>
          <Typography className="typographyDetalhes">
            {estaAtivo(Boolean(vaga?.status))}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Título:</Typography>
          <Typography className="typographyDetalhes">{vaga?.title}</Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Local:</Typography>
          <Typography className="typographyDetalhes">{`${vaga?.city} - ${vaga?.state}`}</Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">
            Área de conhecimento:
          </Typography>
          <Typography className="typographyDetalhes">{vaga?.area}</Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">
            Tipo de contrato:
          </Typography>
          <Typography className="typographyDetalhes">
            {vaga?.contract_types_id}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">
            Descrição:
          </Typography>
          <Typography className="typographyDetalhes">
            {vaga?.description}
          </Typography>
        </Box>
        <hr></hr> */}
			</Container>
			{/* <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                    style={{
                        backgroundColor: "#B50000", color: "white", height: "40px", width: "15%"
                    }}
                >
                    voltar
                </ Button>
            </div> */}
		</>
	);
}

export default ListaDetalhesVagaView;
