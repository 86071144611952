import {
	ArrowBack,
	CopyAll,
	Info,
	Refresh,
	Stop,
	Upload,
	Videocam,
} from "@mui/icons-material";
import {
	Backdrop,
	Button,
	Chip,
	CircularProgress,
	Container,
	Dialog,
	DialogContent,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import useSession from "../../../../context/session/SessionHook";
import api from "../../../../services/api";
import ProgressIndicator from "../ProgressIndicator";
import { convertBase64ToBlob } from "../biometry/biometry.helper";
import BiometryService from "../biometry/biometry.service";
import { fileManagerApi } from "../filemanager";
import { compareFaces, uploadFilesViaLambda } from "../service/lambda";
import { RekognitionService } from "./Rekognition.service";
import { ActivityService } from "./activity.service";
import AuditionService from "./audition.service";

export default function AuditionComponent() {
	const theme = useTheme();
	const params = useParams();
	const history = useNavigate();

	// const { state } = useAuth();
	const { setActiveStep, images, setImages, browserAgent } = useSession();

	// const [activity, setActivity] = useState<any>({});
	const [auditionCode, setAuditionCode] = useState<any>({});

	const playbackRef = React.useRef<any>(null);
	const playbackSubmittedRef = React.useRef<any>(null);
	const videoRef = React.useRef<any>(null);
	const downloadLinkRef = React.useRef<any>(null);
	const stopButtonRef = React.useRef<any>(null);
	const canvasRef = React.useRef<any>(null);
	const countDownRef = React.useRef<any>(null);
	const [url, setURL] = React.useState<string | null>(null);
	const [startRecording, setStartRecording] = React.useState<boolean>(false);
	const [showPreview, setShowPreview] = React.useState<boolean>(false);
	const [shouldStop, setShouldStop] = React.useState<boolean>(false);
	const [showBackdrop, setShowBackdrop] = React.useState<boolean>(false);

	const [openPreviewer, setOpenPreviewer] = React.useState<boolean>(false);

	const [vagas, setVagas] = React.useState<any[] | any>([]);
	const [vagaId, setVagaId] = React.useState<number | string | null>(null);
	const [topicos, setTopicos] = React.useState<any | any[]>([]);

	const [showUploadProgressBackdrop, setShowUploadProgressBackdrop] =
		React.useState<boolean>(false);
	const [uploadProgressPercent, setUploadProgressPercent] =
		useState<number>(0);

	const onUploadProgress = (progressEvent: any) => {
		const progress = Math.round(
			(progressEvent.loaded / (progressEvent.total ?? 0)) * 100
		);
		setUploadProgressPercent(progress);
		console.log(`Upload Progress: ${progress}%`);
	};

	async function gerarRoteiro(id?: number | string) {
		if (vagaId === 0 || id === 0) {
			return toast.error("Selecione uma vaga válida!");
		}

		setShowBackdrop(true);
		setTopicos([]);

		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		// Mock do comportamento do componente

		try {
			const data = `Roteiro gerado com sucesso para a vaga ${vagaId}`;

			await api
				.get(`/interview/job/${id ?? vagaId}`, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((res: AxiosResponse<any>) => {
					const questions = res.data.text.split("\n");

					// Remove any empty elements or elements with only whitespace
					// const filteredQuestions = questions.filter(
					// 	(question) => question.trim() !== ""
					// );
					// Remove the numbers from the beginning of each question
					const filteredQuestions = questions.map((question) =>
						question.replace(/^\d+\.\s/, "")
					);

					setTopicos(filteredQuestions);
				})
				.catch(async (err: any) => {
					if (err.response.status === 404) {
						await api
							.post(
								`/interview`,
								{ id_job: id ?? vagaId },
								{
									headers: {
										Authorization: `Bearer ${token}`,
									},
								}
							)
							.then((res: AxiosResponse<any>) => {
								const questions = res.data.text.split("\n");

								// Remove any empty elements or elements with only whitespace
								// const filteredQuestions = questions.filter(
								// 	(question) => question.trim() !== ""
								// );
								// Remove the numbers from the beginning of each question
								const filteredQuestions = questions.map(
									(question) =>
										question.replace(/^\d+\.\s/, "")
								);

								setTopicos(filteredQuestions);
							});
					}
				})
				.finally(() => {});

			toast.success(data);
		} catch (error) {
			// Handle any errors that might occur during the Promise execution
			console.error("Error:", error);
		} finally {
			setShowBackdrop(false);
		}
	}

	async function getVagas() {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		await api
			.get("/jobs-for-student", {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				console.log(res.data);
				setVagas(res.data);
			});
	}

	const [acItems, setAcItems] = useState<any[]>([]);
	async function getActivitieItems() {
		return await new AuditionService(fileManagerApi)
			.getActivity(params.activity_id)
			.then((res: any) => {
				setAcItems(res.data.item);
			});
	}

	React.useEffect(() => {
		getVagas();
		getActivitieItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [err, setErr] = React.useState<any>(null);
	/**
	 * @description Função responsável por iniciar o stream de vídeo e áudio
	 */
	function getUserAudioAndVideo() {
		var player = videoRef.current;
		let stopped = false;

		const handleRecording = function (stream: MediaStream) {
			// BrowserAgent true -> Safari; false -> Chrome based
			const options = {
				mimeType: browserAgent ? "video/mp4" : "video/webm",
				videoBitsPerSecond: 500000, // Adjust as needed, lower values will reduce quality and file size
			};
			const recordedChunks: any = [];
			const mediaRecorder = new MediaRecorder(stream, options);

			// Se o usuário está no Safari então usa o onstop, senão adiciona o evento por meio do listener
			if (browserAgent) {
				mediaRecorder.onstop = function () {
					// Calculate the size in megabytes
					const blob = new Blob(recordedChunks);
					const sizeInBytes = blob.size;
					const sizeInMegabytes = sizeInBytes / (1024 * 1024);
					console.log(
						`Recorded video size: ${sizeInMegabytes.toFixed(2)} MB`
					);
					//TODO: remover alert do tamanho do vídeo
					// alert(
					// 	`Recorded video size: ${sizeInMegabytes.toFixed(2)} MB`
					// );
					// End calc

					downloadLinkRef.current.href = URL.createObjectURL(
						new Blob(recordedChunks, {
							type: browserAgent ? "video/mp4" : "video/webm",
						})
					);
					downloadLinkRef.current.download = "acetest.mp4";

					setURL(URL.createObjectURL(new Blob(recordedChunks)));

					if (playbackRef.current) {
						try {
							playbackRef.current.src = URL.createObjectURL(
								new Blob(recordedChunks, {
									type: browserAgent
										? "video/mp4"
										: "video/webm",
								})
							);
							playbackRef.current.controls = true;
							playbackRef.current.play();
						} catch (e) {
							console.error(e);
						}
					}
				};
			} else {
				mediaRecorder.addEventListener("stop", function () {
					const blob = new Blob(recordedChunks, {
						type: browserAgent ? "video/mp4" : "video/webm",
					});

					// Calculate the size in megabytes
					const sizeInBytes = blob.size;
					const sizeInMegabytes = sizeInBytes / (1024 * 1024);

					// Alert do tamanho do vídeo gerado
					// alert(
					// 	`Recorded video size: ${sizeInMegabytes.toFixed(2)} MB`
					// );
					console.log(
						`Recorded video size: ${sizeInMegabytes.toFixed(2)} MB`
					);

					downloadLinkRef.current.href = URL.createObjectURL(
						new Blob(recordedChunks)
					);
					downloadLinkRef.current.download = "acetest.webm";

					setURL(URL.createObjectURL(new Blob(recordedChunks)));

					if (playbackRef.current) {
						try {
							playbackRef.current.src = URL.createObjectURL(
								new Blob(recordedChunks)
							);
							playbackRef.current.controls = true;
							playbackRef.current.play();
						} catch (e) {
							console.error(e);
						}
					}
				});
			}

			// captura o stream de vídeo
			mediaRecorder.addEventListener("dataavailable", function (e) {
				if (e.data.size > 0) {
					recordedChunks.push(e.data);
				}

				if (shouldStop === true && stopped === false) {
					mediaRecorder.stop();
					stopped = true;
				}
			});

			// inicia o stream de vídeo
			mediaRecorder.start();

			if (stopButtonRef && stopButtonRef.current)
				stopButtonRef?.current?.addEventListener(
					"click",
					function onStopClick() {
						// habilita a exibicao da previa
						setShowPreview(true);

						// interrompe o stream de video
						// stopAudioAndVideoStream();

						// interrompe o recorder
						mediaRecorder.stop();
						// seta o active step para 2 (etapa de preview)
						setActiveStep(2);
						// seta o startRecording para false
						setStartRecording(false);

						// remove o evento de click do botao de stop
						// stopButtonRef.current.removeEventListener("click", onStopClick);

						// interrompe o timer
						if (countDownRef.current) {
							countDownRef.current.getApi().stop();
						}
					}
				);
		};

		const handleSuccess = (stream: MediaStream) => {
			player.srcObject = stream;
			handleRecording(stream);
		};

		navigator.mediaDevices
			.getUserMedia({
				audio: true,
				video: {
					width: window.innerWidth < 800 ? 240 : 427, // Adjust the resolution as needed
					height: window.innerWidth < 800 ? 427 : 240,
				},
				// video: {
				// 	width: window.innerWidth < 800 ? 480 / 2 : 854 / 2,
				// 	height: window.innerWidth < 800 ? 854 / 2 : 480 / 2,
				// },
			})
			.then(handleSuccess)
			.then(() => player.play());
	}

	/**
	 * Stop audio and video stream
	 */
	// const stopAudioAndVideoStream = () => {
	// 	var stream: any = videoRef.current.srcObject;
	// 	var tracks: any = stream.getTracks();
	// 	tracks?.forEach((track: any) => {
	// 		track?.stop();
	// 	});
	// 	videoRef.current.srcObject = null;
	// 	setShowPreview(true);
	// };

	useEffect(() => {
		const extAud = JSON.parse(
			localStorage.getItem("@current_audition") as string
		);

		if (extAud && extAud.activity_id === params.activity_id) {
			setAuditionCode(extAud);
		} else {
			let user: any = JSON.parse(
				localStorage.getItem("@palco-profissional:token") as string
			).data.usuario.id;

			new AuditionService(fileManagerApi)
				.create({
					user_id: user,
					activity_id: Number(params.activity_id),
				})
				.then((res: AxiosResponse<any>) => {
					setAuditionCode(res.data);
					localStorage.setItem(
						"@current_audition",
						JSON.stringify(res.data)
					);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	/**
	 * faz o upload do vídeo para o backend.
	 */
	async function uploadVideoFile() {
		// video

		let formData = new FormData();
		let response = await fetch(downloadLinkRef.current);
		let videoBlob = await response.blob();

		setShowBackdrop(true);

		/**
		 * Generate file name and submit it to the lambda
		 * Generate {data} and submit it to the backend API
		 */

		formData.append("video", videoBlob, "probe.webm");
		let user: any = JSON.parse(
			localStorage.getItem("@palco-profissional:token") as string
		).data.usuario.id;

		formData.append("ra", user);
		formData.append("type", "video");
		formData.append("label", "Palco Virtual");
		formData.append("audition", auditionCode.code);
		formData.append("audition_id", auditionCode.id);

		setShowUploadProgressBackdrop(true);

		try {
			await uploadFilesViaLambda(
				"state.usuario",
				videoBlob,
				"probe.webm",
				auditionCode.code,
				true,
				"chrome",
				onUploadProgress
			)
				.then(async () => {
					const pi = 5000;
					setTimeout(() => {}, pi);
					let key = `${process.env.REACT_APP_FILE_LOCATION}/chrome/${auditionCode.code}`;
					await new AuditionService(fileManagerApi)
						.checkFileExists(key)
						.then(async (res: any) => {
							// alert(JSON.stringify(res.data));
							if (JSON.parse(res.data)) {
								await new AuditionService(fileManagerApi)
									.update(auditionCode.id, {
										file_key: `${process.env.REACT_APP_FILE_LOCATION}/videos/${auditionCode.code}.mp4`,
									})
									.then(() => {
										// toast.success("Vídeo enviado com sucesso");
										setActiveStep(4);
										localStorage.removeItem(
											"@current_audition"
										);
									})
									.then(() => {
										// history(`/submission/${auditionCode.id}`);
										setOpenPreviewer(true);
										setActiveStep(4);
									});
							} else {
								throw new Error("File not found");
							}
						});
				})
				.finally(() => {
					setShowUploadProgressBackdrop(true);
				});

			setShowBackdrop(false);
		} catch (err) {
			console.clear();
			console.error(err);
			// toast.error(err.message)
		}
	}

	/**
	 * faz o upload da imagem do canvas para o backend.
	 * @param {*}
	 * @returns {*}
	 */
	async function handleCanvasUploadLambda() {
		if (
			canvasRef.current &&
			videoRef &&
			videoRef.current &&
			videoRef.current.videoWidth &&
			videoRef.current.videoHeight
		) {
			var el: HTMLCanvasElement | any = document.createElement("canvas");

			/**
			 * o trecho el.width = videoRef.current.videoWidth -> soluciona o problema de altura do canvas
			 * o trecho el.height = videoRef.current.videoHeight -> soluciona o problema de largura do canvas
			 */
			el.width = videoRef.current.videoWidth;
			el.height = videoRef.current.videoHeight;

			el.getContext("2d").drawImage(
				videoRef.current,
				0,
				0,
				videoRef.current.videoWidth,
				videoRef.current.videoHeight
			);
			canvasRef.current.appendChild(el);

			let formData = new FormData();
			formData.append("image", el.toDataURL("image/png"));

			const file = convertBase64ToBlob(el.toDataURL("image/png"));
			const temp_postfix = new Date().getTime();
			let user: any = JSON.parse(
				localStorage.getItem("@palco-profissional:token") as string
			).data.usuario.id;

			const temp_path = `${process.env.REACT_APP_FILE_LOCATION}/${user}/sample_${temp_postfix}`;

			try {
				console.log("IIIIIIIIIIIIII");
				console.log(images);
				await uploadFilesViaLambda(
					"state.usuario",
					file,
					"image/png",
					temp_path
				).then(async () => {
					await compareFaces({
						target: temp_path,
						source:
							images[Math.floor(Math.random() * 5) + 1]
								.file_key ?? images[0].file_key,
					}).then(async (response: AxiosResponse<any>) => {
						let maxConfidence = -Infinity;
						let maxConfidenceType = "";

						if (response.data.emotions) {
							const emotions = response.data.emotions;

							emotions.forEach((emotion: any) => {
								const confidence = parseFloat(
									emotion.Confidence
								);
								if (confidence > maxConfidence) {
									maxConfidence = confidence;
									maxConfidenceType = emotion.Type;
								}
							});
						}

						await new RekognitionService().create({
							audition_id: auditionCode.id,
							file_key: temp_path,
							recognition_feedback: response.data.matches,
							similarity: Number(response.data.Similarity),
							emotion: maxConfidenceType,
							emotion_confidence: Number(maxConfidence),
						});
					});
				});
			} catch (err) {
				// Pass
				console.log(err);
				console.error(err);
			}
		}
	}

	/**
	 * memoiza o tempo de execução do timer
	 */
	const time = useMemo(() => {
		// return Date.now() + $settings.settings.audition_duration ?? 420000;
		return Date.now() + 420000;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * renderiza o timer na tela do usuário
	 * @param param0
	 * @returns
	 */
	function renderer({ minutes, seconds, completed }: any) {
		if (completed) {
			// setTimerOn(false)
			setShouldStop(true);
			return <span>Tempo esgotado!</span>;
		} else {
			return (
				<React.Fragment>
					<span>
						{minutes < 10 ? "0" + minutes : minutes}:
						{seconds < 10 ? "0" + seconds : seconds}
					</span>
				</React.Fragment>
			);
		}
	}

	/**
	 * 	Metodo que inicia a gravação
	 * 	@description Inicia a gravação e o timer
	 * 	@author Lucas Gabriel
	 * 	30000 -> corresponde a cerca de 30 segundos
	 * 	1800000 -> corresponde a cerca de 30 minutos
	 * 	$settings.settings.audition_duration ?? 420000 -> corresponde a cerca de 7 minutos
	 * 	chamada alternativa: setInterval(handleCanvasUploadLambda, $settings.settings.rekognition_interaval ?? 10000);
	 */
	function handleStart() {
		const ct = countDownRef.current;

		if (ct) {
			const api = ct.getApi();
			getUserAudioAndVideo();
			api.start();
			setStartRecording(true);

			setInterval(handleCanvasUploadLambda, 30000);
		}
	}

	React.useEffect(() => {
		// fileManagerApi.get(`/file-retrieve?ra=${user.ra}&type=image`)
		let user: any = JSON.parse(
			localStorage.getItem("@palco-profissional:token") as string
		).data.usuario.id;

		new BiometryService(fileManagerApi)
			.getAll(user)
			.then((response: AxiosResponse<any>) => {
				setImages(response.data);
				if (response.data.length < 5) {
					window.location.href = "/biometrics";
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setActiveStep(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 *
	 */
	React.useEffect(() => {
		if (params)
			new ActivityService(fileManagerApi)
				.findOne(Number(params.activity_id))
				.then((res: AxiosResponse<any>) => {
					// setActivity(res.data)
				})
				.catch((err) => {
					setErr(true);
					// toast.error("A atividade informada não existe!");
				});

		// .catch((err) => {
		// 	setTimeout(() => toast.error("A atividade informada não existe!"), 3000)
		// 	window.location.href = "/"
		// })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	/**
	 * Retorna o componente
	 */

	return err ? (
		<>
			<Container>
				<Grid container>
					<Grid item md={12}>
						<Box
							sx={{
								minHeight: "30vh",
								display: "flex",
								flexDirection: "column",
								gap: "12px",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Typography variant="h5">
								A atividade informada não existe!
							</Typography>
							<Typography variant="body1">
								Clique no botão retornar para retomar a
								navegação.
							</Typography>
							<Button
								variant="outlined"
								startIcon={<ArrowBack />}
								onClick={() => {
									history("/");
								}}
							>
								Voltar para o início
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</>
	) : (
		<React.Fragment>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={showBackdrop}
				// open={true}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
					}}
				>
					<CircularProgress color="inherit" />
					{showUploadProgressBackdrop && (
						<Typography variant="h5" sx={{ mt: 3 }}>
							{uploadProgressPercent}% Enviados
						</Typography>
					)}
					{/* <Typography variant="h5"></Typography> */}
				</Box>
			</Backdrop>
			<Container sx={{ marginTop: "10vh" }} maxWidth="lg">
				{/* <pre>
						{JSON.stringify(auditionCode, null, 4)}
					</pre> */}
				{/* <Box>
					<pre>
						{JSON.stringify(activity, null, 4)}
					</pre>
				</Box> */}
				<Box
					sx={{
						display: "flex",
						gap: "12px",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						textAlign: "center",
						p: 3,
						mb: 5,
					}}
				>
					<Typography variant="h4" sx={{ fontWeight: "500" }}>
						{params.activity_id === "71" ? (
							<>Videocurrículo</>
						) : (
							<>Entrevista Simulada</>
						)}
					</Typography>
					<Typography variant="body1">
						{params.activity_id === "71" ? (
							<>
								É hora de se destacar! Este é o espaço dedicado
								à gravação da sua apresentação do
								Videocurrículo. Siga as etapas abaixo para
								enviar sua mensagem às empresas e mostrar todo o
								seu potencial profissional!
							</>
						) : (
							<>
								Pratique suas entrevistas simuladas para se
								preparar melhor para oportunidades de emprego e
								estágio. Receba feedback detalhado e aprimore
								suas habilidades profissionais.
							</>
						)}
					</Typography>
				</Box>
				<ProgressIndicator />
				<Box
					sx={{
						display: params.activity_id === "71" ? "none" : "",
					}}
				>
					<Typography>
						Na Simulação de Entrevistas do Palco Profissional, as
						perguntas são cuidadosamente selecionadas a partir das
						descrições das vagas que você escolher. Essas perguntas
						abrangem uma variedade de aspectos essenciais para o
						sucesso profissional, sendo elas:
					</Typography>
				</Box>
				<Box mt={5}>
					<Grid container spacing={2}>
						<Grid item md={12}>
							{params.activity_id === "72" ? (
								<Box
									p={0}
									sx={{
										display: "flex",
										flexWrap: "wrap",
										gap: "12px",
									}}
								>
									{acItems &&
										Array.isArray(acItems) &&
										acItems.map((item: any) => {
											return (
												<Fragment>
													<Tooltip
														title={
															<Typography variant="body2">
																{
																	item.description
																}
															</Typography>
														}
													>
														<Chip
															icon={<Info />}
															label={item.title}
														/>
													</Tooltip>
												</Fragment>
											);
										})}
								</Box>
							) : (
								<Box
									p={0}
									sx={{
										display: "flex",
										flexWrap: "wrap",
										gap: "12px",
									}}
								>
									{acItems &&
										Array.isArray(acItems) &&
										acItems.map((item: any) => {
											return (
												<Fragment>
													<Tooltip
														title={
															<Typography variant="body2">
																{
																	item.description
																}
															</Typography>
														}
													>
														<Chip
															icon={<Info />}
															label={item.title}
														/>
													</Tooltip>
												</Fragment>
											);
										})}
								</Box>
							)}
						</Grid>
						<Grid
							item
							p={3}
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							sx={{
								display: "none",
							}}
						>
							<Box mt={3} mb={1}>
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										textAlign: "center",
										height: "356px",
									}}
								>
									<Typography variant="h5">
										<Countdown
											date={time}
											autoStart={false}
											ref={countDownRef}
											onComplete={() => {
												stopButtonRef.current.click();
											}}
											renderer={renderer}
										/>
									</Typography>

									<Typography variant="body1">
										Clique{" "}
										<a
											ref={downloadLinkRef}
											href="/"
											style={{
												textDecoration: "none",
												color: "#333",
												fontWeight: "bold",
											}}
										>
											aqui
										</a>{" "}
										para realizar o download do vídeo.
									</Typography>
								</Box>
							</Box>
						</Grid>

						{/*  CANVAS */}

						<Grid
							item
							md={params.activity_id === "72" ? 6 : 12}
							sx={{
								display:
									params.activity_id === "72" ? "" : "none",
							}}
						>
							{/* <pre>{JSON.stringify(vagas.data, null, 4)}</pre> */}
							<Box mt={1} mb={1} p={0}>
								<Typography
									variant="body1"
									sx={{ mb: 2, mt: 1 }}
								>
									<strong>
										Selecionar vaga para entrevista simulada
									</strong>
								</Typography>
								<FormControl fullWidth>
									<InputLabel>Selecionar vaga</InputLabel>
									<Select
										label="Selecionar vaga"
										onChange={async (e: any) => {
											//TODO: Buscar entrevista personalizada
											// alert(e.target.value);
											setVagaId(e.target.value);
											await gerarRoteiro(e.target.value);
										}}
									>
										<MenuItem value={0}>
											Nenhuma vaga selecionada
										</MenuItem>
										{vagas &&
											vagas.data &&
											vagas.data.map((vaga) => {
												return (
													<MenuItem value={vaga.id}>
														{vaga.title}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Box>
							{/* <pre>{JSON.stringify(topicos, null, 4)}</pre> */}
							{topicos && topicos.length > 0 ? (
								<Box p={1}>
									<Typography>
										<ol style={{ padding: 0 }}>
											{topicos.map((topico) => {
												return (
													<li>
														<p>{topico}</p>
													</li>
												);
											})}
										</ol>
									</Typography>
								</Box>
							) : (
								<></>
							)}
							{/* <Box>
								<Button
									size="large"
									variant="contained"
									disableElevation
									startIcon={<AutoAwesome />}
									onClick={gerarRoteiro}
								>
									Gerar roteiro de entrevista
								</Button>
							</Box> */}
						</Grid>
						<Grid
							item
							p={0}
							xs={12}
							sm={12}
							md={params.activity_id === "72" ? 6 : 12}
							sx={{ mt: 4 }}
							// sx={{ border: "solid red" }}
						>
							<Box>
								<Box
									style={{
										// marginTop: "12px",
										marginBottom: "24px",
										backgroundColor: "transparent",
										borderRadius: "12px",
										width: "100%",
										position: "relative",
									}}
								>
									<Box
										style={{
											display:
												startRecording || showPreview
													? "flex"
													: "none",
											justifyContent: "space-evenly",
											alignItems: "center",
											zIndex: "9999",
											position: "absolute",
											color: "white",
											width: "100%",
											paddingTop: "12px",
											backgroundColor:
												"rgba(0, 0, 0, 0.1)",
										}}
									>
										<Box></Box>
										<Typography
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												gap: "8px",
												color: startRecording
													? theme.palette.primary.main
													: "white",
											}}
										>
											<Videocam />
											{showPreview
												? "Preview"
												: "Gravando"}
										</Typography>
										<Typography>
											<Countdown
												date={time}
												autoStart={false}
												ref={countDownRef}
												onComplete={() => {
													stopButtonRef.current.click();
												}}
												renderer={renderer}
											/>
										</Typography>
									</Box>
									<Box
										style={{
											zIndex: "9998",
										}}
									></Box>
									{showPreview ? (
										<React.Fragment>
											<video
												ref={playbackRef}
												height="auto"
												style={{
													backgroundColor: "#000",
													borderRadius: "4px",
													width: "100%",
												}}
											></video>
										</React.Fragment>
									) : (
										<video
											ref={videoRef}
											autoPlay={true}
											playsInline={true}
											muted={true}
											height={
												window.innerWidth < 800
													? 496
													: "auto"
											}
											style={{
												backgroundColor: "#000",
												width: "100%",
											}}
										></video>
									)}
								</Box>
							</Box>
							<Box
								style={{
									display: "flex",
									gap: "4px",
									flexGrow: "1",
								}}
								mb={3}
							>
								{startRecording || showPreview ? (
									<React.Fragment></React.Fragment>
								) : (
									<Button
										variant="outlined"
										sx={{ flexGrow: "1" }}
										onClick={() => {
											window.location.href = "/";
										}}
									>
										Cancelar
									</Button>
								)}
								{showPreview ? (
									<Button
										size="medium"
										variant="contained"
										disableElevation
										onClick={() => {
											setShowPreview(false);
											setShouldStop(false);
											setURL(null);
										}}
										startIcon={<Refresh />}
										disabled={startRecording ? true : false}
										sx={{ flexGrow: "1" }}
									>
										Refazer
									</Button>
								) : (
									<Button
										size="medium"
										variant="contained"
										disableElevation
										onClick={handleStart}
										startIcon={<Videocam />}
										disabled={startRecording ? true : false}
										sx={{ flexGrow: "1" }}
									>
										Iniciar
									</Button>
								)}
								{showPreview ? (
									<Button
										size="medium"
										variant="contained"
										disableElevation
										onClick={uploadVideoFile}
										startIcon={<Upload />}
										disabled={url ? false : true}
										sx={{ flexGrow: "1" }}
									>
										Enviar
									</Button>
								) : startRecording ? (
									<Button
										size="medium"
										variant="contained"
										disableElevation
										ref={stopButtonRef}
										startIcon={<Stop />}
										disabled={startRecording ? false : true}
										sx={{ flexGrow: "1" }}
										onClick={() => {
											stopButtonRef.current.click();
										}}
									>
										Encerrar
									</Button>
								) : (
									<></>
								)}
							</Box>
						</Grid>
						<Grid item md={12} style={{ display: "none" }}>
							<Box mt={3}>
								<Typography
									variant="h4"
									style={{ marginBottom: "12px" }}
								>
									Capturas
								</Typography>
								<Typography variant="body1">
									Veja suas apresentações anteriores
								</Typography>
								<div ref={canvasRef}></div>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>

			{/* PREVIEW DIALOG */}
			<Dialog
				fullScreen
				open={openPreviewer}
				// open={true}
				sx={{
					zIndex: 10000,
				}}
				onClose={() => {
					setOpenPreviewer(false);
				}}
			>
				{/* <Dialog fullScreen open={true}> */}
				{/* <Appbar /> */}
				<DialogContent>
					<Container>
						<Grid container>
							<Grid item md={12}>
								<ProgressIndicator />
							</Grid>
							<Grid item md={12}>
								<Box
									sx={{
										mt: 3,
										mb: 3,
										textAlign: "center",
									}}
								>
									<Typography variant="body1">
										{/* Parabéns o envio da sua apresentação foi
										realizado com sucesso! copie e envie o
										código da sua apresentação na atividade
										em sala virtual. */}
										Parabéns pelo envio bem-sucedido da sua
										apresentação! Em breve, você receberá um
										feedback por e-mail com a análises e
										dicas para aprimorar ainda mais o seu
										vídeo.
									</Typography>
								</Box>
								<Box
									sx={{
										mb: 3,
										justifyContent: "center",
										display: "flex",
										alignItems: "center",
									}}
								>
									<Button
										variant="outlined"
										startIcon={<CopyAll />}
										onClick={() => {
											navigator.clipboard.writeText(
												auditionCode.code
											);
											// toast.success(
											// 	"Código copiado com sucesso!",
											// 	{
											// 		position: "top-right",
											// 	}
											// );
										}}
									>
										{auditionCode.code}
									</Button>
								</Box>
							</Grid>
							<Grid
								item
								md={12}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
								}}
							>
								<React.Fragment>
									<video
										ref={playbackSubmittedRef}
										controls
										autoPlay
										height="auto"
										style={{
											backgroundColor: "#000",
											borderRadius: "4px",
											width: "70%",
										}}
									>
										<source
											src={url as string}
											type={
												browserAgent
													? "video/mp4"
													: "video/webm"
											}
										/>
									</video>
								</React.Fragment>

								<Box mt={3}>
									<Button
										variant="contained"
										disableElevation
										onClick={() => {
											history("/palco-profissional");
											window.location.reload();
										}}
									>
										Minhas apresentações
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
