import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalCadastroTiposContratoView from "./ModalCadastroTiposContrato.view";

function ModalCadastroTiposContrato(): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalCadastroTiposContratoView />
		</ModalContextProvider>
	);
}

export default ModalCadastroTiposContrato;
