import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AddBusiness } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import Notify from "../../../../utils/notification";
import FormularioAlteracaoEmpresa from "../../../Formularios/Alteracoes/FormularioAlteracaoEmpresa";
import ModalHeader from "../../ModalHeader";

function ModalAlteracoesEmpresaView(props) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down(700));
	// const lg = useMediaQuery(theme.breakpoints.up("sm"));
	const { auth } = useAuthContext();
	const { open, handleOpen } = useModalContext();

	/**
	 * @function handleAtualizarEmpresa
	 * Verifica se o usuário logado é um admin ou se está ativo no sistema, se sim abre o modal de FormularioAlteracaoEmpresa e seta um valor no sessionStorage com a chave de nome id_empresa, senão emite um Notify.
	 * @param {number} id - Representa o id da empresa.
	 * @param {number} isActive - Representa o role do usuário logado no sistema.
	 */
	function handleAtualizarEmpresa(id: number, isActive: number) {
		if (isActive === 1 || auth.role === "admin") {
			handleOpen();
			localStorage.setItem("id_empresa", id.toString());
		} else {
			Notify(
				"Você não está ativo no sistema, aguarde sua ativação!",
				"warning"
			);
		}
	}

	return (
		<>
			<Button
				onClick={() => {
					handleAtualizarEmpresa(props.companieId, props.isActive);
				}}
				title={"Alterar"}
				data-testid="modal-alteracao-empresa"
			>
				<BorderColorIcon style={{ color: "#292B2D" }} />
			</Button>

			<Dialog
				fullScreen={fullScreen}
				// onClose={handleClose}
				fullWidth
				maxWidth={"md"}
				open={open}
			>
				<ModalHeader header="Editar empresa" icon={<AddBusiness />} />
				<DialogContent className="dialogContent">
					<DialogContentText className="dialogContentText">
						<FormularioAlteracaoEmpresa />
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
}
export default ModalAlteracoesEmpresaView;
