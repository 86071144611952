import { Business, Close, Delete, Edit } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	FormGroup,
	IconButton,
	Paper,
	Switch,
	Typography,
	useTheme,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { Fragment, useState } from "react";
import ModalHeader from "../../../../../components/Modal/ModalHeader";
import { useAuthContext } from "../../../../../context/Authentication/hooks/useAuth";
import Notification from "../../../../../utils/notification";
import { useMeuCurriculo } from "../../context";
import EventoForm from "./EventoForm";

/**
 *
 * @param param0
 * @returns Componente de evento
 */
export default function Evento({ evento }: any) {
	const theme = useTheme();
	const { auth } = useAuthContext();
	const [open, setOpen] = useState<any>(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { setSignal } = useMeuCurriculo();

	const handleDelete = async (id) => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		const apiUrl = `${process.env.REACT_APP_URL}/events`;

		await axios
			.delete(`${apiUrl}/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: AxiosResponse<any>) => {
				Notification("Excluindo evento", "success");
			})
			.finally(() => {
				setSignal(true);
			});
	};

	const [, setSwitchValue] = useState(true); // or whatever the initial value should be
	const [candidaturaModalStatus, setCandidaturaModalStatus] =
		useState<boolean>(false);

	const handleUpdate = async (id, value) => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		const apiUrl = `${process.env.REACT_APP_URL}/events`;

		await axios
			.put(
				`${apiUrl}/${id}`,
				{
					visible: value,
				},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			)
			.then((res: AxiosResponse<any>) => {
				// Notification("Atualizando evento!", "success");
			})
			.finally(() => {
				setSignal(true);
			});

		try {
			// Your existing axios code

			// Update the local state or any other logic you need
			setSwitchValue(value);
			Notification("Atualizando experiência profissional", "success");
		} catch (error) {
			console.error("Error updating API:", error);
		} finally {
			setSignal(true);
		}
	};

	return (auth.role === "admin" || auth.role === "representante_empresa") &&
		evento.visible === 0 ? (
		<Box></Box>
	) : (
		<Fragment>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<Box
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box p={2} sx={{ display: "flex", gap: "12px" }}>
						<Business />
						<Typography variant="body1">Editar evento</Typography>
					</Box>
					<Box p={1}>
						<IconButton
							sx={{
								bgcolor: theme.palette.primary.main,
								color: "white",
							}}
							onClick={handleClose}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
				{/* <pre>{JSON.stringify(evento, null, 4)}</pre> */}

				<EventoForm evento={evento} setOpen={setOpen} />
			</Dialog>

			<Dialog open={candidaturaModalStatus}>
				<ModalHeader
					header={`Confirmação de exclusão do evento`}
					icon={<Delete />}
					close={() => setCandidaturaModalStatus(false)}
				/>
				<DialogContent>
					Deseja confirmar a exclusão do evento cadastrado:{" "}
					<b>"{evento.title}"</b> em <b>{evento.sponsor}</b>?
					{/* <pre>
											{JSON.stringify(job, null, 4)}
										</pre> */}
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						onClick={() => {
							setCandidaturaModalStatus(false);
						}}
					>
						Cancelar
					</Button>
					<Button
						variant="contained"
						disableElevation
						onClick={() => {
							console.log("TODO:");
							// handleDelete(experience.id);
							handleDelete(evento.id);
						}}
					>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>

			<Paper elevation={0}>
				<Box m={2} p={2}>
					<Box
						sx={{
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "space-between",
							gap: "12px",
							flexWrap: {
								xs: "wrap",
								md: "nowrap",
							},
						}}
					>
						<Box>
							<Typography variant="body1" component="p">
								{evento.title} - {evento.sponsor}
							</Typography>
							<Box mt={1}>
								<Typography
									variant="body1"
									component="p"
								></Typography>
								<Typography variant="body1" component="p">
									Duração:{" "}
									{new Date(
										evento.started
									).toLocaleDateString()}{" "}
									a{" "}
									{new Date(
										evento.finished
									).toLocaleDateString()}
								</Typography>
							</Box>
							<Typography
								sx={{
									display: evento.certificate ? "" : "none",
								}}
							>
								Certificado:{" "}
								<a
									href={evento.certificate}
									target="_blank"
									rel="noreferrer"
									style={{
										textDecoration: "none",
									}}
								>
									<>Acesse aqui</>
								</a>
							</Typography>
						</Box>
						{/* <pre>
												{JSON.stringify(h, null, 4)}
												</pre> */}

						<Box
							sx={{
								// display: "flex",
								alignItems: "center",
								justifyContent: "flex-start",
								gap: "6px",
								display:
									auth.role === "admin" ||
									auth.role === "representante_empresa"
										? "none"
										: "flex",
							}}
						>
							<IconButton
								size="small"
								sx={{
									bgcolor: theme.palette.primary.main,
									color: "white",
								}}
								onClick={() => {
									handleOpen();
								}}
							>
								<Edit />
							</IconButton>
							<IconButton
								size="small"
								sx={{
									bgcolor: theme.palette.primary.main,
									color: "white",
								}}
								onClick={async () => {
									// handleDelete(evento.id);
									setCandidaturaModalStatus(true);
								}}
							>
								<Delete />
							</IconButton>
							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											checked={
												evento.visible === 1
													? true
													: false
												// evento.visible ?? switchValue
											}
											onChange={(event) => {
												const newValue =
													event.target.checked;
												handleUpdate(
													evento.id,
													newValue
												);
											}}
										/>
									}
									label="Divulgar informação"
								/>
							</FormGroup>
						</Box>
					</Box>
				</Box>
			</Paper>
		</Fragment>
	);
}
