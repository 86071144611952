import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalDetalhesEmpresaView from "./ModalDetalhesEmpresa.view";

function ModalDetalhesEmpresa(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalDetalhesEmpresaView companieId={props.companieId} />
		</ModalContextProvider>
	);
}

export default ModalDetalhesEmpresa;
