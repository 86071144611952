import { createContext, useContext, useEffect, useReducer } from "react";

export interface PalcoProfissionalState {
	state: PalcoProfissional;
	reducer: () => {};
}

interface PalcoProfissional {
	user: User;
	profile: Profile;
	cursos: Curso[];
	usuario: Usuario;
	accessToken: string;
}

interface Usuario {
	id: string;
	name: string;
	email: string;
	course: string;
	learning_level: string;
	role: string;
	created_at: string;
	updated_at: string;
}

interface Curso {
	codigo_nivel_ensino: number;
	nome_nivel_ensino: string;
	codigo_curso: string;
	nome_curso: string;
	periodo?: number;
	situacao: string;
	idhabilitacaofilial: string;
	idperlet: string;
}

interface Profile {
	nome: string;
	funcao: string;
	email: string;
}

interface User {
	tipo_auth: string;
	tipo_auth_moodle?: any;
	funcionario_rm: any[];
	professor: boolean;
	aluno: boolean;
	moodle_admin: boolean;
}

const initial = {};

export function taskReducer(state: PalcoProfissional, payload: any) {
	switch (payload.action) {
		case "set_user":
			return { ...state, user: payload.user };
		case "set_profile":
			return { ...state, profile: payload.profile };
		case "set_cursos":
			return { ...state, cursos: payload.cursos };
		case "accessToken":
			return { ...state, accessToken: payload.accessToken };
		case "restore_state":
			return payload.payload; // Assuming payload is the saved state
		default:
			return state;
	}
}

export const PalcoProfissionalContext = createContext<PalcoProfissional | any>(
	{}
);

export const PalcoProfissionalProvider = ({ children }): any => {
	const [state, dispatch] = useReducer(taskReducer, initial);

	useEffect(() => {
		const data = localStorage.getItem(
			"@palco-profissional:state"
		) as string;

		dispatch({
			action: "restore_state",
			payload: JSON.parse(data),
		});
	}, []);

	// useEffect(() => {
	// 	localStorage.setItem(
	// 		"@palco-profissional:state",
	// 		JSON.stringify(state)
	// 	);
	// }, [state]);

	return (
		<PalcoProfissionalContext.Provider
			value={{
				state,
				dispatch,
			}}
		>
			{children}
		</PalcoProfissionalContext.Provider>
	);
};

export const usePalcoProfissional = () => {
	return useContext(PalcoProfissionalContext);
};
