import { BugReport } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

export function ActivityViewerDialogSubmission({
	data,
	matches,
	queryResult,
	state,
	comprehend,
	url,
}) {
	return (
		<>
			{queryResult === "IDLE" ? (
				<>
					<Grid item md={6}>
						<Typography variant="h5" sx={{ mb: 1 }}>
							Apresentação submetida
						</Typography>
						<video
							controls
							muted
							autoPlay
							src={`${url}`}
							width={matches ? "100%" : 480}
							height={matches ? "auto" : 320}
							// width="480"
							// height="320"
							style={{
								backgroundColor: "black",
							}}
						/>
						<Box
							mt={2}
							mb={2}
							sx={{
								display:
									state.user &&
									(state.user.professor ||
										state.user.moodle_admin)
										? "flex"
										: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "flex-start",
								gap: "8px",
							}}
						>
							{data &&
								data.recognition &&
								data.recognition.length > 0 &&
								(() => {
									const emotionTranslations: any = {
										HAPPY: "FELIZ",
										SAD: "TRISTE",
										ANGRY: "IRRITADO",
										CONFUSED: "CONFUSO",
										DISGUSTED: "DESGOSTO",
										SURPRISED: "SURPRESO",
										CALM: "CALMO",
										UNKNOWN: "DESCONHECIDO",
										FEAR: "MEDO",
									};

									let maxEmotion = "";
									let maxEmotionConfidence = 0;

									data.recognition.forEach((item: any) => {
										if (
											item.emotion_confidence >
											maxEmotionConfidence
										) {
											maxEmotion = item.emotion;
											maxEmotionConfidence =
												item.emotion_confidence;
										}
									});

									const translatedMaxEmotion =
										emotionTranslations[maxEmotion];

									return (
										<Typography>
											Emoção predominante:{" "}
											{translatedMaxEmotion} -{" "}
											{maxEmotionConfidence.toFixed(2)}%
										</Typography>
									);
								})()}

							{data &&
								data.recognition &&
								data.recognition &&
								data.recognition.forEach((item: any) => {
									return <>{item.emotion}</>;
								})}

							{Object.keys(comprehend).length > 0 ? (
								<>
									<Typography>
										Sentimento predominante:
										<span
											style={{
												color: "green",
											}}
										>
											{comprehend.Sentiment === "POSITIVE"
												? "Positivo"
												: comprehend.Sentiment ===
												  "NEGATIVE"
												? "Negativo"
												: comprehend.Sentiment ===
												  "MIXED"
												? "Misto"
												: "Neutro"}
										</span>
									</Typography>
									<Typography>
										Positivo:{" "}
										{(
											comprehend.SentimentScore.Positive *
											100
										).toFixed(2)}
										%
									</Typography>
									<Typography>
										Negativo:{" "}
										{(
											comprehend.SentimentScore.Negative *
											100
										).toFixed(2)}
										%
									</Typography>
									<Typography>
										Neutro:{" "}
										{(
											comprehend.SentimentScore.Neutral *
											100
										).toFixed(2)}
										%
									</Typography>
									<Typography>
										Misto:{" "}
										{(
											comprehend.SentimentScore.Mixed *
											100
										).toFixed(2)}
										%
									</Typography>
								</>
							) : (
								<>
									<Typography>
										Sentimento predominante:
										<span
											style={{
												color: "green",
											}}
										>
											{data &&
											data.transcription &&
											data.transcription[0] &&
											data.transcription[0]
												.comprehend_sentiment ===
												"POSITIVE"
												? "Positivo"
												: data &&
												  data.transcription &&
												  data.transcription[0] &&
												  data.transcription[0]
														.comprehend_sentiment ===
														"NEGATIVE"
												? "Negativo"
												: data &&
												  data.transcription &&
												  data.transcription[0] &&
												  data.transcription[0]
														.comprehend_sentiment ===
														"MIXED"
												? "Misto"
												: "Neutro"}
										</span>
									</Typography>
									<Typography>
										Positivo:{" "}
										{data &&
											data.transcription &&
											data.transcription[0] &&
											data.transcription[0]
												.comprehend_feedback &&
											data.transcription[0]
												.comprehend_feedback &&
											(
												JSON.parse(
													data.transcription[0]
														.comprehend_feedback
												).SentimentScore.Positive * 100
											).toFixed(2)}
										%
									</Typography>
									<Typography>
										Negativo:{" "}
										{data &&
											data.transcription &&
											data.transcription[0] &&
											data.transcription[0]
												.comprehend_feedback &&
											data.transcription[0]
												.comprehend_feedback &&
											(
												JSON.parse(
													data.transcription[0]
														.comprehend_feedback
												).SentimentScore.Negative * 100
											).toFixed(2)}
										%
									</Typography>
									<Typography>
										Neutro:{" "}
										{data &&
											data.transcription &&
											data.transcription[0] &&
											data.transcription[0]
												.comprehend_feedback &&
											data.transcription[0]
												.comprehend_feedback &&
											(
												JSON.parse(
													data.transcription[0]
														.comprehend_feedback
												).SentimentScore.Neutral * 100
											).toFixed(2)}
										%
									</Typography>
									<Typography>
										Misto:{" "}
										{data &&
											data.transcription &&
											data.transcription[0] &&
											data.transcription[0]
												.comprehend_feedback &&
											data.transcription[0]
												.comprehend_feedback &&
											(
												JSON.parse(
													data.transcription[0]
														.comprehend_feedback
												).SentimentScore.Mixed * 100
											).toFixed(2)}
										%
									</Typography>
								</>
							)}
						</Box>
						{state.user &&
							(state.user.professor ||
								state.user.moodle_admin) && (
								<>
									<Typography variant="h5" sx={{ mb: 1 }}>
										Transcrição
									</Typography>
									<Box>
										{data &&
										data.transcription &&
										data.transcription.length > 0 ? (
											<>
												{data.transcription &&
													data.transcription[0] &&
													data.transcription[0]
														.transcription}
											</>
										) : (
											<>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: "12px",
														justifyContent:
															"center",
														alignItems: "center",
														minHeight: "200px",
														// backgroundColor: "#f8f8f8"
													}}
												>
													<BugReport />
													<Typography variant="body1">
														Nenhuma transcrição
														disponível
													</Typography>
												</Box>
											</>
										)}
									</Box>
								</>
							)}
					</Grid>
				</>
			) : (
				<Grid item md={12}>
					<Box
						sx={{
							minHeight: "20vh",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<CircularProgress />
					</Box>
				</Grid>
			)}
		</>
	);
}
