import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalAlteracaoVagaView from "./ModalAlteracaoVaga.view";

function ModalAlteracaoVaga(props: any): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalAlteracaoVagaView vagaId={props.vagaId} vaga={props.vaga} />
		</ModalContextProvider>
	);
}

export default ModalAlteracaoVaga;
