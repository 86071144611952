import React, { createContext, useState } from "react";
import { PropsDrawerContext } from "./models/DrawerPropsType";

const DEFAULT_VALUE = {
    drawer: false,
    setDrawer: () => { },
}

export const DrawerContext = createContext<PropsDrawerContext>(DEFAULT_VALUE);

export const DrawerContextProvider: React.FC = ({ children }) => {
    const [drawer, setDrawer] = useState(false);

    return (
        <DrawerContext.Provider value={{ drawer, setDrawer }}>
            {children}
        </DrawerContext.Provider>
    )
}