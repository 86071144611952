import api from "./api";

/**
 * @function postLogout
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo post para a rota /logout, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso o sessionStorage é limpo.
 */

export async function postLogout() {
	let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

	return await api
		.post("/logout", undefined, {
			headers: { Authorization: `Bearer ${token}` },
		})
		.then(() => {
			localStorage.clear();
			localStorage.clear();
		})
		.catch((e) => {
			return e;
		});
}
