export const styles = {
	compound: {
		marginTop: "36px",
		marginBottom: "10vh",
		width: "100%",
	},
	title: {
		marginBottom: "24px",
	},
	centerText: {
		textAlign: "center",
	},
};
