import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalDeleteVagaView from "./ModalDeleteVaga.view";

function ModalDeleteVaga(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalDeleteVagaView vagaId={props.vagaId} dados={props.dados} />
		</ModalContextProvider>
	);
}

export default ModalDeleteVaga;
