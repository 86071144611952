import { DialogActions } from "@material-ui/core";
import { PersonSearch } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { Transition } from "../../../../utils/transition";
import ListaDetalhesUsuario from "../../../Detalhes/DetalhesUsuario";
import ModalHeader from "../../ModalHeader";

function ModalDetalhesUsuarioView(props) {
	const { open, handleOpen, handleClose } = useModalContext();

	/**
	 * @function handleDetalhesUsuario
	 * Abre o modal de ListaDetalhesUsuario e seta um valor no sessionStorage com a chave de nome id_usuario.
	 * @param {number} id - Representa o id do usuário.
	 */
	function handleDetalhesUsuario(id: number) {
		handleOpen();
		localStorage.setItem("id_usuario", id.toString());
	}

	return (
		<>
			<IconButton
				onClick={() => {
					handleDetalhesUsuario(props.userId);
				}}
				title={"Detalhes"}
				data-testid="modal-detalhes-usuario"
			>
				<DescriptionIcon />
			</IconButton>

			<Dialog
				maxWidth="lg"
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
			>
				<ModalHeader
					header={"Detalhes do Usuário"}
					icon={<PersonSearch />}
				/>
				{/* <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "25px",
              padding: "15px",
            }}
          >
            {"Detalhes do Usuário"}
          </DialogTitle> */}
				<DialogContent>
					<DialogContentText>
						<ListaDetalhesUsuario />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						disableElevation
						onClick={handleClose}
					>
						Fechar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
export default ModalDetalhesUsuarioView;
