import api from "../api";

export async function getTerms() {
    try {
        const response = await api.get('/terms');
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function postTerms({ user_id, term_id }) {
    try {
        const response = await api.post('/terms/sign', { user_id, term_id });
        return response.data;
    } catch (error) {
        throw error;
    }
}
