import { Business, VerifiedUser, Work } from "@mui/icons-material";
import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import { pink } from "@mui/material/colors";
import React from "react";
import "../style.scss";

function ListaDetalhesVagaAluno({
	usuario,
	empresa,
	vaga,
	job,
}: any): React.ReactElement {
	// const [open, setOpen] = useState<boolean>(false);

	return (
		<>
			<Container data-testid="detalhes-vaga">
				{/* <Typography variant="h5" sx={{ mb: 3 }}>
					{" "}
					Representante da empresa:{" "}
				</Typography> */}
				<Grid container>
					{/* <pre>{JSON.stringify(job, null, 4)}</pre> */}
					<Grid item md={12}>
						{vaga && vaga.company && vaga.company.logo_image && (
							<Box p={2}>
								<img
									src={vaga.company.logo_image}
									alt="Logo da empresa"
									width="120"
									height="auto"
								/>
							</Box>
						)}
						<Box
							p={2}
							sx={{
								display: "flex",
								alignItems: "flex-start",
								justifyContent: "flex-start",
								gap: "12px",
								flexDirection: "column",
							}}
						>
							<Box>
								{/* <pre>{JSON.stringify(vaga, null, 4)}</pre> */}
								<Box>
									<Typography variant="h5">
										{vaga.title} - {vaga.work_mode}
									</Typography>
								</Box>
								<Typography>
									{vaga && vaga.company && vaga.company.name}
									{/* .Criado em{" "} */}
									{/* {new Date(vaga.created_at.toString() || "")} */}
								</Typography>
								<Typography
									variant="body1"
									component="b"
									sx={{ fontWeight: "bold" }}
								>
									{vaga.city} - {vaga.state} -{" "}
									{vaga.employment_type}
								</Typography>
								<Typography>
									Remuneração inicial: R$
									{vaga.remuneration_initial} -{" "}
									{vaga.students.length} Candidatura(s)
								</Typography>
							</Box>
							<Box mt={2}>
								<Typography variant="h5">
									Veja detalhes da vaga
								</Typography>
							</Box>
							<Box
								p={2}
								sx={{
									display: "flex",
									alignItems: "flex-start",
									justifyContent: "flex-start",
									gap: "12px",
								}}
							>
								<Avatar
									sx={{
										backgroundColor: pink[500],
										color: "white",
									}}
								>
									<Work />
								</Avatar>
								<Box>
									<Box mb={3}>
										<Typography
											variant="body1"
											sx={{
												fontWeight: "600",
												// color: "#212121",
												mb: 1,
											}}
										>
											Descrição da vaga:
										</Typography>
										{vaga.description}
									</Box>
									<Box mb={3}>
										<Typography
											variant="body1"
											sx={{
												fontWeight: "600",
												// color: "#212121",
												mb: 1,
											}}
										>
											Nível de experiência:
										</Typography>
										<Typography>
											{vaga.experience_level}
										</Typography>
									</Box>
									<Box mb={3}>
										<Typography
											variant="body1"
											sx={{
												fontWeight: "600",
												// color: "#212121",
												mb: 1,
											}}
										>
											Requisitos:
										</Typography>
										{vaga.requirements}
									</Box>
									<Box mb={3}>
										<Typography
											variant="body1"
											sx={{
												fontWeight: "600",
												// color: "#212121",
												mb: 1,
											}}
										>
											Tarefas:
										</Typography>{" "}
										{vaga.assignments}
									</Box>
									<Box mb={0}>
										<Typography
											variant="body1"
											sx={{
												fontWeight: "600",
												// color: "#212121",
												mb: 1,
											}}
										>
											Informação adicional:
										</Typography>{" "}
										{vaga.additional_info}
									</Box>
								</Box>
							</Box>
						</Box>
					</Grid>

					{/* Verificar a remoção da info da equipe de contratação  */}
					<Grid item md={12}>
						<Box p={2} sx={{ display: "none" }}>
							<Typography variant="h5">
								Conheça a equipe de contratação
							</Typography>
							<Box
								p={3}
								sx={{
									display: "flex",
									alignItems: "flex-start",
									justidyContetent: "flex-start",
									gap: "12px",
								}}
							>
								<Box>
									<Avatar
										sx={{
											backgroundColor: pink[500],
											color: "white",
										}}
									>
										<VerifiedUser />
									</Avatar>
								</Box>
								<Box>
									<Typography variant="h5">
										{vaga.user.name}
									</Typography>
									{""}
									<Typography>{vaga.user.email}</Typography>
								</Box>
							</Box>
						</Box>

						<Grid item md={12}>
							<Box p={2}>
								<Typography variant="h5">
									Sobre a Empresa
								</Typography>
								<Box
									p={3}
									sx={{
										display: "flex",
										alignItems: "flex-start",
										justifyContetent: "flex-start",
										gap: "12px",
										flexDirection: "column",
									}}
								>
									<Box
										sx={{
											display: "flex",
											gap: "12px",
											justifyContent: "flex-start",
										}}
									>
										<Avatar
											sx={{
												backgroundColor: pink[500],
												color: "white",
											}}
										>
											<Business />
										</Avatar>
										<Box>
											<Box mb={2}>
												<Typography variant="h5">
													{vaga &&
														vaga.company &&
														vaga.company.name}
												</Typography>
												{""}
												<Typography>
													{vaga &&
														vaga.company &&
														vaga.company
															.employees_number}{" "}
													Funcionários -{" "}
													<a
														href={vaga.company.site}
														target="_blank"
														style={{
															textDecoration:
																"none",
														}}
														rel="noreferrer"
													>
														{vaga.company.site}
													</a>
												</Typography>
											</Box>
											<Box mb={2}>
												<Typography
													variant="body1"
													sx={{
														fontWeight: "600",
														// color: "#212121",
														mb: 1,
													}}
												>
													Sobre a empresa:
												</Typography>{" "}
												{vaga.company.about}
											</Box>
											<Box mb={2}>
												<Typography
													variant="body1"
													sx={{
														fontWeight: "600",
														// color: "#212121",
														mb: 1,
													}}
												>
													Área de atuação:
												</Typography>{" "}
												{vaga.company.area}
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default ListaDetalhesVagaAluno;
