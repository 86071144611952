import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Edit, ManageAccounts } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import Notify from "../../../../utils/notification";
import FormularioAlteracaoUsuario from "../../../Formularios/Alteracoes/FormularioAlteracaoUsuario";
import ModalHeader from "../../ModalHeader";

function ModalAlteracaoUsuarioView(props) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down(700));
	const lg = useMediaQuery(theme.breakpoints.up("sm"));
	const { open, handleOpen } = useModalContext();

	/**
	 * @function handleAtualizarEmpresa
	 * Verifica se o usuário logado é um representante, se sim abre o modal de FormularioAlteracaoUsuario e seta um valor no sessionStorage com a chave de nome id_usuario, senão emite um Notify.
	 * @param {number} id - Representa o id do usuário.
	 * @param {number} roleId - Representa o role do usuário.
	 */
	function handleAtualizarUsuario(id: number, roleId: number) {
		if (roleId === 2) {
			handleOpen();
			localStorage.setItem("id_usuario", id.toString());
		} else {
			Notify("Você não pode alterar um usuário ADM", "warning");
		}
	}

	return (
		<>
			<IconButton
				onClick={() => {
					handleAtualizarUsuario(props.userId, props.userRoleId);
				}}
				title={"Alterar"}
				data-testid="modal-alteracao-usuario"
			>
				<Edit />
			</IconButton>

			<Dialog
				fullScreen={fullScreen}
				fullWidth
				maxWidth={lg === true ? "md" : false}
				open={open}
				// onClose={handleClose}
			>
				<ModalHeader
					header="Editar usuário"
					icon={<ManageAccounts />}
				/>
				<DialogContent>
					<FormularioAlteracaoUsuario />
				</DialogContent>
			</Dialog>
		</>
	);
}
export default ModalAlteracaoUsuarioView;
