import { Box, Divider, Grid, Typography } from "@mui/material";

export function ActivityViewerDialogHeading({ data }: any) {
	return (
		<>
			<Grid item md={12} sx={{ mb: 3 }}>
				<Divider />
				<Box mt={3} mb={3}>
					<Box mb={1}>
						<Typography
							variant="body1"
							sx={{
								textAlign: "center",
								fontWeight: 500,
							}}
						>
							Cód. {data && data.activity && data.activity.id} :{" "}
							{data &&
								data.activity &&
								data.activity.title.toUpperCase()}
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "flex-end",
						}}
					>
						<Box>
							<Typography variant="body1">
								{(data && data.user && data.user.name) ??
									"Nome do usuário não disponível"}{" "}
								-{" "}
								{(data && data.user && data.user.id) ??
									"Nome Curso"}
							</Typography>
							<Typography variant="body1">
								{(data && data.user && data.user.course) ??
									"Nome do curso não disponível"}{" "}
							</Typography>
							<Typography variant="body1">
								{(data &&
									data.user &&
									data.user.learning_level) ??
									"Nível de ensino não disponível"}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Divider />
			</Grid>
			<Grid item md={12}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-evenly",
						mb: 3,
					}}
				>
					<Typography>
						<strong>
							{data && data.code && data.code.toUpperCase()}
						</strong>
					</Typography>
				</Box>
			</Grid>
		</>
	);
}
