import {
	Box,
	Button,
	FormControl,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { Fragment, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuthContext } from "../../../../../context/Authentication/hooks/useAuth";
import { useMeuCurriculo } from "../../context";

/**
 *
 * @param param0
 * @returns Formulário para cadastrar experiência profissional
 */
export default function ExperienciaProfissionalForm({
	experience,
	setOpen,
}: any) {
	const { control, handleSubmit, setValue } = useForm();
	// const { state } = usePalcoProfissional();
	const { auth } = useAuthContext();
	const { setSignal } = useMeuCurriculo();

	const onSubmit = async (data) => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		const apiUrl = `${process.env.REACT_APP_URL}/professional-experiences`;

		if (experience) {
			// If experience is provided, update existing data
			await axios
				.put(`${apiUrl}/${experience.id}`, data, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((res: AxiosResponse<any>) => {
					console.log("Success!");
				});
		} else {
			// If experience is not provided, create new data
			await axios
				.post(apiUrl, data, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((res: AxiosResponse<any>) => {
					console.log("Success!");
				});
		}

		setOpen(false);
		setSignal(true);
	};

	// Set default values when experience is provided
	useEffect(() => {
		if (experience) {
			Object.keys(experience).forEach((key) => {
				if (key === "started" || key === "finished") {
					setValue(key, experience[key].split("T")[0]);
				} else {
					setValue(key, experience[key]);
				}
				// setValue(key, experience[key]);
			});
		}
	}, [experience, setValue]);

	return (
		<Fragment>
			<Paper>
				{/* <pre>{JSON.stringify(experience, null, 4)}</pre> */}
				<Box p={2}>
					<Typography variant="h5" mb={2}>
						Adicionar experiência profissional
					</Typography>
					{/* <pre>{JSON.stringify(auth.user_id), null, 4)}</pre> */}
					<form
						onSubmit={handleSubmit(onSubmit)}
						style={{
							display: "flex",
							gap: "12px",
							flexWrap: "wrap",
							flexGrow: "1",
						}}
					>
						<Controller
							name="company_name"
							control={control}
							defaultValue={""}
							render={({ field }) => (
								<FormControl fullWidth>
									<TextField
										label="Nome da empresa"
										{...field}
										required
									/>
								</FormControl>
							)}
						/>

						<Controller
							name="city"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField required label="Cidade" {...field} />
							)}
						/>

						<Controller
							name="state"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField required label="UF" {...field} />
							)}
						/>

						<Controller
							name="department"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField label="Setor" {...field} required />
							)}
						/>

						<Controller
							name="role"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									sx={{
										width: "60%",
									}}
									label="Cargo"
									{...field}
									required
								/>
							)}
						/>

						<Controller
							name="description"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									required
									rows={4}
									multiline
									label="Descrição das atividades realizadas"
									{...field}
									sx={{
										flexGrow: 1,
										width: "100%",
									}}
								/>
							)}
						/>

						<Controller
							name="started"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									InputLabelProps={{
										shrink: !!field.value || !!field.ref,
									}}
									label="Início"
									type="date"
									{...field}
									required
								/>
							)}
						/>

						<Controller
							name="finished"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									InputLabelProps={{
										shrink: !!field.value || !!field.ref,
									}}
									label="Término"
									type="date"
									{...field}
									required
								/>
							)}
						/>

						<Controller
							name="student_id"
							control={control}
							defaultValue={auth.student_id || ""}
							render={({ field }) => (
								<TextField
									sx={{
										display: "none",
										visibility: "hidden",
									}}
									label="RA"
									type="number"
									{...field}
								/>
							)}
						/>

						<Box
							sx={{
								width: "100%",
								flexGrow: 1,
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								gap: "12px",
								mt: 3,
							}}
						>
							<Button
								variant="text"
								onClick={() => setOpen(false)}
							>
								Cancelar
							</Button>
							<Button
								size="large"
								type="submit"
								variant="contained"
								color="primary"
								disableElevation
							>
								{experience ? "Atualizar" : "Salvar"}
							</Button>
						</Box>
					</form>
				</Box>
			</Paper>
		</Fragment>
	);
}
