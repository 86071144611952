import React from "react";
import TabelaTiposContrato from "../../../components/Tabelas/TabelaTiposContrato";

export default function TiposContrato(): React.ReactElement {
	return (
		<>
			<TabelaTiposContrato />
		</>
	);
}
