import { HomePageContextProvider } from "../../../context/HomePage";
import { ModalContextProvider } from "../../../context/Modal/modal.context";
import { HomePageDetailsDialog } from "./components/HomePageDetailsDialog";
import { HomePageRoot } from "./components/HomePageRoot";
import { HomePageSearchForm } from "./components/HomePageSearchForm";
import { HomePageShowCardView } from "./components/HomePageShowCardView";
import { HomePageShowListView } from "./components/HomePageShowListView";
import { HomePageTermsDialog } from "./components/HomePageTermsDialog";

export function HomePage() {
	return (
		<ModalContextProvider>
			<HomePageContextProvider>
				<HomePageRoot>
					<HomePageSearchForm />
					<HomePageShowCardView />
					<HomePageShowListView />
					<HomePageDetailsDialog />
					<HomePageTermsDialog />
				</HomePageRoot>
			</HomePageContextProvider>
		</ModalContextProvider>
	);
}
