import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { IEvent, IEventType, IProfessionaExperience } from "./types";

type ApiResponse = {
	student: {
		id: number | null;
		profile: null | any; // You might want to replace 'any' with a more specific type
		profile_picture: null | any; // You might want to replace 'any' with a more specific type
		ra: string;
		link_lattes: null | any; // You might want to replace 'any' with a more specific type
		id_user: number | null;
		id_course: number | null;
		birthday: null | string; // Replace 'string' with a more specific type if the birthday is expected to have a certain format
		phone: string;
		cep: string;
		state: string;
		city: string;
		address: string;
		number: number | null;
		created_at: string;
		updated_at: string;
		events: any[]; // You might want to replace 'any' with a more specific type
		professionalExperiences: any[]; // You might want to replace 'any' with a more specific type
		course: {
			id: number | null;
			name: string;
			id_knowledge_area: number | null;
			created_at: string;
		};
		jobs: any[]; // You might want to replace 'any' with a more specific type
	};
	user: {
		id: number | null;
		name: string;
		email: string;
		remember_me_token: null | any; // You might want to replace 'any' with a more specific type
		is_active: number | null;
		role_id: number | null;
		created_at: string;
		updated_at: string;
	};
};

export const MeuCurriculoContext = createContext<any>(null);

/**
 *
 * @param param0
 * @returns Provider para os dados partilhados entre os componentes do curriculo
 */
export const MeuCurriculoContextProvider = ({ children }: any) => {
	const { auth } = useAuthContext();
	const params = useParams();
	const [professionalExperiences, setProfessionalExperiences] = useState<
		IProfessionaExperience[] | null
	>(null);
	const [eventos, setEventos] = useState<IEvent[] | null>(null);
	const [student, setStudent] = useState<ApiResponse | ApiResponse[]>({
		student: {
			id: null,
			profile: null,
			profile_picture: null,
			ra: "",
			link_lattes: null,
			id_user: null,
			id_course: null,
			birthday: null,
			phone: "",
			cep: "",
			state: "",
			city: "",
			address: "",
			number: null,
			created_at: "",
			updated_at: "",
			events: [],
			professionalExperiences: [],
			course: {
				id: null,
				name: "",
				id_knowledge_area: null,
				created_at: "",
			},
			jobs: [],
		},
		user: {
			id: null,
			name: "",
			email: "",
			remember_me_token: null,
			is_active: null,
			role_id: null,
			created_at: "",
			updated_at: "",
		},
	});
	const [signal, setSignal] = useState<boolean>(false);
	const [gruposDeEvento, setGruposDeEvento] = useState<any | any[]>([]);

	const loadExperienciasProfissionais = async () => {
		// alert(JSON.stringify(params));
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		let experienciasUrl = `${process.env.REACT_APP_URL}/professional-experiences/`;

		// Concise ternary operator approach:
		experienciasUrl +=
			params.id !== undefined ? `student/${params.id}` : "";

		await axios
			.get(experienciasUrl, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				setProfessionalExperiences(res.data.data);
			});
	};

	const loadEventos = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		// let eventosUrl = `${process.env.REACT_APP_URL}/events/`;
		// eventosUrl += params.id !== undefined ? `student/${params.id}` : "";

		if (!params.id && !auth.student_id) {
			return;
		}
		let eventosUrl = `${process.env.REACT_APP_URL}/students/${
			auth.student_id ? auth.student_id : params.id
		}`;
		await axios
			.get(eventosUrl, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				console.log(
					"%c EVENTOS - 1",
					"color: purple; font-size: 24px;"
				);
				console.log(res.data.student.events);
				setEventos(res.data.student.events);
				// setEventos(res.data.data);
			})
			.finally(() => {});
	};
	const loadData = async () => {
		// alert(JSON.stringify(params));
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		let apiUrl = `${process.env.REACT_APP_URL}/students/`;
		apiUrl += `${
			auth.student_id ? auth.student_id : params.id ? params.id : ""
		}`;

		await axios
			.get(`${apiUrl}`, {
				headers: {
					Authorization: "Bearer " + token,
				},
			})
			.then(async (res: any) => {
				if (Array.isArray(res.data)) {
					setStudent(res.data[0]);
					if (
						res.data &&
						res.data[0].student &&
						res.data[0].student.eventos
					) {
						setEventos(res.data[0].student.eventos);
					} else {
						loadEventos();
					}

					if (
						res.data &&
						res.data[0].student &&
						res.data[0].student.professionalExperiences
					) {
						setProfessionalExperiences(
							res.data[0].student.professionalExperiences
						);
					} else {
						loadExperienciasProfissionais();
					}
				} else {
					setStudent(res.data);
					if (
						res.data &&
						res.data.student &&
						res.data.student.eventos
					) {
						setEventos(res.data.student.eventos);
					} else {
						loadEventos();
					}

					if (
						res.data &&
						res.data.student &&
						res.data.student.professionalExperiences
					) {
						setProfessionalExperiences(
							res.data.student.professionalExperiences
						);
					} else {
						loadExperienciasProfissionais();
					}
				}
			})
			.catch(() => {
				alert("Falha ao buscar perfil do estudante");
			});
	};

	useEffect(() => {
		try {
			if (params.id) loadData();
			else loadData();
		} catch (err) {
			console.log(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		try {
			loadData();
			setSignal(false);
		} catch (err) {
			console.log(err);
		}
		// eslint-disable-next-line
	}, [signal, setSignal]);

	/**
	 * Busca tipos de evento e em seguida categoriza os eventos cadastrado pelo aluno em
	 * grupos de evento definidos por tipo e nome
	 */
	const getTiposDeEvento = async () => {
		const token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		const apiUrl = `${process.env.REACT_APP_URL}/event-type`;

		axios
			.get(`${apiUrl}`, {
				headers: {
					Authorization: "Bearer " + token,
				},
			})
			.then((res) => {
				console.log("%cEVENTOS", "color:green; font-size: 24px;");
				console.log(res.data);
				console.table(res.data);
				return res.data;
			})
			.then((data: IEventType[]) => {
				if (eventos && Array.isArray(eventos)) {
					const group = eventos.reduce((acc: any, event) => {
						const eventType = data.find(
							(type) => type.id === event.id_event_type
						);
						if (eventType) {
							acc[eventType.name] = acc[eventType.name] || [];
							acc[eventType.name].push(event);
						}
						return acc;
					}, {});
					console.log(
						"%cGRUPOS DE EVENTOS",
						"color:green; font-size: 24px;"
					);
					console.log(eventos);
					console.log(group);
					setGruposDeEvento(group);
				}
			});
	};

	useEffect(() => {
		getTiposDeEvento();
		// eslint-disable-next-line
	}, [eventos]);

	return (
		<MeuCurriculoContext.Provider
			value={{
				professionalExperiences,
				eventos,
				signal,
				student,
				gruposDeEvento,
				setSignal,
				setEventos,
				setProfessionalExperiences,
				setStudent,
				setGruposDeEvento,
			}}
		>
			{children}
		</MeuCurriculoContext.Provider>
	);
};

export const useMeuCurriculo = () => {
	return useContext(MeuCurriculoContext);
};
