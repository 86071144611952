/**
 * Convert BASE64 to BLOB
 * Implementado em: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript#:~:text=/**%0A%20*%20Convert%20BASE64%20to,type%3A%20imageType%20%7D)%3B%0A%7D
 * Explicação da implementação: https://meta.stackoverflow.com/questions/392712/explaining-entirely-code-based-answers
 * @param base64Image Pass Base64 image data to convert into the BLOB
 */
export function convertBase64ToBlob(base64Image: string) {
    // Split into two parts
    const parts = base64Image.split(';base64,');
  
    // Hold the content type
    const imageType = parts[0].split(':')[1];
  
    // Decode Base64 string
    const decodedData = window.atob(parts[1]);
  
    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
  
    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
  
    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }