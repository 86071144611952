
export const poses = [
    {
      label: "Frente",
      image: "poses/image 133.svg"
    },
    {
      label: "Esquerda",
      image: "poses/image 134.svg"
    },
    {
      label: "Direita",
      image: "poses/image 135.svg"
    },
    {
      label: "Cima",
      image: "poses/image 136.svg"
    },
    {
      label: "Baixo",
      image: "poses/image 137.svg"
    },
  ];
  
export const refImages = [
    {
      label: "Frente",
      image: null,
    },
    {
      label: "Esquerda",
      image: null
    },
    {
      label: "Direita",
      image: null
    },
    {
      label: "Cima",
      image: null
    },
    {
      label: "Baixo",
      image: null
    },
  ];