import { DisplaySettings, Refresh } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";

export function ActivityViewerDialogFooter({
	data,
	setShowImages,
	showImages,
	getAIEvaluation,
}) {
	return (
		<Grid item md={12}>
			<Box
				mb={3}
				mt={3}
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<Button
					variant="contained"
					disableElevation
					startIcon={<DisplaySettings />}
					// sx={{ mt: 3 }}
					onClick={() => setShowImages(!showImages)}
				>
					Visualizar Biometria
				</Button>
				<Button
					variant="contained"
					disableElevation
					color="primary"
					onClick={() => {
						//TODO: Verificar retorno deste método para não sair da tela e do diálogo do usuário
						getAIEvaluation(data.id);
					}}
					startIcon={<Refresh />}
					sx={{ display: "none" }}
				>
					Feedback
				</Button>
			</Box>
		</Grid>
	);
}
