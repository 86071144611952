import React from "react";
import TabelaVagas from "../../../components/Tabelas/TabelaVagas";

export default function Vagas(): React.ReactElement {
	return (
		<>
			<TabelaVagas />
		</>
	);
}
