import { AtualizarTipoContrato, CriarTipoContrato, RespostaTipoContrato, TipoContrato } from "../../models/TipoContrato";
import Notify from "../../utils/notification";
import api from "../api";

/**
 * @function postTipoContrato
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo post para a rota /contract-types, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<TipoContrato> contendo os dados do tipo de contrato cadastrado. 
 * @param {CriarTipoContrato} data - Dados do tipo de contrato a ser cadastrado.
 */

export async function postTipoContrato(data: CriarTipoContrato): Promise<TipoContrato> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  let dados = {
    name: data.name,
  }

  return await api.post("/contract-types", dados, { headers: { "Authorization": `Bearer ${token}` } }).then((data) => {
    Notify('Contrato cadastrado com sucesso!');
    return data;
  }).catch((e: any) => {
    Notify("Contrato não foi cadastrada, verifique seus dados e tente novamente!", "error");
    return e;
  });
}

/**
 * @function getTiposContratos
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /contract-types/:pagina, caso o parâmetro página não seja nulo, caso não, a requisição do tipo get é feita para a rota /contract-types, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaTipoContrato> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com todas os tipos de contrato disponíveis na base de dados.
 * @param {string | null} pagina - Número da página escolhida para a paginação.
 */

export async function getTiposContratos(pagina: string | null): Promise<RespostaTipoContrato> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get(pagina !== null ? `/contract-types/${pagina}` : "/contract-types", { headers: { "Authorization": `Bearer ${token}` } })
    .then(({ data }) => {
      return data;
    }).catch((e: any) => {
      return e;
    });

}

/**
 * @function getTipoContrato
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /companies/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<TipoContrato> contendo os dados dum tipo de contrato específico associado ao id.
 * @param {number} id - Id do tipo de contrato a ser buscado.
 */

export async function getTipoContrato(id: number): Promise<TipoContrato> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get(`/contract-types/${id}`, { headers: { "Authorization": `Bearer ${token}` } })
    .then(({ data }) => {
      return data;
    }).catch((e: any) => {
      return e;
    });
}

/**
 * @function putTipoContrato
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo put para a rota /contract-types/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso nada é retornado pela api.
 * @param {number} id - Id do tipo de contrato a ser atualizado.
 * @param {AtualizarTipoContrato} data - Dados do tipo de contrato a ser atualizado.
 */

export async function putTipoContrato(id: number, data: AtualizarTipoContrato) {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  let dados = {
    name: data.name,
  }

  return await api.put(`/contract-types/${id}`, dados, {
    headers: { "Authorization": `Bearer ${token}` }
  }).then(() => {
    Notify("Contrato alterado com sucesso!");
  }).catch((e) => {
    Notify("Contrato não foi alterado, verifique seus dados e tente novamente!", "error");
    return e;
  });

}

/**
 * @function deleteTipoContrato
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo delete para a rota /contract-types/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso nada é retornado.
 * @param {number} id - Id do tipo de contrato a ser deletado.
 */

export async function deleteTipoContrato(id: number) {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.delete(`/contract-types/${id}`, { headers: { "Authorization": `Bearer ${token}` } }
  ).then(() => {
    Notify("Contrato deletado com sucesso!");
  }).catch((e) => {
    Notify("Contrato não foi deletado, verifique seus dados e tente novamente!", "error");
    return e;
  });
}