import React, { createContext } from "react";
import { PropsModalContext } from "./models/PropsModalContext";

const DEFAULT_VALUE: PropsModalContext = {
	open: false,
	setOpen: () => {},
	handleOpen: () => {},
	handleClose: () => {},
};

export const ModalContext = createContext<PropsModalContext>(DEFAULT_VALUE);

export const ModalContextProvider: React.FC = ({ children }) => {
	const [open, setOpen] = React.useState(false);

	function handleOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return (
		<ModalContext.Provider
			value={{
				open,
				setOpen,
				handleOpen,
				handleClose,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};
