import { Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TipoContrato } from "../../../models/TipoContrato";
import { getTipoContrato } from "../../../services/crud/crud.tiposContrato";
import "../style.scss";

function ListaDetalhesTipoContratoView(): React.ReactElement {
	let idTipoContrato = Number(localStorage.getItem("id_tipocontrato"));
	const [contrato, setContrato] = useState<TipoContrato>();
	// const { handleClose } = useModalContext();

	useEffect(() => {
		if (idTipoContrato)
			getTipoContrato(idTipoContrato)
				.then((data) => {
					setContrato(data);
					localStorage.removeItem("id_tipocontrato");
				})
				.catch((e) => {
					console.log(e);
				});
	}, [idTipoContrato]);

	return (
		<>
			<Container data-testid="detalhes-tipo-contrato">
				<table className="DetalhesUsuarioTable">
					<tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Id:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{contrato?.id}
							</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Nome:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{contrato?.name}
							</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Criado:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{new Date(
									contrato?.created_at as string
								).toLocaleDateString()}
							</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Typography className="typographyDetalhesUsuario">
								Atualizado:
							</Typography>
						</td>
						<td>
							<Typography className="typographyDetalhes">
								{new Date(
									contrato?.updated_at as string
								).toLocaleDateString()}
							</Typography>
						</td>
					</tr>
				</table>

				{/* <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Id:</Typography>
          <Typography className="typographyDetalhes">{contrato?.id}</Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Nome:</Typography>
          <Typography className="typographyDetalhes">
            {contrato?.name}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">Criado:</Typography>
          <Typography className="typographyDetalhes">
            {contrato?.created_at}
          </Typography>
        </Box>

        <Box className="BoxListaDetalhesUsuario">
          <Typography className="typographyDetalhesUsuario">
            Atualizado:
          </Typography>
          <Typography className="typographyDetalhes">
            {contrato?.updated_at}
          </Typography>
        </Box> */}
				{/* <hr></hr> */}
			</Container>
		</>
	);
}

export default ListaDetalhesTipoContratoView;
