import { styled } from "@mui/material";

const drawerWidth = 296;

/**
 * Container de tamanho variável para as páginas do projeto
 * Este container é posicionado ao lado do Drawer e abaixo da Appbar
 */
export const AppMain = styled("main", {
	shouldForwardProp: (prop) => prop !== "open",
})<{
	open?: boolean;
}>(({ theme, open }) => ({
	flexGrow: 1,
	transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: 0,
	...(open && {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: drawerWidth,
	}),
}));
