import { fileManagerApi } from "../filemanager";
import { uploadFilesViaLambda } from "../service/lambda";
// import MiniInfo from "@components/Dialogs/MiniInfo";
import ProgressIndicator from "../ProgressIndicator";
// import useAuth from "@context/auth/AuthHook";
// import useSession from "@context/session/SessionHook";
import { ArrowForward, CenterFocusStrong, Delete } from "@mui/icons-material";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	Paper,
	Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
// import { toast } from "react-toastify";
import { convertBase64ToBlob } from "./biometry.helper";
import BiometryService from "./biometry.service";

import useSession from "../../../../context/session/SessionHook";
import { poses as references } from "../mydata/constants";

function getState(user: any) {
	return [
		`${process.env.REACT_APP_FILE_LOCATION}/${user}/frente.png`,
		`${process.env.REACT_APP_FILE_LOCATION}/${user}/direita.png`,
		`${process.env.REACT_APP_FILE_LOCATION}/${user}/esquerda.png`,
		`${process.env.REACT_APP_FILE_LOCATION}/${user}/cima.png`,
		`${process.env.REACT_APP_FILE_LOCATION}/${user}/baixo.png`,
	];
}

export default function BiometryTemplate() {
	let state: any = "";
	let user: any = JSON.parse(
		localStorage.getItem("@palco-profissional:token") as string
	).data.usuario.id;
	// const history = useNavigate();
	const canvasRef = useRef<any>(null);
	const videoRef = useRef<any>(null);

	const { setActiveStep, images, setImages } = useSession();

	const [firstShotDelay, setFirstShotDelay] = useState<number>(3000);
	const [finalState, setFinalState] = useState<any>([]);
	const [initialState, setInitialState] = useState<any>(() => getState(user));

	const [, setOpen] = useState<boolean>(false);
	const [openCamera, setOpenCamera] = useState<boolean>(true);

	const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

	useEffect(() => {
		loadBiometry();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function getUserVideo() {
		setInterval(() => {
			return;
		}, firstShotDelay);

		setFirstShotDelay(0);

		setOpenCamera(true);
		try {
			const player = videoRef.current;

			const handleSuccess = (stream: MediaStream) => {
				player.srcObject = stream;

				player.addEventListener("loadedmetadata", () => {
					setOpenCamera(false);
				});
			};

			navigator.mediaDevices
				.getUserMedia({
					audio: false,
					video: {
						width: window.innerWidth < 800 ? 480 / 2 : 854 / 2,
						height: window.innerWidth < 800 ? 854 / 2 : 480 / 2,
					},
				})
				.then(handleSuccess)
				.then(() => videoRef.current.play());
		} catch (e) {}
	}

	async function capture() {
		if (canvasRef.current) {
			var el: HTMLCanvasElement | any = document.createElement("canvas");

			el.width = videoRef.current.videoWidth;
			el.height = videoRef.current.videoHeight;

			console.log(videoRef.current.videoHeight);
			console.log(videoRef.current.videoWidth);
			el.getContext("2d").drawImage(
				videoRef.current,
				0,
				0,
				videoRef.current.videoWidth,
				videoRef.current.videoHeight
			);

			// canvasRef.current.append(el)
			const file = convertBase64ToBlob(el.toDataURL("image/png"));

			// await uploadFilesViaLambda(user, file, "image/png", segments[-1])
			await uploadFilesViaLambda(
				state?.usuario,
				file,
				"image/png",
				initialState[0]
			).then(() => {
				// toast.success("Imagem enviada com sucesso");
			});
		}
	}

	async function loadBiometry() {
		await new BiometryService(fileManagerApi)
			.getAll(state?.usuario?.id)
			.then((res: AxiosResponse<any>) => {
				setImages(res.data);

				const { data } = res;

				data.forEach((item: any) => {
					setInitialState((prevState: any) => {
						return prevState.filter(
							(key: any) => key !== item.file_key
						);
					});
				});
				setFinalState(res.data);

				if (res.data.length >= 5) {
					setActiveStep(1);
				} else {
					getUserVideo();
				}

				// toast.success("Carregando biometria existente");
			})
			.catch((err: Error) => {
				// toast.error("Falha ao carregar");
				// throw new Error("Falha ao carregar");
			});
	}

	async function submitBiometry() {
		if (initialState.length > 0) {
			setOpenBackdrop(true);
			await capture();
			let user: any = JSON.parse(
				localStorage.getItem("@palco-profissional:token") as string
			).data.usuario.id;

			await new BiometryService(fileManagerApi)
				.create({
					user_id: user,
					file_key: initialState[0],
				})
				.then(async (res: AxiosResponse<any>) => {
					await loadBiometry();
				})
				.catch(async (res: AxiosResponse<any>) => {
					await loadBiometry();
				})
				.finally(async () => {
					setOpenBackdrop(false);
					// window.location.reload();
					await loadBiometry();
				});
		}
	}

	async function deleteBiometry(id: number) {
		setOpenBackdrop(true);
		const bio = finalState.filter((item: any) => item.id === id)[0];
		await new BiometryService(fileManagerApi)
			.delete(id)
			.then(async (res: AxiosResponse<any>) => {
				await loadBiometry();
				setInitialState([...initialState, bio.file_key]);
			})
			.catch(async (res: AxiosResponse<any>) => {
				await loadBiometry();
			})
			.finally(() => {
				setOpenBackdrop(false);
				setActiveStep(0);
			});
	}

	useEffect(() => {
		if (initialState.length === 0 && finalState.length >= 5) {
			setOpen(true);
		}
	}, [finalState.length, initialState.length]);

	useEffect(() => {
		if (images.length >= 5) {
			setOpen(true);
			setActiveStep(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function extractFormattedString(inputString: string): string {
		const lastSlashIndex = inputString.lastIndexOf("/");
		const lastDotIndex = inputString.lastIndexOf(".");

		if (
			lastSlashIndex !== -1 &&
			lastDotIndex !== -1 &&
			lastDotIndex > lastSlashIndex
		) {
			const desiredSubstring = inputString.substring(
				lastSlashIndex + 1,
				lastDotIndex
			);
			const formattedResult =
				desiredSubstring.charAt(0).toUpperCase() +
				desiredSubstring.slice(1).toLowerCase();
			return formattedResult;
		} else {
			throw new Error("Invalid input string");
		}
	}

	return (
		<>
			<Backdrop open={openBackdrop}>
				<CircularProgress />
			</Backdrop>
			<Container
				maxWidth={"xl"}
				sx={{ paddingTop: "36px", width: "80%" }}
			>
				<Grid container spacing={1}>
					<Grid item md={12}>
						<ProgressIndicator />
					</Grid>
					<Grid
						item
						md={initialState.length === 0 ? 12 : 6}
						order={{ xs: 3, sm: 3, md: 2 }}
					>
						{initialState.length !== 0 && (
							<Grid container spacing={1} mb={2} mt={2}>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
								>
									<Typography variant="h5">
										Imagens pendentes - Posição do rosto
									</Typography>
								</Grid>
								{initialState.map(
									(file_key: any, index: number) => {
										return (
											<Grid
												item
												md={2}
												xs={2}
												sm={2}
												key={index}
											>
												<Box
													p={1}
													sx={{
														display: "flex",
														alignItems: "center",
														flexDirection: "column",
													}}
												>
													{references.map(
														(
															reference: any,
															index: number
														) => {
															return (
																extractFormattedString(
																	file_key
																) ===
																	reference.label && (
																	<Box
																		key={
																			index
																		}
																		sx={{
																			display:
																				"flex",
																			flexDirection:
																				"column",
																			alignItems:
																				"center",
																		}}
																	>
																		<img
																			src={
																				reference.image
																			}
																			width="68"
																			alt=""
																		></img>
																	</Box>
																)
															);
														}
													)}
													<Typography
														sx={{
															textAlign: "center",
															mt: 1,
															mb: 1,
														}}
														variant="caption"
													>
														{extractFormattedString(
															file_key
														)}
													</Typography>
												</Box>
											</Grid>
										);
									}
								)}
							</Grid>
						)}

						<Grid container spacing={2}>
							<Grid item md={12} sx={{ mt: 3, mb: 2 }}>
								<Typography variant="h5">
									Imagens capturadas
								</Typography>
							</Grid>

							<Grid
								item
								md={12}
								spacing={2}
								container
								sx={{
									overflowX: "hidden",
									marginBottom: "20vh",
								}}
							>
								<Box
									sx={{
										// border: "solid thin #ccc",
										display: "flex",
										flexDirection: "row",
										width: "100vw",
										overflow: "hidden",
										overflowX: "scroll",
										flexWrap: "nowrap",
										gap: "12px",
									}}
								>
									{finalState.map(
										(media: any, index: number) => {
											return (
												<Box
													key={index}
													component={Paper}
													mb={3}
													mt={3}
													sx={{
														// margin: "6px",
														minWidth: "172px",
														borderRadius: "8px",
														// border: "solid thin #ccc",
														overflow: "hidden",
													}}
												>
													<Box
														p={1}
														sx={
															{
																// backgroundColor:
																// 	"#ffffff",
																// borderRadius: "4px",
															}
														}
													>
														<img
															src={
																"https://dirin.s3.us-east-1.amazonaws.com/" +
																media.file_key
															}
															alt="item"
															width="150px"
														/>
														<Box
															sx={{
																display: "flex",
																flexDirection:
																	"row",
																alignItems:
																	"center",
																justifyContent:
																	"space-between",
																mb: 1,
															}}
														>
															<Typography
																variant="caption"
																sx={{
																	border: "none",
																}}
															>
																{extractFormattedString(
																	media.file_key
																).toUpperCase()}
															</Typography>
															<IconButton
																size="large"
																onClick={async () => {
																	await deleteBiometry(
																		media.id
																	).finally(
																		() => {
																			// window.location.reload();
																			loadBiometry();
																		}
																	);
																}}
															>
																<Delete />
															</IconButton>
														</Box>
													</Box>
												</Box>
											);
										}
									)}
								</Box>
								{/* <pre>
									{JSON.stringify(finalState, null, 4)}
								</pre> */}
							</Grid>
						</Grid>
					</Grid>
					{initialState.length !== 0 && (
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							xl={6}
							order={{ xs: 2, sm: 2, md: 3 }}
						>
							<Grid container>
								<Grid
									item
									md={12}
									xs={12}
									sm={12}
									lg={12}
									xl={12}
									sx={{
										mt: 3,
									}}
								>
									<Typography variant="h5">Câmera</Typography>
								</Grid>
								<Grid
									item
									md={12}
									// xs={12}
									// sm={12}
									sx={{ mt: "12px" }}
								>
									<Box
										sx={{
											minHeight: "480px",
											minWidth: "180px",
											backgroundColor: "black",
											borderRadius: "12px",
											mb: 2,
											mt: 1,
											overflow: "hidden",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<video
											ref={videoRef}
											style={{
												width: "100%",
												height: "100%",
											}}
										></video>
									</Box>

									{/* <Button onClick={getUserVideo} variant="outlined" startIcon={<CameraAlt />}>Abrir câmera</Button> */}
									<Button
										disabled={openCamera}
										onClick={submitBiometry}
										variant="outlined"
										startIcon={<CenterFocusStrong />}
									>
										Capturar imagem
									</Button>
								</Grid>
								<Grid
									item
									md={12}
									sx={{ mt: 2, mb: 1, display: "none" }}
								>
									<Typography variant="h5">
										Posições de rosto
									</Typography>
								</Grid>
								<Grid
									item
									md={12}
									sx={{
										display: "none",
										// display: "flex",
										alignItems: "center",
									}}
								>
									{references.map(
										(reference: any, index: number) => {
											return (
												<Box
													key={index}
													sx={{
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
													}}
												>
													<img
														src={reference.image}
														width="64"
														alt=""
													></img>
													<Typography
														variant="caption"
														component="p"
														sx={{
															textAlign: "center",
														}}
													>
														{reference.label}
													</Typography>
												</Box>
											);
										}
									)}
								</Grid>
							</Grid>
						</Grid>
					)}
					{initialState.length === 0 && finalState.length === 5 && (
						<Grid
							item
							md={12}
							sx={{
								minHeight: "20vh",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Button
								endIcon={<ArrowForward />}
								variant="outlined"
								onClick={() => {
									// history("/recorder")
									window.location.href = "/recorder/71";
									// setOpen(true);
								}}
							>
								Ir para a apresentação
							</Button>
						</Grid>
					)}

					<Grid item md={12}>
						<Box ref={canvasRef}></Box>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
