import { makeStyles } from "@material-ui/core";
import { Save } from "@mui/icons-material";
import {
	Box,
	Button,
	Container,
	Grid,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { AreaConhecimento } from "../../../../models/AreaConhecimento";
import { Empresa } from "../../../../models/Empresa";
import { TipoContrato } from "../../../../models/TipoContrato";
import { CriarVaga } from "../../../../models/Vaga";
import { getAreasConhecimento } from "../../../../services/crud/crud.areaConhecimento";
import {
	getAllEmpresas,
	getEmpresasRepresentante,
} from "../../../../services/crud/crud.empresa";
import { getTiposContratos } from "../../../../services/crud/crud.tiposContrato";
import { postVaga } from "../../../../services/crud/crud.vaga";
import { useReloadContext } from "../../../Tabelas/hooks/useReload";
import "../styles.scss";

function FormularioCadastroVagaView(): React.ReactElement {
	const [empresa, setEmpresa] = useState("");
	const [area, setArea] = useState("");
	const [tipoContrato, setTipoContrato] = useState("");
	const [estado, setEstado] = useState("");
	const [empresas, setEmpresas] = useState<Array<Empresa>>([]);
	const [areas, setAreas] = useState<Array<AreaConhecimento>>([]);
	const [tiposContratos, setTiposContratos] = useState<Array<TipoContrato>>(
		[]
	);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	const { handleClose } = useModalContext();
	const { handleReload } = useReloadContext();
	const { auth } = useAuthContext();
	const useStyles = makeStyles((theme) => ({
		textField: {
			"& label": {
				color: "grey !important",
			},
			"& label.Mui-focused": {
				color: "black !important",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				// '&:hover fieldset': {
				//   borderColor: 'blue',
				// },
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));
	const classes = useStyles();

	/**
	 * @function cadastroVaga
	 * Faz a converção de string para number nas propriedades remuneration_inicial e remuneration_final de data, após a converção faz a chamada da função de postVaga enviando data como parâmetro, se caso a requisição der certo emite um Notify, chama as funções handleClose e handleReload fechando o modal e dando um refresh na página.
	 * @param {CriarVaga} data - Representa os dados cadastrados das Vagas.
	 */
	async function cadastroVaga(data: CriarVaga) {
		data.remuneration_initial = Number(data.remuneration_initial);
		data.remuneration_final = Number(data.remuneration_final);
		postVaga(data)
			.then(() => {
				handleClose();
				handleReload();
			})
			.catch((e) => {
				console.log(e);
			});
	}

	useEffect(() => {
		if (auth.role === "admin") {
			getAllEmpresas()
				.then((data) => {
					setEmpresas(data);
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			getEmpresasRepresentante(null)
				.then((data) => {
					setEmpresas(data.data);
				})
				.catch((e) => {
					console.log(e);
				});
		}

		getAreasConhecimento()
			.then((data) => {
				setAreas(data.data);
			})
			.catch((e) => {
				console.log(e);
			});

		getTiposContratos(null)
			.then((data) => {
				setTiposContratos(data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [auth.role]);

	return (
		<Container
			// className="container"
			maxWidth="xl"
			component="section"
			// style={{ width: "100%", height: "100%" }}
			data-testid="formulario-cadastro-vaga"
		>
			<Box mt={2} mb={3}>
				<Typography variant="h5" component="h5">
					Cadastrar Vaga
				</Typography>
				<Typography sx={{ mt: 1 }}>
					Preencha o formulário com os detalhes da vaga.
				</Typography>
			</Box>
			<form
				onSubmit={handleSubmit((data) =>
					cadastroVaga({
						title: data.title,
						description: data.description,
						requirements: data.requirements,
						additional_info: data.additional_info,
						experience_level: data.experience_level,
						department: data.department,
						assignments: data.assignments,
						city: data.city,
						state: data.state,
						remuneration_initial: data.remuneration_initial,
						remuneration_final: data.remuneration_final,
						status: true,
						companies_id: data.companies_id,
						knowledge_areas_id: data.knowledge_areas_id,
						contract_types_id: data.contract_types_id,
					})
				)}
			>
				<Grid container spacing={1}>
					<Grid item md={12}>
						<TextField
							className={classes.textField}
							label="Vaga"
							type="text"
							fullWidth
							margin="normal"
							{...register("title", { required: true })}
							error={errors.title?.type === "required"}
							helperText={
								errors.title?.type === "required" && (
									<span>O campo "Vaga" é obrigatório!</span>
								)
							}
						/>
					</Grid>

					<Grid item md={12}>
						<TextField
							className={classes.textField}
							label="Situação da vaga*"
							select
							fullWidth
							margin="normal"
							{...register("status", { required: true })}
							error={errors.status?.type === "required"}
							helperText={
								errors.status?.type === "required" && (
									<span>
										O campo "Situação da Vaga" é
										obrigatório!
									</span>
								)
							}
						>
							<MenuItem value="">
								Selecione a situação da vaga
							</MenuItem>
							<MenuItem value={1}>Aberta</MenuItem>
						</TextField>
					</Grid>

					<Grid item md={6}>
						<TextField
							className={classes.textField}
							label="Empresa*"
							select
							fullWidth
							margin="normal"
							value={empresa}
							{...register("companies_id", { required: true })}
							onChange={(event) => {
								setEmpresa(event.target.value);
							}}
							error={errors.companies_id?.type === "required"}
							helperText={
								errors.companies_id?.type === "required" && (
									<span>
										O campo "Empresa" é obrigatório!
									</span>
								)
							}
						>
							<MenuItem value="">Selecione uma empresa</MenuItem>
							{empresas.map((empresa, index) => (
								<MenuItem value={empresa.id} key={index}>
									{empresa.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>

					<Grid item md={6}>
						<>
							<TextField
								className={classes.textField}
								label="Área de Conhecimento*"
								select
								fullWidth
								value={area}
								margin="normal"
								{...register("knowledge_areas_id", {
									required: true,
								})}
								onChange={(event) => {
									setArea(event.target.value);
								}}
								error={
									errors.knowledge_areas_id?.type ===
									"required"
								}
								helperText={
									errors.knowledge_areas_id?.type ===
										"required" && (
										<span>
											O campo "Área de Conhecimento" é
											obrigatório!
										</span>
									)
								}
							>
								<MenuItem value="">
									Selecione a área de conhecimento da vaga
								</MenuItem>
								{areas.map((area, index) => (
									<MenuItem value={area.id} key={index}>
										{area.name}
									</MenuItem>
								))}
							</TextField>
						</>
					</Grid>
					<Grid item md={6}>
						<TextField
							className={classes.textField}
							label="Tipo de Contrato*"
							select
							fullWidth
							value={tipoContrato}
							margin="normal"
							{...register("contract_types_id", {
								required: true,
							})}
							onChange={(event) => {
								setTipoContrato(event.target.value);
							}}
							error={
								errors.contract_types_id?.type === "required"
							}
							helperText={
								errors.contract_types_id?.type ===
									"required" && (
									<span>
										O campo "Tipo de Contrato" é
										obrigatório!
									</span>
								)
							}
						>
							<MenuItem value="">
								Selecione o tipo de contrato
							</MenuItem>
							{tiposContratos.map((contractType, index) => (
								<MenuItem value={contractType.id} key={index}>
									{contractType.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>

					<Grid item md={6}>
						<>
							<TextField
								className={classes.textField}
								label="Estado*"
								select
								fullWidth
								value={estado}
								margin="normal"
								{...register("state", { required: true })}
								onChange={(event) => {
									setEstado(event.target.value);
								}}
								error={errors.state?.type === "required"}
								helperText={
									errors.contract_types_id?.type ===
										"required" && (
										<span>
											O campo "Estado" é obrigatório!
										</span>
									)
								}
							>
								<MenuItem value="">
									Selecione o estado onde há vaga
								</MenuItem>
								<MenuItem value="AC">Acre</MenuItem>
								<MenuItem value="AL">Alagoas</MenuItem>
								<MenuItem value="AP">Amapá</MenuItem>
								<MenuItem value="AM">Amazonas</MenuItem>
								<MenuItem value="BA">Bahia</MenuItem>
								<MenuItem value="Ceará">Ceará</MenuItem>
								<MenuItem value="DF">Distrito Federal</MenuItem>
								<MenuItem value="ES">Espírito Santo</MenuItem>
								<MenuItem value="GO">Goiás</MenuItem>
								<MenuItem value="MA">Maranhão</MenuItem>
								<MenuItem value="MT">Mato Grosso</MenuItem>
								<MenuItem value="MS">
									Mato Grosso do Sul
								</MenuItem>
								<MenuItem value="MG">Minas Gerais</MenuItem>
								<MenuItem value="PA">Pará</MenuItem>
								<MenuItem value="PB">Paraíba</MenuItem>
								<MenuItem value="PR">Paraná</MenuItem>
								<MenuItem value="PE">Pernambuco</MenuItem>
								<MenuItem value="PI">Piauí</MenuItem>
								<MenuItem value="RJ">Rio de Janeiro</MenuItem>
								<MenuItem value="RN">
									Rio Grande do Norte
								</MenuItem>
								<MenuItem value="RS">
									Rio Grande do Sul
								</MenuItem>
								<MenuItem value="RO">Rondônia</MenuItem>
								<MenuItem value="RR">Roraima</MenuItem>
								<MenuItem value="SC">Santa Catarina</MenuItem>
								<MenuItem value="SP">São Paulo</MenuItem>
								<MenuItem value="SE">Sergipe</MenuItem>
								<MenuItem value="TO">Tocantins</MenuItem>
								<MenuItem value="EX">Estrangeiro</MenuItem>
							</TextField>
						</>
					</Grid>
					<Grid item md={6}>
						<TextField
							className={classes.textField}
							label="Cidade*"
							type="text"
							fullWidth
							margin="normal"
							{...register("city", { required: true })}
							error={errors.city?.type === "required"}
							helperText={
								errors.city?.type === "required" && (
									<span>O campo "Cidade" é obrigatório!</span>
								)
							}
						/>
					</Grid>

					<Grid item md={6}>
						<>
							<TextField
								className={classes.textField}
								label="Remuneração Inicial*"
								type="number"
								fullWidth
								margin="normal"
								{...register("remuneration_initial", {
									required: true,
								})}
								error={
									errors.remuneration_initial?.type ===
									"required"
								}
								helperText={
									errors.remuneration_initial?.type ===
										"required" && (
										<span>
											O campo "Remuneração Inicial" é
											obrigatório!
										</span>
									)
								}
							/>
						</>
					</Grid>
					<Grid item md={6}>
						<TextField
							className={classes.textField}
							label="Remuneração Final"
							type="number"
							fullWidth
							margin="normal"
							{...register("remuneration_final", {
								required: false,
							})}
							error={
								errors.remuneration_final?.type === "required"
							}
							// helperText={
							// 	errors.remuneration_final?.type ===
							// 		"required" && (
							// 		<span>
							// 			O campo "Remuneração Final" é
							// 			obrigatório!
							// 		</span>
							// 	)
							// }
						/>
					</Grid>
					{/* <Grid item md={6}>
						<TextField
							className={classes.textField}
							label="Remuneração Final*"
							type="number"
							fullWidth
							margin="normal"
							{...register("remuneration_final", {
								required: true,
							})}
							error={
								errors.remuneration_final?.type === "required"
							}
							helperText={
								errors.remuneration_final?.type ===
									"required" && (
									<span>
										O campo "Remuneração Final" é
										obrigatório!
									</span>
								)
							}
						/>
					</Grid> */}

					<Grid item md={6}>
						<>
							<TextField
								className={classes.textField}
								label="Setor de atuação*"
								type="text"
								fullWidth
								margin="normal"
								{...register("department", { required: true })}
								error={errors.department?.type === "required"}
								helperText={
									errors.department?.type === "required" && (
										<span>
											O campo "Setor de atuação" é
											obrigatório!
										</span>
									)
								}
							/>
						</>
					</Grid>
					<Grid item md={6}>
						<TextField
							className={classes.textField}
							label="Nível de experiência / Formação*"
							type="text"
							fullWidth
							margin="normal"
							{...register("experience_level", {
								required: true,
							})}
							error={errors.experience_level?.type === "required"}
							helperText={
								errors.experience_level?.type ===
									"required" && (
									<span>
										O campo "Nível de experiência /
										Formação" é obrigatório!
									</span>
								)
							}
						/>
					</Grid>

					<Grid item md={12}>
						<TextField
							className={classes.textField}
							label="Descrição da vaga*"
							type="text"
							fullWidth
							margin="normal"
							multiline={true}
							rows={4}
							{...register("description", { required: true })}
							error={errors.description?.type === "required"}
							helperText={
								errors.description?.type === "required" && (
									<span>
										O campo "Descrição" é obrigatório!
									</span>
								)
							}
						/>
					</Grid>

					<Grid item md={12}>
						<TextField
							className={classes.textField}
							label="Tarefas*"
							type="text"
							fullWidth
							margin="normal"
							multiline={true}
							rows={4}
							{...register("assignments", { required: true })}
							error={errors.assignments?.type === "required"}
							helperText={
								errors.assignments?.type === "required" && (
									<span>
										O campo "Tarefas" é obrigatório!
									</span>
								)
							}
						/>
					</Grid>

					<Grid item md={12}>
						<TextField
							className={classes.textField}
							label="Requisitos*"
							type="text"
							fullWidth
							margin="normal"
							multiline={true}
							rows={4}
							{...register("requirements", { required: true })}
							error={errors.requirements?.type === "required"}
							helperText={
								errors.requirements?.type === "required" && (
									<span>
										O campo "Requisitos" é obrigatório!
									</span>
								)
							}
						/>
					</Grid>

					<Grid item md={12}>
						<TextField
							className={classes.textField}
							label="Informações adicionais"
							type="text"
							fullWidth
							margin="normal"
							multiline={true}
							rows={4}
							{...register("additional_info", {
								required: false,
							})}
							error={errors.additional_info?.type === "required"}
							helperText={
								errors.additional_info?.type === "required" && (
									<span>
										O campo "Informações adicionais" é
										obrigatório!
									</span>
								)
							}
						/>
					</Grid>

					<Grid
						item
						md={12}
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
							gap: "12px",
						}}
					>
						<Button variant="text" onClick={handleClose}>
							Cancelar
						</Button>
						<Button
							variant="contained"
							type="submit"
							disableElevation
							startIcon={<Save />}
						>
							Cadastrar
						</Button>
						{/* <BotaoVoltar />
                    <BotaoAdicionar /> */}
					</Grid>
				</Grid>
			</form>
		</Container>
	);
}

export default FormularioCadastroVagaView;
