import { Input } from "@material-ui/core";
import {
	Box,
	Button,
	Container,
	FormControl,
	Grid,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { AtualizarEmpresa } from "../../../../models/Empresa";
import {
	getEmpresa,
	getEmpresaRepresentante,
	putEmpresa,
} from "../../../../services/crud/crud.empresa";
import maskCnpj from "../../../../utils/mask";
import Notify from "../../../../utils/notification";
import { useReloadContext } from "../../../Tabelas/hooks/useReload";

function FormularioAlteracaoEmpresaView(): React.ReactElement {
	let idEmpresa = Number(localStorage.getItem("id_empresa"));
	const {
		register,
		handleSubmit,
		reset,
		getValues,
		setValue,
		control,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	const [formValues, setFormValues] = useState<any>();
	const { handleClose } = useModalContext();
	const { handleReload } = useReloadContext();
	const { auth } = useAuthContext();

	const [selectedField, setSelectedField] = useState<string>(
		formValues?.cnpj ? "cnpj" : "rnp"
	); // Set selectedField based on cnpj or numero_registro

	const [files, setFiles] = useState<any>({
		cover_image: null,
		logo_image: null,
	});

	useEffect(() => {
		if (auth.role === "admin") {
			getEmpresa(idEmpresa)
				.then((data) => {
					if (data.cnpj) {
						setSelectedField("cnpj");
						data.cnpj = maskCnpj(data.cnpj);
					} else {
						setSelectedField("rnp");
					}
					setFormValues(data);
					reset(data);
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			getEmpresaRepresentante(idEmpresa)
				.then((data) => {
					if (data.cnpj) {
						setSelectedField("cnpj");
						data.cnpj = maskCnpj(data.cnpj);
					} else {
						setSelectedField("rnp");
					}
					setFormValues(data);
					reset(data);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [idEmpresa, auth.role, reset]);

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedField(event.target.value as string);
	};

	async function atualizacaoEmpresa(data, event) {
		if (data.cnpj) {
			delete data.numero_registro;
			data.cnpj = data.cnpj?.replace(/[^\w]+/g, "");
		}

		if (data.numero_registro) {
			delete data.cnpj;
		}

		let arrayDados = Object.entries(data);

		let arrayFormValues = Object.entries(formValues);

		let dataEmpresa = arrayDados.filter((value, index) => {
			return value[1] !== arrayFormValues[index][1];
		});

		console.log("Form Data:", data);

		// Access file details for logo_image and cover_image
		if (data.logo_image) {
			console.log("Logo Image:", {
				name: data.logo_image[0].name,
				type: data.logo_image[0].type,
				size: data.logo_image[0].size,
			});
		}

		if (data.cover_image) {
			console.log("Cover Image:", {
				name: data.cover_image[0].name,
				type: data.cover_image[0].type,
				size: data.cover_image[0].size,
			});
		}

		if (files.logo_image) {
			data.logo_image = files.logo_image;
		} else {
			delete data.logo_image;
		}
		if (files.cover_image) {
			data.cover_image = files.cover_image;
		} else {
			delete data.cover_image;
		}

		const formData = new FormData();
		for (const key in data) {
			// Check if the value is a File object
			if (data[key] instanceof FileList) {
				formData.append(key, data[key][0]);
			} else {
				formData.append(key, data[key]);
			}
		}

		if (dataEmpresa.length > 0) {
			putEmpresa(idEmpresa, formData)
				.then(() => {
					handleClose();
					handleReload();
					localStorage.setItem(
						"id_usuario_filter",
						formValues.empresa.user_id.toString()
					);
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			event.preventDefault();
			Notify(
				"Atualize a informação para efetuar a operação de atualização!",
				"warning"
			);
		}
	}

	return (
		<Container
			component="section"
			style={{ width: "100%", height: "100%" }}
			data-testid="formulario-alteracao-empresa"
		>
			<Box mb={2}>
				<Typography variant="h5" component="h5">
					Editar Empresa
				</Typography>
			</Box>
			<form
				onSubmit={handleSubmit((data: AtualizarEmpresa, event) =>
					atualizacaoEmpresa(data, event)
				)}
				style={{ width: "100%", height: "100%" }}
			>
				<Grid container spacing={2}>
					<Grid item md={12}>
						<Controller
							name="name"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									// className={classes.textField}
									label="Nome Fantasia*"
									type="text"
									fullWidth
									// margin="normal"
									onChange={(e) => {
										onChange(e);
									}}
									value={getValues("name") ? value : ""}
									error={errors.name?.type === "required"}
									helperText={
										errors.name?.type === "required" && (
											<span>
												O campo "Nome Fantasia" não pode
												ser vazio!
											</span>
										)
									}
								/>
							)}
						/>
					</Grid>

					<Grid item md={6}>
						<FormControl sx={{ mb: 1 }} fullWidth>
							<Select
								value={selectedField}
								onChange={handleChange}
								fullWidth
								label="Selecione o campo"
							>
								<MenuItem value="cnpj">CNPJ</MenuItem>
								<MenuItem value="rnp">
									Registro Profissional
								</MenuItem>
							</Select>
						</FormControl>

						{selectedField === "cnpj" && (
							<Controller
								name="cnpj"
								control={control}
								render={({ field: { value, onChange } }) => (
									<TextField
										// className={classes.textField}
										// margin="normal"
										label="CNPJ*"
										type="text"
										fullWidth
										onChange={(e) => {
											setValue(
												"cnpj",
												maskCnpj(e.target.value)
											);
										}}
										value={getValues("cnpj") ? value : ""}
										error={errors.cnpj?.type === "required"}
										helperText={
											errors.cnpj?.type ===
												"required" && (
												<span>
													O campo "CNPJ" é
													obrigatório!
												</span>
											)
										}
									/>
								)}
							/>
						)}

						{selectedField === "rnp" && (
							<Controller
								name="numero_registro"
								control={control}
								render={({ field: { value, onChange } }) => (
									<TextField
										// className={classes.textField}
										// margin="normal"
										label="Registro Profissional*"
										type="text"
										fullWidth
										onChange={(e) => {
											setValue(
												"numero_registro",
												e.target.value
											);
										}}
										value={
											getValues("numero_registro")
												? value
												: ""
										}
										error={errors.cnpj?.type === "required"}
										helperText={
											errors.cnpj?.type ===
												"required" && (
												<span>
													O campo "Registro
													Profissional" é obrigatório!
												</span>
											)
										}
									/>
								)}
							/>
						)}
					</Grid>

					<Grid item md={6}>
						<Controller
							name="area"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									// className={classes.textField}
									label="Área de atuação*"
									type="text"
									fullWidth
									// margin="normal"
									onChange={(e) => {
										onChange(e);
									}}
									value={getValues("area") ? value : ""}
									error={errors.area?.type === "required"}
									helperText={
										errors.area?.type === "required" && (
											<span>
												O campo "Área de atuação" é
												obrigatório!
											</span>
										)
									}
								/>
							)}
						/>
					</Grid>

					<Grid item md={4}>
						<Controller
							name="email_curriculum"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									// className={classes.textField}
									label="E-mail para recebimento de curriculum*"
									type="email"
									fullWidth
									// margin="normal"
									onChange={(e) => {
										onChange(e);
									}}
									value={
										getValues("email_curriculum")
											? value
											: ""
									}
									error={
										errors.email_curriculum?.type ===
										"required"
									}
									helperText={
										errors.email_curriculum?.type ===
											"required" && (
											<span>
												O campo "E-mail para recebimento
												de curriculum" é obrigatório!
											</span>
										)
									}
								/>
							)}
						/>
					</Grid>

					<Grid item md={12}>
						<Controller
							name="about"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									// className={classes.textField}
									label="Sobre a empresa*"
									type="text"
									fullWidth
									multiline
									rows={4}
									// margin="normal"
									onChange={(e) => {
										onChange(e);
									}}
									value={getValues("about") ? value : ""}
									error={errors.about?.type === "required"}
									helperText={
										errors.about?.type === "required" && (
											<span>
												O campo "Sobre a empresa" é
												obrigatório!
											</span>
										)
									}
								/>
							)}
						/>
					</Grid>

					<Grid item md={9}>
						<Controller
							name="site"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									// className={classes.textField}
									label="Site"
									type="text"
									fullWidth
									// margin="normal"
									onChange={(e) => {
										onChange(e);
									}}
									value={getValues("site") ? value : ""}
								/>
							)}
						/>
					</Grid>

					<Grid item md={3}>
						<TextField
							// className={classes.textField}
							{...register("employees_number")}
							// focused={true}
							InputLabelProps={{
								shrink: true,
							}}
							label="Número de Empregados"
							type="number"
							fullWidth
							// margin="normal"
						/>
					</Grid>

					{/* <Box>
          <FormControl fullWidth>
            <Input type="file" {...register("logo_image")} />
          </FormControl>
          <FormControl fullWidth>
            <Input type="file" {...register("cover_image")} />
          </FormControl>
        </Box> */}
					<Grid item md={12}>
						<FormControl fullWidth>
							<Typography
								variant="body1"
								sx={{ fontWeight: "500", mb: 1, mt: 1 }}
							>
								Envio da logo
							</Typography>
							<div
								style={{
									backgroundColor: "#f8f8f8",
									borderRadius: "12px",
									padding: "12px",
									position: "relative",
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
									gap: "12px",
								}}
							>
								<Input
									type="file"
									id="logo_image"
									{...register("logo_image")}
									style={{
										display: "none",
									}}
									onChange={(e: any) => {
										setFiles({
											...files,
											logo_image: e.target.files[0],
										});
									}}
								/>
								<label htmlFor="logo_image">
									<Button
										variant="contained"
										component="span"
										disableElevation
									>
										Escolher arquivo
									</Button>
								</label>
								{(files &&
									files.logo_image &&
									files.logo_image.name) ||
									"Nenhum arquivo selecionado"}
								{/* {getValues("logo_image") &&
								getValues("logo_image")[0]
									? getValues("logo_image")[0].name
									: "Nenhum arquivo selecionado"} */}
							</div>
							{formValues &&
							formValues.logo_image &&
							typeof formValues.logo_image === "string" ? (
								<img
									src={formValues.logo_image} // Assuming logo_image is the S3 URL
									alt="Logo Preview"
									style={{
										maxWidth: "100px",
										marginTop: "10px",
									}}
								/>
							) : (
								formValues &&
								formValues.logo_image && (
									<img
										src={URL.createObjectURL(
											formValues.logo_image[0]
										)}
										alt="Logo Preview"
										style={{
											maxWidth: "100px",
											marginTop: "10px",
										}}
									/>
								)
							)}
						</FormControl>
					</Grid>

					<Grid item md={12}>
						<FormControl fullWidth>
							<Typography
								variant="body1"
								sx={{ fontWeight: "500", mb: 1, mt: 1 }}
							>
								Envio da capa
							</Typography>
							<div
								style={{
									backgroundColor: "#f8f8f8",
									borderRadius: "12px",
									padding: "12px",
									position: "relative",
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "center",
									gap: "12px",
								}}
							>
								<Input
									type="file"
									id="cover_image"
									{...register("cover_image")}
									style={{ display: "none" }}
									onChange={(e: any) => {
										setFiles({
											...files,
											cover_image: e.target.files[0],
										});
									}}
								/>
								<label htmlFor="cover_image">
									<Button
										variant="contained"
										component="span"
										disableElevation
									>
										Escolher arquivo
									</Button>
								</label>
								{formValues &&
								formValues.logo_image &&
								typeof formValues.cover_image === "string" ? (
									<img
										src={formValues.cover_image} // Assuming logo_image is the S3 URL
										alt="Logo Preview"
										style={{
											maxWidth: "100px",
											marginTop: "10px",
										}}
									/>
								) : (
									formValues &&
									formValues.cover_image && (
										<img
											src={URL.createObjectURL(
												formValues.cover_image[0]
											)}
											alt="Cover Preview"
											style={{
												maxWidth: "100px",
												marginTop: "10px",
											}}
										/>
									)
								)}
								{(files &&
									files.cover_image &&
									files.cover_image.name) ||
									"Nenhum arquivo selecionado"}
								{/* <pre>{JSON.stringify(formValues, null, 4)}</pre> */}
								{/* {getValues("cover_image") &&
								getValues("cover_image")[0]
									? getValues("cover_image")[0].name
									: "Nenhum arquivo selecionado"} */}
							</div>
						</FormControl>
					</Grid>

					<Grid item md={12}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								mt: 2,
								mb: 2,
							}}
						>
							<Button variant="text" onClick={handleClose}>
								Cancelar
							</Button>
							<Button
								variant="contained"
								disableElevation
								type="submit"
							>
								Atualizar
							</Button>
							{/* <BotaoVoltar /><BotaoAtualizar /> */}
						</Box>
					</Grid>
				</Grid>
			</form>
		</Container>
	);
}

export default FormularioAlteracaoEmpresaView;
