import { Box, CssBaseline, useTheme } from "@mui/material";
import { Fragment } from "react";
import { Route, Routes } from "react-router";
import AppFooter from "../components/layout/AppFooter";
import AppHeaderStudent from "../components/layout/AppHeaderStudent";
import CandidaturasAluno from "../pages/Private/Candidaturas/CandidaturasAluno";
import HomePage from "../pages/Private/HomePage";
import MeuCurriculo from "../pages/Private/MeuCurriculo";
import { MeuCurriculoContextProvider } from "../pages/Private/MeuCurriculo/context";
import MinhasVagasFavoritas from "../pages/Private/MinhasVagasFavoritas";
import OrientacoesAluno from "../pages/Private/OrientacoesAluno";
import PalcoProfissional from "../pages/Private/PalcoProfissional";
import AuditionModule from "../pages/Private/PalcoProfissional/audition";
import BiometryModule from "../pages/Private/PalcoProfissional/biometry";
import NotFound from "../pages/Public/NotFound";

/**
 *
 * @returns Retorna as rotas do aluno -> Uninta Jobs + Palco Profissional
 */
export const AlunoRouter = () => {
	const theme = useTheme();
	return (
		<Fragment>
			<CssBaseline />
			<MeuCurriculoContextProvider>
				<AppHeaderStudent>
					<Box
						sx={{
							marginTop: "64px",
							bgcolor: theme.palette.background.default,
						}}
					>
						<Routes>
							<Route path="/" element={<HomePage />} />
							<Route path="/curriculum">
								<Route path="" element={<MeuCurriculo />} />
								<Route path=":id" element={<MeuCurriculo />} />
							</Route>
							<Route
								path="/minhas-vagas"
								element={<MinhasVagasFavoritas />}
							/>
							<Route
								path="/candidaturas"
								element={<CandidaturasAluno />}
							/>
							<Route
								path="/palco-profissional"
								element={<PalcoProfissional />}
							/>
							<Route
								path="/biometrics"
								element={<BiometryModule />}
							/>
							<Route
								path="/recorder/:activity_id"
								element={<AuditionModule />}
							/>
							<Route
								path="/orientacoes"
								element={<OrientacoesAluno />}
							/>
							<Route path="*" element={<NotFound />} />
						</Routes>
					</Box>
					<AppFooter />
				</AppHeaderStudent>
			</MeuCurriculoContextProvider>
		</Fragment>
	);
};
