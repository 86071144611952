import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Work } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import Notify from "../../../../utils/notification";
import FormularioCadastroEmpresa from "../../../Formularios/Cadastro/FormularioCadastroEmpresa";
import ModalHeader from "../../ModalHeader";
import "../styles.scss";

function ModalCadastroEmpresaView(props) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down(700));
	const lg = useMediaQuery(theme.breakpoints.up("sm"));
	const { auth } = useAuthContext();
	const { open, handleOpen } = useModalContext();

	/**
	 * @function userAtive
	 * Verifica se o usuário logado é um admin ou se está ativo no sistema, se sim abre o modal de FormularioCadastroEmpresa, senão emite um Notify.
	 * @param {number} id - Representa o role do usuário logado no sistema.
	 */
	function userAtive(id: number) {
		if (id === 1 || auth.role === "admin") {
			handleOpen();
		} else {
			Notify(
				"Você não esta ativo no sistema, aguarde sua ativação",
				"warning"
			);
		}
	}

	return (
		<div className="divButtonModalCadastro">
			<Button
				style={{
					float: "right",
					border: "#BD0E0E solid",
					height: "70%",
					width: "219.34px",
				}}
				className="buttonModalCadastro"
				onClick={() => {
					userAtive(props.isActive);
				}}
				data-testid="modal-cadastro-empresa"
			>
				ADICIONAR EMPRESA
				<AddIcon />
			</Button>
			<Dialog
				fullWidth
				fullScreen={fullScreen}
				maxWidth={lg === true ? "md" : false}
				open={open}
				// onClose={handleClose}
			>
				<ModalHeader
					header={"Adicionar nova Empresa"}
					icon={<Work />}
				/>
				<DialogContent>
					<DialogContentText>
						<FormularioCadastroEmpresa />
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}
export default ModalCadastroEmpresaView;
