import { AppRoot } from "./AppRoot";
import { AuthContextProvider } from "./context/Authentication/auth.context";
import { DrawerContextProvider } from "./context/Drawer/drawer.context";
import { PalcoProfissionalProvider } from "./context/PalcoProfissional";
import { PalcoProfissionalRootProvider } from "./context/PalcoProfissionalPage";
import { SessionProvider } from "./context/session/SessionProvider";
import { ThemingProvider } from "./context/Theming";

/**
 *
 * - Responsável por encapsular e exportar o App do JOBS
 *
 * - Adiciona os providers utilizados em todo o projeto em volta dos componente principal do projeto
 */
function App() {
	return (
		<ThemingProvider>
			<AuthContextProvider>
				<PalcoProfissionalProvider>
					<SessionProvider>
						<DrawerContextProvider>
							<PalcoProfissionalRootProvider>
								<AppRoot />
							</PalcoProfissionalRootProvider>
						</DrawerContextProvider>
					</SessionProvider>
				</PalcoProfissionalProvider>
			</AuthContextProvider>
		</ThemingProvider>
	);
}

export default App;
