import { Close, Delete, Place, Search } from "@mui/icons-material";
import {
	Box,
	Button,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Paper,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { useHomePage } from "../../../../context/HomePage";

export interface HomePageSearchFormProps {
	jobData: any;
	setJobData: any;
	breakpoint: any;
	fetchJobs: any;
	showList: any;
	setShowList: any;
	theme: any;
}

export function HomePageSearchForm() {
	const {
		showList,
		jobData,
		setJobData,
		breakpoint,
		fetchJobs,
		setShowList,
	} = useHomePage();
	const theme = useTheme();
	const { auth } = useAuthContext();

	return (
		<>
			<Grid container>
				<Grid item md={12}>
					<Box
						sx={{
							mt: 3,
							minHeight: "10vh",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Typography variant="h5">
							Olá{" "}
							{auth && auth.name
								? auth.name
										.toLowerCase()
										.split(" ")
										.map(
											(word) =>
												word.charAt(0).toUpperCase() +
												word.slice(1)
										)
										.join(" ")
								: ""}
							, seja bem vindo 👋
						</Typography>
						{/* <pre>{JSON.stringify(auth, null, 4)}</pre> */}
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Paper
						sx={{
							mt: 3,
							borderRadius: "12px",
							p: 3,
							mb: 5,
							position: "relative",
							minHeight: "100px",
						}}
					>
						<form
							style={{
								display: "flex",
								flexDirection: breakpoint ? "column" : "row",
								gap: "12px",
								// display: "flex",
								// gap: "12px",
								// alignItems: "center",
								// justifyContent: "flex-start",
								// position: "absolute",
								// bottom: "-22px",
								// width: "60%",
								// transform: "translateX(25%)",
							}}
							onSubmit={(e) => {
								e.preventDefault();
								fetchJobs();
								setShowList(true);
							}}
						>
							<FormControl
								sx={{
									flexGrow: 1,
									minWidth: "256px",
									// backgroundColor: "white",
								}}
							>
								<TextField
									label="Pesquisar vagas"
									onChange={(e) => {
										setJobData({
											...jobData,
											title: e.target.value,
										});
									}}
									value={jobData.title}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<IconButton>
													<Search />
												</IconButton>
											</InputAdornment>
										),
									}}
								></TextField>
							</FormControl>
							<FormControl
								fullWidth
								sx={{
									flexGrow: "1",
									minWidth: "152px",
									// backgroundColor: "white",
								}}
							>
								<TextField
									label="Estado"
									select
									value={jobData.state}
									// margin="normal"
									onChange={(event) => {
										setJobData({
											...jobData,
											state: event.target.value,
										});
									}}
								>
									<MenuItem value="">
										Selecione o estado onde há vaga
									</MenuItem>
									<MenuItem value="AC">Acre</MenuItem>
									<MenuItem value="AL">Alagoas</MenuItem>
									<MenuItem value="AP">Amapá</MenuItem>
									<MenuItem value="AM">Amazonas</MenuItem>
									<MenuItem value="BA">Bahia</MenuItem>
									<MenuItem value="Ceará">Ceará</MenuItem>
									<MenuItem value="DF">
										Distrito Federal
									</MenuItem>
									<MenuItem value="ES">
										Espírito Santo
									</MenuItem>
									<MenuItem value="GO">Goiás</MenuItem>
									<MenuItem value="MA">Maranhão</MenuItem>
									<MenuItem value="MT">Mato Grosso</MenuItem>
									<MenuItem value="MS">
										Mato Grosso do Sul
									</MenuItem>
									<MenuItem value="MG">Minas Gerais</MenuItem>
									<MenuItem value="PA">Pará</MenuItem>
									<MenuItem value="PB">Paraíba</MenuItem>
									<MenuItem value="PR">Paraná</MenuItem>
									<MenuItem value="PE">Pernambuco</MenuItem>
									<MenuItem value="PI">Piauí</MenuItem>
									<MenuItem value="RJ">
										Rio de Janeiro
									</MenuItem>
									<MenuItem value="RN">
										Rio Grande do Norte
									</MenuItem>
									<MenuItem value="RS">
										Rio Grande do Sul
									</MenuItem>
									<MenuItem value="RO">Rondônia</MenuItem>
									<MenuItem value="RR">Roraima</MenuItem>
									<MenuItem value="SC">
										Santa Catarina
									</MenuItem>
									<MenuItem value="SP">São Paulo</MenuItem>
									<MenuItem value="SE">Sergipe</MenuItem>
									<MenuItem value="TO">Tocantins</MenuItem>
									<MenuItem value="EX">Estrangeiro</MenuItem>
								</TextField>
							</FormControl>
							<FormControl
								fullWidth
								sx={{
									flexGrow: "1",
									minWidth: "196px",
									// backgroundColor: "white",
								}}
							>
								<TextField
									label="Inserir sua cidade"
									onChange={(e) => {
										setJobData({
											...jobData,
											location: e.target.value,
										});
									}}
									value={jobData.location}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<IconButton>
													<Place />
												</IconButton>
											</InputAdornment>
										),
									}}
								></TextField>
							</FormControl>
							{breakpoint ? (
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										gap: "12px",
									}}
								>
									<Button
										type={"submit"}
										startIcon={<Search />}
										onClick={() => {
											fetchJobs();
										}}
									>
										Pesquisar
									</Button>
									<Button
										variant="contained"
										disableElevation
										sx={{
											display: showList ? "" : "none",
										}}
										onClick={() => {
											setShowList(false);
										}}
										endIcon={<Delete />}
									>
										Limpar resultados
									</Button>
								</Box>
							) : (
								<>
									<IconButton
										type={"submit"}
										size="large"
										sx={{
											zoom: 1.1,
											// border: "solid thin #ddd",
											// bgcolor: "white",
										}}
										onClick={() => {
											fetchJobs();
										}}
									>
										<Search />
									</IconButton>
									<IconButton
										size="large"
										sx={{
											display: showList ? "" : "none",
											zoom: 1.1,
										}}
										onClick={() => {
											setShowList(false);
										}}
									>
										<Close />
									</IconButton>
								</>
							)}
						</form>
					</Paper>
					{/* Banner */}
					<Box
						sx={{
							// border: "solid thin purple",
							textAlign: "center",
							display: "flex",
							gap: "12px",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{/* <pre>{JSON.stringify(jobData, null, 4)}</pre> */}
						<Typography variant="h5" component="h5">
							{/* Principais vagas na sua área de formação */}
							Conexão com as principais informações e
							oportunidades
						</Typography>
						<Box
							sx={{
								width: "100px",
								height: "6px",
								bgcolor: theme.palette.primary.light,
								borderRadius: "12px",
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</>
	);
}
