import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalAlteracoesEmpresaView from "./ModalAlteracoesEmpresa.view";

function ModalAlteracoesEmpresa(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalAlteracoesEmpresaView
				isActive={props.isActive}
				companieId={props.companieId}
			/>
		</ModalContextProvider>
	);
}

export default ModalAlteracoesEmpresa;
