import {
	AssignmentInd,
	Bookmark,
	BugReport,
	Business,
	DocumentScanner,
	EmojiPeople,
	ExitToApp,
	Home,
	ManageSearch,
	OndemandVideo,
	People,
	Search,
	VideoCameraFront,
} from "@mui/icons-material";
import {
	Box,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { Fragment } from "react";

export function AppDrawer({
	open,
	drawerWidth,
	theme,
	navigate,
	handleDrawerClose,
	auth,
	styles,
	handleLogout,
	menuIcon: Icon,
	location,
}): React.ReactElement {
	return (
		<Fragment>
			<Drawer
				open={open}
				variant="persistent"
				anchor="left"
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
					},
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-start",
						gap: "12px",
						// padding: "12px 24px",
						padding: "7px 12px",
						backgroundColor: theme.palette.primary.main,
						color: "white",
					}}
				>
					<IconButton
						sx={{
							color: "white",
						}}
						onClick={() => {
							handleDrawerClose();
						}}
					>
						<Icon />
					</IconButton>
					<img
						src="./img/Logo 06.png"
						alt=""
						height="50"
						style={{ border: "none", left: "-30px" }}
					/>
					<Box></Box>
				</Box>
				<List>
					<ListItem
						sx={{
							display: "none",
						}}
					>
						<ListItemButton
							style={
								location.pathname === "/" ? styles.listItem : {}
							}
							onClick={() => {
								navigate("/");
								handleDrawerClose();
							}}
						>
							<ListItemIcon>
								<Home
									style={
										location.pathname === "/"
											? styles.ListItemIcon
											: {}
									}
								/>
							</ListItemIcon>
							<ListItemText>Início</ListItemText>
						</ListItemButton>
					</ListItem>
					{/*  */}
					{auth.role === "ALUNO" && (
						<Fragment>
							<ListItem>
								<ListItemButton
									style={
										location.pathname === "/"
											? styles.listItem
											: {}
									}
									onClick={() => {
										navigate("/");
									}}
								>
									<ListItemIcon>
										<Search
											style={
												location.pathname === "/"
													? styles.ListItemIcon
													: {}
											}
										/>
									</ListItemIcon>
									<ListItemText>Pesquisar vagas</ListItemText>
								</ListItemButton>
							</ListItem>
							<ListItem>
								<ListItemButton
									style={
										location.pathname === "/minhas-vagas"
											? styles.listItem
											: {}
									}
									onClick={() => {
										navigate("/minhas-vagas");
									}}
								>
									<ListItemIcon>
										<Bookmark
											style={
												location.pathname ===
												"/minhas-vagas"
													? styles.listItem
													: {}
											}
										/>
									</ListItemIcon>
									<ListItemText>Minhas vagas</ListItemText>
								</ListItemButton>
							</ListItem>

							<ListItem>
								<ListItemButton
									style={
										location.pathname === "/candidaturas"
											? styles.listItem
											: {}
									}
									onClick={() => {
										navigate("/candidaturas");
									}}
								>
									<ListItemIcon>
										<EmojiPeople
											style={
												location.pathname ===
												"/candidaturas"
													? styles.ListItemIcon
													: {}
											}
										/>
									</ListItemIcon>
									<ListItemText>Candidaturas</ListItemText>
								</ListItemButton>
							</ListItem>
							<ListItem>
								<ListItemButton
									style={
										location.pathname === "/curriculum" ||
										location.pathname ===
											"/curriculum/" + auth.student_id
											? styles.listItem
											: {}
									}
									onClick={() => {
										navigate(
											"/curriculum/" + auth.student_id
										);
									}}
								>
									<ListItemIcon>
										<AssignmentInd
											style={
												location.pathname ===
												"/curriculum"
													? styles.ListItemIcon
													: {}
											}
										/>
									</ListItemIcon>
									<ListItemText>Meu currículo</ListItemText>
								</ListItemButton>
							</ListItem>
							<ListItem>
								<ListItemButton
									style={
										location.pathname === "/orientacoes"
											? styles.listItem
											: {}
									}
									onClick={() => {
										navigate("/orientacoes");
									}}
								>
									<ListItemIcon>
										<OndemandVideo
											style={
												location.pathname ===
												"/orientacoes"
													? styles.ListItemIcon
													: {}
											}
										/>
									</ListItemIcon>
									<ListItemText>
										Cursos e seleções
									</ListItemText>
								</ListItemButton>
							</ListItem>
							<ListItem>
								<ListItemButton
									style={
										location.pathname ===
										"/palco-profissional"
											? styles.listItem
											: {}
									}
									onClick={() => {
										navigate("/palco-profissional");
									}}
								>
									<ListItemIcon>
										<VideoCameraFront
											style={
												location.pathname ===
												"/palco-profissional"
													? styles.ListItemIcon
													: {}
											}
										/>
									</ListItemIcon>
									<ListItemText>
										Palco Profissional
									</ListItemText>
								</ListItemButton>
							</ListItem>
						</Fragment>
					)}
					{/*  */}
					{auth.role !== "ALUNO" && (
						<Fragment>
							{auth.role === "admin" ? (
								<ListItem>
									<ListItemButton
										style={
											location.pathname === "/usuarios"
												? styles.listItem
												: {}
										}
										onClick={() => {
											navigate("/usuarios");
										}}
									>
										<ListItemIcon>
											<People
												style={
													location.pathname ===
													"/usuarios"
														? styles.ListItemIcon
														: {}
												}
											/>
										</ListItemIcon>
										<ListItemText>Usuários</ListItemText>
									</ListItemButton>
								</ListItem>
							) : (
								<></>
							)}

							<ListItem>
								<ListItemButton
									style={
										location.pathname === "/empresas"
											? styles.listItem
											: {}
									}
									onClick={() => {
										navigate("/empresas");
									}}
								>
									<ListItemIcon>
										<Business
											style={
												location.pathname ===
												"/empresas"
													? styles.ListItemIcon
													: {}
											}
										/>
									</ListItemIcon>
									<ListItemText>Empresas</ListItemText>
								</ListItemButton>
							</ListItem>
							<ListItem>
								<ListItemButton
									style={
										location.pathname === "/vagas"
											? styles.listItem
											: {}
									}
									onClick={() => {
										navigate("/vagas");
									}}
								>
									<ListItemIcon>
										<ManageSearch
											style={
												location.pathname === "/vagas"
													? styles.ListItemIcon
													: {}
											}
										/>
									</ListItemIcon>
									<ListItemText>Vagas</ListItemText>
								</ListItemButton>
							</ListItem>
							{auth.role === "representante_empresa" ? (
								<></>
							) : (
								<ListItem>
									<ListItemButton
										style={
											location.pathname === "/orientacoes"
												? styles.listItem
												: {}
										}
										onClick={() => {
											navigate("/orientacoes");
										}}
									>
										<ListItemIcon>
											<OndemandVideo
												style={
													location.pathname ===
													"/orientacoes"
														? styles.ListItemIcon
														: {}
												}
											/>
										</ListItemIcon>
										<ListItemText>
											Cursos e seleções
										</ListItemText>
									</ListItemButton>
								</ListItem>
							)}
							{auth.role === "admin" ? (
								<ListItem>
									<ListItemButton
										style={
											location.pathname ===
											"/tipos-contrato"
												? styles.listItem
												: {}
										}
										onClick={() => {
											navigate("/tipos-contrato");
										}}
									>
										<ListItemIcon>
											<DocumentScanner
												style={
													location.pathname ===
													"/tipos-contrato"
														? styles.ListItemIcon
														: {}
												}
											/>
										</ListItemIcon>
										<ListItemText>Contratos</ListItemText>
									</ListItemButton>
								</ListItem>
							) : (
								<></>
							)}
						</Fragment>
					)}
					<ListItem>
						<ListItemButton
							onClick={() => {
								window.open(
									"https://uninta.edu.br/site/suporte-a-sistemas/"
								);
							}}
						>
							<ListItemIcon>
								<BugReport />
							</ListItemIcon>
							<ListItemText>Reportar problema</ListItemText>
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemButton onClick={handleLogout}>
							<ListItemIcon>
								<ExitToApp />
							</ListItemIcon>
							<ListItemText>Sair</ListItemText>
						</ListItemButton>
					</ListItem>
				</List>
			</Drawer>
		</Fragment>
	);
}
