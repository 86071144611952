/**
 * ## PerfilForm: Componente ReactJS funcional para exibir o formulário de edição de perfil.
 *
 * Este componente recebe uma prop para fechar o editor no modal e uma prop para realizar o reload do perfil.
 *
 * ## Exemplo de uso:
 *
 * ```javascript
 * const App = () => {
 *   return <PerfilForm ...{props} />;
 * };
 * ```
 *
 * ## Props:
 *
 * * `handleCloseProfileEditor`: Um método para fechar o modal de edição de perfil.
 * * `setReload`: Um método para realizar o reload do perfil do usuário.
 *
 * ## Funções:
 *
 * * `handleFileSelect(e, onChange)`: Define o estado que irá armazenar o arquivo de imagem.
 * * `onSubmitEvents(data)` : Faz o envio dos dados do formulário do componente
 */

// Importações
import { CameraAlt } from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	FormControl,
	Input,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuthContext } from "../../../../../context/Authentication/hooks/useAuth";
import { usePalcoProfissional } from "../../../../../context/PalcoProfissional";
import Notification from "../../../../../utils/notification";
import { useMeuCurriculo } from "../../context";

// Código do componente

function PerfilForm({ handleCloseProfileEditor, setReload }: any) {
	const { auth } = useAuthContext();
	// react-hook-form
	const { control, handleSubmit, setValue } = useForm();
	//	contexto do palco profissional
	const { state } = usePalcoProfissional();
	// estado interno do component
	const [perfil, setProfile] = useState<any>(null);
	// variável de estado para armazenar arquivo selecionado
	const [selectedFile, setSelectedFile] = useState<any>(null);
	const { setSignal } = useMeuCurriculo();

	/**
	 *
	 * @param e
	 * @param onChange
	 * @description	define o método empregado para selecionar o arquivo a ser anexado no formulário
	 */
	const handleFileSelect = (e, onChange) => {
		const selectedFile = e.target.files[0];
		onChange(selectedFile);
	};

	/**
	 *
	 * @param data
	 * @description define o método empregado para submeter o formulário e dar feedback ao usuário
	 */
	const onSubmitEvents = async (data: any) => {
		console.clear();
		console.log(data);

		const token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		try {
			const formData = new FormData();
			// Anexa campos do formulário que não sejam a foto de perfil
			for (const key in data) {
				if (key !== "profile_picture") {
					formData.append(key, data[key]);
				}
			}

			// Anexa campo de profile_picture somente se esta tiver sido definida previamente
			if (selectedFile) {
				formData.append("profile_picture", selectedFile);
			}

			const url =
				perfil || data.id
					? `${process.env.REACT_APP_URL}/students/${data.id}`
					: `${process.env.REACT_APP_URL}/students`;
			const method = perfil ? "put" : "post";

			await axios[method](url, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "multipart/form-data",
				},
			}).then(() => {
				window.location.reload();
			});

			//	Resposta da chamada no Axios
			handleCloseProfileEditor();

			Notification(
				`Sucesso ao ${perfil ? "atualizar" : "cadastrar"} perfil!`
			);
		} catch (error) {
			Notification(
				`Falha ao ${perfil ? "atualizar" : "cadastrar"} perfil!`,
				"error"
			);
		} finally {
			setReload(true);
		}
		setSignal(true);
	};

	/**
	 * ao montar o componente busca os dados existentes do perfil e popula o estado.
	 */
	useEffect(() => {
		const token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		// const apiUrl = `${process.env.REACT_APP_URL}/profile`;
		const studentUrl = `${process.env.REACT_APP_URL}/students`;

		axios
			.get(`${studentUrl}/${auth.student_id}`, {
				headers: {
					Authorization: "Bearer " + token,
				},
			})
			.then((res) => {
				// let email = res.data.student.email;
				delete res.data.student.jobs;
				delete res.data.student.professionalExperiences;
				delete res.data.student.events;
				setProfile({
					...res.data.student,
					curso: res.data.student.course.name,
					email: res.data.user.email.toLowerCase(),
				});
			});

		// axios
		// 	.get(`${apiUrl}`, {
		// 		headers: {
		// 			Authorization: "Bearer " + token,
		// 		},
		// 	})
		// 	.then(async (res: any) => {
		// 		let email = res.data[0].email;
		// 		await axios
		// 			.get(`${studentUrl}/${res.data[0].student.id}`, {
		// 				headers: {
		// 					Authorization: "Bearer " + token,
		// 				},
		// 			})
		// 			.then((res) => {
		// 				delete res.data.jobs;
		// 				setProfile({ ...res.data, email });
		// 			});
		// 	});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (perfil) {
			Object.keys(perfil).forEach((key) => {
				if (key === "birthday") {
					if (perfil["birthday"] !== null) {
						setValue(key, perfil[key].split("T")[0]);
					} else {
						setValue(key, new Date());
					}
				} else {
					setValue(key, perfil[key]);
				}
				// setValue(key, perfil[key]);
			});
		}
	}, [perfil, setValue]);

	return (
		<Fragment>
			<Paper sx={{ p: 2 }}>
				<Box mb={1} p={2}>
					<Typography variant="h5">Editar perfil</Typography>
					<Typography variant="body1">
						Atualize as informações do seu perfil e demais detalhes
						aqui.
					</Typography>
				</Box>
				<Box p={2}>
					<form onSubmit={handleSubmit(onSubmitEvents)}>
						<Box
							style={{
								display: "flex",
								gap: "12px",
								flexWrap: "wrap",
								flexGrow: "1",
							}}
						>
							<Box
								sx={{
									display: "flex",
									width: "100%",
									gap: "12px",
								}}
							>
								<Controller
									name="ra"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											label="Ra"
											{...field}
											sx={{ flexGrow: "1/2" }}
										/>
									)}
								/>

								<Controller
									name="curso"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											label="Curso"
											disabled
											{...field}
											sx={{ flexGrow: "1" }}
										/>
									)}
								/>
							</Box>

							<Box
								sx={{
									width: "100%",
									display: "flex",
									alignItems: "center",
									gap: "12px",
								}}
							>
								<Controller
									name="birthday"
									control={control}
									defaultValue={""}
									render={({ field }) => (
										<TextField
											InputLabelProps={{
												shrink: !!field.value,
											}}
											sx={{
												flexGrow: "1",
												width: "33%",
											}}
											label="Data de aniversário"
											type="date"
											{...field}
											required
										/>
									)}
								/>

								<Controller
									name="email"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											disabled
											label="Email"
											{...field}
											sx={{
												flexGrow: "1",
												width: "33%",
											}}
										/>
									)}
								/>

								<Controller
									name="link_lattes"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											label="Link do Lattes"
											{...field}
											sx={{
												flexGrow: "1",
												width: "33%",
											}}
										/>
									)}
								/>
							</Box>

							<Controller
								name="id_user"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<TextField
										InputLabelProps={{
											shrink:
												!!field.value || !!field.ref,
										}}
										sx={{
											display: "none",
										}}
										label="Cód. do Usuário"
										{...field}
									/>
								)}
							/>
							<Controller
								name="id_course"
								control={control}
								defaultValue={
									state.cursos[0].codigo_curso ?? ""
								}
								render={({ field }) => (
									<TextField
										InputLabelProps={{
											shrink:
												!!field.value || !!field.ref,
										}}
										disabled
										sx={{
											display: "none",
										}}
										label="Cód. do Curso"
										{...field}
									/>
								)}
							/>
							<Box
								sx={{
									width: "100%",
									display: "flex",
									alignItems: "center",
									gap: "12px",
								}}
							>
								<Controller
									name="phone"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											label="Celular"
											{...field}
											sx={{
												flexGrow: "1",
												width: "33%",
											}}
											required
										/>
									)}
								/>
								<Controller
									name="cep"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											label="CEP"
											{...field}
											sx={{
												flexGrow: "1",
												width: "33%",
											}}
										/>
									)}
								/>
								<Controller
									name="state"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											label="Estado"
											{...field}
											sx={{
												flexGrow: "1",
												width: "33%",
											}}
										/>
									)}
								/>
							</Box>
							<Box
								sx={{
									width: "100%",
									display: "flex",
									alignItems: "center",
									gap: "12px",
								}}
							>
								<Controller
									name="city"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											label="Cidade"
											{...field}
											sx={{
												flexGrow: "1",
												width: "33%",
											}}
										/>
									)}
								/>
								<Controller
									name="address"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											label="Endereço"
											{...field}
											sx={{
												flexGrow: "1",
												width: "33%",
											}}
										/>
									)}
								/>
								<Controller
									name="number"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											label="Número"
											{...field}
											sx={{
												flexGrow: "1",
												width: "33%",
											}}
										/>
									)}
								/>
							</Box>

							<Controller
								name="profile"
								control={control}
								defaultValue={state.usuario.name ?? ""}
								render={({ field }) => (
									<FormControl fullWidth>
										<TextField
											multiline
											rows={4}
											label="Perfil"
											{...field}
											inputProps={{ maxLength: 280 }}
											InputLabelProps={{
												shrink:
													!!field.value ||
													!!field.ref,
											}}
											helperText={`${
												field.value &&
												field.value.length
											}/280`}
										/>
									</FormControl>
								)}
							/>

							{/* <Controller
								name="profile_picture"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<div>
										<InputLabel htmlFor="profile_picture">
											Foto de perfil
										</InputLabel>
										<Input
											id="profile_picture"
											type="file"
											onChange={(e: any) => {
												const selectedFile =
													e.target.files[0];
												field.onChange(selectedFile);
											}}
										/>
									</div>
								)}
							/> */}

							<Controller
								name="profile_picture"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<div
										style={{
											width: "100%",
											// backgroundColor: "#f8f8f8",
											// borderRadius: "12px",
											padding: "12px",
											position: "relative",
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "12px",
										}}
									>
										<Input
											id="profile_picture"
											type="file"
											sx={{
												display: "none",
											}}
											onChange={(e: any) => {
												const selectedFile =
													e.target.files[0];
												field.onChange(selectedFile);
												handleFileSelect(
													e,
													setSelectedFile
												);
											}}
										/>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												gap: "12px",
											}}
										>
											<Typography
												variant="caption"
												sx={{ color: "gray" }}
											>
												{selectedFile
													? selectedFile.name
													: "Foto para o seu perfil"}
											</Typography>
											<label htmlFor="profile_picture">
												{selectedFile ? (
													<Avatar
														src={URL.createObjectURL(
															selectedFile
														)}
														alt={selectedFile.name}
														sx={{
															width: "96px",
															height: "96px",
															cursor: "pointer",
														}}
													/>
												) : (
													<Avatar
														src={
															perfil &&
															perfil.profile_picture
																? perfil.profile_picture
																: null
														}
														sx={{
															width: "96px",
															height: "96px",
															cursor: "pointer",
														}}
													>
														<CameraAlt
															sx={{
																width: "64px",
																height: "64px",
															}}
														/>
													</Avatar>
												)}
											</label>
										</div>
									</div>
								)}
							/>
						</Box>

						<Box
							mt={2}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								gap: "12px",
							}}
						>
							<Button
								variant="text"
								// onClick={() => setOpenProfileEditor(false)}
								onClick={() => handleCloseProfileEditor()}
							>
								Cancelar
							</Button>
							<Button
								size="large"
								type="submit"
								variant="contained"
								color="primary"
								disableElevation
							>
								{perfil ? "Atualizar" : "Salvar"}
							</Button>
						</Box>
					</form>
				</Box>
			</Paper>
		</Fragment>
	);
}

export default PerfilForm;
