import { makeStyles } from "@material-ui/core";
import {
	Box,
	Button,
	Container,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuthContext } from "../../../../context/Authentication/hooks/useAuth";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { AreaConhecimento } from "../../../../models/AreaConhecimento";
import { TipoContrato } from "../../../../models/TipoContrato";
import { AtualizarVaga } from "../../../../models/Vaga";
import { getAreasConhecimento } from "../../../../services/crud/crud.areaConhecimento";
import { getTiposContratos } from "../../../../services/crud/crud.tiposContrato";
import {
	getVaga,
	getVagaRepresentante,
	putVaga,
} from "../../../../services/crud/crud.vaga";
import Notify from "../../../../utils/notification";
import { useReloadContext } from "../../../Tabelas/hooks/useReload";

const useStyles = makeStyles((theme: any) => ({
	select: {
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "grey ",
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "grey !important",
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "black !important",
		},
	},
	inputLabel: {
		// color: "red",
		"&.Mui-focused": {
			color: "black !important",
		},
	},
	textField: {
		"& label": {
			color: "grey !important",
		},
		"& label.Mui-focused": {
			color: "black !important",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "Grey",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "Grey",
			},
			// '&:hover fieldset': {
			//   borderColor: 'blue',
			// },
			"&.Mui-focused fieldset": {
				borderColor: "Grey",
			},
		},
	},
}));

function FormularioAlteracaoVagaView({ vaga }: any): React.ReactElement {
	const classes = useStyles();
	let idVagaAlterar = Number(localStorage.getItem("id_vaga"));

	const [areas, setAreas] = useState<Array<AreaConhecimento>>([]);
	const [tiposContratos, setTiposContratos] = useState<Array<TipoContrato>>(
		[]
	);
	const [formValues, setFormValues] = useState({} as any);
	const {
		handleSubmit,
		getValues,
		control,
		reset,
		setValue,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	const { handleClose } = useModalContext();
	const { handleReload } = useReloadContext();
	const { auth } = useAuthContext();

	async function atualizacaoVaga(data: AtualizarVaga, event) {
		let arrayDados = Object.entries(data);
		let arrayFormValues = Object.entries(formValues);

		if (arrayFormValues.length === 0) {
			console.error("arrayFormValues is empty or undefined");
			return; // Prevent further execution
		}

		let dataVaga = arrayDados.filter((value, index) => {
			if (arrayFormValues[index] === undefined) {
				console.error(
					"Index",
					index,
					"is undefined in arrayFormValues"
				);
				return false; // Exclude this entry
			}
			return value[1] !== arrayFormValues[index][1];
		});

		if (dataVaga.length > 0) {
			let newDataVaga = Object.fromEntries(dataVaga);
			putVaga(idVagaAlterar, newDataVaga)
				.then(() => {
					handleClose();
					handleReload();
					localStorage.setItem(
						"id_empresa_filter",
						formValues.companies_id.toString()
					);
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			event.preventDefault();
			Notify(
				"Atualize a informação para efetuar a operação de atualização!",
				"warning"
			);
		}
	}

	useEffect(() => {
		if (auth.role === "admin") {
			getVaga(idVagaAlterar)
				.then((res) => {
					let data = res[0];
					setFormValues({
						status: data.status,
						title: data.title,
						knowledge_areas_id: data.knowledge_areas_id,
						contract_types_id: data.contract_types_id,
						state: data.state,
						city: data.city,
						remuneration_initial: data.remuneration_initial,
						remuneration_final: data.remuneration_final,
						department: data.department,
						experience_level: data.experience_level,
						description: data.description,
						assignments: data.assignments,
						requirements: data.requirements,
						additional_info: data.additional_info,
						companies_id: data.companies_id,
					});
					reset({
						status: data.status,
						title: data.title,
						knowledge_areas_id: data.knowledge_areas_id,
						contract_types_id: data.contract_types_id,
						state: data.state,
						city: data.city,
						remuneration_initial: data.remuneration_initial,
						remuneration_final: data.remuneration_final,
						department: data.department,
						experience_level: data.experience_level,
						description: data.description,
						assignments: data.assignments,
						requirements: data.requirements,
						additional_info: data.additional_info,
						companies_id: data.companies_id,
					});
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			getVagaRepresentante(idVagaAlterar)
				.then((data) => {
					// let data = res[0];
					setFormValues({
						status: data.status,
						title: data.title,
						knowledge_areas_id: data.knowledge_areas_id,
						contract_types_id: data.contract_types_id,
						state: data.state,
						city: data.city,
						remuneration_initial: data.remuneration_initial,
						remuneration_final: data.remuneration_final,
						department: data.department,
						experience_level: data.experience_level,
						description: data.description,
						assignments: data.assignments,
						requirements: data.requirements,
						additional_info: data.additional_info,
						companies_id: data.companies_id,
					});
					reset({
						status: data.status,
						title: data.title,
						knowledge_areas_id: data.knowledge_areas_id,
						contract_types_id: data.contract_types_id,
						state: data.state,
						city: data.city,
						remuneration_initial: data.remuneration_initial,
						remuneration_final: data.remuneration_final,
						department: data.department,
						experience_level: data.experience_level,
						description: data.description,
						assignments: data.assignments,
						requirements: data.requirements,
						additional_info: data.additional_info,
						companies_id: data.companies_id,
					});
				})
				.catch((e) => {
					console.log(e);
				});
		}

		getAreasConhecimento()
			.then((data) => {
				setAreas(data.data);
			})
			.catch((e) => {
				console.log(e);
			});

		getTiposContratos(null)
			.then((data) => {
				setTiposContratos(data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [idVagaAlterar, auth.role, reset, getValues]);

	// Populate form fields with data from the 'vagas' prop
	React.useEffect(() => {
		if (vaga && vaga.vaga) {
			Object.keys(vaga.vaga).forEach((key) => {
				// Set formValues object

				if (key === "started" || key === "finished") {
					setValue(key, vaga.vaga[key].split("T")[0]);
				} else {
					setValue(key, vaga.vaga[key]);
				}
			});
			setFormValues({
				status: vaga.vaga.status,
				title: vaga.vaga.title,
				knowledge_areas_id: vaga.vaga.knowledge_areas_id,
				contract_types_id: vaga.vaga.contract_types_id,
				state: vaga.vaga.state,
				city: vaga.vaga.city,
				remuneration_initial: vaga.vaga.remuneration_initial,
				remuneration_final: vaga.vaga.remuneration_final,
				department: vaga.vaga.department,
				experience_level: vaga.vaga.experience_level,
				description: vaga.vaga.description,
				assignments: vaga.vaga.assignments,
				requirements: vaga.vaga.requirements,
				additional_info: vaga.vaga.additional_info,
				companies_id: vaga.vaga.companies_id,
			});
		}
	}, [vaga, setValue]);

	return (
		<Container component="section" data-testid="formulario-alteracao-vaga">
			<Typography variant="h5" component="h5" mt={3} mb={3}>
				Editar Vaga
			</Typography>
			{/* <pre>{JSON.stringify(formValues, null, 4)}</pre> */}

			<form
				onSubmit={handleSubmit((data: AtualizarVaga, event) =>
					atualizacaoVaga(data, event)
				)}
				style={{ width: "100%", height: "100%" }}
			>
				<div>
					<Controller
						name="status"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								label="Situação da vaga"
								select
								fullWidth
								margin="normal"
								value={getValues("status") ? value : ""}
								onChange={(e) => {
									onChange(e);
								}}
								error={errors.status?.type === "required"}
								helperText={
									errors.status?.type === "required" && (
										<span>
											O campo "Situação da vaga" é
											obrigatório!
										</span>
									)
								}
							>
								<MenuItem value="">
									Selecione a situação da vaga
								</MenuItem>
								<MenuItem value="1">Aberta</MenuItem>
								<MenuItem value="2">Fechada</MenuItem>
							</TextField>
						)}
					/>
				</div>

				<div>
					<Controller
						name="title"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="Vaga"
								type="text"
								fullWidth
								margin="normal"
								onChange={(e) => {
									onChange(e);
								}}
								value={getValues("title") ? value : ""}
								error={errors.title?.type === "required"}
								helperText={
									errors.title?.type === "required" && (
										<span>
											O campo "Vaga" é obrigatório!
										</span>
									)
								}
							/>
						)}
					/>
				</div>

				{/* <div>
          <FormControl fullWidth margin="normal">
              <InputLabel className={classes.inputLabel} id="empresa">Empresa</InputLabel>
              <Select
              className={classes.select}
                  {...register("companies_id", { required: isEmpty(formValues.vaga?.companies_id) })}
                  value={formValues.vaga?.companies_id ? formValues.vaga.companies_id : ""}
                  label="Empresa*"
                  onChange={handleInputChange}
              >
                  <MenuItem value="">Selecione uma empresa</MenuItem>
                  {
                      empresas.map((empresa, index) => (
                          <MenuItem value={empresa.id} key={index}>{empresa.name}</MenuItem>
                      ))
                  }

              </Select>
          </FormControl>
          {errors.companies_id?.type === "required" && (<span>O campo "Empresa" é obrigatório!</span>)}
        </div> */}

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<div style={{ width: "49%" }}>
						<Controller
							name="knowledge_areas_id"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									className={classes.textField}
									select
									fullWidth
									margin="normal"
									value={
										getValues("knowledge_areas_id")
											? value
											: ""
									}
									label="Área de Conhecimento"
									onChange={(e) => {
										onChange(e);
									}}
									error={
										errors.knowledge_areas_id?.type ===
										"required"
									}
									helperText={
										errors.knowledge_areas_id?.type ===
											"required" && (
											<span>
												O campo "Área de Conhecimento" é
												obrigatório!
											</span>
										)
									}
								>
									<MenuItem value="">
										Selecione a área de conhecimento da vaga
									</MenuItem>
									{areas.map((area, index) => (
										<MenuItem value={area.id} key={index}>
											{area.name}
										</MenuItem>
									))}
								</TextField>
							)}
						/>
					</div>

					<div style={{ width: "49%" }}>
						<Controller
							name="contract_types_id"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									className={classes.textField}
									select
									fullWidth
									margin="normal"
									value={
										getValues("contract_types_id")
											? value
											: ""
									}
									label="Tipo de Contrato"
									onChange={(e) => {
										onChange(e);
									}}
									error={
										errors.contract_types_id?.type ===
										"required"
									}
									helperText={
										errors.contract_types_id?.type ===
											"required" && (
											<span>
												O campo "Tipo de Contrato" é
												obrigatório!
											</span>
										)
									}
								>
									<MenuItem value="">
										Selecione o tipo de contrato
									</MenuItem>
									{tiposContratos.map(
										(contractType, index) => (
											<MenuItem
												value={contractType.id}
												key={index}
											>
												{contractType.name}
											</MenuItem>
										)
									)}
								</TextField>
							)}
						/>
					</div>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<div style={{ width: "49%" }}>
						<Controller
							name="state"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									className={classes.textField}
									select
									fullWidth
									margin="normal"
									value={getValues("state") ? value : ""}
									label="Estado"
									onChange={(e) => {
										onChange(e);
									}}
									error={errors.state?.type === "required"}
									helperText={
										errors.state?.type === "required" && (
											<span>
												O campo "Estado" é obrigatório!
											</span>
										)
									}
								>
									<MenuItem value="">
										Selecione o estado onde há vaga
									</MenuItem>
									<MenuItem value="AC">Acre</MenuItem>
									<MenuItem value="AL">Alagoas</MenuItem>
									<MenuItem value="AP">Amapá</MenuItem>
									<MenuItem value="AM">Amazonas</MenuItem>
									<MenuItem value="BA">Bahia</MenuItem>
									<MenuItem value="Ceará">Ceará</MenuItem>
									<MenuItem value="DF">
										Distrito Federal
									</MenuItem>
									<MenuItem value="ES">
										Espírito Santo
									</MenuItem>
									<MenuItem value="GO">Goiás</MenuItem>
									<MenuItem value="MA">Maranhão</MenuItem>
									<MenuItem value="MT">Mato Grosso</MenuItem>
									<MenuItem value="MS">
										Mato Grosso do Sul
									</MenuItem>
									<MenuItem value="MG">Minas Gerais</MenuItem>
									<MenuItem value="PA">Pará</MenuItem>
									<MenuItem value="PB">Paraíba</MenuItem>
									<MenuItem value="PR">Paraná</MenuItem>
									<MenuItem value="PE">Pernambuco</MenuItem>
									<MenuItem value="PI">Piauí</MenuItem>
									<MenuItem value="RJ">
										Rio de Janeiro
									</MenuItem>
									<MenuItem value="RN">
										Rio Grande do Norte
									</MenuItem>
									<MenuItem value="RS">
										Rio Grande do Sul
									</MenuItem>
									<MenuItem value="RO">Rondônia</MenuItem>
									<MenuItem value="RR">Roraima</MenuItem>
									<MenuItem value="SC">
										Santa Catarina
									</MenuItem>
									<MenuItem value="SP">São Paulo</MenuItem>
									<MenuItem value="SE">Sergipe</MenuItem>
									<MenuItem value="TO">Tocantins</MenuItem>
									<MenuItem value="EX">Estrangeiro</MenuItem>
								</TextField>
							)}
						/>
					</div>

					<div style={{ width: "49%" }}>
						<Controller
							name="city"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									className={classes.textField}
									label="Cidade"
									type="text"
									fullWidth
									margin="normal"
									onChange={(e) => {
										onChange(e);
									}}
									value={getValues("city") ? value : ""}
									error={errors.city?.type === "required"}
									helperText={
										errors.city?.type === "required" && (
											<span>
												O campo "Cidade" é obrigatório!
											</span>
										)
									}
								/>
							)}
						/>
					</div>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<div style={{ width: "49%" }}>
						<Controller
							name="remuneration_initial"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									className={classes.textField}
									label="Remuneração Inicial"
									type="text"
									fullWidth
									margin="normal"
									onChange={(e) => {
										onChange(e);
									}}
									value={
										getValues("remuneration_initial")
											? value
											: ""
									}
									error={
										errors.remuneration_initial?.type ===
										"required"
									}
									helperText={
										errors.remuneration_initial?.type ===
											"required" && (
											<span>
												O campo "Remuneração Inicial" é
												obrigatório!
											</span>
										)
									}
								/>
							)}
						/>
					</div>

					<div style={{ width: "49%" }}>
						<Controller
							name="remuneration_final"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									className={classes.textField}
									label="Remuneração Final"
									type="text"
									fullWidth
									margin="normal"
									onChange={(e) => {
										onChange(e);
									}}
									value={
										getValues("remuneration_final")
											? value
											: ""
									}
									error={
										errors.remuneration_final?.type ===
										"required"
									}
									helperText={
										errors.remuneration_final?.type ===
											"required" && (
											<span>
												O campo "Remuneração Final" é
												obrigatório!
											</span>
										)
									}
								/>
							)}
						/>
					</div>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<div style={{ width: "49%" }}>
						<Controller
							name="department"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									className={classes.textField}
									label="Setor de atuação"
									type="text"
									fullWidth
									margin="normal"
									onChange={(e) => {
										onChange(e);
									}}
									value={getValues("department") ? value : ""}
									error={
										errors.department?.type === "required"
									}
									helperText={
										errors.department?.type ===
											"required" && (
											<span>
												O campo "Setor de atuação" é
												obrigatório!
											</span>
										)
									}
								/>
							)}
						/>
					</div>

					<div style={{ width: "49%" }}>
						<Controller
							name="experience_level"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									className={classes.textField}
									label="Nível de experiência / Formação"
									type="text"
									fullWidth
									margin="normal"
									onChange={(e) => {
										onChange(e);
									}}
									value={
										getValues("experience_level")
											? value
											: ""
									}
									error={
										errors.experience_level?.type ===
										"required"
									}
									helperText={
										errors.experience_level?.type ===
											"required" && (
											<span>
												O campo "Nível de experiência /
												Formação" é obrigatório!
											</span>
										)
									}
								/>
							)}
						/>
					</div>
				</div>

				<div>
					<Controller
						name="description"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="Descrição da vaga"
								type="text"
								fullWidth
								multiline={true}
								rows={4}
								margin="normal"
								onChange={(e) => {
									onChange(e);
								}}
								value={getValues("description") ? value : ""}
								error={errors.description?.type === "required"}
								helperText={
									errors.description?.type === "required" && (
										<span>
											O campo "Descrição" é obrigatório!
										</span>
									)
								}
							/>
						)}
					/>
				</div>

				<div>
					<Controller
						name="assignments"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="Tarefas"
								type="text"
								fullWidth
								multiline={true}
								rows={4}
								margin="normal"
								onChange={(e) => {
									onChange(e);
								}}
								value={getValues("assignments") ? value : ""}
								error={errors.assignments?.type === "required"}
								helperText={
									errors.assignments?.type === "required" && (
										<span>
											O campo "Tarefas" é obrigatório!
										</span>
									)
								}
							/>
						)}
					/>
				</div>

				<div>
					<Controller
						name="requirements"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="Requisitos"
								type="text"
								fullWidth
								multiline={true}
								rows={4}
								margin="normal"
								onChange={(e) => {
									onChange(e);
								}}
								value={getValues("requirements") ? value : ""}
								error={errors.requirements?.type === "required"}
								helperText={
									errors.requirements?.type ===
										"required" && (
										<span>
											O campo "Requerimentos" é
											obrigatório!
										</span>
									)
								}
							/>
						)}
					/>
				</div>

				<div>
					<Controller
						name="additional_info"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="Informações adicionais"
								type="text"
								fullWidth
								multiline={true}
								rows={4}
								margin="normal"
								onChange={(e) => {
									onChange(e);
								}}
								value={
									getValues("additional_info") ? value : ""
								}
								error={
									errors.additional_info?.type === "required"
								}
								helperText={
									errors.additional_info?.type ===
										"required" && (
										<span>
											O campo "Informações adicionais" é
											obrigatório!
										</span>
									)
								}
							/>
						)}
					/>
				</div>

				<Box
					sx={{
						display: "flex",
						mt: 1,
						justifyContent: "flex-end",
						gap: "8px",
					}}
				>
					<Button variant="text" onClick={handleClose}>
						Cancelar
					</Button>
					<Button type="submit" variant="contained" disableElevation>
						Atualizar
					</Button>
					{/* <BotaoVoltar /> <BotaoAtualizar /> */}
				</Box>
			</form>
		</Container>
	);
}

export default FormularioAlteracaoVagaView;
