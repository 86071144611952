import { TableCell, TableRow, Typography } from "@mui/material";

export function ActivityEmptyTableRow() {
	return (
		<>
			<TableRow sx={{ textAlign: "center" }}>
				<TableCell colSpan={8}>
					<Typography
						variant="body1"
						sx={{
							textAlign: "center",
						}}
					>
						Nenhum conteúdo disponível
					</Typography>
				</TableCell>
			</TableRow>
		</>
	);
}
