import { CssBaseline } from "@mui/material";
import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import FormularioForgotPassword from "../components/Formularios/FormularioForgotPassword";
import FormularioLoginAluno from "../components/Formularios/FormularioLoginAluno";
import FormularioRecoverPassword from "../components/Formularios/FormularioRecoverPassword";
import Login from "../pages/Public/Login";
import NotFound from "../pages/Public/NotFound";
import SignInAdministrador from "../pages/Public/SignIn/SignInAdministrador";
import SignInAluno from "../pages/Public/SignIn/SignInAluno";
import SignUp from "../pages/Public/SignUp";

const PublicRouter = (email): React.ReactElement => {
	return (
		<Fragment>
			<CssBaseline />
			<Routes>
				<Route path="/" element={<SignInAluno />} />
				<Route path="/login-admin" element={<SignInAdministrador />} />
				<Route path="/signin-admin" element={<Login />} />
				<Route path="/signin" element={<FormularioLoginAluno />} />
				<Route path="/signup" element={<SignUp />} />
				<Route
					path="/access-recovery/solicitation"
					element={<FormularioForgotPassword />}
				/>
				<Route
					path={`/api/oportunidades/reset-password/:email`}
					element={<FormularioRecoverPassword />}
				/>
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Fragment>
	);
};

export default PublicRouter;
