/**
 * @function isEmpty
 * Verifica se os valores de alguns imputs são vazios ou falsos.
 * @param {string | boolean | number | undefined} valor - Representa o valor do imput.
 * @returns {boolean} - Retorna true ou false de acordo com a verificação.
 */
export function isEmpty(valor: string | boolean | number | undefined): boolean {
    if (valor === "" || valor === false) {
        return true;
    } else {
        return false;
    }
}

/**
 * @function estaAtivo
 * Verifica se o usuário está ativo no sistema.
 * @param {boolean | undefined} active -Representa o status do usuário no sistema.
 * @returns {string} - Retorna uma string de sim ou não de acordo com a verificação.
 */
export function estaAtivo(active: boolean | undefined): string {
    if (active === true) {
        return "Sim";
    } else {
        return "Não";
    }
}

/**
 * @function verificarRole
 * Verifica o role do usuário, se é um admin ou um representante.
 * @param {number | undefined} role - Representa o nível do usuário.
 * @returns {string} - Retorna uma string que representa o nível do usuário de acordo com a verificação.
 */
export function verificarRole(role: number | undefined): string {
    if (role === 1) {
        return "ADM";
    } else if(role === 2) {
        return "Representante";
    } else {
		return "Estudante";
	}
}

// /**
//  * @function calcularQtdPaginas
//  * Faz o cálculo da quantidades de páginas a serem mostradas com a verificação especificada no if.
//  * @param {number} totalPaginas - Representa o total de páginas.
//  * @returns {number} - Retorna o número de páginas a serem mostradas no Pagination do @mui/material.
//  */
// export function calcularQtdPaginas(totalPaginas: number): number {
//     if (totalPaginas <= 10 || (typeof(totalPaginas) === "undefined")) {
//         return 1;
//     } else {
//         return Math.ceil(totalPaginas / 10);
//     }
// }