import { useMediaQuery, useTheme } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import api from "../../services/api";
import { getTerms } from "../../services/terms";
import Notify from "../../utils/notification";
import { useAuthContext } from "../Authentication/hooks/useAuth";

//TODO Criar tipos para o contexto da página inicial
export const HomePageContext = createContext<any>({});

export const HomePageContextProvider = ({ children }) => {
	const theme = useTheme();
	const { auth } = useAuthContext();
	const breakpoint = useMediaQuery(theme.breakpoints.down("md"));

	const [open, setOpen] = useState<boolean>(false);
	const [job, setJob] = useState<any>({
		vaga: null,
		usuario: null,
		empresa: null,
	});
	const [jobs, setJobs] = useState<any>({
		meta: null,
		data: [],
	});

	const [queryJobs, setQueryJobs] = useState<any>({
		meta: null,
		data: [],
	});

	const [page, setPage] = useState<number>(1);
	const [showList, setShowList] = useState<boolean>(false);
	const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

	const [jobData, setJobData] = useState<any>({
		title: null,
		location: "",
		state: "",
	});

	const [termState, setTermState] = useState<{
		dialog: boolean;
		termText: string;
		idTerm: number | null;
	}>({ dialog: !auth.has_terms, termText: "", idTerm: null });

	const [candidaturaModalStatus, setCandidaturaModalStatus] =
		useState<boolean>(false);

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
		fetchJobs(value);
	};

	//Busca vaga por título e localização
	const fetchJobs = async (value?: any) => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		setShowBackdrop(true);
		await api
			.get("/jobs-for-student", {
				headers: { Authorization: `Bearer ${token}` },
				params: {
					title: jobData.title,
					city: jobData.location,
					state: jobData.state,
					page: value,
					limit: 10,
				},
			})
			.then((res: any) => {
				// setShowList(true);
				setQueryJobs({
					meta: res.data.meta,
					data: res.data.data,
				});
			})
			.finally(() => {
				setShowBackdrop(false);
				Notify("Consulta finalizada", "success");
			});
	};

	const jobsForStudents = async (value?: number | string) => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		await api
			.get("/jobs-for-student", {
				headers: { Authorization: `Bearer ${token}` },
				// params: {
				//   page: value,
				//   limit: 10,
				// },
			})
			.then((res: any) => {
				setJobs(res.data);
			});
	};

	const getApplications = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		await api
			.get("/applications", {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				console.log(res.data);
			});
	};

	const jobForStudentDetails = async (item?: any, id?: number | string) => {
		var applications = [];
		if (item) {
			applications = item.applications;
		}

		if (id) localStorage.setItem("id_vaga", id.toString());

		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		if (id === null || id === 0) {
			return Promise.resolve("ID is null  or 0, cancelling the call");
		} else {
			await api
				.get(`/jobs/${id}/student`, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((res) => {
					console.log(res.data[0]);
					setJob({
						vaga: res.data[0],
						usuario: res.data[0].user,
						empresa: res.data[0].company,
						applications: applications,
					});
				})
				.finally(() => {
					setOpen(true);
				});
		}
	};

	const handleCandidaturaStatus = async (item: any) => {
		if (item && item.applications && item.applications.length === 0) {
			await api
				.post("/applications", {
					job_id: item.vaga ? item.vaga.id : item.id,
					ra: JSON.parse(
						localStorage.getItem("@palco-profissional:token") || ""
					).data.usuario.id,
				})
				.then(() => {
					Notify("Candidatura submetida com sucesso!", "success");
				})
				.finally(async () => {
					await fetchJobs();
					await jobsForStudents();
					// await fetchJobs(); -> HOTFIX: Removi pois estava causando uma duplicidade nas requisições
				});
		} else if (item && item.applications && item.applications.length > 0) {
			item &&
				item.applications &&
				item.applications.forEach(async (i: any) => {
					await api
						.delete(`/applications/${i.id}`)
						.then((res: any) => {
							Notify(
								"Candidatura removida com sucesso",
								"success"
							);
						})
						.catch((err: any) => {
							Notify("Falha ao remover candidatura!", "error");
						})
						.finally(async () => {
							await fetchJobs();
							await jobsForStudents();
							// await fetchJobs();
						});
				});
		}
		await jobsForStudents()
			.then(() => {})
			.catch(() => {})
			.finally(() => {
				setOpen(false);
				setCandidaturaModalStatus(false);
			});
	};

	useEffect(() => {
		jobsForStudents();
		getApplications();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const termsData: any = await getTerms();
				const desiredObject: any = termsData?.find(
					(item) => item.type === "aluno"
				);

				setTermState((prevTermState) => ({
					...prevTermState,
					termText: desiredObject?.term_text,
				}));
				setTermState((prevTermState) => ({
					...prevTermState,
					idTerm: desiredObject?.id,
				}));
			} catch (error) {
				console.error("Error fetching terms:", error);
			}
		};
		fetchData();
	}, []);
	return (
		<HomePageContext.Provider
			value={{
				getApplications,
				jobForStudentDetails,
				handleCandidaturaStatus,
				jobsForStudents,
				fetchJobs,
				handleChange,
				breakpoint,
				open,
				setOpen,
				job,
				setJob,
				jobs,
				setJobs,
				queryJobs,
				setQueryJobs,
				page,
				setPage,
				showList,
				setShowList,
				showBackdrop,
				setShowBackdrop,
				jobData,
				setJobData,
				termState,
				setTermState,
				candidaturaModalStatus,
				setCandidaturaModalStatus,
			}}
		>
			{children}
		</HomePageContext.Provider>
	);
};

export const useHomePage = () => {
	return useContext(HomePageContext);
};
