import React from "react";
import FormularioLogin from "../../../components/Formularios/FormularioLogin";

export default function Login(): React.ReactElement {
	return (
		<>
			<FormularioLogin />
		</>
	);
}
