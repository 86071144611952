import { Box, Grid, Pagination, Typography } from "@mui/material";
import { Fragment } from "react";
import { useHomePage } from "../../../../context/HomePage";
import HomePageItemView from "./HomePageItemView";

export interface HomePageShowListViewProps {
	showList: boolean;
	queryJobs: any;
	jobData: any;
	theme: any;
	setJob: any;
	setJobs: any;
	setOpen: any;
	page: any;
	handleChange: any;
	jobsForStudents: any;
	jobForStudentDetails: any;
	setCandidaturaModalStatus: any;
}

export function HomePageShowListView() {
	const {
		jobData,
		showList,
		theme,
		queryJobs,
		page,
		handleChange,
		setJob,
		setJobs,
		setOpen,
		jobsForStudents,
		jobForStudentDetails,
		setCandidaturaModalStatus,
	} = useHomePage();

	return (
		<Fragment>
			<Grid container>
				{/* <pre>{JSON.stringify(queryJobs, null, 4)}</pre> */}
				{showList ? (
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Box mt={5} mb={3}>
							<Typography variant="body1" component="p">
								{queryJobs.data.length} Resultados encontrados{" "}
								{jobData.title ? `para ${jobData.title}` : " "}
							</Typography>
						</Box>
					</Grid>
				) : (
					<></>
				)}

				{showList ? (
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Box
							sx={{
								minHeight: "80vh",
								marginBottom: "20vh",
							}}
						>
							<Box
								sx={{
									display:
										queryJobs.data.length > 0
											? "flex"
											: "none",
									align: "center",
									justifyContent: "center",
									mb: 3,
								}}
							>
								<Pagination
									// shape="rounded"
									count={queryJobs.meta?.last_page || 1}
									color="secondary"
									page={page}
									onChange={handleChange}
								/>
							</Box>
							{/* <pre>{JSON.stringify(jobs, null, 4)}</pre> */}
							{queryJobs.data.map((j_job: any, index: number) => {
								return (
									<>
										<HomePageItemView
											variant={"list_view"}
											key={index}
											item={j_job}
											theme={theme}
											setJob={setJob}
											setJobs={setJobs}
											setOpen={setOpen}
											jobsForStudents={jobsForStudents}
											jobForStudentDetails={
												jobForStudentDetails
											}
											setCandidaturaModalStatus={
												setCandidaturaModalStatus
											}
										/>
									</>
								);
							})}
							<Box
								sx={{
									display:
										queryJobs.data.length > 0
											? "flex"
											: "none",
									align: "center",
									justifyContent: "center",
									mb: 3,
								}}
							>
								<Pagination
									// shape="rounded"
									// count={jobs.meta?.last_page || 1}
									count={queryJobs.meta?.last_page || 1}
									color="secondary"
									page={page}
									onChange={handleChange}
								/>
							</Box>
						</Box>
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</Fragment>
	);
}
