import { Help } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import { DialogActions } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { Transition } from "../../../../utils/transition";
import ListaDetalhesTipoContrato from "../../../Detalhes/DetalhesTipoContrato";
import ModalHeader from "../../ModalHeader";

function ModalDetalhesTipoContratoView(props) {
	const { open, handleOpen, handleClose } = useModalContext();

	/**
	 * @function handleDetalhesTipoContrato
	 * Abre o modal de ListaDetalhesTipoContrato e seta um valor no sessionStorage com a chave de nome id_tipocontrato.
	 * @param {number} id - Representa o id do tipo contrato.
	 */
	function handleDetalhesTipoContrato(id: number) {
		handleOpen();
		localStorage.setItem("id_tipocontrato", id.toString());
	}

	return (
		<>
			<Button
				onClick={() => {
					handleDetalhesTipoContrato(props.tipoContratoId);
				}}
				title={"Detalhes"}
				data-testid="modal-detalhes-tipo-contrato"
			>
				<DescriptionIcon style={{ color: "#292B2D" }} />
			</Button>

			<Dialog
				maxWidth="lg"
				open={open}
				TransitionComponent={Transition}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
			>
				<ModalHeader header={"Detalhes do Contrato"} icon={<Help />} />
				<DialogContent>
					<DialogContentText>
						<ListaDetalhesTipoContrato />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						disableElevation
						onClick={handleClose}
					>
						Fechar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
export default ModalDetalhesTipoContratoView;
