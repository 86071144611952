import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
	Button,
	CircularProgress,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	Pagination,
	Stack,
	TextField,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { CSSProperties, Fragment, useEffect } from "react";
// import { getAllUsuarios } from '../../services/crud/crud.usuario';
import { Box, Paper } from "@material-ui/core";
import { Delete } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import ModalHeader from "../../../components/Modal/ModalHeader";
import {
	deleteOrientations,
	getOrientations,
	postOrientations,
	putOrientations,
} from "../../../services/crud/crud.orientacoes";
import { Transition } from "../../../utils/transition";

export default function OrientacoesAdmin() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [ellipsisEnabled, setEllipsisEnabled] = React.useState({});

	const styles: { [key: string]: CSSProperties } = {
		div: {
			// border: "1px solid #ddd",
			width: "100%",
			overflowX: "auto",
		},
		table: {
			borderCollapse: "collapse",
			width: "100%",
			overflowX: "auto",
		},
		Button: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		caption: {
			width: "100%",
			padding: "10px",
			display: "flex",
			gap: isMobile ? "10px" : "0px",
			justifyContent: isMobile ? "centre" : "space-between",
			alignItems: isMobile ? "flex-start" : "center",
			flexDirection: isMobile ? "column" : "row",
		},
		pagination: {
			width: "100%",
			padding: "10px",
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center",
		},
		Checkbox: {
			backgroundColor: "#f2f2f2",
			padding: "10px",
			border: "1px solid #ddd",
			textAlign: "center",
			width: "5%",
		},
		Banner: {
			backgroundColor: "#f2f2f2",
			padding: "10px",
			border: "1px solid #ddd",
			textAlign: "center",
			width: "10%",
		},
		Titulo: {
			backgroundColor: "#f2f2f2",
			padding: "10px",
			border: "1px solid #ddd",
			textAlign: "left",
			width: "30%",
		},
		Descricao: {
			backgroundColor: "#f2f2f2",
			padding: "10px",
			border: "1px solid #ddd",
			textAlign: "left",
			width: "45%",
		},
		Edit: {
			backgroundColor: "#f2f2f2",
			padding: "10px",
			border: "1px solid #ddd",
			textAlign: "center",
			width: "10%",
		},

		//*Cell

		CellCheckbox: {
			padding: "10px",
			border: "1px solid #ddd",
			textAlign: "center",
			width: "5%",
		},
		CellBanner: {
			padding: "10px",
			border: "1px solid #ddd",
			textAlign: "center",
			width: "10%",
		},
		CellTitulo: {
			padding: "10px",
			border: "1px solid #ddd",
			textAlign: "left",
			width: "30%",
		},
		CellDescricao: {
			padding: "10px",
			border: "1px solid #ddd",
			textAlign: "left",
			width: "45%",
			maxWidth: ellipsisEnabled ? `100px` : "",
			whiteSpace: ellipsisEnabled ? "nowrap" : "normal",
			overflow: ellipsisEnabled ? "hidden" : "visible",
			textOverflow: ellipsisEnabled ? "ellipsis" : "clip",
			cursor: "pointer",
		},
		CellEdit: {
			padding: "10px",
			border: "1px solid #ddd",
			textAlign: "center",
			width: "10%",
		},
		image: {
			maxWidth: "40px",
			maxHeight: "40px",
		} as CSSProperties,
	};
	const [stateDrawer, setStateDrawer] = React.useState({
		dialog: false,
		id: undefined,
	});
	const [stateOrientations, setStateOrientations] = React.useState<any>(null);
	const [load, setLoad] = React.useState<boolean>(true);
	const [refesh, setRefesh] = React.useState<boolean>(true);
	const {
		handleSubmit,
		reset,
		getValues,
		setValue,
		setError,
		control,
		clearErrors,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	const [image, setImage] = React.useState<any>(null);
	const inputRef: any = React.useRef(null);
	const [hancleTitle, setHancleTitle] = React.useState("");
	let timeoutId;

	const handleDrop = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		handleImage(file);
	};

	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		handleImage(file);
	};

	const handleImage = (file) => {
		if (file && file.type.startsWith("image/")) {
			const reader = new FileReader();

			reader.onload = function (event: any) {
				setImage(event.target.result);
				setValue("banner_path", file);
				clearErrors("banner_path");
			};

			reader.readAsDataURL(file);
		} else {
			alert("Por favor, escolha uma imagem válida.");
		}
	};

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setLoad(true);
		getOrientations({ page: value, title: hancleTitle }).then((data) => {
			setStateOrientations(data);
			setLoad(false);
		});
	};

	useEffect(() => {
		getOrientations().then((data) => {
			setStateOrientations(data);
			setLoad(false);
		});
	}, [refesh]);

	return (
		<Fragment>
			<Container
				maxWidth="xl"
				style={{
					marginTop: "100px",
					marginBottom: "10vh",
					width: "100%",
					display: "flex",
				}}
			>
				<Paper style={styles.div}>
					<Box>
						<div style={styles.caption}>
							<Button
								variant="contained"
								disableElevation
								onClick={() => {
									setStateDrawer((state) => ({
										...state,
										dialog: true,
										id: undefined,
									}));
								}}
							>
								ADICIONAR ORIENTAÇÃO
							</Button>

							<TextField
								id="outlined-basic"
								variant="outlined"
								size="small"
								placeholder="Search"
								onChange={(e) => {
									setHancleTitle(e.target.value);
									setLoad(true);
									// Cancela o timeout existente para evitar chamadas desnecessárias
									clearTimeout(timeoutId);

									// Configura um novo timeout
									timeoutId = setTimeout(() => {
										getOrientations({
											title: e.target.value,
										}).then((data) => {
											setStateOrientations(data);
											setLoad(false);
										});
									}, 3000); // Atraso de 1000 milissegundos (3 segundo)
								}}
								InputProps={{
									startAdornment: (
										<IconButton>
											<SearchIcon />
										</IconButton>
									),
								}}
							/>
						</div>
						<table style={styles.table}>
							<thead>
								<tr>
									<th style={styles.Checkbox}>Ocultar</th>
									<th style={styles.Banner}>Banner</th>
									<th style={styles.Titulo}>Título</th>
									<th style={styles.Descricao}>Descrição</th>
									<th style={styles.Edit}>Ações</th>
								</tr>
							</thead>
							<tbody>
								{load ? (
									<tr>
										<td
											colSpan={5}
											style={{
												width: "100%",
												textAlign: "center",
												height: "200px",
											}}
										>
											<CircularProgress />
										</td>
									</tr>
								) : (
									<>
										{stateOrientations?.data?.map(
											(itens, index) => (
												<tr>
													<td
														style={
															styles.CellCheckbox
														}
													>
														<input
															type="checkbox"
															checked={
																!itens.show
															}
															onChange={() => {
																setLoad(true);
																putOrientations(
																	{
																		show: Boolean(
																			itens.show
																		)
																			? "false"
																			: "true",
																		id: itens.id,
																	}
																).finally(
																	() => {
																		setLoad(
																			false
																		);
																		setRefesh(
																			!refesh
																		);
																	}
																);
															}}
														/>
													</td>
													<td
														style={
															styles.CellBanner
														}
													>
														<img
															style={styles.image}
															src={
																itens.banner_path
															}
															alt="Banner"
														/>
													</td>
													<td
														style={
															styles.CellTitulo
														}
													>
														{itens.title}
													</td>
													<td
														style={{
															padding: "10px",
															border: "1px solid #ddd",
															textAlign: "left",
															width: "45%",
															maxWidth:
																ellipsisEnabled[
																	index
																]
																	? ``
																	: "100px",
															whiteSpace:
																ellipsisEnabled[
																	index
																]
																	? "normal"
																	: "nowrap",
															overflow:
																ellipsisEnabled[
																	index
																]
																	? "visible"
																	: "hidden",
															textOverflow:
																ellipsisEnabled[
																	index
																]
																	? "clip"
																	: "ellipsis",
															cursor: "pointer",
														}}
														onClick={() => {
															setEllipsisEnabled(
																(
																	prevState
																) => ({
																	...prevState,
																	[index]:
																		!prevState[
																			index
																		],
																})
															);
														}}
													>
														{itens.description}
													</td>
													<td style={styles.CellEdit}>
														<Button
															style={
																styles.Button
															}
															variant="text"
															startIcon={
																<EditIcon />
															}
															onClick={() => {
																reset({
																	description:
																		itens.description,
																	access_link:
																		itens.access_link,
																	title: itens.title,
																	banner_path:
																		itens.banner_path,
																});
																// setImage(itens.banner_path)
																setStateDrawer(
																	(
																		state
																	) => ({
																		...state,
																		dialog: true,
																		id: itens.id,
																	})
																);
															}}
														>
															Edição
														</Button>
														<Button
															variant="contained"
															onClick={async () => {
																await deleteOrientations(
																	{
																		id: itens.id,
																	}
																).then(
																	async () => {
																		await getOrientations().then(
																			(
																				data
																			) => {
																				setStateOrientations(
																					data
																				);
																				setLoad(
																					false
																				);
																			}
																		);
																	}
																);
															}}
															startIcon={
																<Delete />
															}
														>
															Excluir
														</Button>
													</td>
												</tr>
											)
										)}
									</>
								)}
							</tbody>
						</table>
						<div style={styles.pagination}>
							<Stack spacing={2}>
								<Pagination
									count={stateOrientations?.meta?.last_page}
									onChange={handleChange}
									variant="outlined"
									shape="rounded"
								/>
							</Stack>
						</div>
					</Box>
				</Paper>
			</Container>

			<Dialog
				open={stateDrawer.dialog}
				TransitionComponent={Transition}
				onClose={() => {
					setStateDrawer((state) => ({
						...state,
						dialog: true,
						id: undefined,
					}));
				}}
				maxWidth={"sm"}
				fullWidth={true}
			>
				<ModalHeader header="Adicionar orientação" />
				<form
					onSubmit={handleSubmit((data: any, event) => {
						if (!stateDrawer.id) {
							if (!data.banner_path) {
								setError("banner_path", {
									type: "manual",
								});
								return;
							}

							postOrientations({
								title: data.title,
								description: data.description,
								banner_path: data.banner_path,
								access_link: data.access_link,
							}).finally(() => {
								setStateDrawer((state) => ({
									...state,
									dialog: false,
									id: undefined,
								}));
								reset({
									description: null,
									access_link: null,
									title: null,
									banner_path: null,
								});
								setLoad(true);
								setRefesh(!refesh);
								setImage(null);
							});
						} else {
							putOrientations({
								title: data.title,
								description: data.description,
								access_link: data.access_link,
								banner_path: image
									? data.banner_path
									: undefined,
								id: stateDrawer.id,
							}).finally(() => {
								setStateDrawer((state) => ({
									...state,
									dialog: false,
									id: undefined,
								}));
								reset({
									description: null,
									access_link: null,
									title: null,
									banner_path: null,
								});
								setLoad(true);
								setRefesh(!refesh);
								setImage(null);
							});
						}
					})}
				>
					<DialogContent
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "20px",
						}}
					>
						<Controller
							name="title"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									variant="outlined"
									size="small"
									label="Título"
									type="text"
									// value={orientations?.titulo}
									value={getValues("title") ? value : ""}
									onChange={(e) => {
										// setOrientations((prevorientations) => ({
										// 	...prevorientations,
										// 	titulo: e.target.value,
										// }));
										// setOrientationsPut((prevorientationsPut: any) => ({
										// 	...prevorientationsPut,
										// 	titulo: e.target.value,
										// }));
										onChange(e);
									}}
									error={errors.title?.type === "required"}
									helperText={
										errors.title?.type === "required" && (
											<span>
												O campo "Título" não pode ser
												vazio!
											</span>
										)
									}
								/>
							)}
						/>

						<Controller
							name="description"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									id="outlined-basic"
									variant="outlined"
									size="small"
									label="Descrição"
									type="text"
									multiline
									rows={6}
									// value={orientations?.description}
									value={
										getValues("description") ? value : ""
									}
									onChange={(e) => {
										// setOrientations((prevorientations) => ({
										// 	...prevorientations,
										// 	description: e.target.value,
										// }));
										// setOrientationsPut((prevorientationsPut: any) => ({
										// 	...prevorientationsPut,
										// 	description: e.target.value,
										// }));
										onChange(e);
									}}
									error={
										errors.description?.type === "required"
									}
									helperText={
										errors.description?.type ===
											"required" && (
											<span>
												O campo "Descrição" não pode ser
												vazio!
											</span>
										)
									}
								/>
							)}
						/>

						<Controller
							name="access_link"
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => (
								<TextField
									id="outlined-basic"
									variant="outlined"
									size="small"
									label="Link"
									type="text"
									// value={orientations?.access_link}
									value={
										getValues("access_link") ? value : ""
									}
									onChange={(e) => {
										// setOrientations((prevorientations) => ({
										// 	...prevorientations,
										// 	access_link: e.target.value,
										// }));
										// setOrientationsPut((prevorientationsPut: any) => ({
										// 	...prevorientationsPut,
										// 	access_link: e.target.value,
										// }));
										onChange(e);
									}}
									error={
										errors.access_link?.type === "required"
									}
									helperText={
										errors.access_link?.type ===
											"required" && (
											<span>
												O campo "Link" não pode ser
												vazio!
											</span>
										)
									}
								/>
							)}
						/>

						<div>
							<div
								style={
									errors.banner_path
										? {
												border: "2px dashed #d32f2f",
												padding: "10px",
												textAlign: "center",
												cursor: "pointer",
										  }
										: {
												border: "2px dashed #ccc",
												padding: "10px",
												textAlign: "center",
												cursor: "pointer",
										  }
								}
								onDrop={handleDrop}
								onDragOver={(e) => {
									e.preventDefault();
								}}
								onClick={() => {
									inputRef.current.click();
								}}
							>
								<input
									ref={inputRef}
									type="file"
									accept="image/*"
									onChange={handleFileInputChange}
									style={{ display: "none" }}
								/>
								{image || getValues("banner_path") ? (
									<img
										src={
											image
												? image
												: getValues("banner_path")
										}
										alt="Imagem carregada"
										style={{
											maxWidth: "100%",
											maxHeight: "200px",
										}}
									/>
								) : (
									<p
										style={{ margin: "0" }}
										onClick={() => {
											inputRef.current.click();
										}}
									>
										Clique ou arraste e solte uma imagem
										aqui
									</p>
								)}
							</div>
							{errors.banner_path?.type === "manual" && (
								<span
									style={{
										fontSize: "12px",
										margin: "4px 14px 0px 14px",
										color: "#d32f2f",
									}}
								>
									O campo "Banner" não pode ser vazio!
								</span>
							)}
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								setStateDrawer((state) => ({
									...state,
									dialog: false,
									id: undefined,
								}));
								reset({
									description: null,
									access_link: null,
									title: null,
									banner_path: null,
								});
								setImage(null);
							}}
						>
							cancelar
						</Button>
						<Button type="submit">
							{!stateDrawer.id ? "Salvar" : "Editar"}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</Fragment>
	);
}
