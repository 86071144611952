import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalAlteracaoTiposContratoView from "./ModalAlteracaoTiposContrato.view";

function ModalAlteracaoTiposContrato(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalAlteracaoTiposContratoView
				tipoContratoId={props.tipoContratoId}
			/>
		</ModalContextProvider>
	);
}

export default ModalAlteracaoTiposContrato;
