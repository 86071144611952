import { Delete, Search, Videocam } from "@mui/icons-material";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	InputAdornment,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import api from "../../../services/api";
import Notification from "../../../utils/notification";
import ModalHeader from "../../Modal/ModalHeader";

export default function TabelaCandidaturasAdministrador({
	applications,
	setApplications,
}: any) {
	const history = useNavigate();

	const [candidatos, setCandidatos] = useState<any[]>([]);
	const [, setOpen] = useState<boolean>(false);
	const [video, setVideo] = useState<string | null>(null);
	const [openVideo, setOpenVideo] = useState<boolean>(false);
	const getVideoCurriculo = async (ra) => {
		await axios
			.get(
				`${process.env.REACT_APP_FILE_MANAGER_API}/auditions/${ra}/published`
			)
			.then((res) => {
				setOpenVideo(true);
				setVideo(res.data);
			});
	};

	const filtraCandidaturas = (e) => {
		if (e.target.value === "" || e.target.value === null) {
			setCandidatos(applications);
		} else {
			const searchTerm = e.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive comparison
			console.clear();
			console.log(searchTerm);
			const filtered = applications.filter((application) =>
				application.applications.some((c) =>
					c.student.user.name.toLowerCase().includes(searchTerm)
				)
			);
			console.log(filtered);
			setCandidatos(filtered);
		}
	};

	useEffect(() => {
		setCandidatos(applications);
	}, [applications]);

	return (
		<Box data-testid="tabela-usuario">
			<Paper>
				<Box>
					<Box
						p={1}
						sx={{
							borderBottom: "solid thin #ddd",
						}}
					>
						<Typography variant="body1" sx={{ fontWeight: "500" }}>
							Candidaturas realizadas
						</Typography>
					</Box>
					<Box
						p={1}
						sx={{
							borderBottom: "solid thin #ddd",
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<TextField
							// label="Search"
							placeholder="Buscar"
							size="small"
							onChange={(e) => {
								filtraCandidaturas(e);
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<IconButton aria-label="search">
											<Search />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				</Box>
				<TableContainer className="tableContainer">
					<Table stickyHeader aria-label="sticky table">
						<TableHead sx={{ borderTop: "solid thin #ddd" }}>
							<TableRow hover role="checkbox" tabIndex={-1}>
								<TableCell align="left">Titulo</TableCell>
								<TableCell align="left">Empresa</TableCell>
								<TableCell align="left">Local</TableCell>
								<TableCell align="center">Área</TableCell>
								<TableCell align="center">RA</TableCell>
								<TableCell align="center">Nome</TableCell>
								<TableCell align="center">Currículo</TableCell>
								<TableCell align="center">Vídeo</TableCell>
								<TableCell align="center">Ações</TableCell>
								<TableCell align="center">Contratado</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{/* <pre>{JSON.stringify(applications, null, 4)}</pre> */}
							{candidatos && candidatos.length > 0 ? (
								candidatos.map((opportunity: any) => {
									return opportunity.applications.length >
										0 ? (
										opportunity.applications.map(
											(c, index) => {
												return (
													<>
														<TableRow key={index}>
															<TableCell align="center">
																{
																	opportunity.title
																}
															</TableCell>
															<TableCell align="center">
																{
																	opportunity.company
																}
															</TableCell>
															<TableCell align="center">
																{
																	opportunity.city
																}{" "}
																{
																	opportunity.state
																}
															</TableCell>
															<TableCell align="center">
																{
																	opportunity.area
																}
															</TableCell>
															<TableCell align="center">
																{c.student.ra}{" "}
															</TableCell>
															<TableCell align="center">
																{
																	c.student
																		.user
																		.name
																}{" "}
															</TableCell>
															<TableCell align="center">
																<Button
																	variant="text"
																	disableElevation
																	onClick={() => {
																		history(
																			"/curriculum/" +
																				c.student_id
																		);
																	}}
																>
																	Visualizar
																</Button>
																{/* {c.student.user.email} */}
															</TableCell>
															<TableCell align="center">
																<Button
																	onClick={() => {
																		setOpenVideo(
																			true
																		);
																		getVideoCurriculo(
																			c
																				.student
																				.ra
																		);
																	}}
																>
																	Reproduzir
																</Button>
																{/* {c.student.user.email} */}
															</TableCell>
															<TableCell align="center">
																{/* <IconButton>
															<Search />
														</IconButton> */}
																{/* <IconButton>
														<Edit />
													</IconButton> */}
																<IconButton
																	onClick={async () =>
																		setOpen(
																			true
																		)
																	}
																>
																	<Delete />
																</IconButton>
															</TableCell>
															<TableCell align="center">
																<Checkbox
																	checked={
																		c.hired ===
																		1
																			? true
																			: false
																	}
																	disabled
																/>
															</TableCell>
														</TableRow>
														<Dialog
															open={false}
															maxWidth={"md"}
															fullWidth
														>
															<ModalHeader
																header="Exclusão de candidatura"
																icon={
																	<Delete />
																}
																// close={() => setOpen(false)}
															/>
															<DialogContent>
																<Typography>
																	Você deseja
																	prosseguir
																	com a
																	exclusão da
																	candidatura?
																</Typography>
															</DialogContent>
															<DialogActions>
																<Button
																	variant="text"
																	onClick={() =>
																		setOpen(
																			false
																		)
																	}
																>
																	Cancelar
																</Button>
																<Button
																	variant="contained"
																	disableElevation
																	onClick={async () => {
																		await api
																			.delete(
																				`/applications/${c.id}`
																			)
																			.then(
																				(
																					res: any
																				) => {
																					Notification(
																						"Candidatura removida com sucesso",
																						"success"
																					);
																				}
																			)
																			.catch(
																				(
																					err: any
																				) => {
																					Notification(
																						"Falha ao remover candidatura!",
																						"error"
																					);
																				}
																			)
																			.finally(
																				() => {
																					history(
																						"/candidaturas"
																					);
																				}
																			);
																	}}
																>
																	Excluir
																</Button>
															</DialogActions>
														</Dialog>
													</>
												);
											}
										)
									) : (
										<></>
									);
								})
							) : (
								<TableCell
									colSpan={9}
									rowSpan={4}
									align="center"
								>
									<Typography
										variant="body1"
										sx={{
											height: "100px",
											textAlign: "center",
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
										}}
									>
										Nenhuma candidatura encontrada
									</Typography>
								</TableCell>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>

			<Dialog open={openVideo} fullWidth maxWidth="md">
				<ModalHeader
					header="Videocurrículo"
					icon={<Videocam />}
					close={() => setOpenVideo(false)}
				/>
				<DialogContent>
					<Box mb={2}>
						<Typography variant="h5">Reprodutor</Typography>
						<Typography variant="body1">
							Apresentando o vídeo currículo do aluno
						</Typography>
					</Box>
					<video
						src={video as string}
						style={{
							width: "100%",
							height: "auto",
							borderRadius: "12px",
						}}
						controls
						autoPlay
						muted
					/>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						disableElevation
						onClick={() => {
							setOpenVideo(false);
						}}
					>
						Fechar player
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
