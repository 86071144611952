import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Container } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import TabelaCandidaturasAdministrador from "../../../components/Tabelas/TabelaCandidaturas/TabelaCandidaturasAdministrador";
import api from "../../../services/api";

/**
 *
 * @description Tela de candidaturas Empresa e Administrador partilham de views parecidas
 */
export default function CandidaturasAdministrador(): JSX.Element {
	const history = useNavigate();
	/**
	 * @description define o estado das aplicações realizadas no sistema
	 */
	const params = useParams();
	const [fullApplications, setFullApplications] = useState<any | []>([]);
	/**
	 * @description busca applications existentes e em seguida informações de candidato e vaga
	 */
	const getApplications = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		try {
			await api
				.get("/applications", {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(async (res: any) => {
					res.data.forEach((item: any) => {
						if (item.jobs.length > 0)
							item.jobs.forEach(async (job) => {
								await api
									.get("/jobs/" + job.id, {
										headers: {
											Authorization: `Bearer ${token}`,
										},
									})
									.then((res: any) => {
										// var d = res.data[0];
										if (Array.isArray(res.data)) {
											if (
												res.data[0].applications &&
												res.data[0].applications
													.length > 0
											) {
												setFullApplications((prev) => [
													...prev,
													res.data[0],
												]);
											}
										} else {
											setFullApplications((prev) => [
												...prev,
												res.data,
											]);
										}
									});
							});
					});
				})
				.then(() => {
					toast.success("Listando candidaturas encontradas.");
				});
		} catch (err) {
			toast.error(
				"Falha ao buscar candidaturas! Recarregue a página e tente novamente."
			);
		}
	};

	/**
	 * @description busca applications a partir de um dado job_id
	 */
	const getApplicationsByJobId = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		await api
			.get("/jobs/" + params.id, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				if (res.data.applications && res.data.applications.length === 0)
					return setFullApplications([]);
				setFullApplications([res.data]);
			});
	};

	useEffect(() => {
		if (params.id) {
			getApplicationsByJobId();
		} else {
			getApplications();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<Box p={2}>
				<Container maxWidth="xl">
					<Button
						variant="contained"
						disableElevation
						startIcon={<ArrowBack />}
						onClick={() => history(-1)}
						sx={{ mb: 3 }}
					>
						Voltar
					</Button>
					<TabelaCandidaturasAdministrador
						applications={fullApplications}
						setApplications={setFullApplications}
					/>
					{/* <pre>{JSON.stringify(fullApplications, null, 4)}</pre> */}
				</Container>
			</Box>
		</Fragment>
	);
}
