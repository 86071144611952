import axios from "axios";
import {
	LoginData,
	LoginResponse,
	RecoveryData,
	RecoveryResponse,
	ResetData,
	ResetResponse,
} from "../models/Login";
import Notify from "../utils/notification";
import api from "./api";

/**
 * @function postLogin
 * Faz uma requisição do tipo post para a rota /login, da api, passando um objeto com as informações do login. No caso de sucesso é retornado um objeto do tipo Promise<LoginResponse>.
 * @param {LoginData} data - Dados do login.
 */

export async function postLogin(data: LoginData): Promise<LoginResponse> {
	return await api
		.post("/login", { email: data.email, password: data.password })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			Notify(
				"Usuário e/ou senha inválidos, verifique seus dados e tente novamente!",
				"error"
			);
			return e;
		});
}

/**
 * @function postLoginStudent
 * Faz uma requisição do tipo post para a rota /login-student, da api, passando um objeto com as informações do login. No caso de sucesso é retornado um objeto do tipo Promise<LoginResponse>.
 * @param {LoginData} data - Dados do login.
 */

export async function postLoginStudent(
	data: LoginData
): Promise<LoginResponse> {
	return await api
		.post("/login-student", { ra: data.ra, password: data.password })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			Notify(
				"Usuário e/ou senha inválidos, verifique seus dados e tente novamente!",
				"error"
			);
			return e;
		});
}

export async function postLoginAlunoPalcoVirtual(
	data: LoginData
): Promise<any> {
	const palco_api = axios.create({
		baseURL: process.env.REACT_APP_FILE_MANAGER_API,
	});
	return await palco_api
		.post("auth/login", data)
}

export async function postRecovery(
	data: RecoveryData
): Promise<RecoveryResponse> {
	return await api
		.post(`/forgot-password`, { email: data.email })
		.then(({ data }) => {
			Notify("Verifique seu email e siga as instruções!");
			return data;
		})
		.catch((e: any) => {
			Notify(
				"E-mail inválido, verifique seus dados e tente novamente!",
				"error"
			);
			return e;
		});
}

export async function postReset(
	email: string | undefined,
	signature: string | null,
	data: ResetData
): Promise<ResetResponse> {
	return await api
		.post(`/reset-password/${email}?signature=${signature}`, {
			password: data.password,
		})
		.then(({ data }) => {
			Notify("Senha redefinida com sucesso!");
			return data;
		})
		.catch((e: any) => {
			Notify(
				"E-mail inválido, verifique seus dados e tente novamente!",
				"error"
			);
			console.log(e);
			return e;
		});
}
