import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useAuthContext } from "../../../../../context/Authentication/hooks/useAuth";
import { HomePageItemCardView } from "./HomePageItemCardView";
import { HomePageItemListView } from "./HomePageItemListView";

const api = axios.create({
	baseURL: process.env.REACT_APP_URL,
});

export default function HomePageItemView({
	variant,
	item,
	setJob,
	jobsForStudents,
	jobForStudentDetails,
	setCandidaturaModalStatus,
	bookmark,
}: any) {
	const theme = useTheme();
	const breakpoint = useMediaQuery(theme.breakpoints.down("md"));

	const { auth } = useAuthContext();

	const sendBookMark = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		await api
			.post(
				"/bookmark",
				{ student_id: auth.student_id, job_id: item.id },
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			)
			.then(async () => {
				await jobsForStudents();
			});
	};

	const deleteBookMark = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		await api
			.delete("/bookmark/" + item.bookmark_student[0].id, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then(async () => {
				jobsForStudents();
			});
	};

	if (variant === "list_view") {
		return (
			<HomePageItemListView
				item={item}
				breakpoint={breakpoint}
				theme={theme}
				setJob={setJob}
				jobForStudentDetails={jobForStudentDetails}
				setCandidaturaModalStatus={setCandidaturaModalStatus}
				bookmark={bookmark}
				deleteBookMark={deleteBookMark}
				sendBookMark={sendBookMark}
			/>
		);
	} else {
		return (
			<HomePageItemCardView
				item={item}
				breakpoint={breakpoint}
				theme={theme}
				setJob={setJob}
				jobForStudentDetails={jobForStudentDetails}
				setCandidaturaModalStatus={setCandidaturaModalStatus}
				bookmark={bookmark}
				deleteBookMark={deleteBookMark}
				sendBookMark={sendBookMark}
			/>
		);
	}
}
