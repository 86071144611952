import { ActivityEmptyTableRow } from "./ActivityEmptyTableRow";
import { ActivityTableBody } from "./ActivityTableBodyContainer";
import { ActivityTableContainer } from "./ActivityTableContainer";
import { ActivityTableHeader } from "./ActivityTableHeader";
import { ActivityTableRow } from "./ActivityTableRow";

export const ActivityTableViewRoot = {
	ActivityTableContainer: ActivityTableContainer,
	ActivityTableHeader: ActivityTableHeader,
	ActivityTableBody: ActivityTableBody,
	ActivityTableRow: ActivityTableRow,
	ActivityEmptyTableRow: ActivityEmptyTableRow,
};
