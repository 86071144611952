import { AtualizarVaga, CriarVaga, RespostaVaga, Vaga } from "../../models/Vaga";
import Notify from "../../utils/notification";
import api from "../api";

/**
 * @function postVaga
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo post para a rota /jobs, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaVaga> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com um elemento contendo os dados da vaga cadastrada. 
 * @param {CriarVaga} data - Dados da vaga a ser cadastrada.
 */

export async function postVaga(data: CriarVaga): Promise<RespostaVaga> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.post("/jobs", data, { headers: { "Authorization": `Bearer ${token}` } })
    .then((data) => {
      Notify('Vaga cadastrada com sucesso!');
      return data;
    }).catch((e: any) => {
      Notify("Vaga não foi cadastrada, verifique seus dados e tente novamente!", "error");
      return e;
    });
}

/**
 * @function getVagas
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /jobs/:pagina, caso o parâmetro página não seja nulo, caso não, a requisição do tipo get é feita para a rota /jobs, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaVaga> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com todas as vagas disponíveis na base de dados.
 * @param {string | null} pagina - Número da página escolhida para a paginação.
 */

export async function getVagas(pagina: string | null): Promise<RespostaVaga> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get(`/jobs?page=${pagina === null ? '1' : pagina}&limit=10`, { headers: { "Authorization": `Bearer ${token} ` } })
    .then(({ data }) => {
      return data;
    }).catch((e: any) => {
      return e;
    });

}

/**
 * @function getVagasRepresentante
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota jobs/users, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaVaga> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com todas as vagas do usuário logado, disponíveis na base de dados.
 */

export async function getVagasRepresentante(pagina: string | null): Promise<RespostaVaga> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get(`/jobs/user?page=${pagina === null ? '1' : pagina}&limit=10`, { headers: { "Authorization": `Bearer ${token} ` } })
    .then(({ data }) => {
      return data;
    }).catch((e: any) => {
      return e;
    });

}

/**
 * @function getVagasEmpresa
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota jobs/company/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaVaga> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com todas as vagas da empresa associada ao id.
 * @param {number} id - Id da empresa detentora das vagas a serem buscadas.
 */

export async function getVagasEmpresa(pagina: string | null, id: number): Promise<RespostaVaga> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get(`/jobs/company/${id}?page=${pagina === null ? '1' : pagina}&limit=10`, { headers: { "Authorization": `Bearer ${token} ` } })
    .then(({ data }) => {
      return data;
    }).catch((e: any) => {
      return e;
    });
}

/**
 * @function getVaga
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /jobs/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<Vaga> contendo os dados duma vaga específica associada ao id.
 * @param {number} id - Id da vaga a ser buscada.
 */

export async function getVaga(id: number): Promise<Vaga> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get(`/jobs/${id}`, { headers: { "Authorization": `Bearer ${token} ` } })
    .then(({ data }) => {
      return data;
    }).catch((e: any) => {
      return e;
    });

}

/**
 * @function getVagaRepresentante
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /jobs/user/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<Vaga> contendo os dados duma vaga específica do usuário representante, que está logado no sistema, associada ao id.
 * @param {number} id - Id da vaga a ser buscada.
 */

export async function getVagaRepresentante(id: number): Promise<Vaga> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get(`/jobs/user/${id}`, { headers: { "Authorization": `Bearer ${token} ` } })
    .then(({ data }) => {
      return data;
    }).catch((e: any) => {
      return e;
    });

}

/**
 * @function putVaga
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo put para a rota /jobs/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso nada é retornado pela api.
 * @param {number} id - Id da vaga a ser atualizada.
 * @param {AtualizarVaga} data - Dados da vaga a ser atualizada.
 */

export async function putVaga(id: number, data: AtualizarVaga) {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.put(`/jobs/${id}`, data, { headers: { "Authorization": `Bearer ${token} ` } }
  ).then(() => {
    Notify("Vaga alterada com sucesso!");
  }).catch((e) => {
    Notify("Vaga não foi alterada, verifique seus dados e tente novamente!", "error");
    return e;
  });
}

/**
 * @function deleteVaga
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo delete para a rota /jobs/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso nada é retornado.
 * @param {number} id - Id da vaga a ser deletada.
 */

export async function deleteVaga(id: number) {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.delete(`/jobs/${id}`, { headers: { "Authorization": `Bearer ${token} ` } }
  ).then(() => {
    Notify("Vaga deletada com sucesso!");
  }).catch((e: any) => {
    Notify("Vaga não foi deletada, verifique seus dados e tente novamente!", "error");
    return e;
  });
}