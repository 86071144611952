import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalDetalhesTipoContratoView from "./ModalDetalhesTipoContrato.view";

function ModalDetalhesTipoContrato(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalDetalhesTipoContratoView
				tipoContratoId={props.tipoContratoId}
			/>
		</ModalContextProvider>
	);
}

export default ModalDetalhesTipoContrato;
