import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import React, { Fragment } from "react";
import "./style.scss";

export default function CardsView({ orientacao }: any) {
	const [truncateText, setTruncateText] = React.useState(true);
	const typographyRef: any = React.useRef(null);

	const handleMouseOver = () => {
		if (typographyRef.current.innerText.length > 230) {
			setTruncateText(false);
		}
	};

	const handleMouseOut = () => {
		setTruncateText(true);
		if (typographyRef.current) {
			typographyRef.current.scrollTop = 0;
		}
	};

	return (
		<Fragment>
			<Card
				sx={{
					maxWidth: 345,
					minWidth: 345,
					maxHeight: 450,
					minHeight: 450,
					display: "flex",
					flexDirection: "column",
				}}
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}
			>
				<CardMedia
					sx={{ height: 180 }}
					className={`imagem-com-hover ${
						truncateText ? "" : "hovered"
					}`}
					image={orientacao?.banner_path}
					title="green iguana"
				/>

				<CardContent>
					<Tooltip title={orientacao?.title} placement="bottom" arrow>
						<Typography
							gutterBottom
							variant="body1"
							component="div"
							sx={{
								fontWeight: "semibold",
								// overflow: "hidden",
								// textOverflow: "ellipsis",
								// whiteSpace: "nowrap",
								// maxWidth: "72ch", // Adjust as needed
							}}
						>
							{`${orientacao?.title}`}
						</Typography>
					</Tooltip>
					<Typography
						className={`typography-transition ${
							truncateText
								? ""
								: "hovered-typography scroll-container"
						}`}
						ref={typographyRef}
						variant="body2"
						color="text.secondary"
						sx={
							truncateText
								? {
										display: "-webkit-box",
										WebkitBoxOrient: "vertical",
										overflow: "hidden",
										WebkitLineClamp: 6,
								  }
								: {}
						}
					>
						{orientacao?.description}
					</Typography>
				</CardContent>
				<CardActions>
					<Button
						className="ButtonAdicionar"
						color="primary"
						variant="outlined"
						size="small"
						onClick={() => {
							window.open(orientacao?.access_link, "_blank");
						}}
					>
						Acessar
					</Button>
				</CardActions>
			</Card>
		</Fragment>
	);
}
