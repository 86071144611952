import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalAlteracaoUsuarioView from "./ModalAlteracaoUsuario.view";

function ModalAlteracaoUsuario(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalAlteracaoUsuarioView
				userId={props.userId}
				userRoleId={props.userRoleId}
			/>
		</ModalContextProvider>
	);
}

export default ModalAlteracaoUsuario;
