import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@mui/material";
import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { useTheming } from "./context/Theming";
import Routes from "./routes";
import { createTheme } from "./theme";

/**
 *
 * - Responsável por importar o objeto com as Rotas(páginas) do projeto
 *
 * - Responsável por definir o objeto com o tema no modo claro e no modo escuro do projeto
 */
export function AppRoot() {
	const { dark } = useTheming();
	const theme = createTheme(dark);
	return (
		<React.Fragment>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Toaster position="top-right" reverseOrder={false} />
					<Routes />
				</BrowserRouter>
			</ThemeProvider>
		</React.Fragment>
	);
}
