import axios, { AxiosResponse } from "axios";
import { ReactNode, useEffect, useState } from "react";
import { useAuthContext } from "../Authentication/hooks/useAuth";
import { usePalcoProfissional } from "../PalcoProfissional";
import useSession from "../session/SessionHook";
import { PalcoProfissionalRootContext } from "./PalcoProfissionalRoot.context";

interface Props {
	children?: ReactNode;
}

export function PalcoProfissionalRootProvider({ children }: Props) {
	const { state } = usePalcoProfissional();
	const { setVideos, setImages, setAuditions } = useSession();
	const { auth } = useAuthContext();

	const [reloadSignal, setReloadSignal] = useState<boolean>(false);
	const [data, setData] = useState<any | any[]>(null);
	const [activityGroup, setActivityGroup] = useState<any>({
		curriculos: [],
		entrevistas: [],
	});

	// alert("AQUI: " + state.usuario.id);
	const fetchPalcoData = async () => {
		let localUser = localStorage.getItem("@palco-profissional:token")
			? JSON.parse(
					localStorage.getItem("@palco-profissional:token") as string
			  )
			: null;
		let localToken = localStorage.getItem("@palco-profissional:token")
			? JSON.parse(
					localStorage.getItem("@palco-profissional:token") as string
			  )
			: null;
		let user: any = localUser ? localUser.data?.usuario?.id : null;
		let token: any = localToken ? localToken.data?.accessToken : null;

		if (user && token) {
			await axios
				.get(`${process.env.REACT_APP_FILE_MANAGER_API}/auditions`, {
					params: {
						// user_id: state.usuario.id,
						transcription: 1,
						recognition: 1,
						// activity_id: 71,
						activity: 1,
						user: 1,
						project_id: 2,
					},
					headers: {
						Authorization: `Bearer ${token}`,
						ra: user,
					},
				})
				.then((response: AxiosResponse<any>) => {
					setData(response.data.data);
					setAuditions(response.data.data);
					setVideos(response.data.data);
				})
				.catch((err) => {
					if (err.response) {
					} else if (err.request) {
						console.log(err.request);
					} else {
						console.log(err.message);
					}
					console.log(err.config);
				});

			await axios
				.get(`${process.env.REACT_APP_FILE_MANAGER_API}/biometrics`, {
					params: { user_id: auth.user_id },
					headers: {
						Authorization: `Bearer ${token}`,
						ra: user,
					},
				})
				.then((res: any) => {
					// alert(JSON.stringify(res.data, null, 4));
					setImages(res.data);
				})
				.catch((err) => {
					// alert(err);
				})
				.finally(() => {
					// alert("Fim!");
				});

			setReloadSignal(false);
		}
	};

	useEffect(() => {
		if (data) {
			let curriculo = data.filter((data) => data.activity_id === 71);
			let entrevistas = data.filter((data) => data.activity_id === 72);
			setActivityGroup({ curriculo, entrevistas });
		}
	}, [state, data]);

	useEffect(() => {
		if (state) fetchPalcoData();
		// eslint-disable-next-line
	}, [state]);

	useEffect(() => {
		if (state) fetchPalcoData();
		// eslint-disable-next-line
	}, [state, reloadSignal]);

	return (
		<PalcoProfissionalRootContext.Provider
			value={{
				activityGroup,
				setActivityGroup,
				reloadSignal,
				setReloadSignal,
				data,
				setData,
				fetchPalcoData,
			}}
		>
			{children}
		</PalcoProfissionalRootContext.Provider>
	);
}
