import { CriarNivelUsuario, RespostaNivelUsuario } from "../../models/NivelUsuario";
import api from "../api";

/**
 * @function postNivelUsuario
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo post para a rota /roles, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um array do tipo Promise<Array<RespostaNivelUsuario>> onde o único elemento contém duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com o nível de usuário cadastrado.
 * @param {CriarNivelUsuario} data - Dados do nível de usuário a ser cadastrado.
 */

export async function postNivelUsuario(data: CriarNivelUsuario): Promise<Array<RespostaNivelUsuario>> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  let dados = {
    name: data.name,
  }

  return await api.post("/roles", dados, { headers: { "Authorization": `Bearer ${token}` } }).then((data) => {
    return data;
  }).catch((e: any) => {
    return e;
  });

}

/**
 * @function getNiveisUsuario
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /roles, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaNivelUsuario> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com todos os níveis de usuário disponíveis na base de dados.
 */

export async function getNiveisUsuario(): Promise<RespostaNivelUsuario> {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get("/roles", { headers: { "Authorization": `Bearer ${token}` } }).then(({ data }) => {
    return data;
  }).catch((e: any) => {
    console.log(e);
  });

}

/**
 * @function getNivelUsuario
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /roles/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaNivelUsuario> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array contendo o nível de usuário associado ao id.
 * @param {number} id - Id do nível de usuário a ser buscado. 
 */

export async function getNivelUsuario(id: number): Promise<RespostaNivelUsuario> {

  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api.get(`roles/${id}`, { headers: { "Authorization": `Bearer ${token}` } }).then(({ data }) => {
    return data;
  }).catch((e: any) => {
    return e;
  });

}
