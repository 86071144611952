import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { postSignup } from "../../services/signup";
import Notify from "../../utils/notification";
import { PropsSignUpContext } from "./models/SignUpPropsType";

const DEFAULT_VALUE = {
	dados: {
		user: {
			name: "",
			email: "",
			password: "",
			passwordConfirm: "",
		},
		company: {
			name: "",
			cnpj: "",
			about: "",
			area: "",
			email_curriculum: "",
			employees_number: 0,
		},
	},
	dadosUsuario: {
		name: "",
		email: "",
		password: "",
		passwordConfirm: "",
	},
	dadosEmpresa: {
		name: "",
		cnpj: "",
		about: "",
		area: "",
		email_curriculum: "",
		employees_number: 0,
	},
	proximo: false,
	setDados: () => {},
	setDadosUsuario: () => {},
	setDadosEmpresa: () => {},
	setProximo: () => {},
};

const dadosVazio = {
	user: {
		name: "",
		email: "",
		password: "",
		passwordConfirm: "",
	},
	company: {
		name: "",
		cnpj: "",
		about: "",
		area: "",
		email_curriculum: "",
		employees_number: 0,
	},
};

const dadosEmpresaVazio = {
	name: "",
	cnpj: "",
	about: "",
	area: "",
	email_curriculum: "",
	employees_number: 0,
};

export const SignUpContext = createContext<PropsSignUpContext>(DEFAULT_VALUE);

export const SignUpContextProvider: React.FC = ({ children }) => {
	const navigate = useNavigate();
	const [dados, setDados] = useState(DEFAULT_VALUE.dados);
	const [dadosUsuario, setDadosUsuario] = useState(
		DEFAULT_VALUE.dadosUsuario
	);
	const [dadosEmpresa, setDadosEmpresa] = useState(
		DEFAULT_VALUE.dadosEmpresa
	);
	const [proximo, setProximo] = useState(DEFAULT_VALUE.proximo);

	useEffect(() => {
		if (JSON.stringify(dados) !== JSON.stringify(dadosVazio)) {
			postSignup(dados).then(() => {
				navigate("/");
			});
		}

		if (
			JSON.stringify(dadosEmpresa) !==
				JSON.stringify(dadosEmpresaVazio) &&
			JSON.stringify(dados) === JSON.stringify(dadosVazio)
		) {
			if (dadosEmpresa.email_curriculum !== dadosUsuario.email) {
				setDados({
					user: dadosUsuario,
					company: dadosEmpresa,
				});

				localStorage.clear();
			} else {
				Notify(
					"Diferencie o e-mail do usuário do e-mail de recebimento de currículo!",
					"error"
				);
			}
		}
	}, [dados, dadosUsuario, dadosEmpresa, navigate]);

	return (
		<SignUpContext.Provider
			value={{
				dados,
				setDados,
				dadosUsuario,
				setDadosUsuario,
				dadosEmpresa,
				setDadosEmpresa,
				proximo,
				setProximo,
			}}
		>
			{children}
		</SignUpContext.Provider>
	);
};
