import { Store } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import { DialogActions } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { Transition } from "../../../../utils/transition";
import ListaDetalhesEmpresa from "../../../Detalhes/DetalhesEmpresa";
import ModalHeader from "../../ModalHeader";

function ModalDetalhesEmpresaView(props) {
	const { open, handleOpen, handleClose } = useModalContext();

	/**
	 * @function handleDeletaEmpresa
	 * Abre o modal de ListaDetalhesEmpresa e seta um valor no sessionStorage com a chave de nome id_empresa.
	 * @param {number} id - Representa o id pra empresa.
	 */
	function handleDetalhesEmpresa(id: number) {
		handleOpen();
		localStorage.setItem("id_empresa", id.toString());
	}

	return (
		<>
			<Button
				onClick={() => {
					handleDetalhesEmpresa(props.companieId);
				}}
				title={"Detalhes"}
				data-testid="modal-detalhes-empresa"
			>
				<DescriptionIcon style={{ color: "#292B2D" }} />
			</Button>

			<Dialog
				maxWidth="lg"
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
			>
				<ModalHeader header={"Detalhes da Empresa"} icon={<Store />} />
				{/* <DialogTitle style={{ display: "flex", justifyContent: "space-between", fontSize: "25px", padding: "15px" }}> */}
				{/* </DialogTitle> */}
				<DialogContent>
					<DialogContentText>
						<ListaDetalhesEmpresa />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						disableElevation
						onClick={handleClose}
					>
						Voltar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
export default ModalDetalhesEmpresaView;
