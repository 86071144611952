import { PermContactCalendar } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import { DialogActions, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { Transition } from "../../../../utils/transition";
import ListaDetalhesVaga from "../../../Detalhes/DetalhesVaga";
import ModalHeader from "../../ModalHeader";

function ModalDetalhesVagaView(props) {
	const { open, handleOpen, handleClose } = useModalContext();

	/**
	 * @function handleDetalhesVaga
	 * Abre o modal de ListaDetalhesVaga e seta um valor no sessionStorage com a chave de nome id_vaga.
	 * @param {number} id - Representa o id  da vaga.
	 */
	function handleDetalhesVaga(id: number) {
		handleOpen();
		localStorage.setItem("id_vaga", id.toString());
	}

	return (
		<>
			<IconButton
				onClick={() => {
					handleDetalhesVaga(props.vagaId);
				}}
				title={"Detalhes"}
				data-testid="modal-detalhes-vaga"
			>
				<DescriptionIcon style={{ color: "#292B2D" }} />
			</IconButton>

			<Dialog
				maxWidth="lg"
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
			>
				<ModalHeader
					header={"Detalhes da Vaga"}
					icon={<PermContactCalendar />}
				/>
				<DialogContent>
					<DialogContentText>
						<ListaDetalhesVaga />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="text" onClick={handleClose}>
						Voltar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
export default ModalDetalhesVagaView;
