import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalCadastroEmpresaView from "./ModalCadastroEmpresa.view";

function ModalCadastroEmpresa(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalCadastroEmpresaView isActive={props.isActive} />
		</ModalContextProvider>
	);
}

export default ModalCadastroEmpresa;
