import { AxiosInstance } from "axios";

export default class AuditionService {
	private api: AxiosInstance;
	
	constructor(api: AxiosInstance) {
		this.api = api;
		// this.api = axios.create({ baseURL: process.env.REACT_APP_FILE_MANAGER_API })

		this.api.interceptors.request.use((config) => {
			let user: any = JSON.parse(localStorage.getItem("@palco-profissional:token") as string).data.usuario.id;
			let token: any = JSON.parse(localStorage.getItem("@palco-profissional:token") as string).data.accessToken;

			if (token && config.headers) {

				config.headers["Authorization"] = `Bearer ${token}`;
				config.headers["ra"] = user;

			}

			return config;
		});
	}


	async findAll(id: number | string, params?: any) {
		return await this.api.get(`/auditions`, {
			params: {
				activity_id: id,
				activity: 1,
				user: 1,
				recognition: 1,
				transcription: 1,
				...params
			}
		})
	}

	async getAll(ra: string | number, params?: any) {		
		return await this.api.get("/auditions", {
			params: {
				user_id: ra ? ra : null,
				...params
			}
		})
	}
	
	async getAIEvaluation(id) {
		return await this.api.get("/gpt/" + id)
	}

	async publishCurriculum(id: string | number, status: string | boolean) {				
		return await this.api.get(`/auditions/${id}/publish` )
		// return await this.api.put("/auditions/" + id, {l
		// 	published: !status			
		// })
	}

	async getOneById(id: string | number) {
		return await this.api.get(`/auditions/${id}`)
	}

	async getOne(code: string | number, id?: string | number, params?: any) {
		return await this.api.get("/auditions", {
			params: {
				code: code,
				activity_id: id,
				activity: 1,
				user: 1,
				recognition: 1,
				transcription: 1
			}
		})
	}

	async create(data: any) {
		return await this.api.post('/auditions', data)
	}

	async update(id: number | string, data: any) {
		return await this.api.put(`/auditions/${Number(id)}`, data);
	}

	async delete(id: number | string) {
		return await this.api.delete(`/auditions/${Number(id)}`)
	}

	async checkFileExists(key: any) {
		return await this.api.get("/s3/check-file",{
			params: {
				key: key
			}
		})
	}

	async checkFeedbackExists(id: any) {
		return await this.api.get(`/auditions/${id}/check`)
	}
}