import { Grid } from "@mui/material";

export function ActivityViewerDialogBiometrics({ data, showImages }: any) {
	return (
		<>
			<Grid
				container
				spacing={1}
				sx={{
					mt: 3,
					display: showImages ? "" : "none",
				}}
			>
				{data &&
					data.recognition &&
					data.recognition.map((d: any) => {
						return (
							<Grid item md={2} key={d.id}>
								<img
									width="100%"
									height="auto"
									src={
										"https://dirin.s3.us-east-1.amazonaws.com/" +
										d.file_key
									}
									alt=""
								/>
							</Grid>
						);
					})}
			</Grid>
		</>
	);
}
