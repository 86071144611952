// import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PeopleAlt } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import FormularioCadastroUsuario from "../../../Formularios/Cadastro/FormularioCadastroUsuario";
import ModalHeader from "../../ModalHeader";
import "../styles.scss";

function ModalCadastroUsuarioView() {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down(700));
	// const lg = useMediaQuery(theme.breakpoints.up("sm"));
	const { open, handleOpen } = useModalContext();

	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				display: "flex",
				justifyContent: "end",
				alignItems: "center",
				marginRight: "5px",
			}}
		>
			<Button
				variant="contained"
				size="large"
				onClick={handleOpen}
				// className="buttonModalCadastro"
				data-testid="modal-cadastro-usuario"
				disableElevation
				endIcon={<AddIcon />}
			>
				Adicionar Usuário
			</Button>
			<Dialog
				fullScreen={fullScreen}
				fullWidth
				maxWidth={"md"}
				open={open}
				// onClose={handleClose}
			>
				<ModalHeader header="Cadastrar usuário" icon={<PeopleAlt />} />
				<DialogContent className="dialogContent">
					<DialogContentText className="dialogContentText">
						<FormularioCadastroUsuario />
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</Box>
	);
}
export default ModalCadastroUsuarioView;
