import { Business, Close, Work } from "@mui/icons-material";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
	IconButton,
	Typography,
	useTheme,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import ListaDetalhesVagaAluno from "../../../components/Detalhes/DetalhesVagaAluno";
import ModalHeader from "../../../components/Modal/ModalHeader";
import api from "../../../services/api";
import Notify from "../../../utils/notification";
import IndexedCard from "../HomePage/components/HomePageItemView";

export default function MinhasVagasFavoritas() {
	const theme = useTheme();
	// const { auth } = useAuthContext();
	const [profile, setProfile] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [openJobsLoader, setOpenJobsLoader] = useState<boolean>(false);
	const [job, setJob] = useState<any>({
		vaga: null,
		usuario: null,
		empresa: null,
	});
	const [jobs, setJobs] = useState<any | any[]>([]);
	// const [applications, setApplications] = useState<any>(null);
	const [candidaturaModalStatus, setCandidaturaModalStatus] =
		useState<boolean>(false);

	const getProfile = async () => {
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		await api
			.get("/profile", {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				if (Array.isArray(res.data)) {
					setProfile(res.data[0]);
				} else {
					setProfile(res.data);
				}
			});
	};

	const jobsForStudents = async (value?: number | string) => {
		setOpenJobsLoader(true);
		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		var firstArray = await api
			.get("/jobs-for-student", {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				return res.data.data;
			});

		var secondArray = await api
			.get("/student-bookmark", {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				// setJobs(res.data);
				return res.data;
			});

		const mergedArray = secondArray.map((secondObj) => {
			const jobId = secondObj.job_id;
			const correspondingJob = firstArray.find(
				(firstObj) => firstObj.id === jobId
			);
			return { ...secondObj, jobs: correspondingJob };
		});

		setJobs(mergedArray);

		setOpenJobsLoader(false);
	};

	const jobForStudentDetails = async (item?: any, id: number | string) => {
		// alert(JSON.stringify(id));
		// return;
		localStorage.setItem("id_vaga", id.toString());

		let token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		if (id === null || id === 0) {
			return Promise.resolve("ID is null  or 0, cancelling the call");
		} else {
			await api
				.get(`/jobs/${id}/student`, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((res) => {
					setJob({
						vaga: res.data[0],
						usuario: res.data[0].user,
						empresa: res.data[0].company,
					});
				})
				.finally(() => {
					setOpen(true);
				});
		}
	};

	const handleCandidaturaStatus = async (item: any) => {
		if (item && item.applications && item.applications.length === 0) {
			await api
				.post("/applications", {
					job_id: item.id,
					ra: profile.student.ra,
				})
				.then(() => {
					Notify("Candidatura submetida com sucesso!", "success");
				})
				.finally(jobsForStudents);
		} else if (item && item.applications && item.applications.length > 0) {
			item &&
				item.applications &&
				item.applications.forEach(async (i: any) => {
					await api
						.delete(`/applications/${i.id}`)
						.then((res: any) => {
							Notify(
								"Candidatura removida com sucesso",
								"success"
							);
						})
						.catch((err: any) => {
							Notify("Falha ao remover candidatura!", "error");
						})
						.finally(() => {
							jobsForStudents();
						});
				});
		}
		setCandidaturaModalStatus(false);
	};

	useEffect(() => {
		getProfile();
		jobsForStudents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			{/* <pre>{JSON.stringify(profile, null, 4)}</pre> */}
			{/* <pre>{JSON.stringify(jobs, null, 4)}</pre> */}
			<Container maxWidth="xl">
				<Fragment>
					<Backdrop
						open={openJobsLoader}
						sx={{
							zIndex: 9999,
						}}
					>
						<CircularProgress />
					</Backdrop>
					<Grid container spacing={2} sx={{ mt: 3 }}>
						<Box p={3}>
							<Typography variant="h5">Minhas vagas</Typography>
						</Box>
						{jobs.map((item, index) => {
							return (
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
								>
									<IndexedCard
										variant="list_view"
										key={index}
										item={item.jobs}
										theme={theme}
										setJob={setJob}
										setJobs={setJobs}
										setOpen={setOpen}
										jobsForStudents={jobsForStudents}
										jobForStudentDetails={
											jobForStudentDetails
										}
										setCandidaturaModalStatus={
											setCandidaturaModalStatus
										}
										bookmark={true}
										data={item}
									/>
								</Grid>
							);
						})}
						<Dialog open={candidaturaModalStatus}>
							<ModalHeader
								header={`Confirmação de ${
									job &&
									job.applications &&
									job.applications.length > 0
										? "exclusão"
										: "aplicação"
								} de candidatura`}
								icon={<Work />}
								close={() => setCandidaturaModalStatus(false)}
							/>
							<DialogContent>
								Deseja confirmar a{" "}
								{job &&
								job.applications &&
								job.applications.length > 0
									? "exclusão"
									: "aplicação"}{" "}
								da candidatura à vaga de: <b>"{job.title}"</b>{" "}
								em <b>{job.company}</b>?
							</DialogContent>
							<DialogActions>
								<Button
									variant="text"
									onClick={() => {
										setCandidaturaModalStatus(false);
									}}
								>
									Cancelar
								</Button>
								<Button
									variant="contained"
									disableElevation
									onClick={() => {
										handleCandidaturaStatus(job);
									}}
								>
									Confirmar
								</Button>
							</DialogActions>
						</Dialog>
					</Grid>
				</Fragment>
			</Container>
			{/* DIALOG com detalhes da vaga selecionada */}
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				maxWidth="md"
				sx={{ zIndex: 99999 }}
			>
				<Box
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box p={2} sx={{ display: "flex", gap: "12px" }}>
						<Business />{" "}
						<Typography variant="body1">
							Detalhes da vaga
						</Typography>
					</Box>
					<Box p={1}>
						<IconButton
							sx={{
								color: "white",
							}}
							onClick={() => setOpen(false)}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
				<DialogContent>
					<DialogContentText>
						<ListaDetalhesVagaAluno
							vaga={job.vaga}
							usuario={job.usuario}
							empresa={job.empresa}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="text" onClick={() => setOpen(false)}>
						Voltar
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}
