import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalCadastroUsuarioView from "./ModalCadastroUsuario.view";

function ModalCadastroUsuario(): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalCadastroUsuarioView />
		</ModalContextProvider>
	);
}

export default ModalCadastroUsuario;
