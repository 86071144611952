import {
	TableCell,
	TableHead,
	TableRow,
	styled,
	tableCellClasses,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Fragment } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		// backgroundColor: "#fff",
		// color: "black",
		fontWeight: "semibold",
		fontSize: "14px",
	},
}));

export function ActivityTableHeader({ props }: any) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Adjust 'sm' if needed

	return (
		<Fragment>
			<TableHead>
				<TableRow>
					{!props.tutor && !fullScreen && (
						<StyledTableCell>Atividade</StyledTableCell>
					)}
					{/* <StyledTableCell align="left">
								CÓD. ATIVIDADE
							</StyledTableCell> */}
					{!props.tutor && !fullScreen && (
						<StyledTableCell align="left">
							Descrição
						</StyledTableCell>
					)}
					{/* <StyledTableCell>RA</StyledTableCell> */}
					{props.tutor && (
						<StyledTableCell align="left">RA</StyledTableCell>
					)}
					<StyledTableCell align="center" sx={{ width: "46px" }}>
						Apresentação
					</StyledTableCell>
					{/* <StyledTableCell align="center">Id. Submissão</StyledTableCell> */}
					{!fullScreen && (
						<StyledTableCell align="center">Código</StyledTableCell>
					)}
					{!fullScreen && (
						<StyledTableCell align="center">Ações</StyledTableCell>
					)}
					{props.tabelaCurriculo && !fullScreen && (
						<StyledTableCell align="center">
							Divulgar Currículo
						</StyledTableCell>
					)}
					<StyledTableCell align="center">Data</StyledTableCell>
					<StyledTableCell align="center">Score</StyledTableCell>
					{fullScreen && (
						<StyledTableCell align="center">Ações</StyledTableCell>
					)}
				</TableRow>
			</TableHead>
		</Fragment>
	);
}
