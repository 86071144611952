import React from "react";
import { Button, Container, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import "./styles.scss";
import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import { postRecovery } from "../../../services/login";
import { useNavigate } from "react-router-dom";
import { RecoveryData } from "../../../models/Login";

function FormularioForgotPasswordView(): React.ReactElement {
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onSubmit" });
  const useStyles = makeStyles((theme) => ({
    textField: {
      '& label': {
        color: 'grey !important',
      },
      '& label.Mui-focused': {
        color: 'black !important',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'Grey',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'Grey',
        },
        // '&:hover fieldset': {
        //   borderColor: 'blue',
        // },
        '&.Mui-focused fieldset': {
          borderColor: 'Grey',
        },
      },
    }
  }));
  const classes = useStyles();
  const navigate = useNavigate();

  async function forgotPassword(data: RecoveryData) {
    navigate("/");
    postRecovery(data).then((data) => {
    }).catch((e) => {
      console.log(e);
    });
  };

  return (
    <div className="divContainer">
      <div className="divAiamisForgot">
        <img alt="aiamis" src="../img/aiamis_Prancheta_1.svg" className="imgAiamisForgot" />
        <h1 className="spanOportunidadesForgot">&nbsp; | Oportunidades </h1>
      </div>
      <Typography style={{ marginTop: "30px" }} className="titleCampForgot">
        Não se preocupe, estamos aqui para ajudá-lo a recuperar sua senha.
      </Typography>
      <Container component="section" className="containerForgot" data-testid="formulario-cadastro-usuario">
        <form onSubmit={handleSubmit((data) => forgotPassword({
          email: data.email,
        }))}>
          <div>
            <Typography style={{ marginTop: "30px" }} className="titleCampSignUp">Informe o e-mail da sua conta</Typography>
            <TextField
              className={classes.textField}
              type="email"
              fullWidth
              placeholder="E-mail"
              margin="none"
              {...register("email", {
                required: true,
                pattern: /@./
              })}
              error={errors.email?.type === "required"}
              helperText={errors.email?.type === "required" && <span>O campo "E-mail" é obrigatório!</span>}
            />
          </div>

          <Box className="boxButton">
            <Button type="submit" className="buttonForgot">
              Recuperar senha
            </Button>
          </Box>
        </form>
      </Container>
    </div>
  );
}

export default FormularioForgotPasswordView;