import { Box, makeStyles } from "@material-ui/core";
import { Button, Container, TextField, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { CriarTipoContrato } from "../../../../models/TipoContrato";
import { postTipoContrato } from "../../../../services/crud/crud.tiposContrato";
import { useReloadContext } from "../../../Tabelas/hooks/useReload";

function FormularioCadastroTiposContratoView(): React.ReactElement {
	const { handleClose } = useModalContext();
	const { handleReload } = useReloadContext();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	const useStyles = makeStyles((theme) => ({
		textField: {
			"& label": {
				color: "grey !important",
			},
			"& label.Mui-focused": {
				color: "black !important",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				// '&:hover fieldset': {
				//   borderColor: 'blue',
				// },
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));
	const classes = useStyles();

	/**
	 * @function cadastroTiposContrato
	 * faz a chamada da função de postTipoContrato enviando data como parâmetro, se caso a requisição der certo emite um Notify, chama as funções handleClose e handleReload fechando o modal e dando um refresh na página.
	 * @param {CriarTipoContrato} data - Representa os dados cadastrados dos Tipos Contrato.
	 */
	async function cadastroTiposContrato(data: CriarTipoContrato) {
		postTipoContrato(data)
			.then(() => {
				handleClose();
				handleReload();
			})
			.catch((e) => {
				console.log(e);
			});
	}

	return (
		<Container
			// className="container"
			component="section"
			// style={{ width: "100%", height: "100%" }}
			data-testid="formulario-cadastro-tipos-contrato"
		>
			<Typography variant="h5" component="h5">
				Adicionar Contrato
			</Typography>
			<Typography variant="body1" component="p">
				Adicione uma nova modalidade de contrato
			</Typography>
			<form
				onSubmit={handleSubmit((data) =>
					cadastroTiposContrato({
						name: data.name,
					})
				)}
				style={{ width: "100%", height: "100%" }}
			>
				<TextField
					className={classes.textField}
					placeholder="Nome do Tipo de Contrato*"
					type="text"
					fullWidth
					margin="normal"
					{...register("name", { required: true })}
					error={errors.name?.type === "required"}
					helperText={
						errors.name?.type === "required" && (
							<span>
								O campo "Nome do Tipo de Contrato" é
								obrigatório!
							</span>
						)
					}
				/>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
						mt: 3,
					}}
				>
					<Button variant="text" onClick={handleClose}>
						Cancelar
					</Button>
					<Button
						variant="contained"
						disableElevation
						onClick={handleClose}
						type="submit"
					>
						Adicionar
					</Button>
				</Box>
			</form>
		</Container>
	);
}

export default FormularioCadastroTiposContratoView;
