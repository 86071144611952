import React from "react";
import FormularioSignUp from "../../../components/Formularios/FormularioSignUp";
import { SignUpContextProvider } from "../../../context/SignUp/signUp.context";

export default function SignUp(): React.ReactElement {
	return (
		<>
			<SignUpContextProvider>
				<FormularioSignUp />
			</SignUpContextProvider>
		</>
	);
}
