import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./styles.css";

export function ActivityViewerDialogFeedback({ analysis, data }) {
	const [feedbackData, setFeedbackData] = useState<any>({});

	useEffect(() => {
		if (
			data &&
			data.transcription &&
			data.transcription.length > 0 &&
			data.transcription[0].gpt_feedback
		) {
			var items = {};
			const temp = data.transcription[0].gpt_feedback.split("\n");
			temp.forEach((line) => {
				const [key, value] = line.split(": ");
				items[key] = isNaN(value) ? value : parseFloat(value);
			});
			setFeedbackData(items);
		}
	}, [data]);

	useEffect(() => {
		if (data && data.transcription && data.transcription.gpt_feedback) {
			// Extracting item-value pairs from the string
			const feedbackLines = data.transcription.gpt_feedback.split("\n");
			const extractedFeedbackData = {};
			feedbackLines.forEach((line) => {
				const [item, value] = line.split(": ");
				if (item && value) {
					extractedFeedbackData[item] = parseInt(value, 10);
				}
			});
			setFeedbackData(extractedFeedbackData);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Grid item md={6} sx={{ display: "none" }}>
				<Box mb={3}>
					<Typography variant="h5" sx={{ mb: 3 }}>
						Feedback
					</Typography>
					{/* <pre>{JSON.stringify(dd, null, 4)}</pre> */}
					{/* <Button
												variant="contained"
												disableElevation
												color="primary"
												onClick={() => {
													//TODO: Verificar retorno deste método para não sair da tela e do diálogo do usuário
													getAIEvaluation(data.id);
												}}
												startIcon={<Refresh />}
											>
												Feedback
											</Button> */}
					{analysis &&
						analysis.gpt_feedback === "" &&
						data &&
						data.transcription &&
						data.transcription.length === 0 && (
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									height: "200px",
								}}
							>
								<Typography>
									Em breve estará disponível
								</Typography>
							</Box>
						)}
					{/* <pre>{JSON.stringify(feedbackData, null, 4)}</pre> */}
					{/* <pre>
						{JSON.stringify(
							data.transcription[0].gpt_feedback,
							null,
							4
						)}
					</pre> */}
					Resumo da apresentação:
					{data && data.transcription ? (
						<div
							// style={{ color: "red" }}
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data.transcription &&
									data.transcription.length > 0 &&
									data.transcription[0].gpt_feedback
										? data.transcription[0].gpt_feedback.replace(
												/\n/g,
												"<br>"
										  )
										: "",
							}}
						/>
					) : analysis && analysis.gpt_feedback ? (
						<div
							dangerouslySetInnerHTML={{
								__html:
									analysis.gpt_feedback !== ""
										? analysis.gpt_feedback.replace(
												/\n/g,
												"<br>"
										  )
										: "",
							}}
						/>
					) : (
						<>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									height: "156px",
								}}
							>
								<Typography>
									Dado indisponível no momento
								</Typography>
							</Box>
						</>
					)}
				</Box>
			</Grid>
			<Grid item md={6}>
				<Box mb={3}>
					<Typography variant="h5" sx={{ mb: 3 }}>
						Feedback
					</Typography>
				</Box>
				{Object.keys(feedbackData).map((d, index) => {
					const isLastChild =
						index === Object.keys(feedbackData).length - 1;

					return (
						d !== "Média" &&
						d !== "" && (
							<Box
								key={index}
								sx={{
									mb: 2,
									pb: 1,
									pt: 1,
									flexDirection:
										isLastChild ||
										typeof feedbackData[d] === "string"
											? "column"
											: "row", // Change flex direction for last child
									display: "flex",
									gap: "12px",
									alignItems: "flex-start",
									justifyContent: "space-between",
								}}
							>
								<label
									className="progress-label"
									style={{
										minWidth: "50%",
									}}
								>
									{d}
								</label>
								<Box
									sx={{
										minWidth: "50%",
										display: "flex",
										gap: "12px",
										flexDirection:
											typeof feedbackData[d] === "string"
												? "column"
												: "row",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									{typeof feedbackData[d] === "number" && (
										<progress
											className="progress-bar"
											id="item"
											value={feedbackData[d]}
											max={100}
										>
											{d}
										</progress>
									)}
									<span>
										{feedbackData[d] &&
										typeof feedbackData[d] === "number"
											? `${feedbackData[d]}%`
											: // JSON.stringify(
											// 		Number.isNaN(
											// 			feedbackData[d]
											// 		)
											//   )
											Number.isNaN(feedbackData[d])
											? ""
											: feedbackData[d]}
									</span>
								</Box>
							</Box>
						)
					);
				})}
			</Grid>
		</>
	);
}
