import { CopyAll, Delete, MoreVert, PlayArrow } from "@mui/icons-material";
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { amber } from "@mui/material/colors";
import { AxiosResponse } from "axios";
import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import AuditionService from "../audition.service";
import ActivityViewerDialog from "../dialogs/ActivityViewerDialog";
import DeleteActivityDialog from "../dialogs/DeleteActivityDialog";
import { fileManagerApi } from "../filemanager";
import { useActivityTable } from "./context";

interface ActivityTableRowInterface {
	row?: any;
	props?: any;
}

export function ActivityTableRow({ row, props }: ActivityTableRowInterface) {
	const {
		submission,
		setSubmission,
		openDelete,
		setOpenDelete,
		deleteConfirmation,
		setDeleteConfirmation,
		getVideo,
		setData,
	} = useActivityTable();

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Adjust 'sm' if needed

	function truncateString(str: string, len?: number) {
		if (str === undefined || str === null) {
			return "⚠️ Informação não disponível";
		}

		if (str.length <= 12) {
			return str;
		} else {
			return str.substring(0, len ?? 12) + "...";
		}
	}

	const handleCurriculum = async (id, status) => {
		await new AuditionService(fileManagerApi)
			.publishCurriculum(id, status)
			.then(() => {
				toast.success("Vídeo currículo atualizado com sucesso!");
			})
			.catch((err) => {
				toast.success(
					"Falha ao atualizar vídeo currículo, tente novamente mais tarde."
				);
			})
			.finally(() => {
				props.setReloadSignal(true);
			});
	};

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Fragment>
			<TableRow
				key={row.id}
				sx={{
					"&:last-child td, &:last-child th": {
						border: 0,
					},
				}}
			>
				{!props.tutor && !fullScreen && (
					<TableCell component="th" scope="row">
						<Typography variant="body2" sx={{ minWidth: "32ch" }}>
							{" "}
							{row?.activity?.title ??
								"⚠️ Informação não disponível"}{" "}
						</Typography>
					</TableCell>
				)}
				{!props.tutor && !fullScreen && (
					<TableCell component="th" scope="row">
						<Tooltip
							title={
								<Typography variant="body2">
									{row.activity.description}
								</Typography>
							}
						>
							<Typography
								variant="body2"
								sx={{
									wordWrap: "break-word",
									cursor: "pointer",
									maxWidth: "50ch",
									maxHeight: "100px",
									overflowY: "scroll",
									p: 2,
								}}
							>
								{row?.activity?.description}
							</Typography>
						</Tooltip>
					</TableCell>
				)}
				{props.tutor && (
					<TableCell component="th" scope="row" align="left">
						<Button
							variant="text"
							size="small"
							startIcon={<CopyAll />}
							sx={{ minWidth: "100px" }}
							onClick={() => {
								navigator.clipboard.writeText(row.user_id);
								toast.success("RA copiado com sucesso! ", {
									position: "top-right",
								});
							}}
						>
							{row.user_id}
						</Button>
					</TableCell>
				)}
				<TableCell align="center">
					<IconButton
						sx={{
							bgcolor: amber[500],
						}}
						onClick={async () => {
							// await new AuditionService(fileManagerApi)
							// 	.checkFeedbackExists(row.id)
							// 	.then((res) => {
							// 		console.log(res.data);
							// 		alert(JSON.stringify(res.data));
							// 	});

							// alert(`code: ${row.code}, id: ${row.id}`);
							let key = `${process.env.REACT_APP_FILE_LOCATION}/videos/${row.code}.mp4`;
							await new AuditionService(fileManagerApi)
								.checkFileExists(key)
								.then((res: AxiosResponse<any>) => {
									if (res.status === 404) {
										toast.error(
											"Feedback indisponível no momento, por favor, aguarde"
										);
									} else {
										getVideo(row.file_url);
										setData(row);
									}
								})
								.catch((err) => {
									console.log(err);
									toast.error(
										"Feedback indisponível no momento, por favor, aguarde"
									);
								});
						}}
					>
						<PlayArrow />
					</IconButton>
				</TableCell>

				{!fullScreen && (
					<TableCell align="center">
						<Tooltip title={row.code}>
							<Button
								variant="text"
								size="small"
								startIcon={<CopyAll />}
								sx={{
									minWidth: "200px",
								}}
								onClick={() => {
									navigator.clipboard.writeText(row.code);
									toast.success(
										"Código copiado com sucesso! ",
										{
											position: "top-right",
										}
									);
								}}
							>
								{truncateString(row.code)}
							</Button>
						</Tooltip>
					</TableCell>
				)}
				{!fullScreen && (
					<TableCell align="center">
						<Tooltip title="Excluir apresentação">
							<IconButton
								size="small"
								onClick={async () => {
									setOpenDelete(true);
									setSubmission(row);
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</TableCell>
				)}
				{props.tabelaCurriculo && !fullScreen && (
					<TableCell align="center">
						<FormControl>
							<FormControlLabel
								control={
									<Checkbox
										checked={JSON.parse(row.published)}
										onChange={() => {
											handleCurriculum(
												row.id,
												row.published
											);
										}}
									/>
								}
								label="Publicar"
							/>
						</FormControl>
					</TableCell>
				)}
				<TableCell align="center">{row.created_at}</TableCell>
				<TableCell align="center">
					{Math.ceil(row.gpt_avg / 10)}
				</TableCell>
				{fullScreen && (
					<>
						<TableCell align="center">
							<IconButton
								id="basic-button"
								aria-controls={open ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								onClick={handleClick}
							>
								<MoreVert />
							</IconButton>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem>
									{/* <Button
										startIcon={<Publish />}
										onClick={() => {
											handleCurriculum(
												row.id,
												row.published
											);
										}}
									>
										Publicar
									</Button> */}
									<FormControl>
										<FormControlLabel
											control={
												<Checkbox
													checked={JSON.parse(
														row.published
													)}
													onChange={() => {
														handleCurriculum(
															row.id,
															row.published
														);
													}}
												/>
											}
											label="Publicar"
										/>
									</FormControl>
								</MenuItem>
								<MenuItem>
									<Button
										startIcon={<Delete />}
										onClick={async () => {
											setOpenDelete(true);
											setSubmission(row);
										}}
									>
										Excluir
									</Button>
								</MenuItem>
							</Menu>
						</TableCell>
					</>
				)}
			</TableRow>
			<DeleteActivityDialog
				submission={submission}
				openDelete={openDelete}
				setOpenDelete={setOpenDelete}
				setDeleteConfirmation={setDeleteConfirmation}
				deleteConfirmation={deleteConfirmation}
				setReloadSignal={props.setReloadSignal}
			/>
			<ActivityViewerDialog />
		</Fragment>
	);
}
