import { Box, Typography, makeStyles } from "@material-ui/core";
import { Button, Container, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import {
	AtualizarTipoContrato,
	PutTipoContrato,
} from "../../../../models/TipoContrato";
import {
	getTipoContrato,
	putTipoContrato,
} from "../../../../services/crud/crud.tiposContrato";
import Notify from "../../../../utils/notification";
import { useReloadContext } from "../../../Tabelas/hooks/useReload";
// import { FormularioAlteracaoTipoContrato } from "../../../../models/FormulariosAlteracao";
// import { isEmpty } from "../../../../utils/funcoes-globais";

function FormularioAlteracaoTiposContratoView(): React.ReactElement {
	let idTipoContrato = Number(localStorage.getItem("id_tipocontrato"));
	const {
		handleSubmit,
		reset,
		control,
		getValues,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });
	const [formValues, setFormValues] = useState({} as PutTipoContrato);
	// const [formValues, setFormValues] = useState({} as FormularioAlteracaoTipoContrato);
	const { handleClose } = useModalContext();
	const { handleReload } = useReloadContext();
	const useStyles = makeStyles((theme) => ({
		textField: {
			"& label": {
				color: "grey !important",
			},
			"& label.Mui-focused": {
				color: "black !important",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				// '&:hover fieldset': {
				//   borderColor: 'blue',
				// },
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));
	const classes = useStyles();

	useEffect(() => {
		if (idTipoContrato)
			getTipoContrato(idTipoContrato)
				.then((data) => {
					setFormValues({
						name: data.name,
					});
					reset({
						name: data.name,
					});
				})
				.catch((e) => {
					console.log(e);
				});
	}, [idTipoContrato, reset]);

	// function handleInputChange(event) {
	//   const { name, value } = event.target;
	//   setFormValues({ contract: { ...formValues.contract, [`${name}`]: value } });
	// }

	async function atualizacaoTipoContrato(data: AtualizarTipoContrato, event) {
		let arrayDados = Object.entries(data);
		let arrayFormValues = Object.entries(formValues);
		let dataContrato = arrayDados.filter((value, index) => {
			return value[1] !== arrayFormValues[index][1];
		});

		if (dataContrato.length > 0) {
			putTipoContrato(idTipoContrato, data)
				.then(() => {
					handleClose();
					handleReload();
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			event.preventDefault();
			Notify(
				"Atualize a informação para efetuar a operação de atualização!",
				"warning"
			);
		}
	}

	return (
		<Container
			// className="container"
			component="section"
			// style={{ width: "100%", height: "100%" }}
			data-testid="formulario-alteracao-tipos-contrato"
		>
			<Typography variant="h5" component={"h5"}>
				Editar Contrato
			</Typography>
			<form
				onSubmit={handleSubmit((data: AtualizarTipoContrato, event) =>
					atualizacaoTipoContrato(data, event)
				)}
				style={{ width: "100%", height: "100%" }}
			>
				<div>
					<Controller
						name="name"
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange } }) => (
							<TextField
								className={classes.textField}
								label="Nome do Tipo de Contrato*"
								type="text"
								fullWidth
								margin="normal"
								onChange={(e) => {
									onChange(e);
								}}
								value={getValues("name") ? value : ""}
								error={errors.name?.type === "required"}
								helperText={
									errors.name?.type === "required" && (
										<span>
											O campo "Nome do Tipo de Contrato" é
											obrigatório!
										</span>
									)
								}
							/>
						)}
					/>
				</div>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
					}}
				>
					<Button variant="text" onClick={handleClose}>
						Cancelar
					</Button>
					<Button variant="contained" disableElevation type="submit">
						Atualizar
					</Button>
				</Box>
			</form>
		</Container>
	);
}

export default FormularioAlteracaoTiposContratoView;
