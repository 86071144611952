import React, { createContext } from "react";
import { PropsReloadContext } from "./models/PropsReloadContext";

const DEFAULT_VALUE: PropsReloadContext = {
    reload: true, 
    setReload: () => {},
    handleReload: () => {}
}

export const ReloadContext = createContext<PropsReloadContext>(DEFAULT_VALUE);

export const ReloadContextProvider: React.FC = ({ children }) => {
    const [reload, setReload] = React.useState(false);

    function handleReload() {
        setReload(!reload);
    }

    return (
        <ReloadContext.Provider value={{
            reload,
            setReload,
            handleReload
        }}>
            {children}
        </ReloadContext.Provider>

    );
}