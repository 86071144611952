import { makeStyles } from "@material-ui/core";
import { Business, ManageSearch } from "@mui/icons-material";
import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../../context/Authentication/hooks/useAuth";
import { TabelaEmpresas } from "../../../models/FormulariosAlteracao";
import { Meta } from "../../../models/Meta";
import { Usuario } from "../../../models/Usuario";
import {
	getEmpresas,
	getEmpresasRepresentante,
	getEmpresasUsuario,
} from "../../../services/crud/crud.empresa";
import {
	getAllUsuarios,
	getUsuario,
} from "../../../services/crud/crud.usuario";
import maskCnpj from "../../../utils/mask";
import Notify from "../../../utils/notification";
import ModalAlteracoesEmpresa from "../../Modal/ModalAlteracao/ModalAlteracaoEmpresa";
import ModalCadastroEmpresa from "../../Modal/ModalCadastro/ModalCadastroEmpresa";
import ModalDeleteEmpresa from "../../Modal/ModalDelete/ModalDeleteEmpresa";
import ModalDetalhesEmpresa from "../../Modal/ModalDetalhes/ModalDetalhesEmpresa";
import { useReloadContext } from "../hooks/useReload";
import "../style.scss";

function TabelaEmpresasView() {
	const useStyles = makeStyles((theme) => ({
		select: {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey ",
			},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: "grey !important",
			},
			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: "black !important",
			},
		},
		inputLabel: {
			// color: "red",
			"&.Mui-focused": {
				color: "black !important",
			},
		},
	}));
	const classes = useStyles();

	const navigate = useNavigate();
	const { auth } = useAuthContext();
	const { reload, setReload } = useReloadContext();

	const [paginacao, setPaginacao] = useState({} as Meta);
	const [selectValue, setSelectValue] = useState("");

	// const [tableRowsInitial, setTableRowsInitial] = useState({} as TabelaEmpresas);
	const [dadosEmpresa, setDadosEmpresa] = useState({} as TabelaEmpresas);
	const [representantes, setRepresentantes] = useState<Array<Usuario>>([]);
	const [representante, setRepresentante] = useState({} as Usuario);

	/**
	 * @function handleIsRepresentante
	 * Recebe um objeto do tipo Usuario e verifica se o role_id é igual a 2.
	 * @param {Usuario} usuario - Representa um usuario.
	 * @returns {boolean} - Verifica se o usuário é um representante e retorna true ou false.
	 */
	function handleIsRepresentante(usuario: Usuario): boolean {
		return usuario.role_id === 2;
	}

	/**
	 * @function handleEmpresa
	 * Verifica se o usuário não tem empresas cadastradas, caso sim exibe um Notify, senão salva uma chave no sessionStorage de nome id_usuario_filter cujo o valor será o id do usuário selecionado.
	 * @param {number} id - Id do usuário selecionado.
	 * @param {number} index - Representa uma posição no array de usuários.
	 */
	function handleEmpresa(id: number, index: number) {
		// Check if dadosEmpresa is defined and has the property empresas
		if (dadosEmpresa && dadosEmpresa.empresas) {
			// Check if empresas[index] is defined
			if (dadosEmpresa.empresas[index]) {
				// Check if jobs is defined on empresas[index]
				if (
					dadosEmpresa.empresas[index].jobs &&
					dadosEmpresa.empresas[index].jobs.length > 0
				) {
					localStorage.setItem("id_empresa_filter", id.toString());
					navigate(`/vagas`);
				} else {
					Notify(
						"Essa empresa não tem vagas cadastradas!",
						"warning"
					);
				}
			} else {
				// Handle the case where empresas[index] is undefined
				console.error("Empresas at index", index, "is undefined");
			}
		} else {
			// Handle the case where dadosEmpresa or dadosEmpresa.empresas is undefined
			console.error("dadosEmpresa or dadosEmpresa.empresas is undefined");
		}
	}

	/**
	 * @function handleChangePage
	 * Faz uma requisição para a api e traz as informações da paginação e o array de tipos de contrato.
	 * @param {object} event - Representa o elemento que sofreu o evento.
	 * @param {number} page - Representa a página atual.
	 */
	function handleChangePage(event: unknown, page: number) {
		if (auth.role === "admin") {
			if (selectValue !== " ") {
				let idUsuario = selectValue;

				getEmpresasUsuario(`${page}`, Number(idUsuario)).then(
					(data) => {
						setPaginacao(data.meta);
						setSelectValue(idUsuario);
						setDadosEmpresa({ empresas: data.data });
					}
				);
			} else {
				getEmpresas(`${page}`).then((data) => {
					setPaginacao(data.meta);
					setDadosEmpresa({ empresas: data.data });
				});
			}
		} else {
			getEmpresasRepresentante(`${page}`)
				.then((data) => {
					setPaginacao(data.meta);
					setDadosEmpresa({ empresas: data.data });
					// setTableRowsInitial({ empresas: data.data });
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}

	/**
	 * @function filterUser
	 * Verifica se o valor do evento for diferente de vazio, caso sim atribui à constante results o array empresas atualizado com as empresas do usuário selecionado e em seguida atualiza o tableRows com o valor do results e o selecValue para o valor do evento, caso contrario seta o tableRows para o valor inicial e o selectValue para o valor do evento.
	 * @param event - Representa o elemento que sofreu o evento.
	 */
	function filterUser(event) {
		if (event.target.value !== "") {
			getEmpresasUsuario(null, event.target.value).then((data) => {
				// setTableRowsInitial({ empresas: data.data });
				setPaginacao(data.meta);
				setDadosEmpresa({ empresas: data.data });
			});

			// const results = tableRowsInitial.empresas.filter((empresa) => {
			//   return empresa.user_id === event.target.value;
			// });

			setSelectValue(event.target.value);
		} else {
			setReload(!reload);
			// setSelectValue(event.target.value);
			// setDadosEmpresa(tableRowsInitial);
		}
	}

	/**
	 * @function exibirFiltroUsuario
	 * Verifica se o usuário logado é um admin, se sim retorna um componente select.
	 * @returns {JSX.Element} - Retorna um select que filtra as empresas por representates.
	 */
	function exibirFiltroUsuario(): JSX.Element {
		if (auth.role === "admin") {
			return (
				<FormControl
					size="small"
					style={{ marginRight: "10px", marginLeft: "15px" }}
					fullWidth
				>
					<InputLabel className={classes.inputLabel}>
						Filtro de empresa por representantes
					</InputLabel>
					<Select
						className={classes.select}
						fullWidth
						style={{ height: "42.5px" }}
						label="Filtro de empresa por representantes"
						onChange={filterUser}
						value={selectValue}
					>
						<MenuItem value="">Todas as empresas</MenuItem>
						{representantes.map((representante, index) => (
							<MenuItem
								value={representante.id}
								key={index}
							>{`${representante.name} | ${representante.email}`}</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		} else {
			return <></>;
		}
	}

	useEffect(() => {
		if (auth.role === "admin") {
			getEmpresas(null)
				.then((data) => {
					// setTableRowsInitial({ empresas: data.data });
				})
				.catch((e) => {
					console.log(e);
				});

			getAllUsuarios()
				.then((data) => {
					setRepresentantes(data.filter(handleIsRepresentante));
				})
				.catch((e) => {
					console.log(e);
				});

			if (localStorage.getItem("id_usuario_filter")) {
				let idUsuario = localStorage.getItem(
					"id_usuario_filter"
				) as string;

				// alert("AQUI: " + idUsuario);
				getEmpresasUsuario(null, Number(idUsuario)).then((data) => {
					setPaginacao(data.meta);
					setSelectValue(idUsuario);
					setDadosEmpresa({ empresas: data.data });
					localStorage.removeItem("id_usuario_filter");
				});
			} else {
				getEmpresas(null)
					.then((data) => {
						setPaginacao(data.meta);
						setDadosEmpresa({ empresas: data.data });
						setSelectValue(" ");
					})
					.catch((e) => {
						console.log(e);
					});
			}
		} else {
			getEmpresasRepresentante(null)
				.then((data) => {
					setPaginacao(data.meta);
					setDadosEmpresa({ empresas: data.data });
					// setTableRowsInitial({ empresas: data.data });
				})
				.catch((e) => {
					console.log(e);
				});

			getUsuario(auth.user_id)
				.then((data) => {
					setRepresentante(data);
				})
				.catch((e) => {
					console.log(e);
				});
		}

		setReload(true);
	}, [reload, setReload, auth.role, auth.user_id]);

	return (
		<>
			<div className="boxTabelaUser" data-testid="tabela-empresas">
				<Paper
					className="divPaper"
					sx={{
						mt: 3,
						// border: "solid thin #ddd",
					}}
					elevation={1}
				>
					<Box
						p={3}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Box>
							<Typography variant="h5">
								Empresas cadastradas
							</Typography>
							<Typography variant="body2">
								Listando todas as empresas cadastrados no
								sistema
							</Typography>
						</Box>
						<ModalCadastroEmpresa
							isActive={representante.is_active}
						/>
					</Box>
					<Box className="boxModalCadastro">
						<div
							style={{
								display: "flex",
								height: "80% !important",
								margin: "10px 0px 10px 0px",
							}}
						>
							{/* <ModalMenuNavegacao /> */}
							{exibirFiltroUsuario()}
						</div>
					</Box>
					<TableContainer className="tableContainer">
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow hover role="checkbox" tabIndex={-1}>
									<TableCell align="left">Nome</TableCell>
									<TableCell align="center">CNPJ</TableCell>
									<TableCell align="center">Vagas</TableCell>
									<TableCell align="center">Ações</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{dadosEmpresa.empresas?.map(
									(empresa, index) => (
										<TableRow key={index}>
											<TableCell align="left">
												<Box
													sx={{
														display: "flex",
														alignItems: "center",
														gap: "12px",
													}}
												>
													<Business /> {empresa.name}
												</Box>
											</TableCell>
											<TableCell align="center">
												{empresa.cnpj
													? maskCnpj(empresa.cnpj)
													: empresa.numero_registro ??
													  "Dado indisponível"}
											</TableCell>
											<TableCell align="center">
												<Button
													onClick={() => {
														// alert("EMPRESA ID: " + empresa.id);
														// alert("INDEX: " + JSON.stringify(index));
														handleEmpresa(
															empresa.id,
															index
														);
													}}
													title="Vagas da empresa"
													endIcon={<ManageSearch />}
												>
													{empresa.jobs
														? empresa.jobs.length
														: 0}
												</Button>
											</TableCell>

											<TableCell align="center">
												<ModalAlteracoesEmpresa
													isActive={
														representante.is_active
													}
													companieId={empresa.id}
												/>
												<ModalDetalhesEmpresa
													companieId={empresa.id}
												/>
												<ModalDeleteEmpresa
													companieId={empresa.id}
													isActive={
														representante.is_active
													}
													dados={{
														dadosEmpresa:
															dadosEmpresa,
														setDadosEmpresa:
															setDadosEmpresa,
														selectValue:
															selectValue,
														setSelectValue:
															setSelectValue,
													}}
												/>
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
						</Table>
					</TableContainer>

					<Stack alignItems="center" spacing={2} sx={{ p: 1 }}>
						<Pagination
							count={
								typeof paginacao === "undefined"
									? 1
									: paginacao.last_page
							}
							onChange={handleChangePage}
						/>
					</Stack>
				</Paper>
				{/* <MenuNavegacao /> */}
			</div>
		</>
	);
}

export default TabelaEmpresasView;
