import { AreaConhecimento, RespostaAreaConhecimento } from "../../models/AreaConhecimento";
import api from "../api";

/**
 * @function getAreasConhecimento
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /knowledge-areas, na api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaAreaConhecimento> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com todas as áreas de conhecimento disponíveis.
 */

export async function getAreasConhecimento(): Promise<RespostaAreaConhecimento> {

    let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

    return await api.get("/knowledge-areas", { headers: { "Authorization": `Bearer ${token}` } }).then(({ data }) => {
        return data;
    }).catch((e: any) => {
        return e;
    });
}

/**
 * @function getAreaConhecimento
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /knowledge-areas/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<AreaConhecimento> contendo os dados da área de conhecimento associada ao id.
 */

export async function getAreaConhecimento(id: number): Promise<AreaConhecimento> {

    let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

    return await api.get(`/knowledge-areas/${id}`, { headers: { "Authorization": `Bearer ${token}` } }).then(({ data }) => {
        return data;
    }).catch((e: any) => {
        return e;
    });
}
