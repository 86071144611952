import { Box, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { Meta } from "../../../models/Meta";
import { TabelaTiposContratos } from "../../../models/Tabelas";
import { getTiposContratos } from "../../../services/crud/crud.tiposContrato";
import ModalAlteracaoTiposContrato from "../../Modal/ModalAlteracao/ModalAlteracaoTiposContrato";
import ModalCadastroTiposContrato from "../../Modal/ModalCadastro/ModalCadastroTiposContrato";
import ModalDeleteTipoContrato from "../../Modal/ModalDelete/ModalDeleteTipoContrato";
import ModalDetalhesTipoContrato from "../../Modal/ModalDetalhes/ModalDetalhesTipoContrato";
import { useReloadContext } from "../hooks/useReload";
import "../style.scss";

function TabelaTiposContratoView() {
	const [tableRows, setTableRows] = useState({} as TabelaTiposContratos);
	const [paginacao, setPaginacao] = useState({} as Meta);
	const { reload, setReload } = useReloadContext();

	/**
	 * @function handleChangePage
	 * Faz uma requisição para a api e traz as informações da paginação e o array de tipos de contrato.
	 * @param {object} event - Representa o elemento que sofreu o evento.
	 * @param {number} page - Representa a página atual
	 */
	function handleChangePage(event: object, page: number) {
		getTiposContratos(`?page=${page}`).then((data) => {
			setPaginacao(data.meta);
			setTableRows({ tiposContratos: data.data });
		});
	}

	useEffect(() => {
		getTiposContratos(null)
			.then((data) => {
				setPaginacao(data.meta);
				setTableRows({ tiposContratos: data.data });
			})
			.catch((e) => {
				console.log(e);
			});

		setReload(true);
	}, [reload, setReload]);

	return (
		<div className="boxTabelaUser" data-testid="tabela-tipos-contrato">
			<Paper
				className="divPaper"
				sx={{
					mt: 3,
					// border: "solid thin #ddd",
				}}
			>
				<Box
					p={3}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<div style={{ flexGrow: 1 }}>
						<Box>
							<Typography variant="h5">Contratos</Typography>
							<Typography variant="body2">
								Visualizando tipos de contrato cadastrados no
								sistema
							</Typography>
						</Box>
					</div>
					<div>
						<ModalCadastroTiposContrato />
					</div>
				</Box>
				<TableContainer className="tableContainer">
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow hover role="checkbox" tabIndex={-1}>
								<TableCell align="center">
									Tipos de Contrato
								</TableCell>
								<TableCell align="center">Ações</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableRows.tiposContratos?.map(
								(tipoContrato, index) => (
									<TableRow key={index}>
										<TableCell align="center">
											{tipoContrato.name}
										</TableCell>

										<TableCell align="center">
											<ModalAlteracaoTiposContrato
												tipoContratoId={tipoContrato.id}
											/>
											<ModalDetalhesTipoContrato
												tipoContratoId={tipoContrato.id}
											/>

											<ModalDeleteTipoContrato
												tipoContratoId={tipoContrato.id}
												dados={{
													tableRows: tableRows,
													setTableRows: setTableRows,
												}}
											/>

											{/* <Button onClick={() => { handleDeletarTipoContrato(tipoContrato.id) }} title={`${tipoContrato.id}`}>
                      <DeleteForeverIcon style={{ color: "#292B2D" }} />
                    </Button> */}
										</TableCell>
									</TableRow>
								)
							)}
						</TableBody>
					</Table>
				</TableContainer>

				<Stack alignItems="center" spacing={2} sx={{ p: 1 }}>
					<Pagination
						count={
							typeof paginacao === "undefined"
								? 1
								: paginacao.last_page
						}
						onChange={handleChangePage}
					/>
				</Stack>
			</Paper>

			{/* <MenuNavegacao /> */}
		</div>
	);
}

export default TabelaTiposContratoView;
