import axios from "axios";

export const fileManagerApi = axios.create({
    baseURL: process.env.REACT_APP_FILE_MANAGER_API
    // baseURL: 'http://localhost:8000/api/v2 -> API Local Laravel',
    // baseURL: 'http://localhost:3030/v2'
})

fileManagerApi.interceptors.request.use((config) => {
    let user: any =  localStorage.getItem("@palco-profissional:state");
    let token: any = localStorage.getItem("@palco-profissional:state");

    if (token && config.headers) {
        user = JSON.parse(user).usuario.id;
        token = JSON.parse(token).accessToken;

        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["ra"] = user;
    }

    return config;
});

// fileManagerApi.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         // Check if error.response exists before attempting to destructure
//         const status = error.response ? error.response.status : null;

//         if (status === 401 || status === 403) {
//             // Clear user session and storage
//             localStorage.removeItem("@application");
//             localStorage.removeItem("token");

//             // Redirect to '/'
//             window.location.href = '/';
//         }

//         return Promise.reject(error);
//     }
// );
