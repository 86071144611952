import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalDetalhesVagaView from "./ModalDetalheVaga.view";

function ModalDetalhesVaga(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalDetalhesVagaView vagaId={props.vagaId} />
		</ModalContextProvider>
	);
}

export default ModalDetalhesVaga;
