import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Input,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuthContext } from "../../../../../context/Authentication/hooks/useAuth";
import Notification from "../../../../../utils/notification";
import { useMeuCurriculo } from "../../context";

// Define a function to handle file selection
const handleFileSelect = (e, onChange) => {
	const selectedFile = e.target.files[0];
	onChange(selectedFile);
};

type PropType = {
	evento: any;
	setOpen: Dispatch<SetStateAction<boolean>>;
};

/**
 *
 * @param param0
 * @returns Formulário para cadastrar nova participação em evento
 */
export default function EventoForm({ evento, setOpen }: PropType) {
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();
	// const { state } = usePalcoProfissional();
	const { auth } = useAuthContext();
	const { setSignal } = useMeuCurriculo();

	const onSubmitEvents = async (data) => {
		const token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;

		try {
			const formData = new FormData();
			// Append other form fields
			for (const key in data) {
				if (key !== "certificate") {
					formData.append(key, data[key]);
				}
			}

			// Append profile picture only if it's not provided through perfil.profile_picture
			if (selectedFile) {
				formData.append("certificate", selectedFile);
			}

			const url = evento
				? `${process.env.REACT_APP_URL}/events/${evento.id}`
				: `${process.env.REACT_APP_URL}/events`;
			const method = evento ? "put" : "post";

			const response = await axios[method](url, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "multipart/form-data",
				},
				onUploadProgress: (progressEvent: any) => {
					const progress = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					console.log(`Upload Progress: ${progress}%`);
				},
			});

			console.log(response);

			Notification("Successo", "success");
		} catch (error) {
			Notification("Falha", "error");
			// console.error("Error:", error);
		} finally {
			setOpen(false);
			// alert(typeof setOpen);
			// setOpen(false);
		}
		setSignal(true);
	};

	const [tiposDeEvento, setTiposDeEvento] = useState<any | any[]>([]);
	const getTiposDeEvento = async () => {
		const token = JSON.parse(
			localStorage.getItem("@app-jobs:token") as string
		).token;
		const apiUrl = `${process.env.REACT_APP_URL}/event-type`;

		axios
			.get(`${apiUrl}`, {
				headers: {
					Authorization: "Bearer " + token,
				},
			})
			.then((res) => {
				setTiposDeEvento(res.data);
			});
	};

	useEffect(() => {
		getTiposDeEvento();
	}, []);

	// Set default values when experience is provided
	useEffect(() => {
		if (evento) {
			Object.keys(evento).forEach((key) => {
				if (key === "started" || key === "finished") {
					setValue(key, evento[key].split("T")[0]);
				} else {
					setValue(key, evento[key]);
				}
				// setValue(key, evento[key]);
			});
		}
	}, [evento, setValue]);

	const [selectedFile, setSelectedFile] = useState<any>(null);

	return (
		<>
			<Paper>
				<Box p={2}>
					<Typography variant="h5" mb={2}>
						Adicionar participação em eventos
					</Typography>
					{/* <pre>{JSON.stringify(evento, null, 4)}</pre> */}
					<form
						onSubmit={handleSubmit(onSubmitEvents)}
						style={{
							display: "flex",
							gap: "12px",
							flexWrap: "wrap",
							flexGrow: "1",
						}}
					>
						<FormControl
							sx={{
								width: "50%",
							}}
							error={Boolean(errors.id_event_type)}
						>
							<InputLabel>Tipo de evento</InputLabel>
							<Controller
								name="id_event_type"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<>
										<Select
											{...field}
											label={"Tipo de evento"}
											defaultValue={""}
											required
										>
											{tiposDeEvento &&
												tiposDeEvento.map((item) => {
													return (
														<MenuItem
															value={item.id}
														>
															{item.name}
														</MenuItem>
													);
												})}
										</Select>
										{errors.id_event_type && ( // Display error message if there's an error for id_event_type
											<FormHelperText>
												Error message for id_event_type
											</FormHelperText>
										)}
									</>
								)}
							/>
						</FormControl>

						<FormControl fullWidth>
							<Controller
								name="title"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<TextField
										required
										label="Nome da atividade realizada"
										{...field}
									/>
								)}
							/>
						</FormControl>

						<FormControl fullWidth>
							<Controller
								name="sponsor"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<FormControl fullWidth>
										<TextField
											required
											label="Realização"
											{...field}
										/>
									</FormControl>
								)}
							/>
						</FormControl>

						<Controller
							name="started"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									InputLabelProps={{
										shrink: !!field.value || !!field.ref,
									}}
									label="Data de início"
									type="date"
									{...field}
									required
								/>
							)}
						/>

						<Controller
							name="finished"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<TextField
									InputLabelProps={{
										shrink: !!field.value || !!field.ref,
									}}
									label="Data de conclusão"
									type="date"
									{...field}
									required
								/>
							)}
						/>

						<FormControl
							fullWidth
							sx={{
								mb: 2,
							}}
						>
							<Typography
								variant="body1"
								sx={{ fontWeight: "500", mb: 1, mt: 1 }}
							>
								Envio do certificado
							</Typography>
							<Controller
								name="certificate"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<div
										style={{
											backgroundColor: "#f8f8f8",
											borderRadius: "12px",
											padding: "12px",
											position: "relative",
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "12px",
										}}
									>
										<Input
											id="certificate"
											type="file"
											sx={{
												display: "none",
											}}
											onChange={(e: any) => {
												const selectedFile =
													e.target.files[0];
												field.onChange(selectedFile);
												handleFileSelect(
													e,
													setSelectedFile
												);
											}}
										/>
										<label htmlFor="certificate">
											<Button
												variant="contained"
												component="span"
												disableElevation
											>
												Escolher arquivo
											</Button>
										</label>
										{selectedFile
											? selectedFile.name
											: "Nenhum arquivo selecionado"}
									</div>
								)}
							/>
						</FormControl>

						<Controller
							name="student_id"
							control={control}
							defaultValue={auth.student_id || ""}
							render={({ field }) => (
								<TextField
									disabled
									label="Cód. do usuário"
									type="number"
									{...field}
									sx={{
										visibility: "hidden",
										display: "none",
									}}
								/>
							)}
						/>

						<Box
							sx={{
								width: "100%",
								display: "flex",
								gap: "8px",
								justifyContent: "flex-end",
								alignItems: "center",
							}}
						>
							<Button
								variant="text"
								onClick={() => {
									setOpen(false);
								}}
							>
								Cancelar
							</Button>
							<Button
								size="large"
								type="submit"
								variant="contained"
								color="primary"
								disableElevation
							>
								Salvar
							</Button>
						</Box>
					</form>
				</Box>
			</Paper>
		</>
	);
}
