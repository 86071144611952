import {
  AtualizarEmpresa,
  RespostaEmpresa,
  RespostaEmpresaId,
} from "../../models/Empresa";
import Notify from "../../utils/notification";
import api from "../api";

/**
 * @function postEmresa
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo post para a rota /companies, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaEmpresa> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com a empresa cadastrada.
 * @param {CriarEmpresa} data - Dados da empresa a ser cadastrada.
 */

export async function postEmpresa(data: any): Promise<RespostaEmpresa> {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api
    .post("/companies", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    // .then((data) => {
    //   Notify("Empresa cadastrada com sucesso!");
    //   return data;
    // })
    // .catch((e: any) => {
    //   Notify(
    //     "Empresa não foi cadastrada, verifique seus dados e tente novamente!",
    //     "error",
    //   );
    //   return e;
    // });
}

/**
 * @function getEmpresa
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /companies/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaEmpresaId> contendo os dados duma empresa específica.
 * @param {number} id - Id da empresa a ser buscada.
 */

export async function getEmpresa(id: number): Promise<RespostaEmpresaId | any> {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  if (id === null || id === 0) {
    return Promise.resolve("ID is null  or 0, cancelling the call");
  } else {
    console.log("ID AQUI é :" + id);
    return await api
      .get(`/companies/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e: any) => {
        return e;
      });
  }
}

/**
 * @function getEmpresaRepresentante
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /companies/user/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaEmpresaId> contendo os dados de uma empresa específica do usuário representante logado no sistema.
 */

export async function getEmpresaRepresentante(
  id: number,
): Promise<RespostaEmpresaId | any> {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  if (id === null || id === 0) {
    return Promise.resolve("ID is null  or 0, cancelling the call");
  }

  return await api
    .get(`/companies/user/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((e: any) => {
      return e;
    });
}

/**
 * @function getEmpresas
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /companies/:pagina, caso o parâmetro página seja nulo, caso não, a requisição do tipo get é feita para a rota /companies, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaEmpresa> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com todas as empresas disponíveis na base de dados.
 * @param {string | null} pagina - Número da página escolhida para a paginação
 */

export async function getEmpresas(
  pagina: string | null,
): Promise<RespostaEmpresa> {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api
    .get(`/companies?page=${pagina === null ? "1" : pagina}&limit=10`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((e: any) => {
      return e;
    });
}

export async function getAllEmpresas() {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api
    .get("/companies", { headers: { Authorization: `Bearer ${token}` } })
    .then(({ data }) => {
      return data;
    })
    .catch((e: any) => {
      return e;
    });
}

/**
 * @function getEmpresasUsuario
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /companies/list/user/:idUsuario, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaEmpresa> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com todas as empresas do usuário associado ao idUsuario.
 * @param {number} idUsuario - Id do usuário dono das empresas que serão buscadas.
 */

export async function getEmpresasUsuario(
  pagina: string | null,
  idUsuario: number,
): Promise<RespostaEmpresa> {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api
    .get(
      `/companies/list/user/${idUsuario}?page=${pagina === null ? "1" : pagina}`,
      { headers: { Authorization: `Bearer ${token}` } },
    )
    .then(({ data }) => {
      return data;
    })
    .catch((e: any) => {
      return e;
    });
}

/**
 * @function getEmpresasRepresentante
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo get para a rota /companies/user, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso é retornado um objeto do tipo Promise<RespostaEmpresa> contendo duas propriedades meta e data. Na propriedade meta temos um objeto com os dados da paginação e na propriedade data temos um array com todas as empresas do usuário logado no sistema.
 */

export async function getEmpresasRepresentante(
  pagina: string | null,
): Promise<RespostaEmpresa> {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api
    .get(`/companies/user?page=${pagina === null ? "1" : pagina}&limit=10`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((e: any) => {
      return e;
    });
}

export async function getAllEmpresasRepresentante() {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api
    .get(`/companies/user`, { headers: { Authorization: `Bearer ${token}` } })
    .then(({ data }) => {
      return data;
    })
    .catch((e: any) => {
      return e;
    });
}

/**
 * @function putEmpresa
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo put para a rota /companies/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso nada é retornado pela api.
 * @param {number} id - Id da empresa a ser atualizada.
 * @param {AtualizarEmpresa} data - Dados da empresa a ser atualizada.
 */

export async function putEmpresa(id: number, data: AtualizarEmpresa | any) {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api
    .put(`/companies/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      Notify("Empresa alterada com sucesso!");
      return;
    })
    .catch((e) => {
      Notify(
        "Empresa não foi alterada, verifique seus dados e tente novamente!",
        "error",
      );
      return e;
    });
}

/**
 * @function deleteEmpresa
 * Converte o valor da chave do sessionStorage de nome 'token' para JSON, acessa a propriedade token e salva o valor dela na variável de nome token. Em seguida é feita uma requisição do tipo delete para a rota /companies/:id, da api, passando no headers o valor da variável token para que a requisição seja autorizada pela api. No caso de sucesso nada é retornado.
 * @param {number} id - Id da empresa a ser deletada.
 */

export async function deleteEmpresa(id: number) {
  let token = JSON.parse(localStorage.getItem("@app-jobs:token") as string).token;

  return await api
    .delete(`/companies/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      Notify("Empresa deletada com sucesso!");
      return;
    })
    .catch((e) => {
      Notify(
        "Empresa não foi deleta, verifique seus dados e tente novamente!",
        "error",
      );
      return e;
    });
}
