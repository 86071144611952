import React from "react";
import { ReloadContextProvider } from "../reload.context";
import TabelaTiposContratoView from "./TabelaTiposContrato.view";

function TabelaTiposContrato(): React.ReactElement {
  return (
    <ReloadContextProvider>
      <TabelaTiposContratoView />
    </ReloadContextProvider>
  );
}

export default TabelaTiposContrato;