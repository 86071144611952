import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { usePalcoProfissional } from "../../../context/PalcoProfissional";
import { usePalcoProfissionalRootContext } from "../../../context/PalcoProfissionalPage";
import { Banner } from "./banner";
import { styles } from "./styles";
import { default as ActivityTableRoot } from "./tables/ActivityTableRoot";

export default function PalcoProfissional() {
	const { state } = usePalcoProfissional();
	const { activityGroup, setReloadSignal } =
		usePalcoProfissionalRootContext();

	const theme = useTheme();
	return (
		<Container maxWidth="xl" style={styles.compound}>
			<Grid container spacing={3}>
				{/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
				<Banner usuario={state.profile} />

				<Grid
					item
					md={12}
					sm={12}
					xs={12}
					lg={12}
					xl={12}
					mt={3}
					mb={3}
				>
					{/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
					{/* <ActivityTable auditions={auditions} /> */}
					<ActivityTableRoot
						auditions={activityGroup.curriculo}
						setReloadSignal={setReloadSignal}
						tabelaCurriculo={true}
					>
						<Box
							mb={3}
							mt={5}
							sx={{
								background: theme.palette.primary.main,
								borderRadius: "4px",
							}}
						>
							<Typography
								variant="h5"
								component="div"
								sx={{
									p: 1,
									background: theme.palette.primary.main,
									color: "white",
									borderRadius: "4px",
								}}
							>
								Meus Videocurrículos
							</Typography>
						</Box>
					</ActivityTableRoot>

					{/* <ActivityTable auditions={auditions} /> */}

					<ActivityTableRoot
						auditions={activityGroup.entrevistas}
						setReloadSignal={setReloadSignal}
						tabelaCurriculo={false}
					>
						<Box
							mb={3}
							mt={5}
							sx={{
								background: theme.palette.primary.main,
								borderRadius: "4px",
							}}
						>
							<Typography
								variant="h5"
								component="div"
								sx={{
									p: 1,
									background: theme.palette.primary.main,
									color: "white",
									borderRadius: "4px",
								}}
							>
								Minhas Entrevistas Simuladas
							</Typography>
						</Box>
					</ActivityTableRoot>
				</Grid>
			</Grid>
		</Container>
	);
}
