import {
	Add,
	Business,
	Close,
	PersonAdd,
	PlaylistRemove,
} from "@mui/icons-material";
import {
	Box,
	Dialog,
	Grid,
	IconButton,
	Paper,
	Typography,
	useTheme,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useAuthContext } from "../../../../../context/Authentication/hooks/useAuth";
import { useMeuCurriculo } from "../../context";
import { useProfile } from "../../hooks/Profile/useProfile";
import PerfilForm from "../Perfil/PerfilForm";
import ExperienciaProfissional from "./ExperienciaProfissional";
import ExperienciaProfissionalForm from "./ExperienciaProfissionalForm";

/**
 *
 * @returns Tela de experiencias profissionais do curriculo
 */
export default function ExperienciasProfissionais() {
	const theme = useTheme();
	// const history = useNavigate();
	const { auth } = useAuthContext();
	// const { state } = usePalcoProfissional();
	const { loadProfile } = useProfile();
	const [reload, setReload] = useState<boolean>(false);
	const { professionalExperiences } = useMeuCurriculo();

	/**
	 * Controlador do formulário de experiencia profissional
	 */
	const [open, setOpen] = useState<boolean>(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	/**
	 * Controlador do formulário de perfil do usuário
	 */
	const [openProfileEditor, setOpenProfileEditor] = useState<boolean>(false);
	// const handleOpenProfileEditor = () => setOpenProfileEditor(true);
	const handleCloseProfileEditor = () => setOpenProfileEditor(false);

	/**
	 * Carrega dados de perfil do usuário
	 */
	useEffect(() => {
		loadProfile();
		// eslint-disable-next-line
	}, [reload]);

	useEffect(() => {
		loadProfile();
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Dialog
				open={openProfileEditor}
				onClose={handleCloseProfileEditor}
				maxWidth="md"
				fullWidth
			>
				<Box
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box p={2} sx={{ display: "flex", gap: "12px" }}>
						<PersonAdd />
						<Typography variant="body1">
							Cadastrar perfil
						</Typography>
					</Box>
					<Box p={1}>
						<IconButton
							size="small"
							sx={{
								color: "white",
							}}
							onClick={handleCloseProfileEditor}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
				<PerfilForm
					setOpen={setOpen}
					handleCloseProfileEditor={handleCloseProfileEditor}
					setReload={setReload}
				/>
			</Dialog>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<Box
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box p={2} sx={{ display: "flex", gap: "12px" }}>
						<Business />
						<Typography variant="body1">
							Cadastrar experiência Profissional
						</Typography>
					</Box>
					<Box p={1}>
						<IconButton
							size="small"
							sx={{
								color: "white",
							}}
							onClick={handleClose}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
				<ExperienciaProfissionalForm setOpen={setOpen} />
			</Dialog>
			<Grid item md={6}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						jusitifyContent: "flex-start",
						gap: "12px",
					}}
				>
					<Typography variant="h5" sx={{ mb: 2 }}>
						Experiência profissional
					</Typography>
					<IconButton
						onClick={handleOpen}
						size="small"
						sx={{
							bgcolor: theme.palette.primary.main,
							color: "white",
							display:
								auth.role === "admin" ||
								auth.role === "representante_empresa"
									? "none"
									: "",
						}}
					>
						<Add />
					</IconButton>
				</Box>
				<Box
					sx={{
						maxHeight: "43vh",
						overflowY: "scroll",
					}}
				>
					{professionalExperiences &&
					Array.isArray(professionalExperiences) &&
					professionalExperiences.length > 0 ? (
						professionalExperiences.map((h, index) => {
							return (
								<ExperienciaProfissional
									experience={h}
									key={index}
								/>
							);
						})
					) : (
						<Box>
							<Paper
								elevation={1}
								sx={{
									minHeight: "250px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
								}}
							>
								<PlaylistRemove
									sx={{
										width: "64px",
										height: "auto",
										mb: 3,
										color: "gray",
									}}
								/>
								<Typography sx={{ p: 2, textAlign: "center" }}>
									Nenhuma experiência profissional encontrada
								</Typography>
							</Paper>
						</Box>
					)}
				</Box>
			</Grid>
		</Fragment>
	);
}
