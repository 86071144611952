import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalDeleteEmpresaView from "./ModalDeleteEmpresa.view";

function ModalDeleteEmpresa(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalDeleteEmpresaView
				isActive={props.isActive}
				companieId={props.companieId}
				dados={props.dados}
			/>
		</ModalContextProvider>
	);
}

export default ModalDeleteEmpresa;
