import React from "react";
import { ModalContextProvider } from "../../../../context/Modal/modal.context";
import ModalCadastroVagaView from "./ModalCadastroVaga.view";

function ModalCadastroVaga(props): React.ReactElement {
	return (
		<ModalContextProvider>
			<ModalCadastroVagaView isActive={props.isActive} />
		</ModalContextProvider>
	);
}

export default ModalCadastroVaga;
