import {
	Add,
	Bookmark,
	BookmarkRemove,
	Check,
	Close,
	Hail,
} from "@mui/icons-material";
import {
	Box,
	Button,
	Chip,
	IconButton,
	Paper,
	Typography,
} from "@mui/material";
import { Fragment } from "react";

export interface CardItemCardViewInterface {
	item?: any;
	logo?: any;
	breakpoint?: any;
	theme?: any;
	setJob?: any;
	jobForStudentDetails?: any;
	setCandidaturaModalStatus?: any;
	bookmark?: any;
	deleteBookMark?: any;
	sendBookMark?: any;
}

export function HomePageItemCardView({
	item,
	theme,
	setJob,
	jobForStudentDetails,
	setCandidaturaModalStatus,
	bookmark,
	deleteBookMark,
	sendBookMark,
}: CardItemCardViewInterface) {
	return (
		<Fragment>
			<Paper
				className="card"
				elevation={3}
				sx={{
					minHeight: "372px",
					minWidth: "356px",
					width: { xs: "256px", sm: "256px", md: "396px" },
					borderRadius: "12px",
					overflow: "hidden",
					mb: 3,
					position: "relative",
					border:
						item &&
						item.applications &&
						item.applications.length > 0
							? "solid " + theme.palette.secondary.main
							: "none",
				}}
			>
				<Box
					sx={{
						zIndex: 10,
						position: "absolute",
						top: "0px",
						left: "0px",
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							width: "60%",
							display: "flex",
							bgcolor:
								item &&
								item.applications &&
								item.applications.length > 0 &&
								theme.palette.secondary.main,
							// : theme.palette
							// 		.primary
							// 		.main,
							color: "white",
							p: 1,
							borderBottomRightRadius: "24px",
							// display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							gap: "6px",
						}}
					>
						{item &&
							item.applications &&
							item.applications.length > 0 && (
								<Fragment>
									<Check />
									<Typography variant="body2">
										{" "}
										Candidatura Enviada{" "}
									</Typography>
								</Fragment>
							)}
					</Box>
				</Box>
				<Box
					sx={{
						height: "126px",
						background:
							"linear-gradient(to bottom, skyblue, white)",
					}}
				>
					{item.cover_image && (
						<img
							src={item.cover_image}
							alt=""
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "120px",
								objectFit: "cover",
								opacity: ".3",
								filter: "blur(3px)", // Adjust blur amount as needed
								zIndex: 1,
							}}
						/>
					)}
					<Box
						sx={{
							position: "absolute",
							top: "20%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							zIndex: 2,
						}}
					>
						{item.logo_image && (
							<img
								src={item.logo_image}
								alt=""
								height="96px"
								width="auto"
							/>
						)}
					</Box>
				</Box>
				<Box
					sx={{
						height: "225px",
						display: "flex",
						// border: "solid red",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					<Box p={2}>
						<Box
							sx={{
								display: "flex",
								alignItems: "flex-start",
								justifyContent: "space-between",
							}}
						>
							<Typography
								variant="body1"
								component="p"
								style={{
									fontWeight: "500",
									fontSize: "1.1rem",
									width: "80%",
								}}
							>
								{item.title}
								{item &&
								item.contract_type &&
								item.contract_type.name
									? `(${item.contract_type.name})`
									: ""}
							</Typography>
							<IconButton
								// size="small"
								className="bookmark-icon-button"
								sx={{
									mr: 1,
									// backgroundColor: "rgba(255,255,255,.6)",
									// color: "white",
								}}
								onClick={() =>
									(Array.isArray(item.bookmark_student) &&
										item.bookmark_student.length > 0) ||
									bookmark
										? deleteBookMark()
										: sendBookMark()
								}
							>
								{(Array.isArray(item.bookmark_student) &&
									item.bookmark_student.length > 0) ||
								bookmark ? (
									<BookmarkRemove className="remove-bookmark-icon" />
								) : (
									<Bookmark className="add-bookmark-icon" />
								)}
							</IconButton>
						</Box>
						<Box
							sx={{
								display: "none",
								gap: "6px",
								flexWrap: "wrap",
							}}
						>
							<Chip label="Grande área 1" />
							<Chip label="Grande área 2" />
							<Chip label="Grande área 3" />
						</Box>
						<Typography
							variant="body2"
							component="p"
							sx={{ mt: 1 }}
						>
							{item.description.length > 128 ? (
								<>{item.description.slice(0, 96) + "..."}</>
							) : (
								item.description
							)}
						</Typography>
						<Typography
							variant="caption"
							component="p"
							sx={{ mt: 1 }}
						>
							{item.city} - {item.state}
						</Typography>
					</Box>

					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							m: 2,
						}}
					>
						<Button
							variant="text"
							startIcon={<Add />}
							// sx={{ color: "black" }}
							onClick={async () => {
								setJob(item);
								jobForStudentDetails(item, item.id);
							}}
						>
							Detalhes
						</Button>
						<Button
							variant="contained"
							className="apply-button"
							disableElevation
							startIcon={
								item &&
								item.applications &&
								item.applications.length > 0 ? (
									<Close />
								) : (
									<Hail />
								)
							}
							// onClick={handleCandidaturaStatus}
							onClick={() => {
								setJob(item);
								setCandidaturaModalStatus(true);
							}}
						>
							{item &&
							item.applications &&
							item.applications.length > 0
								? "Cancelar"
								: "Candidatar-se"}
						</Button>
					</Box>
				</Box>
			</Paper>
		</Fragment>
	);
}
