import { Avatar } from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBarProps,
	Box,
	IconButton,
	Switch,
	Toolbar,
	styled,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { CSSProperties, Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/Authentication/hooks/useAuth";
import { useTheming } from "../../../context/Theming";
import { useMeuCurriculo } from "../../../pages/Private/MeuCurriculo/context";
import { postLogout } from "../../../services/logout";
import { AppDrawer } from "../AppDrawer";
import { AppMain } from "../AppMain";

const drawerWidth = 296;

/**
 * Appbar do projeto, contendo o Drawer e o Main, container principal do projeto
 */
const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }: any) => ({
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export default function AppHeaderStudent({ children }: any) {
	const { dark, setDark } = useTheming();
	const { auth, setAuth } = useAuthContext();
	const { student } = useMeuCurriculo();
	// alert(JSON.stringify(auth));

	const theme = useTheme();
	const breakpoint = useMediaQuery(theme.breakpoints.down("md"));
	const navigate = useNavigate();
	const [open, setOpen] = useState<boolean>(!breakpoint);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		// Update 'open' state when the breakpoint changes
		setOpen(!breakpoint);
	}, [breakpoint]);

	/**
	 *
	 * @param e Toggle Switch of color theme
	 */
	const handleChange = (e) => {
		setDark(e.target.checked);
	};
	/**
	 * @function handleLogout
	 * Chama a função de postLogout, faz uma mudança de rotas para / e seta valores vazios na propriedade auth do contexto AuthContext.
	 * @returns Retorna o menu navegação.
	 */
	function handleLogout() {
		postLogout();
		if (
			auth.role === "admin" ||
			auth.role === "representante" ||
			auth.role === "representante_empresa"
		) {
			navigate("/login-admin");
		} else {
			navigate("/");
		}
		setAuth({
			role: "",
			type: "",
			token: "",
			expires_at: "",
			user_id: 0,
			student_id: 0,
			has_terms: false,
		});
	}

	const styles: { [key: string]: CSSProperties } = {
		listItem: {
			backgroundColor: theme.palette.primary.main,
			color: "white",
			borderRadius: "5px",
		},
		ListItemIcon: {
			color: "white",
		},
	};
	const location = useLocation();

	useEffect(() => {
		handleDrawerClose();
	}, [location]);

	return (
		<Fragment>
			<AppBar
				elevation={0}
				sx={{
					bgcolor: dark
						? theme.palette.background.paper
						: theme.palette.primary.main,
					// borderBottom: "solid thin #ddd",
				}}
			>
				<Toolbar
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							gap: "12px",
							// padding: "12px 24px",
							padding: "7px 12px",
							backgroundColor: theme.palette.primary.main,
							color: "white",
						}}
					>
						<IconButton
							sx={{
								color: "white",
							}}
							onClick={() => {
								handleDrawerOpen();
							}}
						>
							<MenuIcon />
						</IconButton>
						<img
							src="./img/Logo 06.png"
							alt=""
							height="50"
							style={{ border: "none", left: "-30px" }}
						/>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "12px",
						}}
					>
						{auth.student_id ? (
							<Link to={"/curriculum/" + auth.student_id}>
								<Avatar
									style={{ color: "white" }}
									color="secondary"
									src={student.student.profile_picture}
								>
									{student &&
										student.user &&
										student.user.name &&
										student.user.name[0] &&
										student.user.name[0]}
								</Avatar>
							</Link>
						) : (
							<></>
						)}
						<Switch
							checked={dark}
							onChange={handleChange}
							inputProps={{ "aria-label": "controlled" }}
						/>
						{/* <Button
							endIcon={<ExitToApp />}
							sx={{ color: "white" }}
							onClick={handleLogout}
						>
							Sair
						</Button> */}
					</Box>
				</Toolbar>
				{/* <pre>
					{JSON.stringify(student.user.name[0] as string, null, 4)}
				</pre> */}
			</AppBar>
			<AppDrawer
				open={open}
				drawerWidth={drawerWidth}
				theme={theme}
				navigate={navigate}
				handleDrawerClose={handleDrawerClose}
				auth={auth}
				styles={styles}
				handleLogout={handleLogout}
				menuIcon={MenuIcon}
				location={location}
			/>
			<AppMain open={open}>{children}</AppMain>
		</Fragment>
	);
}
