import { Dispatch, SetStateAction, createContext } from "react";

interface ContextInterface {
	activityGroup: any;
	setActivityGroup: Dispatch<SetStateAction<any>>;
	reloadSignal: boolean;
	setReloadSignal: Dispatch<SetStateAction<boolean>>;
	data: any;
	setData: Dispatch<SetStateAction<any>>;
	fetchPalcoData: () => void;
}

export const PalcoProfissionalRootContext = createContext<ContextInterface>({
	activityGroup: null,
	setActivityGroup: () => {},
	reloadSignal: false,
	setReloadSignal: () => {},
	data: null,
	setData: () => {},
	fetchPalcoData: () => {},
});
