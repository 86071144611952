import { Warning } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import { Usuario } from "../../../../models/Usuario";
import { deleteUsuario } from "../../../../services/crud/crud.usuario";
import ModalHeader from "../../ModalHeader";

function ModalDeleteUsuarioView(props) {
	const navigate = useNavigate();
	let idUsuarioDeletar;
	let usuario: Usuario[];
	const { open, handleOpen, handleClose } = useModalContext();
	const [modalDelete, setModalDelete] = useState(false);

	/**
	 * @function handleNaoEDeletado
	 * Faz uma verifição se o tipo de contrato foi deletado.
	 * @param {Usuario} usuario - Representa cada usuário.
	 * @returns {boolean} - Verifica os contratos que não foram deletados.
	 */
	function handleNaoEDeletado(usuario: Usuario) {
		return usuario.id !== idUsuarioDeletar;
	}

	/**
	 * @functiaz uma mudança de rotas para /empresas
	 */
	function handleUsuario() {
		localStorage.setItem("id_usuario_filter", props.userId.toString());
		navigate(`/empresas`);
	}

	/**
	 * @function handleDeletaUsuario
	 * Salva na variavel de nome usuario o usuário da tabela usuários ao qual o usuário quer deletar e em seguida verifica se a quantidade de empresas do usuário é igual a 0, caso sim será salvo uma chave de nome id_usuario_filter com o valor do id do usuario a ser deletado e abrirá o modal questionando se o usuário deseja ver as empresas existentes, caso não será emitido um Notify para a confirmação da deleção
	 * @param {number} id - Representa o id do usuário
	 */
	function handleDeletaUsuario(id: number) {
		usuario = props.dados.tableRows.usuarios.filter((usuario: Usuario) => {
			return usuario.id === id;
		});
		if (usuario[0].companies.length > 0) {
			handleOpen();
		} else {
			setModalDelete(true);
		}
	}

	function ConfirmDeletaUsuario(id: number) {
		setModalDelete(!modalDelete);
		deleteUsuario(id)
			.then(() => {
				idUsuarioDeletar = id;
				let novoArrayUsuarios =
					props.dados.tableRows.usuarios.filter(handleNaoEDeletado);
				props.dados.setTableRows({ usuarios: novoArrayUsuarios });
			})
			.catch((e) => {
				console.log(e);
			});
	}

	return (
		<>
			<IconButton
				onClick={() => {
					handleDeletaUsuario(props.userId);
				}}
				title={`${props.userId}`}
				data-testid="modal-delete-usuario"
			>
				<DeleteForeverIcon />
			</IconButton>
			<Dialog open={open}>
				<DialogTitle>
					{
						"Você não pode deletar esse usuário caso haja empresa(s) existente(s) no nome dele!"
					}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Deseja ver a(s) empresa(s) existente(s), desse usuário?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "end",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							style={{
								backgroundColor: "#B50000",
								color: "white",
								height: "40px",
								width: "20%",
								marginRight: "20px",
							}}
							onClick={handleClose}
						>
							Não
						</Button>
						<Button
							variant="contained"
							color="primary"
							style={{
								backgroundColor: "#B50000",
								color: "white",
								height: "40px",
								width: "20%",
								marginRight: "20px",
							}}
							onClick={() => {
								handleUsuario();
							}}
						>
							Sim
						</Button>
					</div>
				</DialogActions>
			</Dialog>
			<Dialog open={modalDelete}>
				<ModalHeader header="Excluir usuário" icon={<Warning />} />
				<DialogContent>
					<Typography variant="body1">
						Você realmente deseja deletar este usuário?
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						color="primary"
						onClick={() => {
							setModalDelete(!modalDelete);
						}}
					>
						Não
					</Button>
					<Button
						variant="contained"
						disableElevation
						color="primary"
						onClick={() => {
							ConfirmDeletaUsuario(props.userId);
						}}
					>
						Sim
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
export default ModalDeleteUsuarioView;
