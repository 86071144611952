/**
 * ## MeuCurriculo: Tela de MeuCurriculo do aluno, retorna view com perfil, experiências profissionais e
 * participação em eventos.
 *
 * Este componente não recebe props.
 *
 * ## Exemplo de uso:
 *
 * ```javascript
 * const App = () => {
 *   return <MeuCurriculoView />;
 * };
 * ```
 *
 *
 */

// Importações
import { Container, Grid } from "@mui/material";
import { ModalContextProvider } from "../../../context/Modal/modal.context";
import Eventos from "./components/Eventos";
import ExperienciasProfissionais from "./components/ExperienciasProfissionais";
import MeuPerfil from "./components/Perfil";
import { MeuCurriculoContextProvider } from "./context";

function MeuCurriculo() {
	return (
		<MeuCurriculoContextProvider>
			<ModalContextProvider>
				<Container maxWidth="xl" sx={{ mt: 2 }}>
					<Grid container spacing={2}>
						<MeuPerfil />
						<ExperienciasProfissionais />
						<Eventos />
					</Grid>
				</Container>
			</ModalContextProvider>
		</MeuCurriculoContextProvider>
	);
}

export default MeuCurriculo;
