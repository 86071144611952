import { SessionProvider } from "../../../../context/session/SessionProvider";
import BiometryTemplate from "./biometry.template";

export default function BiometryModule() {
	return (
		<>
			<SessionProvider>
				<BiometryTemplate />
			</SessionProvider>
		</>
	);
}
