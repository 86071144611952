import { Delete, Search, Send, Videocam } from "@mui/icons-material";
import {
	Box,
	Button,
	Checkbox,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	IconButton,
	InputAdornment,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { fileManagerApi } from "../../../pages/Private/PalcoProfissional/filemanager";
import api from "../../../services/api";
import Notification from "../../../utils/notification";
import ModalHeader from "../../Modal/ModalHeader";

/**
 *
 * @param applications
 * @returns tabela de aplicações para vagas
 */
export default function TabelaCandidaturas({ applications, reloadData }: any) {
	const history = useNavigate();
	const [open, setOpen] = useState<boolean>(false);
	const [fullApplications, setFullApplications] = useState<any[]>([]);
	const [video, setVideo] = useState<string | null>(null);
	const [openVideo, setOpenVideo] = useState<boolean>(false);

	const [jobId, setJobId] = useState<any>(0);

	/**
	 * Atualiza status de candidatura do candidato
	 */
	const hireCandidate = async () => {
		if (jobId === 0) {
			toast.error("Atenção! Selecione um candidato para prosseguir");
			return;
		}
		await api
			.put(`/applications/${jobId}/hired`)
			.then((res) => {
				toast.success("Candidatura atualizada com sucesso");
			})
			.catch((err) => {
				console.error(err);
				toast.error("Falha ao atualizar candidatura");
			})
			.finally(() => {
				window.location.reload();
			});
	};

	/**
	 * Atualiza status de candidatura do candidato
	 */
	const fireCandidate = async (id) => {
		if (id === 0) {
			toast.error("Atenção! Selecione um candidato para prosseguir");
			return;
		}
		await api
			.put(`/applications/${id}/hired`)
			.then((res) => {
				toast.success("Candidatura atualizada com sucesso");
			})
			.catch((err) => {
				console.error(err);
				toast.error("Falha ao atualizar candidatura");
			})
			.finally(() => {
				window.location.reload();
			});
	};

	/**
	 *
	 * @param ra
	 * @description busca URL do video do aluno
	 */
	const getVideoCurriculo = async (ra) => {
		await fileManagerApi.get(`/auditions/${ra}/published`).then((res) => {
			setOpenVideo(true);
			setVideo(res.data);
		});
	};

	/**
	 *
	 * @param e valor do nome do usuário
	 */
	const filtraCandidaturas = (e) => {
		if (e.target.value === "" || e.target.value === null) {
			setFullApplications(applications);
		} else {
			const searchTerm = e.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive comparison
			console.clear();
			console.log(searchTerm);
			const filtered = fullApplications.filter((application) =>
				application.student.user.name.toLowerCase().includes(searchTerm)
			);
			console.log(filtered);
			setFullApplications(filtered);
		}
	};

	/**
	 * Define o estado interno da tabela ao inicializar tela de componente
	 */
	useEffect(() => {
		setFullApplications(applications);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applications]);

	return (
		<>
			<Box data-testid="tabela-usuario">
				<Paper>
					<Box>
						<Box
							p={1}
							sx={{
								borderBottom: "solid thin #ddd",
							}}
						>
							<Typography
								variant="body1"
								sx={{ fontWeight: "500" }}
							>
								Candidaturas realizadas
							</Typography>
						</Box>
						<Box
							p={1}
							sx={{
								borderBottom: "solid thin #ddd",
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<TextField
								// label="Search"
								placeholder="Buscar"
								size="small"
								onChange={(e) => {
									filtraCandidaturas(e);
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<IconButton aria-label="search">
												<Search />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Box>
					</Box>

					<TableContainer className="tableContainer">
						<Table stickyHeader aria-label="sticky table">
							<TableHead sx={{ borderTop: "solid thin #ddd" }}>
								<TableRow hover role="checkbox" tabIndex={-1}>
									<TableCell align="left">Cód.</TableCell>
									<TableCell align="left">Vaga</TableCell>
									<TableCell align="left">Nome</TableCell>
									<TableCell align="center">
										Currículo
									</TableCell>
									<TableCell align="center">
										Vídeo de apresentação
									</TableCell>
									<TableCell align="center">E-mail</TableCell>
									<TableCell align="center">
										Telefone
									</TableCell>
									<TableCell align="center">Ações</TableCell>
									<TableCell align="center">
										Notificar candidato
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{fullApplications &&
								fullApplications.length > 0 ? (
									fullApplications.map((c, index) => {
										return (
											<>
												<TableRow key={index}>
													<TableCell align="center">
														{c.id}
													</TableCell>
													<TableCell align="center">
														{c.job_id}
													</TableCell>
													<TableCell align="center">
														{c.student.user.name}{" "}
													</TableCell>
													<TableCell align="center">
														{/* <pre>
														{JSON.stringify(c)}
													</pre> */}
														<a
															href={
																"/curriculum/" +
																c.student_id
															}
															style={{
																textDecoration:
																	"none",
															}}
														>
															<Button
																variant="contained"
																disableElevation
															>
																Acessar
															</Button>
														</a>
														{/* {c.student.user.email} */}
													</TableCell>
													<TableCell align="center">
														<Button
															onClick={() => {
																setOpenVideo(
																	true
																);
																getVideoCurriculo(
																	c.student.ra
																);
															}}
														>
															Reproduzir
														</Button>
														{/* {c.student.user.email} */}
													</TableCell>
													<TableCell align="center">
														{c.student.user.email}
													</TableCell>
													<TableCell align="center">
														{c.student.phone}
													</TableCell>
													<TableCell align="center">
														{/* <IconButton>
															<Search />
														</IconButton> */}
														{/* <IconButton>
														<Edit />
													</IconButton> */}
														<IconButton
															onClick={async () =>
																setOpen(true)
															}
														>
															<Delete />
														</IconButton>
													</TableCell>
													<TableCell align="center">
														<Checkbox disabled />
													</TableCell>
												</TableRow>
												<Dialog
													open={open}
													maxWidth={"md"}
													fullWidth
												>
													<ModalHeader
														header="Exclusão de candidatura"
														icon={<Delete />}
														close={() =>
															setOpen(false)
														}
													/>
													<DialogContent>
														<Typography>
															Você deseja
															prosseguir com a
															exclusão da
															candidatura?
														</Typography>
													</DialogContent>
													<DialogActions>
														<Button
															variant="text"
															onClick={() =>
																setOpen(false)
															}
														>
															Cancelar
														</Button>
														<Button
															variant="contained"
															disableElevation
															onClick={async () => {
																await api
																	.delete(
																		`/applications/${c.id}`
																	)
																	.then(
																		(
																			res: any
																		) => {
																			Notification(
																				"Candidatura removida com sucesso",
																				"success"
																			);
																		}
																	)
																	.catch(
																		(
																			err: any
																		) => {
																			Notification(
																				"Falha ao remover candidatura!",
																				"error"
																			);
																		}
																	)
																	.finally(
																		() => {
																			history(
																				"/candidaturas"
																			);
																		}
																	);
															}}
														>
															Excluir
														</Button>
													</DialogActions>
												</Dialog>
											</>
										);
									})
								) : (
									<TableCell
										colSpan={9}
										rowSpan={4}
										align="center"
									>
										<Typography
											variant="body1"
											sx={{
												height: "100px",
												textAlign: "center",
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
											}}
										>
											Nenhuma candidatura encontrada
										</Typography>
									</TableCell>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Box>
			<Box
				mt={1}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
				}}
			>
				<Button startIcon={<Send />} variant="contained">
					Enviar notificação
				</Button>
			</Box>
			<Box mt={5}>
				<Typography sx={{ textAlign: "center" }}>
					Indicar candidato contrato e concluir ocupação da vaga
				</Typography>

				<Box mb={2} mt={2}>
					{/* <pre>{JSON.stringify(fullApplications, null, 4)}</pre> */}

					<FormControl sx={{ width: "60%" }}>
						<Select
							name="name"
							value={jobId}
							onChange={(e) => setJobId(e.target.value)}
						>
							<MenuItem value={0}>
								{fullApplications &&
								fullApplications.length > 0 ? (
									fullApplications.map((opportunity: any) => {
										return opportunity.hired === 1 ? (
											<>
												<Chip
													label={
														opportunity.student.user
															.name
													}
													style={{
														backgroundColor:
															"#F29E87",
														color: "black",
													}}
													onDelete={async () => {
														// alert(
														// 	JSON.stringify(
														// 		opportunity,
														// 		null,
														// 		4
														// 	)
														// );
														fireCandidate(
															opportunity.id
														);
													}}
												/>
											</>
										) : (
											<>Selecionar Candidato</>
										);
									})
								) : (
									<>Selecionar candidato</>
								)}
							</MenuItem>
							{fullApplications && fullApplications.length > 0 ? (
								fullApplications.map((opportunity: any) => {
									return (
										<MenuItem value={opportunity.id}>
											{opportunity.student.user.name}
										</MenuItem>
									);
								})
							) : (
								<></>
							)}
						</Select>
					</FormControl>
				</Box>
				<Button
					variant="contained"
					disableElevation
					onClick={hireCandidate}
				>
					Concluir ocupação da vaga
				</Button>
			</Box>
			<Dialog open={openVideo} fullWidth maxWidth="md">
				<ModalHeader
					header="Videocurrículo"
					icon={<Videocam />}
					close={() => setOpenVideo(false)}
				/>
				<DialogContent>
					<Box mb={2}>
						<Typography variant="h5">Reprodutor</Typography>
						<Typography variant="body1">
							Apresentando o vídeo currículo do aluno
						</Typography>
					</Box>
					<video
						src={video as string}
						style={{
							width: "100%",
							height: "auto",
							borderRadius: "12px",
						}}
						controls
						autoPlay
						muted
					/>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						disableElevation
						onClick={() => {
							setOpenVideo(false);
						}}
					>
						Fechar player
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
