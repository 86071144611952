import axios, { AxiosInstance } from "axios";

export default class AuditionService {

	constructor(private api: AxiosInstance) {
		this.api = axios.create({
			baseURL: process.env.REACT_APP_FILE_MANAGER_API
		})

		this.api.interceptors.request.use((config) => {
			let user: any = JSON.parse(localStorage.getItem("@palco-profissional:token") as string).data.usuario.id;
			let token: any = JSON.parse(localStorage.getItem("@palco-profissional:token") as string).data.accessToken;

			if (token && config.headers) {

				config.headers["Authorization"] = `Bearer ${token}`;
				config.headers["ra"] = user;

			}

			return config;
		});
	}

	async create(data: any) {
		return await this.api.post("/auditions", data)
	}

	async update(id: number | string, data: any) {
		return await this.api.put("/auditions/" + id, data)
	}

	async checkFileExists(key: any) {
		return await this.api.get("/s3/check-file",{
			params: {
				key: key
			}
		})
	}

	async getActivity(id: any) { 
		return await this.api.get(`/activities/${id}`, {
			params: {
				item: 1,
				autidion: 1
			}
		})
	}
}