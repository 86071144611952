import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { EditNote } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useModalContext } from "../../../../context/Modal/hooks/useModal";
import FormularioAlteracaoVaga from "../../../Formularios/Alteracoes/FormularioAlteracaoVaga";
import ModalHeader from "../../ModalHeader";

function ModalAlteracaoVagaView(props: any) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down(700));
	const lg = useMediaQuery(theme.breakpoints.up("sm"));
	const { open, handleOpen } = useModalContext();

	/**
	 * @function handleAtualizarVaga
	 * Abre o modal de FormularioAlteracaoVaga e seta um valor no sessionStorage com a chave de nome id_vaga.
	 * @param {number} id - Representa o id da vaga.
	 */
	function handleAtualizarVaga(id: number) {
		handleOpen();
		localStorage.setItem("id_vaga", id.toString());
	}

	return (
		<>
			<IconButton
				onClick={() => {
					handleAtualizarVaga(props.vagaId);
				}}
				title="Alterar"
				data-testid="modal-alteracao-vaga"
			>
				<BorderColorIcon style={{ color: "#292B2D" }} />
			</IconButton>

			<Dialog
				fullScreen={fullScreen}
				fullWidth
				maxWidth={lg === true ? "lg" : false}
				open={open}
				// onClose={handleClose}
			>
				{/* <pre>{JSON.stringify(props, null, 4)}</pre> */}
				<ModalHeader header="Editar vaga" icon={<EditNote />} />
				<DialogContent>
					<DialogContentText>
						<FormularioAlteracaoVaga vaga={props.vaga} />
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
}
export default ModalAlteracaoVagaView;
